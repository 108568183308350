import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomMuiDropdown from '../../CustomMuiDropdown';

const CompanyCapitalStructureSelection = props => {
  const {
    value,
    name = '',
    label = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    // selectedList,
    //setEmailList = () => { },
    required = false,
    defaultValue,
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const companyCapitalStructureOption = useSelector(state => state.commonReducer.companyCapitalStructure);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyCapitalStructureOption) {
      dispatch(fetchPickLists('COMPANY_CAPITAL_STRUCTURE', 'companyCapitalStructure'));
    } else {
      setOptions(companyCapitalStructureOption);
    }
  }, [companyCapitalStructureOption, dispatch]);

  return (
    <div>
      <CustomMuiDropdown
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return options?.find(item => item?.field_value === option)?.short_desc || '';
          }
          return option?.short_desc || '';
        }}
        defaultValue={defaultValue}
        {...rest}
        value={value}
        options={options}
        name={name}
        onChange={onChange}
        isDrawer={isDrawer}
        disabled={disabled}
        label={label}
        required={required}
      />
    </div>
  );
};
CompanyCapitalStructureSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  setEmailList: PropTypes.func,
  required: PropTypes.bool,
  defaultValue: PropTypes.object
};

export default CompanyCapitalStructureSelection;
