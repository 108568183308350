import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Unauthorized from './Containers/Commons/Unauthorized';
import JobBoard from './Containers/JobBoard/JobBoard';
import { PUBLIC_BASE_ROUTES, PUBLIC_SUB_ROUTES } from './services/constantService';

function PublicRouters() {
  const JobBoardWrap = () => {
    return (
      <div className='content-wrapper2 d-flex w-100'>
        <div className='main-content'>
          <Outlet />
        </div>
      </div>
    );
  };

  return (
    <Routes>
      <Route path={PUBLIC_BASE_ROUTES.unauthorized} element={<Unauthorized />} />

      <Route element={<JobBoardWrap />}>
        <Route path={PUBLIC_BASE_ROUTES.jobBoard} element={<JobBoard />} />
        <Route path={PUBLIC_SUB_ROUTES.jobBoardWithId} element={<JobBoard />} />
      </Route>
    </Routes>
  );
}

export default PublicRouters;
