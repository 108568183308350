import CloseIcon from '@mui/icons-material/Close';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FileDragUpload from '../../../../components/common/FileUpload';
import { showToast } from '../../constants';
import { processTranscriptData } from '../../helpers';

const RequestFileUploadManager = props => {
  const { audioUploadRes = null, fileType = null, setFile = () => {}, setFileType = () => {}, setAudioUploadRes = () => {}, file = null, setSpeakerData } = props;
  const { t } = useTranslation();
  const handleJsonSelect = file => {
    const reader = new FileReader();
    reader.onload = event => {
      try {
        let speakerData;
        if (file.type === 'text/vtt' || file.type === 'application/octet-stream') {
          const vttResponse = processTranscriptData(event.target.result, file.type);
          speakerData = vttResponse.speakerData;
        } else {
          const json = JSON.parse(event.target.result);
          const jsonResponse = processTranscriptData(json, file.type);
          speakerData = jsonResponse.speakerData;
        }
        setFile(file);
        setFileType('transcript');
        setAudioUploadRes(null);
        setSpeakerData(speakerData);
      } catch (error) {
        showToast('Invalid File Contents', 'error');
      }
    };

    reader.readAsText(file);
  };
  return (
    <>
      <Box className={`${audioUploadRes && fileType == 'audio' ? 'col-12 text-center my-2' : file && fileType == 'transcript' ? 'd-none' : 'col-6'} fs-15`}>
        <Box className='d-flex flex-column'>
          {t('echoSense.uploadFile')}
          <br />
          {/* <Box className='d-flex flex-row fs-12'>
            <strong>{t('utils.help')}</strong>: {t('echoSense.ignyteIntelligenceDashboardLink')},
            <a className='custom-link ml-2' href='/ignyte-Intelligence' target='_blank' rel='noreferrer'>
              click here
            </a>
          </Box> */}
        </Box>
      </Box>
      <Box className={`${audioUploadRes && fileType == 'audio' ? 'col-12 text-center' : 'col-6 text-right'} fs-14 `}>
        <Box display='flex' justifyContent='flex-end' gap={2}>
          <Button className='text-transform-none' disabled={fileType == 'transcript'} style={{ width: 200 }}>
            <FileDragUpload
              disabled={fileType == 'transcript'}
              types={['MP3', 'WAV', 'OGG', 'M4A']}
              label={'Audio File'}
              handleChangeDocument={file => {
                setFile(() => file);
                setFileType('audio');
                setAudioUploadRes(null);
              }}
              width={200}
              showFileName={false}
            />
          </Button>
          <Button className='text-transform-none' disabled={fileType == 'audio'} style={{ width: 200 }}>
            <FileDragUpload disabled={fileType == 'audio'} types={['json', 'vtt']} maxSize={15} label={'Transcript File'} handleChangeDocument={handleJsonSelect} width={200} showFileName={false} />
          </Button>
        </Box>
        <Box className={'mx-3 fs-14'}>
          {file && !audioUploadRes && (
            <>
              <b>File Name:</b> {file.name}{' '}
              <button
                className='btn'
                onClick={() => {
                  setFile(null);
                  setFileType(null);
                }}
              >
                <CloseIcon fontSize='fs-15' />
              </button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

RequestFileUploadManager.propTypes = {
  audioUploadRes: PropTypes.object,
  setFile: PropTypes.func,
  setFileType: PropTypes.func,
  setAudioUploadRes: PropTypes.func,
  fileType: PropTypes.string,
  file: PropTypes.object,
  setSpeakerData: PropTypes.func
};

export default RequestFileUploadManager;
