import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function AddContactDrawerAccordion(props) {
  const { title, children, expand, setExpand, addTitle, onClickAdd, loading, visible, globalLoading, isPersonalityTab = false, onSave, onSaveTitle } = props;
  const [saveLoading, setSaveLoading] = useState(false);

  return (
    <Accordion
      sx={{
        width: '100%',
        borderRadius: isPersonalityTab ? '0px !important' : '12px !important',
        '&:before': {
          display: 'none'
        },
        boxShadow: isPersonalityTab ? '0px 0px 0px 0px rgba(0, 0, 0, 0)' : '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        display: visible ? 'block' : 'none'
      }}
      disableGutters
      square={false}
      expanded={expand}
      onChange={e => {
        setExpand(e.target.value);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize='large' />}
        sx={{
          bgcolor: 'background.white',
          borderRadius: '12px',
          height: '60px',
          '&.Mui-expanded': {
            borderRadius: '12px 12px 0 0'
          },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: 0
          }
        }}
      >
        <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} width={'100%'}>
          <Typography color={'primary.main'} fontWeight={'bold'} className='fs-14'>
            {title}
          </Typography>
          {addTitle && (
            <Button
              onClick={e => {
                e.stopPropagation();
                onClickAdd();
              }}
              sx={{
                padding: 0,
                // marginLeft: 'auto',
                textDecoration: 'underline',
                fontStyle: 'italic',
                zIndex: 10
              }}
            >
              +{addTitle || 'Add'}
            </Button>
          )}
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          {onSave && (
            <Button
              sx={{
                padding: 0,
                textDecoration: 'underline',
                fontStyle: 'italic',
                zIndex: 10,
                marginRight: '10px',
                width: '100px'
              }}
              onClick={async e => {
                e.stopPropagation();
                setSaveLoading(true);
                await onSave?.();
                setSaveLoading(false);
              }}
              startIcon={<SaveIcon fontSize='small' />}
              disabled={saveLoading}
            >
              {onSaveTitle || 'Save'}
            </Button>
          )}
          {loading || saveLoading ? <CircularProgress size={20} sx={{ marginLeft: 'auto', marginRight: '10px' }} /> : null}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ borderRadius: '0 0 12px 12px' }}>{globalLoading ? <Skeleton variant='rounded' width={'100%'} height={'80px'} /> : children}</AccordionDetails>
    </Accordion>
  );
}

export default AddContactDrawerAccordion;

AddContactDrawerAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expand: PropTypes.bool,
  setExpand: PropTypes.func,
  addTitle: PropTypes.string,
  onClickAdd: PropTypes.func,
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  globalLoading: PropTypes.bool,
  isPersonalityTab: PropTypes.bool,
  onSave: PropTypes.func,
  onSaveTitle: PropTypes.string,
  id: PropTypes.string
};
