import { Grid, TextField } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import { ignWorkbenchQueryApi, picklistsDataApis } from '../../../../services/ApiService'; // Add API service for fetching queries
import { ERROR, GET, POST, SUCCESS } from '../../../../services/constantService';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { WorkBenchContext } from '../../Context';

function AddQueryModal() {
  const { addQueryModalOpen, setAddQueryModalOpen, setQueryId, refetchFunc } = useContext(WorkBenchContext);
  const [selectedType, setSelectedType] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [workbenchType, setWorkbenchType] = useState([]);

  // Fetch dropdown values for type and operations
  const fetchDropDownValues = async () => {
    try {
      const res = await picklistsDataApis(GET, 'WORKBENCH_TYPE');
      if (res?.data) {
        setWorkbenchType(res.data);
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error fetching dropdown values', { variant: ERROR });
    }
  };

  useEffect(() => {
    fetchDropDownValues();
  }, []);

  const handleTypeChange = value => {
    setSelectedType(value);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const resetForm = () => {
    setSelectedType('');
    setDescription('');
    setName('');
  };

  const onClose = () => {
    resetForm();
    setAddQueryModalOpen(false);
  };

  const handleSave = async () => {
    if (!selectedType?.trim()) {
      enqueueSnackbar('Type is required.', { variant: ERROR });
      return;
    }
    if (!name?.trim()) {
      enqueueSnackbar('Name is required.', { variant: ERROR });
      return;
    }

    const payload = {
      query_type: selectedType,
      query_description: description,
      query_name: name
    };
    try {
      const { data: response } = await ignWorkbenchQueryApi(POST, payload);
      setQueryId(response?.query?.id);
      resetForm();
      refetchFunc();
      setAddQueryModalOpen(false);
      enqueueSnackbar('Workbench Query created successfully', { variant: SUCCESS });
    } catch (err) {
      enqueueSnackbar(err.message || 'Error saving query', { variant: ERROR });
    }
  };

  return (
    <CustomModalWithHeader label={t('utils.addQuery')} closeIcon={true} isOpen={addQueryModalOpen} onClose={onClose} maxWidth='md'>
      <div style={{ marginTop: '24px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomDropdown
              label={t('utils.listType')}
              options={workbenchType}
              // value={selectedType}
              onChange={(e, value) => handleTypeChange(value.field_value)}
              fullWidth
              required
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label={t('utils.listName')} value={name} onChange={event => setName(event.target.value)} fullWidth variant='outlined' required />
          </Grid>
          <Grid item xs={12}>
            <TextField label={t('utils.description')} value={description} onChange={handleDescriptionChange} fullWidth variant='outlined' multiline rows={2} />
          </Grid>
          <Grid item xs={12}>
            <div className='w-100 d-flex justify-content-end align-items-center mt-2'>
              <CustomButton type='tertiary-error' variant='text' buttonText={t('actions.cancel')} onClick={onClose} style={{ marginRight: '12px' }} />
              <CustomButton buttonText={t('utils.addQuery')} disabled={!selectedType || !name} onClick={handleSave} />
            </div>
          </Grid>
        </Grid>
      </div>
    </CustomModalWithHeader>
  );
}

export default AddQueryModal;
