import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function EchoSenseControls(props) {
  const { subRoute = 'transcribe', setOpenCreateNewRequest, navigateTo } = props;
  const { t } = useTranslation();
  return (
    <Stack direction='row' justifyContent={'space-between'} alignItems={'baseline'}>
      <Box>
        <Button
          className='text-transform-none'
          variant='contained'
          color={`${subRoute == 'analyze' ? 'primary' : 'titleBarBackground'}`}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          size='large'
          onClick={() => navigateTo('analyze')}
        >
          Analyze
        </Button>
        <Button
          className='text-transform-none'
          variant='contained'
          color={`${subRoute == 'transcribe' ? 'primary' : 'titleBarBackground'}`}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          size='large'
          onClick={() => navigateTo('transcribe')}
        >
          Transcribe
        </Button>
        <span className='mx-2'>
          <Tooltip
            title={
              <React.Fragment>
                <p style={{ whiteSpace: 'pre-line' }}>
                  {t('echoSense.info')}{' '}
                  <a className='custom-link' href='https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0057886' target='_blank' rel='noreferrer'>
                    Link
                  </a>
                </p>
              </React.Fragment>
            }
            arrow
            placement='right'
          >
            <InfoIcon color='primary' />
          </Tooltip>
        </span>
      </Box>
      <Box>
        <Button className='text-transform-none' variant='contained' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} size='large' onClick={() => setOpenCreateNewRequest(true)}>
          {t('echoSense.newRequest')}
        </Button>
      </Box>
    </Stack>
  );
}

EchoSenseControls.propTypes = {
  subRoute: PropTypes.string,
  setOpenCreateNewRequest: PropTypes.func,
  navigateTo: PropTypes.func
};
