import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, Checkbox, ClickAwayListener, Grid, Stack, TableCell, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GET, IGN_API, IGNYTE_CONSTANTS, LINKEDIN_URL_REGEX_COMPANY, MAX_URL_LENGTH, URL_LENGTH_VALIDATION_MESSAGE, URL_VALIDATION_MESSAGE } from '../../../../services/constantService';
import { ExpandableSection } from '../../../ActivityLog/Components/ExpandableSection';
import CurrencyUnitSelection from '../../../common/CurrencyUnitSelection';
import CustomTable from '../../../common/CustomTable';
import CountrySelection from '../../../common/FunctionalComponents/CountrySelection';
import CurrencySelection from '../../../common/FunctionalComponents/CurrencySelection';
import StateSelection from '../../../common/FunctionalComponents/StateSelection';
import MakeConfidential from '../../../common/MakeConfidential/MakeConfidential';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import '../CompanyDetails/index.scss';

import { useEffect } from 'react';
import CustomPhoneInput, { formatPhoneNumber, isValidPhoneNumber } from '../../../../components/common/CustomPhoneInput';
import ValidatePermit from '../../../../security/ValidatePermit';
import { getMethodWithCancelTokenApi, LocationSetupApi } from '../../../../services/ApiService';
import CompanyAddressTypeSelection from '../../../common/FunctionalComponents/CompanyAddressTypeSelection';
import CompanyCapitalStructureSelection from '../../../common/FunctionalComponents/CompanyCapitalStructureSelection';
import CompanyEmailTypeSelection from '../../../common/FunctionalComponents/CompanyEmailTypeSelection';
import CompanyPhoneTypeSelection from '../../../common/FunctionalComponents/CompanyPhoneTypeSelection';
import GeoLocationInput from '../../../common/GeoLocation';
import RichText from '../../../common/RichText';
import { validateEmailV1 as validateEmail } from '../../../MessageTemplatePopup/utils';

const ViewCompanyProfile = props => {
  const { renderActions, data, updateField, detailsData, register, unregister, setValue = () => {}, isHeaderNav } = props;
  const edit = {
    primaryPhoneNumber: false,
    secondaryPhoneNumber: false,
    primaryEmail: false,
    secondaryEmail: false,
    company_address: true,
    linkedin_url: false,
    revenueRange: false,
    employeeRange: false,
    capital_structure: false,
    ticker_symbol: false,
    comments: false,
    investor_information: false,
    external_id: false
  };
  let phoneNumber = '123456789';
  const [country, setCountry] = useState();
  const [defaultPhoneNumbers, setDefaultPhoneNumbers] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  const [, /* isMakeAddressPrimary */ setIsMakeAddressPrimary] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(true);
  const [defaultEmails, setDefaultEmails] = useState([]);
  const [emails, setEmails] = useState([]);
  const [email /* GIT STS */] = useState('souravvinod@gmail.com');
  const [tempEmail /* setTempEmail */] = useState(email);
  const [workAddress /* setworkAddress */] = useState('XYZ XYZ XYZ');
  const [phoneList, setPhoneList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [editing, setEditing] = useState(edit);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [tempWorkAddress /* setTempWorkAddress */] = useState(workAddress);
  const [isEditingEmail, setIsEditingEmail] = useState(true);
  const [showInvestorInformation, setShowInvestorInformation] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(true);
  const [isShowDoneIconForAddress, setIsShowDoneIconForAddress] = useState(false);
  const [tempPhoneNumber /* setTempPhoneNumber */] = useState(phoneNumber);
  const [, /*selectedOption*/ setSelectedOption] = useState(
    data?.company_address?.map((ele, index) => {
      return ele.is_primary === true ? index : null;
    })
  );

  let address = {
    address_lines: '',
    address_type: null,
    checked: true,
    city: '',
    company_country: null,
    state: '',
    zip_code: '',
    metropolitan_area: '',
    errors: {
      address_type: false,
      company_country: false
    }
  };

  const [addressDetails, setAddressDetails] = useState([address]);
  const [defaultAddressDetails, setDefaultAddressDetails] = useState([address]);

  const [linkedInErrorMessage, setLinkedInErrorMessage] = useState();
  const [islengthLinkedInError, setIslengthLinkedInError] = useState(false);
  const [linkedInUrl, setLinkedInUrl] = useState();
  useEffect(() => {
    register('company_address');
    register('company_phones');
    register('company_emails');
    register('employee_range_from');
    register('employee_range_to');
    register('currency_code');
    register('revenue_range_from');
    register('revenue_range_to');
    register('currency_unit');
    register('linkedin_url');
    register('capital_structure');
    register('ticker_symbol');
    register('inverstor_information');
    register('comments');
    register('external_id');
    // register("makePrimary")
    return () => {
      unregister('company_address');
      unregister('company_phones');
      unregister('company_emails');
      unregister('employee_range_from');
      unregister('employee_range_to');
      unregister('currency_code');
      unregister('revenue_range_from');
      unregister('revenue_range_to');
      unregister('currency_unit');
      unregister('linkedin_url');
      unregister('capital_structure');
      unregister('ticker_symbol');
      unregister('inverstor_information');
      unregister('comments');
      unregister('external_id');
      // unregister("makePrimary")
    };
  }, [register, unregister]);
  const validateLinkedInURL = value => {
    if (!value) return true;
    if (value.length > MAX_URL_LENGTH) {
      setIslengthLinkedInError(true);
      return false;
    }
    if (!LINKEDIN_URL_REGEX_COMPANY.test(value)) {
      setIslengthLinkedInError(false);
      return false;
    }
    return true;
  };
  useEffect(() => {
    const capitalStructure = detailsData?.capital_struct_trans_value?.short_desc;
    setShowInvestorInformation(capitalStructure === 'VC' || capitalStructure === 'PE');
  }, [detailsData?.capital_struct_trans_value?.short_desc]);

  const handleCapitalStructureChange = (e, value) => {
    setValue('capital_structure', value?.field_value ? value?.field_value : '');
    setShowInvestorInformation(value?.short_desc === 'VC' || value?.short_desc === 'PE');
  };

  useEffect(() => {
    const isValid = validateLinkedInURL(linkedInUrl);
    if (!isValid) {
      islengthLinkedInError ? setLinkedInErrorMessage(URL_LENGTH_VALIDATION_MESSAGE) : setLinkedInErrorMessage(URL_VALIDATION_MESSAGE);
    } else {
      setLinkedInErrorMessage('');
    }
  }, [linkedInUrl]);
  useEffect(() => {
    setValue('company_emails', emails);
    setValue('company_phones', phoneNumbers);
    // setValue("contact_relation", relationData);
  }, [phoneNumbers, emails /* , relationData */]);
  useEffect(() => {
    if (detailsData?.company_emails && detailsData?.company_emails.length) {
      setEmails(detailsData?.company_emails?.map(email => ({ ...email, is_valid: validateEmail(email?.email || ''), is_type_selected: email?.email_type !== '' })));
      setDefaultEmails(detailsData?.company_emails?.map(email => ({ ...email, is_valid: validateEmail(email?.email || ''), is_type_selected: email?.email_type !== '' })));
    }
    if (detailsData?.company_phones && detailsData?.company_phones.length) {
      setPhoneNumbers(detailsData?.company_phones?.map(phone => ({ ...phone, is_valid: isValidPhoneNumber(phone?.phone_number || ''), is_type_selected: phone?.phone_type !== '' })));
      setDefaultPhoneNumbers(detailsData?.company_phones?.map(phone => ({ ...phone, is_valid: isValidPhoneNumber(phone?.phone_number || ''), is_type_selected: phone?.phone_type !== '' })));
    }

    setSelectedOption(
      detailsData?.company_address?.map((ele, index) => {
        return ele.is_primary === true ? index : null;
      })
    );
    setIsMakeAddressPrimary(
      detailsData?.company_address?.map(ele => {
        return ele.is_primary;
      })
    );
    if (detailsData?.company_address && detailsData?.company_address.length) {
      const updatedAddresses = detailsData?.company_address?.map(ele => {
        let address = {
          address_lines: ele.address_lines,
          address_type: ele.ign_translate_value,
          checked: ele.is_primary ? ele.is_primary : false,
          city: ele.city,
          company_country: ele.company_country,
          state: ele.company_state,
          zip_code: ele.zip_code,
          metropolitan_area: ele.metropolitan_area,
          errors: {
            address_type: false,
            company_country: false
          }
          //id: ele.id
        };
        return address;
      });
      setAddressDetails(updatedAddresses);
      setDefaultAddressDetails(updatedAddresses);
    }
  }, [detailsData]);
  useEffect(() => {
    setValue(
      'company_address',
      addressDetails?.map(ele => {
        let address = {
          address_lines: ele?.address_lines,
          address_type: ele?.address_type?.field_value,
          city: ele?.city,
          //company_id: data?.id,
          country_id: ele?.company_country?.id,
          is_primary: addressDetails.length === 1 || ele?.checked,
          metropolitan_area: ele?.metropolitan_area,
          state_id: ele?.state?.id,
          zip_code: ele?.zip_code,
          errors: ele.errors || {
            address_type: false,
            company_country: false
          }
        };
        // if (ele.id) {
        //   return { ...address, id: ele.id }
        // }
        return address;
      })
    );
  }, [addressDetails]);
  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { phone_type: '', phone_number: '', is_primary: phoneNumbers.length === 0, is_valid: true, is_type_selected: true }]);
  };
  const handleEditPhoneChange = value => {
    if (phoneNumbers.length === 0) handleAddPhoneNumber();
    setIsEditingPhone(value);
  };
  const handleAddEmail = () => {
    setEmails([...emails, { email_type: '', email: '', is_primary: emails.length === 0, is_valid: true, is_type_selected: true }]);
  };

  const handleEditEmailChange = value => {
    if (emails.length === 0) handleAddEmail();
    setIsEditingEmail(value);
  };

  const handleClickAway = type => {
    if (
      type === 'capital_structure' ||
      type === 'ticker_symbol' ||
      type === 'comments' ||
      type === 'linkedin_url' ||
      type === 'revenueRange' ||
      type === 'employeeRange' ||
      type === 'investor_information' ||
      type === 'external_id'
    ) {
      handleCloseClick(type);
    }
  };

  const handleEditAddressChange = value => {
    setIsEditingAddress(value);
    setEditing({ ...editing, company_address: value });
  };
  const handleRemovePhoneNumber = index => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.splice(index, 1);
    if (updatedPhoneNumbers?.length === 1) {
      const primaryPhone = updatedPhoneNumbers.map(number => {
        return { ...number, is_primary: true };
      });
      setPhoneNumbers(primaryPhone);
    } else {
      setPhoneNumbers(updatedPhoneNumbers);
    }
  };
  const handleRemoveEmail = index => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    if (updatedEmails?.length === 1) {
      const primaryEmail = updatedEmails.map(email => {
        return { ...email, is_primary: true };
      });
      setEmails(primaryEmail);
    } else {
      setEmails(updatedEmails);
    }
  };
  const getAddress = () => {
    let companyAddress = detailsData?.company_address?.map(ele => {
      let address = {};
      const addressArray = [ele?.address_lines || '', ele?.metropolitan_area || '', ele?.city || '', ele?.company_state?.name || '', ele?.company_country?.name || '', ele?.zip_code || ''];
      const newAddress = addressArray.filter(item => item !== '');
      const commaSeparatedAddress = newAddress.join(', ');
      address.address_type = ele.ign_translate_value ? ele.ign_translate_value.short_desc : '';
      address.addressLine = commaSeparatedAddress;
      return address;
    });
    return companyAddress;
  };
  const getRevenueRange = () => {
    const currency = detailsData?.currency_code;
    const unit = detailsData?.currency_unit_trans_value?.short_desc;
    const fromRange = detailsData?.revenue_range_from || '';
    const toRange = detailsData?.revenue_range_to || '';
    if (!fromRange && !toRange) {
      return '-';
    }
    let sign = '';
    if (fromRange && toRange) {
      sign = '-';
    }
    const revenue = [fromRange, sign, toRange];
    if (fromRange || toRange) {
      revenue.unshift(currency);
      revenue.push(unit);
    }
    const revenueRange = revenue.join(' ');
    return revenueRange;
  };
  const getEmployeeRange = () => {
    const fromRange = detailsData?.employee_range_from || '';
    const toRange = detailsData?.employee_range_to || '';
    if (!fromRange && !toRange) {
      return '-';
    }
    let sign = '';
    if (fromRange && toRange) {
      sign = '-';
    }
    const employeeRange = `${fromRange} ${sign} ${toRange}`;
    return employeeRange;
  };
  const handleEditClick = type => {
    if (type === 'company_address') {
      setIsEditingAddress(!isEditingAddress);
    }
    const typeToStateMap = {
      primaryPhoneNumber: {
        tempState: tempPhoneNumber,
        editKey: 'primaryPhoneNumber'
      },
      secondaryPhoneNumber: {
        tempState: tempPhoneNumber,
        editKey: 'secondaryPhoneNumber'
      },
      inverstor_information: { tempState: tempEmail, editKey: 'inverstor_information' },
      primaryEmail: { tempState: tempEmail, editKey: 'primaryEmail' },
      secondaryEmail: { tempState: tempEmail, editKey: 'secondaryEmail' },
      company_address: { tempState: tempWorkAddress, editKey: 'company_address' }
    };

    if (type in typeToStateMap) {
      const { editKey } = typeToStateMap[type];
      setEditing(prevState => ({ ...prevState, [editKey]: true }));
    } else {
      setEditing(prevState => ({ ...prevState, [type]: true }));
    }
  };
  const addAddressForm = () => {
    const newList = [
      ...addressDetails,
      {
        company_country: null,
        state: '',
        zip_code: '',
        city: '',
        metropolitan_area: '',
        address_lines: '',
        address_type: null,
        checked: false,
        errors: {
          company_country: false,
          address_type: false
        }
      }
    ];
    setAddressDetails(newList);
  };
  const hadlechangeForCheckbox = (e, index) => {
    // setIsMakeAddressPrimary(e.target.checked)
    const hasPrimaryAddress = addressDetails.some(address => address.checked);
    const newAddressDetails = addressDetails.map((address, i) => {
      if (i === index) {
        if (!hasPrimaryAddress && i === 0) {
          return { ...address, checked: true };
        }
        return { ...address, checked: true };
      } else {
        return { ...address, checked: false };
      }
    });
    setAddressDetails(newAddressDetails);
  };
  const removeAddress = index => {
    let details = [...addressDetails];
    details.splice(index, 1);
    const hasPrimaryAddress = details.some(address => address.checked);
    const updatedDetails = details.map((ele, i) => {
      if (!hasPrimaryAddress && i === 0) {
        return { ...ele, checked: true };
      } else {
        return ele;
      }
    });
    setAddressDetails(updatedDetails);
  };

  const validateEmailAndType = (field, newValue = '', email) => {
    let is_valid = email.is_valid;
    let is_type_selected = email.is_type_selected;

    if (field === 'email') {
      is_valid = validateEmail(newValue);
    }
    if (field === 'email_type') {
      is_type_selected = newValue !== '';
    }

    return {
      is_valid,
      is_type_selected
    };
  };

  const handleChangeEmail = (index, field, value) => {
    const updatedEmail = emails.map((email, i) => {
      if (i === index) {
        const validateFields = validateEmailAndType(field, value, email);
        if (field === 'is_primary') {
          return { ...email, is_primary: true, ...validateFields };
        }
        return { ...email, [field]: value, ign_translate_value: value, ...validateFields };
      }
      if (i !== index && field === 'is_primary') {
        return { ...email, is_primary: false };
      }

      return { ...email };
    });

    setEmails(updatedEmail);
  };

  const validateAddress = (addressDetails, index, name) => {
    const updatedAddressDetails = [...addressDetails];
    const validations = {
      address_type: true,
      company_country: true
    };

    if (validations[name]) {
      updatedAddressDetails[index] = {
        ...updatedAddressDetails[index],
        errors: {
          ...updatedAddressDetails[index].errors,
          [name]: !updatedAddressDetails[index][name]
        }
      };
    }

    return updatedAddressDetails;
  };

  const handleChangeAddressData = async (index, name, data) => {
    let newList = [...addressDetails];
    newList[index] = { ...newList[index], [name]: data };
    const updatedList = [...newList];
    const validatedAddress = validateAddress(updatedList, index, name);
    setAddressDetails(validatedAddress);

    // Fetch location data when metropolitan area changes
    if (name === 'metropolitan_area') {
      await fetchData(index, data, true);
    }
  };

  const getCompanyAddress = () => {
    const companyAddress = getAddress();
    if (!companyAddress || companyAddress.length === 0) {
      return '-';
    }
    return (
      <>
        <Stack
          direction='column'
          spacing={2}
          // className="main-sub-content-closed ml-1 d-flex align-items-center"
        >
          {companyAddress.map((ele, index) => (
            <Grid container key={index}>
              <Grid item xs={1.5}>
                <Box>{ele.address_type}</Box>
              </Grid>
              <Grid item xs={10.5}>
                <Box className='d-flex'>
                  <Box>:</Box>
                  <Box className='ml-2'>{ele.addresLine}</Box>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </>
    );
  };

  const validatePhoneNumberAndType = (field, newValue = '', phoneNumber) => {
    let is_valid = phoneNumber.is_valid;
    let is_type_selected = phoneNumber.is_type_selected;

    if (field === 'phone_number') {
      is_valid = isValidPhoneNumber(newValue);
    }
    if (field === 'phone_type') {
      is_type_selected = newValue !== '';
    }

    return {
      is_valid,
      is_type_selected
    };
  };

  const validatePhoneNumbersFormData = phone => {
    return isValidPhoneNumber(phone.phone_number || '') && phone.phone_type !== '';
  };

  const validateEmailsFormData = email => {
    return validateEmail(email.email || '') && email.email_type !== '';
  };

  const validateAddressFormData = address => {
    return address?.company_country && address?.address_type ? true : false;
  };

  const getPhoneNumberDisabledState = () => {
    let disabled = false;
    phoneNumbers?.forEach(phone => {
      const validate = validatePhoneNumbersFormData(phone);
      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isPhoneDisabled = getPhoneNumberDisabledState();

  const getEmailDisabledState = () => {
    let disabled = false;
    emails?.forEach(email => {
      const validate = validateEmailsFormData(email);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isEmailDisbled = getEmailDisabledState();

  const getAddressDisabledState = () => {
    let disabled = false;
    addressDetails?.forEach(address => {
      const validate = validateAddressFormData(address);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isAddressDisabled = getAddressDisabledState();

  const handleChangePhoneNumber = (index, field, value) => {
    let updatedPhoneNumbers = phoneNumbers.map((phoneNumber, i) => {
      if (i === index) {
        const validateFields = validatePhoneNumberAndType(field, value, phoneNumber);
        if (field === 'is_primary') {
          return {
            ...phoneNumber,
            [field]: true,
            ...validateFields
          };
        }
        return {
          ...phoneNumber,
          [field]: value,
          ign_translate_value: value,
          ...validateFields
        };
      }
      if (i !== index && field === 'is_primary') {
        return {
          ...phoneNumber,
          is_primary: false
        };
      }
      return { ...phoneNumber };
    });

    setPhoneNumbers(updatedPhoneNumbers);
  };

  const onEditCancel = type => {
    if (type === 'company_phones') {
      setPhoneNumbers(defaultPhoneNumbers);
    }

    if (type === 'company_emails') {
      setEmails(defaultEmails);
    }

    if (type == 'company_address') {
      setIsEditingAddress(false);
      setEditing({ ...editing, company_address: false });
    }
  };
  const handleCloseClick = type => {
    const typeToEditKey = {
      primaryPhoneNumber: 'primaryPhoneNumber',
      secondaryPhoneNumber: 'secondaryPhoneNumber',
      primaryEmail: 'primaryEmail',
      secondaryEmail: 'secondaryEmail',
      company_address: 'company_address',
      highlights: 'highlights'
    };
    if (type in typeToEditKey) {
      setEditing(prevState => ({
        ...prevState,
        [typeToEditKey[type]]: false
      }));
    } else {
      setEditing(prevState => ({ ...prevState, [type]: false }));
    }
  };

  const getCountryData = async countryName => {
    if (!countryName) return;
    const url = `${IGN_API.picklists}/countries?countryName=${countryName}`;
    //   if (!openDrawer) return [];
    const res = await getMethodWithCancelTokenApi(url, { limit: 10 }, {});
    const { status, data } = res;
    if (status === 200) {
      const countryExist = data?.data?.[0] || null;
      return countryExist ? countryExist : null;
    } else {
      return null;
    }
  };

  const getStateData = async (countryName, stateFromGeoLocation) => {
    if (!countryName) return;
    const url = `${IGN_API.picklists}/countries/states?countryName=${countryName}&name=${stateFromGeoLocation}`;
    const res = await getMethodWithCancelTokenApi(url, { limit: 10 }, {});
    const { status, data } = res;
    if (status === 200) {
      const stateExist = data?.find(item => item?.name === stateFromGeoLocation);
      return stateExist ? stateExist : null;
    } else {
      return null;
    }
  };

  const fetchData = async (index, address, isEdit = false) => {
    try {
      const searchAddress = isEdit ? address : addressDetails[index]?.metropolitan_area || address;

      if (!searchAddress) return;

      const response = await LocationSetupApi(GET, searchAddress);

      if (!response?.data?.predictions?.[0]) {
        return;
      }

      const prediction = response.data.predictions[0];
      const terms = prediction.terms || [];
      const locationData = {
        metropolitan_area: prediction.description || '',
        city: terms[terms.length - 3]?.value || '',
        state: terms[terms.length - 2]?.value || '',
        country: terms[terms.length - 1]?.value || ''
      };
      const updatedAddressDetails = [];
      for (const [i, addr] of addressDetails.entries()) {
        if (i === index) {
          let countryData = await getCountryData(locationData.country);
          let stateData = await getStateData(locationData.country, locationData.state);
          updatedAddressDetails.push({
            ...addr,
            metropolitan_area: locationData.metropolitan_area,
            city: locationData.city,
            state: stateData ? { id: stateData.id, name: stateData.name, label: stateData.name } : null,
            company_country: countryData ? { id: countryData.id, name: countryData.name, label: countryData.name } : null,
            address_lines: addr.address_lines
          });
        } else {
          updatedAddressDetails.push(addr);
        }
      }
      setAddressDetails(updatedAddressDetails);
      if (locationData.country) {
        setCountry(locationData.country);
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };
  useEffect(() => {
    if (addressDetails.length > 0) {
      fetchData(0, addressDetails[0]?.metropolitan_area, false);
    }
  }, []);
  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={isHeaderNav === 'closed' ? 'details-container-close drawer-tab-layout-close' : 'details-container'}>
        <Box className={`scroll-content ${isHeaderNav === 'closed' ? 'details-container-subtab-close' : 'details-container-subtab'}`}>
          <ValidatePermit parent={'company'} type='btn-disabled' permissionType={'update'} showReadOnlyInfo={true}>
            <Grid container spacing={2} sx={{ padding: '16px' }}>
              <Grid item xs={12} className='ml-0 pl-0'>
                <Box className='section-details'>
                  <Box className='communication-section'>
                    <Box className='head-container'>
                      <Box className='d-flex sub-head-content'>
                        <Box className='field-label-input'>
                          <CustomTable
                            headerData={['Type', 'Phone', 'Is Primary']}
                            title={'Phone'}
                            handleAnotherRow={handleAddPhoneNumber}
                            rows={phoneNumbers}
                            disable={3}
                            isEditing={isEditingPhone}
                            showAddAnotherButton={false}
                            onEditChange={handleEditPhoneChange}
                            customTableId={'view-contact-content'}
                            headerSize={'fs-12'}
                            titleSize={'fs-13'}
                            isDrawer={true}
                            updateField={async (...rest) => {
                              const updatedPhoneNumbers = phoneNumbers.map(phone => {
                                return { ...phone, is_valid: isValidPhoneNumber(phone.phone_number || ''), is_type_selected: phone.phone_type !== '' };
                              });
                              setPhoneNumbers(updatedPhoneNumbers);
                              if (!isPhoneDisabled) {
                                const value = await updateField(...rest);
                                setIsEditingPhone(false);
                                return value;
                              }
                            }}
                            handleCloseClick={handleCloseClick}
                            onEditCancel={() => onEditCancel('company_phones')}
                            saveDataKey={'company_phones'}
                            disabledDone={isPhoneDisabled}
                          >
                            {isEditingPhone ? (
                              phoneNumbers?.length === 0 ? (
                                <TableRow>
                                  <TableCell align='center' className='p-2'>
                                    <Box className='fs-11'>-</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2'>
                                    <Box className='fs-11'>-</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2'>
                                    <Box className='fs-11'>-</Box>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                phoneNumbers?.map((phoneNumber, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell align='center' className='p-2'>
                                        <Box className='fs-11'> {phoneNumber?.ign_translate_value?.short_desc || '-'}</Box>
                                      </TableCell>
                                      <TableCell align='center' className='p-2'>
                                        <Box className='fs-11'>
                                          {phoneNumber?.phone_number ? (
                                            <div className=''>
                                              <CustomPhoneInput
                                                className='phone-input-read-only d-inline-block'
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                value={phoneNumber.phone_number}
                                                onChange={() => {}}
                                                disabled={true}
                                              />
                                              <span className='text-truncate'>{formatPhoneNumber(phoneNumber?.phone_number)}</span>
                                            </div>
                                          ) : (
                                            '-'
                                          )}
                                        </Box>
                                      </TableCell>
                                      <TableCell align='center' className='p-2'>
                                        <Box className='fs-11'>{phoneNumber?.is_primary ? IGNYTE_CONSTANTS.Yes : IGNYTE_CONSTANTS.No}</Box>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              )
                            ) : (
                              phoneNumbers?.map((phoneNumber, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell className='viewcontact-icon-style p-2' align='center'>
                                      <CompanyPhoneTypeSelection
                                        isDrawer={true}
                                        value={phoneNumber?.ign_translate_value?.short_desc ? phoneNumber?.ign_translate_value?.short_desc : phoneNumber?.phone_type}
                                        onChange={(e, value) => {
                                          handleChangePhoneNumber(index, 'phone_type', value || '');
                                        }}
                                        label={'Type'}
                                        selectedList={phoneNumbers}
                                        setPhoneList={setPhoneList}
                                      />
                                      {!phoneNumber.is_type_selected && <Typography className='invalid-message'>Please select type</Typography>}
                                    </TableCell>
                                    <TableCell align='center' className='p-2'>
                                      <CustomPhoneInput
                                        id='outlined-basic'
                                        variant='outlined'
                                        size='small'
                                        placeholder='Phone Number'
                                        value={phoneNumber.phone_number || ''}
                                        onChange={e => handleChangePhoneNumber(index, 'phone_number', e)}
                                      />
                                      <Typography className='invalid-message' style={{ textAlign: 'center' }}>
                                        {!phoneNumber.is_valid && 'Invalid Phone Number'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align='center' className='primary-checkbox p-2'>
                                      <Checkbox
                                        className='checkboxStyle'
                                        checked={phoneNumber?.is_primary}
                                        onChange={e => handleChangePhoneNumber(index, 'is_primary', e.target.checked)}
                                        size='small'
                                        name={`is_primary_${index}`}
                                      />
                                    </TableCell>
                                    {phoneNumbers?.length > 1 && (
                                      <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                        <CloseIcon onClick={() => handleRemovePhoneNumber(index)} className='table-close-icon' />
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })
                            )}
                          </CustomTable>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} className='ml-0 pl-0'>
                <Box className='section-details'>
                  <Box className='communication-section'>
                    <Box className='d-flex sub-head-content'>
                      <Box className='field-label-input'>
                        <CustomTable
                          headerData={['Type', 'Email', 'Is Primary']}
                          title={'Email'}
                          disabledDone={isEmailDisbled}
                          handleAnotherRow={handleAddEmail}
                          rows={emails}
                          disable={2}
                          isEditing={isEditingEmail}
                          onEditChange={handleEditEmailChange}
                          showAddAnotherButton={false}
                          customTableId={'view-company-content'}
                          headerSize={'fs-12'}
                          titleSize={'fs-13'}
                          isDrawer={true}
                          updateField={async (...rest) => {
                            const updatedemails = emails.map(email => {
                              return { ...email, is_valid: validateEmail(email.email || ''), is_type_selected: email.email_type !== '' };
                            });
                            setEmails(updatedemails);
                            if (!isEmailDisbled) {
                              const value = await updateField(...rest);
                              setIsEditingEmail(false);
                              return value;
                            }
                          }}
                          handleCloseClick={handleCloseClick}
                          onEditCancel={() => onEditCancel('company_emails')}
                          saveDataKey={'company_emails'}
                        >
                          {isEditingEmail ? (
                            emails?.length === 0 ? (
                              <TableRow>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>-</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>-</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>-</Box>
                                </TableCell>
                              </TableRow>
                            ) : (
                              emails?.map((email, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align='center' className='p-2'>
                                      <Box className='fs-11'>{email?.ign_translate_value?.short_desc || '-'}</Box>
                                    </TableCell>
                                    <TableCell align='center' className='p-2'>
                                      <Box className='fs-11'>{email?.email || '-'}</Box>
                                    </TableCell>

                                    <TableCell align='center' className='p-2'>
                                      <Box className='fs-11'>{email?.is_primary ? IGNYTE_CONSTANTS.Yes : IGNYTE_CONSTANTS.No}</Box>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            )
                          ) : (
                            emails?.map((email, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell className='viewcontact-icon-style p-2' align='center'>
                                    <CompanyEmailTypeSelection
                                      isView={true}
                                      isDrawer={true}
                                      value={email?.ign_translate_value?.short_desc ? email?.ign_translate_value?.short_desc : email?.email_type}
                                      onChange={(e, value) => handleChangeEmail(index, 'email_type', value ? value : '')}
                                      selectedList={emails}
                                      setEmailList={setEmailList}
                                      label={'Type'}
                                      className={'custom-drop-down-size'}
                                    />
                                    {!email.is_type_selected && <Typography className='invalid-message'>Select type</Typography>}
                                  </TableCell>
                                  <TableCell align='center' className='p-2'>
                                    <CustomInputField value={email?.email || ''} onChange={e => handleChangeEmail(index, 'email', e.target.value)} label={'Email'} />
                                    {!email.is_valid && <Typography className='invalid-message'>Invalid Email</Typography>}
                                  </TableCell>
                                  <TableCell align='center' className='primary-checkbox p-2'>
                                    <Checkbox
                                      className='checkboxStyle'
                                      checked={email?.is_primary}
                                      onChange={e => handleChangeEmail(index, 'is_primary', e.target.checked)}
                                      size='small'
                                      name={`is_primary_${index}`}
                                    />
                                  </TableCell>
                                  {emails?.length > 1 && (
                                    <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                      <CloseIcon onClick={() => handleRemoveEmail(index)} className='table-close-icon' />
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })
                          )}
                        </CustomTable>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} className='ml-0 pl-0'>
                <Box className='section_details' id='company-address-section'>
                  <Box className='communication-section'>
                    <Box className='d-flex sub-head-content'>
                      <CustomTable
                        title={'Address'}
                        customTableId={'view-company-content'}
                        isShowDoneIconForAddress={isShowDoneIconForAddress}
                        isEditing={isEditingAddress}
                        onEditChange={handleEditAddressChange}
                        handleAnotherRow={addAddressForm}
                        rows={addressDetails}
                        disabledDone={isAddressDisabled}
                        updateField={async (...rest) => {
                          const updatedAddressDetails = addressDetails.map(address => {
                            return {
                              ...address,
                              errors: {
                                address_type: !address.address_type,
                                company_country: !address.company_country
                              }
                            };
                          });
                          setAddressDetails(updatedAddressDetails);
                          if (!isAddressDisabled) {
                            const value = await updateField(...rest);
                            setEditing(prevState => ({ ...prevState, company_address: false }));
                            return value;
                          }
                        }}
                        showAddAnotherButton={false}
                        saveDataKey={'company_address'}
                        onEditCancel={() => onEditCancel('company_address')}
                        titleSize={'fs-13'}
                        handleCloseClick={handleCloseClick}
                        isDrawer={true}
                      >
                        {!editing.company_address ? (
                          <Box className='address-container'>
                            <Box>
                              {addressDetails?.map((element, index) => {
                                return (
                                  <Box className='mb-2 mx-2 address' key={index}>
                                    <ExpandableSection
                                      title={`Address ${index + 1}`}
                                      width={'100%'}
                                      showCheckbox={false}
                                      hadleChange={e => {
                                        hadlechangeForCheckbox(e, index);
                                      }}
                                      defaultOpen={true}
                                    >
                                      {addressDetails.length > 1 && (
                                        <Box className='d-flex flex-row-reverse align-items-center'>
                                          <Box>
                                            <MakeConfidential
                                              name='Make Primary'
                                              defaultChecked={element.checked}
                                              handleChange={e => {
                                                hadlechangeForCheckbox(e, index);
                                              }}
                                            />
                                          </Box>
                                          <Box className='pl-3'>
                                            <CloseIcon className='close-icon' cursor='pointer' onClick={() => removeAddress(index)} />
                                          </Box>
                                        </Box>
                                      )}
                                      <Grid container spacing={2}>
                                        <Grid item sm={12} md={6} lg={12} mt={2}>
                                          <Box>
                                            <CustomInputField
                                              label={'Address'}
                                              value={element?.address_lines || null}
                                              onChange={e => {
                                                handleChangeAddressData(index, 'address_lines', e.target.value);
                                              }}
                                              className='w-100'
                                              size='small'
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                          <Box>
                                            <GeoLocationInput
                                              label={'Metropolitan Area'}
                                              val={element?.metropolitan_area || null}
                                              onChange={(event, location) => {
                                                handleChangeAddressData(index, 'metropolitan_area', location?.description);
                                              }}
                                              className='w-100'
                                              size='small'
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} lg={4}>
                                          <Box className='viewcontact-icon-style'>
                                            <CountrySelection
                                              viewAddress={true}
                                              label={'Country'}
                                              value={element?.company_country || element?.contact_country?.name || ''}
                                              onChange={(e, data) => {
                                                handleChangeAddressData(index, 'company_country', data);
                                                setCountry(data?.name);
                                              }}
                                            />
                                            <Typography className='invalid-message'>{element?.errors?.company_country ? 'Please fill this field' : ''}</Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} lg={4}>
                                          <Box className='viewcontact-icon-style'>
                                            <StateSelection
                                              viewAddress={true}
                                              label={'State'}
                                              value={element?.state || ''}
                                              onChange={(e, data) => {
                                                handleChangeAddressData(index, 'state', data);
                                              }}
                                              country={country}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} lg={4}>
                                          <Box>
                                            <CustomInputField
                                              label={'Zip'}
                                              value={element?.zip_code || ''}
                                              onChange={e => {
                                                handleChangeAddressData(index, 'zip_code', e.target.value);
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={2}>
                                        <Grid item sm={12} md={6} lg={4} mt={2}>
                                          <Box>
                                            <CustomInputField
                                              label={'City'}
                                              value={element?.city || ''}
                                              onChange={e => {
                                                handleChangeAddressData(index, 'city', e.target.value);
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} lg={4} mt={2}>
                                          <Box className='viewcontact-icon-style'>
                                            <CompanyAddressTypeSelection
                                              viewAddress={true}
                                              setAddressTypeList={setAddressTypeList}
                                              label={'Address Type'}
                                              defaultValue={element?.address_type?.short_desc || ''}
                                              selectedList={addressDetails}
                                              onChange={(e, data) => {
                                                handleChangeAddressData(index, 'address_type', data);
                                              }}
                                            />
                                            <Typography className='invalid-message'>{element?.errors?.address_type ? 'Please fill this field' : ''}</Typography>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </ExpandableSection>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        ) : (
                          <Box className='address-container'>
                            {addressDetails?.map((element, index) => {
                              return (
                                <Box key={index} className='mb-2 address px-2'>
                                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography variant='subtitle1' className='fs-12 mb-1'>
                                      <strong>{element?.address_type == null ? `Address ${index + 1}` : `${element?.address_type?.short_desc} Address`}</strong>
                                    </Typography>
                                    {element.checked && (
                                      <Typography variant='subtitle1' className='fs-12 mb-1' style={{ fontStyle: 'italic' }}>
                                        Primary
                                      </Typography>
                                    )}
                                  </Box>
                                  <Typography variant='body2' className='fs-11' style={{ fontSize: 12 }}>
                                    {[element.address_lines, element.metropolitan_area, element.zip_code].filter(Boolean).join(', ')}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </CustomTable>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/* Capital Structure :: Start */}
              <Grid item xs={12} sm={6} md={6} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'Capital Structure'}</Box>
                    </Box>
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('capital_structure')}>
                    <Box>
                      {editing.capital_structure ? (
                        <Box className='d-flex align-items-center content-space'>
                          <Box className='contact-details-value content-color fs-12 w-100'>
                            <CompanyCapitalStructureSelection defaultValue={detailsData?.capital_struct_trans_value} onChange={handleCapitalStructureChange} />
                          </Box>
                          <Box>{renderActions('capital_structure', true, handleCloseClick)}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-95'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('capital_structure')}
                        >
                          {detailsData?.capital_struct_trans_value?.short_desc ? (
                            <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                              {detailsData.capital_struct_trans_value.short_desc}
                            </Typography>
                          ) : (
                            <CustomInputField placeholder='Capital Structure' value='' sx={{ width: '100%', height: '100%' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>
              {/* Investor Information*/}
              {showInvestorInformation && (
                <Grid item xs={12} sm={6} md={6} className='ml-0 pl-0'>
                  <Box className='d-flex flex-column'>
                    <Box className='d-flex align-items-center mb-1'>
                      <UpdateIcon color='disabled' />
                      <Box className='pl-1 hover-border'>
                        <Box className='title-color fs-12'>{'Investor Information'}</Box>
                      </Box>
                    </Box>
                    <ClickAwayListener onClickAway={() => handleClickAway('investor_information')}>
                      <Box>
                        {editing.investor_information ? (
                          <Box className='d-flex align-items-center content-space'>
                            <Box className='contact-details-value content-color fs-12 w-100'>
                              <CustomInputField
                                defaultValue={detailsData.investor_information}
                                onChange={e => {
                                  setValue('investor_information', e.target.value);
                                }}
                              />
                            </Box>
                            <Box>{renderActions('investor_information', true, handleCloseClick)}</Box>
                          </Box>
                        ) : (
                          <Box
                            className='input-field-data flex w-95'
                            sx={{
                              height: '40px',
                              display: 'flex',
                              alignItems: 'center',
                              transition: 'all 0.3s ease',
                              '&:hover': {
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #CCCCCC',
                                borderRadius: '4px',
                                cursor: 'pointer'
                              }
                            }}
                            onClick={() => handleEditClick('investor_information')}
                          >
                            {detailsData?.investor_information ? (
                              <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                                {detailsData.investor_information}
                              </Typography>
                            ) : (
                              <CustomInputField placeholder='Investor Information' value='' sx={{ width: '100%', height: '100%' }} />
                            )}
                          </Box>
                        )}
                      </Box>
                    </ClickAwayListener>
                  </Box>
                </Grid>
              )}
              {/* Ticker symbol:: Start */}
              <Grid item xs={12} sm={6} md={6} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'Ticker Symbol'}</Box>
                    </Box>
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('ticker_symbol')}>
                    <Box>
                      {editing.ticker_symbol ? (
                        <Box className='d-flex align-items-center content-space'>
                          <Box className='contact-details-value content-color fs-12 w-100'>
                            <CustomInputField
                              defaultValue={detailsData.ticker_symbol}
                              // label={'Ticker Symbol'}
                              onChange={e => {
                                setValue('ticker_symbol', e.target.value);
                              }}
                            />
                          </Box>
                          <Box>{renderActions('ticker_symbol', true, handleCloseClick)}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-95'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('ticker_symbol')}
                        >
                          {detailsData?.ticker_symbol ? (
                            <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                              {detailsData.ticker_symbol}
                            </Typography>
                          ) : (
                            <CustomInputField placeholder='Ticker Symbol' value='' sx={{ width: '100%', height: '100%' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>
              {/* Employee Range :: Start */}
              <Grid item xs={12} sm={6} md={6} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'Employee Range'}</Box>
                    </Box>
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('employeeRange')}>
                    <Box>
                      {editing.employeeRange ? (
                        <Box className='d-flex align-items-center content-space'>
                          <Box className='contact-details-value content-color fs-12 w-100'>
                            <Box className='d-flex' sx={{ gap: '16px' }}>
                              <CustomInputField
                                type='number'
                                defaultValue={detailsData?.employee_range_from}
                                label={'From'}
                                onChange={e => {
                                  setValue('employee_range_from', parseInt(e.target.value));
                                }}
                              />
                              <CustomInputField
                                type='number'
                                defaultValue={detailsData?.employee_range_to}
                                label={'To'}
                                onChange={e => {
                                  setValue('employee_range_to', parseInt(e.target.value));
                                }}
                              />
                            </Box>
                          </Box>
                          <Box>{renderActions('employeeRange', true, handleCloseClick)}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-95'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('employeeRange')}
                        >
                          {detailsData?.employee_range_from || detailsData?.employee_range_to ? (
                            <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                              {getEmployeeRange()}
                            </Typography>
                          ) : (
                            <CustomInputField placeholder='Employee Range' value='' sx={{ width: '100%', height: '100%' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>
              {/* Revenue Range :: Start */}
              <Grid item xs={12} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'Revenue Range'}</Box>
                    </Box>
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('revenueRange')}>
                    <Box>
                      {editing.revenueRange ? (
                        <Box className='d-flex align-items-center content-space'>
                          <Box className='contact-details-value content-color fs-12 w-100'>
                            <Box className='d-flex flex-row revenue-gap'>
                              <CurrencySelection
                                value={detailsData?.currency_code}
                                onChange={(e, data) => {
                                  setValue('currency_code', data?.label);
                                }}
                                label={'Currency'}
                                style={{ width: '200px' }}
                              />
                              <CustomInputField
                                type='number'
                                defaultValue={detailsData?.revenue_range_from}
                                label={'From'}
                                onChange={e => {
                                  setValue('revenue_range_from', parseInt(e.target.value));
                                }}
                                style={{ width: '200px' }}
                              />
                              <CustomInputField
                                type='number'
                                defaultValue={detailsData?.revenue_range_to}
                                label={'To'}
                                onChange={e => {
                                  setValue('revenue_range_to', parseInt(e.target.value));
                                }}
                                style={{ width: '200px' }}
                              />
                              <CurrencyUnitSelection
                                value={detailsData?.currency_unit_trans_value}
                                onChange={(e, data) => {
                                  setValue('currency_unit', data?.field_value);
                                }}
                                label={'Unit'}
                                style={{ width: '200px' }}
                              />
                            </Box>
                          </Box>
                          <Box>{renderActions('revenueRange', true, handleCloseClick)}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('revenueRange')}
                        >
                          <CustomInputField
                            placeholder='Revenue Range'
                            value={getRevenueRange() === '-' ? 'Revenue Range' : getRevenueRange() || ''}
                            sx={{
                              width: '100%',
                              height: '100%',
                              '& .MuiInputBase-input': {
                                color: getRevenueRange() ? 'inherit' : '#757575'
                              }
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>

              {/* Linked Url */}
              <Grid item xs={12} sm={6} md={6} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'LinkedIn URL'}</Box>
                    </Box>
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('linkedin_url')}>
                    <Box>
                      {editing.linkedin_url ? (
                        <Box className='d-flex align-items-center content-space'>
                          <Box className='contact-details-value content-color fs-12 w-100'>
                            <CustomInputField
                              defaultValue={detailsData.linkedin_url}
                              onChange={e => {
                                setValue('linkedin_url', e.target.value);
                              }}
                            />
                          </Box>
                          <Box>{renderActions('linkedin_url', true, handleCloseClick)}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-95'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('linkedin_url')}
                        >
                          {detailsData?.linkedin_url ? (
                            <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                              {detailsData.linkedin_url}
                            </Typography>
                          ) : (
                            <CustomInputField placeholder='LinkedIn URL' value='' sx={{ width: '100%', height: '100%' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'External ID'}</Box>
                    </Box>
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('external_id')}>
                    <Box>
                      {editing.external_id ? (
                        <Box className='d-flex align-items-center content-space'>
                          <Box className='contact-details-value content-color fs-12 w-100'>
                            <CustomInputField
                              defaultValue={detailsData.external_id}
                              onChange={e => {
                                setValue('external_id', e.target.value);
                              }}
                            />
                          </Box>
                          <Box>{renderActions('external_id', true, handleCloseClick)}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-95'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('external_id')}
                        >
                          {detailsData?.external_id ? (
                            <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                              {detailsData.external_id}
                            </Typography>
                          ) : (
                            <CustomInputField placeholder='External ID' value='' sx={{ width: '100%', height: '100%' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>

              {/* Comments */}
              <Grid item xs={12} className='ml-0 pl-0'>
                <Box className='d-flex flex-column'>
                  <Box className='d-flex align-items-center mb-1'>
                    <UpdateIcon color='disabled' />
                    <Box className='pl-1 hover-border'>
                      <Box className='title-color fs-12'>{'Comments'}</Box>
                    </Box>
                    {editing.comments && <Box ml={1}>{renderActions('comments', true, handleCloseClick)}</Box>}
                  </Box>
                  <ClickAwayListener onClickAway={() => handleClickAway('comments')}>
                    <Box>
                      <Box sx={!editing.comments ? { border: '1px solid #ccc', paddingLeft: 2, borderRadius: 1 } : {}}>
                        <Box
                          className='contact-details-value content-color fs-12 w-100 rich-text-modal-action-box'
                          sx={{
                            minHeight: '40px',
                            alignItems: 'center',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            },
                            border: '1px solid black'
                          }}
                          onClick={() => handleEditClick('comments')}
                        >
                          <RichText
                            defaultValue={detailsData?.comments || ''}
                            onChange={value => {
                              setValue('comments', value.replace(/<\/?p>/g, ''));
                            }}
                            isView={!editing.comments}
                            displayToolbar={editing.comments}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Grid>
            </Grid>
          </ValidatePermit>
        </Box>
      </Box>
    </div>
  );
};
ViewCompanyProfile.propTypes = {
  renderActions: PropTypes.func,
  data: PropTypes.func,
  updateField: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  detailsData: PropTypes.func,
  isHeaderNav: PropTypes.string,
  getValues: PropTypes.func
};

export default ViewCompanyProfile;
