import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SaveIcon from '@mui/icons-material/Save';
import WorkIcon from '@mui/icons-material/Work';
import { Button, CircularProgress, List, Stack, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemComponent } from '.';
import CompanySelectionDebounce from '../../common/FunctionalComponents/CompanySelection/CompanySelectionDebounce';
import CountryTypeSelection from '../CountryTypeSelection';
import StateTypeSelection from '../StateTypeSelection';
import { FormGrid } from './FormGrid';
import './index.scss';
function ProfessionalExperienceForEdit(props) {
  const { setValue, watch, stateList, countryList, getDropdownData, loadingDropdownData, handleUpdateExperience, deleteExperience, addNewColumnBySection } = props;

  const { t } = useTranslation();
  const addNewColumn = () => {
    addNewColumnBySection('contact_work_experience');
  };

  // useEffect(() => {
  //   if (newProfessionalExperienceOpen) {
  //     const list = watch('contact_work_experience') || [];
  //     const newList = [
  //       ...list,
  //       {
  //         id: list?.length + 1 || 0,
  //         title: '',
  //         company: '',
  //         city: '',
  //         state: null,
  //         country: null,
  //         end_date: null,
  //         start_date: null,
  //         is_present: false,
  //         is_board_member: false,
  //         board_end_date: null,
  //         board_start_date: null,
  //         edit: true,
  //         board_committee: null,
  //         description: '',
  //         new: true
  //       }
  //     ];
  //     setValue('contact_work_experience', newList);
  //     setNewProfessionalExperienceOpen(false);
  //   }
  // }, [newProfessionalExperienceOpen]);
  const onSave = async e => {
    const list = watch('contact_work_experience') || [];
    if (!e?.title || !e?.company) {
      enqueueSnackbar('Job Title and Company are required', { variant: 'error' });
      return;
    }

    const res = await handleUpdateExperience(e);

    if (e?.id) {
      const newList = list.map(item =>
        item.id === e?.id
          ? {
              ...e,
              id: res?.[0]?.id || res?.id || e?.id,
              new: false,
              edit: false
            }
          : item
      );
      setValue('contact_work_experience', newList);
    } else {
      setValue('contact_work_experience', [
        ...list,
        {
          ...e,
          id: res?.[0]?.id || res?.id || list?.length + 1,
          new: false,
          edit: false
        }
      ]);
    }
  };
  const onCancel = async e => {
    const list = watch('contact_work_experience') || [];

    if (!e?.new) {
      const newList = list.map(item => (item.id === e?.id ? { ...e, edit: false } : item));
      setValue('contact_work_experience', newList);
    } else {
      const newList = list.filter(item => item.id !== e?.id);
      setValue('contact_work_experience', newList);
    }
  };
  const onEdit = e => {
    const list = watch('contact_work_experience') || [];

    const newList = list.map(item => (item.id === e?.id ? { ...item, edit: true } : item));
    setValue('contact_work_experience', newList);
  };
  const onDelete = async e => {
    const list = watch('contact_work_experience');

    await deleteExperience?.(e);
    const newList = list.length > 1 ? list.filter(item => item.id !== e) : [];
    setValue('contact_work_experience', newList);
  };
  return (
    <Stack justifyItems={'flex-start'} alignItems={'flex-start'} width={'100%'}>
      <List disablePadding sx={{ width: '100%' }} mb={1}></List>
      {watch('contact_work_experience')
        ?.sort((a, b) => dayjs(b?.start_date).diff(dayjs(a?.start_date)))
        ?.map((item, index) => {
          return item?.edit ? (
            <CreateNewProfessionalExperience
              onSave={onSave}
              onCancel={onCancel}
              stateList={stateList}
              countryList={countryList}
              getDropdownData={getDropdownData}
              loadingDropdownData={loadingDropdownData}
              preData={item}
              watchGlobal={watch('contact_work_experience')}
              setValueGlobal={setValue}
              id={item?.id}
            />
          ) : (
            <ListItemComponent
              id={item.id}
              key={index}
              onEditProp={item}
              addEditBorder={false}
              customList={[
                {
                  id: 1,
                  icon: <BusinessIcon />,
                  title: item?.title || '-',
                  value: `${item?.company?.name} ${item?.company?.id ? '' : '(New)'}` || '-',
                  xs: 3,
                  valueSx: {
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  },
                  onPressCompanyName: () => window.open(`/companies/all-companies/${item?.company?.id}`, '_blank')
                },
                {
                  id: 2,
                  title: `${item?.start_date && dayjs(item?.start_date).isValid() ? dayjs(item?.start_date).format('MMM YYYY') : ''} - ${item?.is_present ? t('addContactDrawer.present') : item?.end_date && dayjs(item?.end_date).isValid() ? dayjs(item?.end_date).format('MMM YYYY') : ''}`,
                  icon: <EventAvailableIcon />,
                  value: '',
                  xs: 3
                },
                {
                  id: 3,
                  icon: <WorkIcon fontSize='small' />,
                  title: item?.is_board_member ? t('addContactDrawer.boardMember') : '-',
                  value: '',
                  xs: 3
                },
                { id: 3, icon: <LocationOnIcon />, title: item?.country?.name || '-', value: '', xs: 2 }
              ]}
              actionBtnXs={1}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          );
        })}
    </Stack>
  );
}
const CreateNewProfessionalExperience = props => {
  const { onSave, onCancel, watchGlobal, setValueGlobal, getDropdownData, loadingDropdownData, preData, id } = props;

  const [loading, setLoading] = useState(false);
  const [initialData] = useState(preData);
  const onClickSave = async () => {
    setLoading(true);
    await onSave(watch());
    setLoading(false);
  };
  const onClickCancel = async () => {
    await onCancel(initialData);
  };
  const setValue = (key, value) => {
    const newList = watchGlobal?.map(item => (item.id === id ? { ...item, [key]: value } : item));
    setValueGlobal('contact_work_experience', newList);
  };
  const watch = key => {
    if (!key) return preData;
    return preData?.[key] || null;
  };

  const getStateData = async countryName => {
    if (!countryName) return;

    const res = await getDropdownData(`/countries/states?countryName=${countryName}`);
    console.log(res, 'res');
  };
  // useEffect(() => {
  //   if (preData) {
  //     for (const key in preData) {
  //       setValue(key, preData[key]);
  //     }
  //   }
  // }, [preData]);
  const { t } = useTranslation();
  const sectionOneColumns = [
    { id: 'title', label: 'addContactDrawer.jobTitle', type: 'text', xs: 3.9, value: watch('title'), onChange: e => setValue('title', e.target.value), required: true },
    {
      id: 'company',
      label: 'addContactDrawer.company',
      type: 'custom',
      xs: 3.9,
      value: watch('company'),
      loading: loadingDropdownData,
      onChange: (e, v) => setValue('company', v),
      customComponent: () => (
        <Tooltip title={t('addContactDrawer.pleaseEnter3Characters')} placement='top' sx={{ width: '100%' }}>
          <CompanySelectionDebounce
            label={t('addContactDrawer.company')}
            defaultValue={watch('company')?.name}
            value={watch('company') || {}}
            onChange={(e, v) =>
              setValue('company', {
                ...v,
                exists: true
              })
            }
            required={true}
          />
        </Tooltip>
      )
    },
    { id: 'empty', type: 'empty', xs: 3.9 },
    {
      id: 'country',
      label: 'addContactDrawer.country',
      type: 'custom',
      xs: 3.9,
      value: watch('country'),
      onChange: (e, v) => setValue('country', v),
      tooltipTitle: t('addContactDrawer.pleaseTypeToSearch'),
      customComponent: () => (
        <CountryTypeSelection
          onChange={(e, option) => {
            setValue('country', option);
            getStateData(option?.name);
          }}
          countryName={watch('country')?.name}
          countryId={watch('country')?.id}
          label={t('addContactDrawer.country')}
          value={watch('country') || null}
          required={false}
          size={'small'}
          fullWidth={true}
        />
      )
    },
    {
      id: 'state',
      label: 'addContactDrawer.state',
      type: 'custom',
      xs: 3.9,
      value: watch('state'),
      onChange: (e, v) => setValue('state', v),
      countryId: watch('country')?.id,
      tooltipTitle: watch('country')?.id ? t('addContactDrawer.pleaseTypeToSearch') : t('addContactDrawer.pleaseSelectCountryFirst'),
      customComponent: () => (
        <StateTypeSelection
          onChange={(e, option) => {
            setValue('state', option);
          }}
          label={t('addContactDrawer.state')}
          value={watch('state')}
          countryId={watch('country')?.id}
          required={false}
          size={'small'}
          fullWidth={true}
          countryName={watch('country')?.name}
        />
      )
    },
    { id: 'city', label: 'addContactDrawer.city', type: 'text', xs: 3.9, value: watch('city'), onChange: e => setValue('city', e.target.value) },

    { id: 'start_date', label: 'addContactDrawer.from', type: 'date', xs: 3.9, value: watch('start_date'), onChange: e => setValue('start_date', e) },
    { id: 'end_date', label: 'addContactDrawer.to', type: 'date', xs: 3.9, value: watch('end_date'), onChange: e => setValue('end_date', e), disabled: watch('is_present') },
    {
      id: 'is_present',
      label: 'addContactDrawer.currentlyWorkingHere',
      fontSize: '12px',
      type: 'checkbox',
      xs: 3.9,
      checked: watch('is_present') ? true : false,
      onChange: e => setValue('is_present', e.target.checked)
    },
    {
      id: 'is_board_member',
      label: 'addContactDrawer.boardMember',
      fontSize: '12px',
      sx: {
        p: 0
      },
      type: 'checkbox',
      xs: 12,
      checked: watch('is_board_member') ? true : false,
      onChange: e => setValue('is_board_member', e.target.checked)
    },
    ...(watch('is_board_member')
      ? [
          {
            id: 'board_start_date',
            label: 'addContactDrawer.from',
            type: 'date',
            xs: 3.9,
            value: watch('board_start_date'),
            onChange: e => setValue('board_start_date', e)
          },
          {
            id: 'board_end_date',
            label: 'addContactDrawer.to',
            type: 'date',
            xs: 3.9,
            value: watch('board_end_date'),
            onChange: e => setValue('board_end_date', e)
          }
        ]
      : []),

    { id: 'board_committee', label: 'addContactDrawer.committeeDetails', type: 'text', xs: 3.9, value: watch('board_committee'), onChange: e => setValue('board_committee', e.target.value) },
    {
      id: 'description',
      label: 'addContactDrawer.description',
      type: 'richText',
      xs: 12,
      defaultValue: watch('description'),
      onChange: e => setValue('description', e),
      customClassName: 'descriptionText'
    }
  ];

  return (
    <Stack
      justifyItems={'flex-start'}
      alignItems={'flex-start'}
      width={'100%'}
      border={'1px solid'}
      mt={1}
      borderColor={'background.main'}
      sx={{
        borderRadius: '4px'
      }}
      gap={2}
      p={1}
    >
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} gap={1}>
        <Typography fontWeight={'600'} className='fs-14'>
          {watch('new') ? t('addContactDrawer.add') : t('addContactDrawer.edit')} {t('addContactDrawer.professionalExperience')}
        </Typography>
        <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={1} width={'50%'} pr={2} height={'100%'}>
          <Button
            sx={{
              borderRadius: '50%',
              minHeight: '20px',
              minWidth: '20px',
              p: 0.5,
              bgcolor: 'primary.light1'
            }}
            variant='text'
            onClick={onClickSave}
          >
            {!loading ? <SaveIcon fontSize='small' /> : <CircularProgress size={24} />}
          </Button>
          <Button
            sx={{
              borderRadius: '50%',
              minHeight: '20px',
              minWidth: '20px',
              p: 0.5,
              bgcolor: 'primary.light1'
            }}
            variant='text'
            onClick={onClickCancel}
          >
            <CloseIcon color='error' fontSize='small' />
          </Button>
        </Stack>
      </Stack>
      <FormGrid columns={sectionOneColumns} rowGap={4} columnGap={1} />
    </Stack>
  );
};
export default ProfessionalExperienceForEdit;
ProfessionalExperienceForEdit.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setNewProfessionalExperienceOpen: PropTypes.func.isRequired,
  newProfessionalExperienceOpen: PropTypes.bool.isRequired,
  companyNameList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  handleUpdateExperience: PropTypes.func.isRequired,
  deleteExperience: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};

CreateNewProfessionalExperience.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  companyNameList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  preData: PropTypes.object.isRequired,
  watchGlobal: PropTypes.func.isRequired,
  setValueGlobal: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};
