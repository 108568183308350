import '@mui/material/styles';
import React from 'react';
import { getFilterQuery } from '../../../Containers/Commons/Utils';
import { CustomAvatar } from '../Components/CustomAvatar';
import CustomHeaderFilter from '../Components/CustomHeader';
import { Tags } from '../Components/Tags';
import { NoteTextRenderer, OfflimitRenderer, TextRenderer, TitleRenderer } from '../Components/TextRenderer';
import LogItem from '../Tabs/All/Components/LogItem';

export const PAGE_LIMIT = 50;
export const SUCCESS_STATUS_CODE = 200;

const defaultColDef = {
  lockPinned: true,
  tooltipField: '',
  width: 100,
  sortingOrder: ['asc', 'desc', null],
  cellStyle: {
    display: 'flex',
    alignItems: 'center'
  }
};

const CustomHeader = props => <CustomHeaderFilter {...props} hasColumnSelector />;

export const columnDefs = selectedTabId => {
  if (selectedTabId === 'ign-contacts') {
    return [
      {
        headerName: '',
        field: 'check_box',
        colId: 'check_box',
        sortable: false,
        resizable: false,
        minWidth: 60,
        headerComponent: 'HeaderCheckbox',
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        pinned: 'left',
        lockPinned: true,
        lockPosition: true,
        visible: true,
        filter: false,
        suppressColumnsToolPanel: true
      },
      {
        ...defaultColDef,
        headerName: '',
        field: 'contact_id',
        colId: 'contact_id',
        resizable: false,
        sortable: false,
        filter: false,
        minWidth: 80,
        cellRenderer: ({ value }) => <CustomAvatar contactId={value} />,
        headerComponentFramework: CustomHeader,
        visible: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true
      },
      {
        ...defaultColDef,
        headerName: 'Contact Name',
        field: 'name',
        colId: 'name',
        sortable: true,
        minWidth: 250,
        cellRenderer: ({ value }) => <TitleRenderer value={value} isContact />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Job Title',
        field: 'current_job_title',
        colId: 'current_job_title',
        sortable: true,
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Company',
        sortable: true,
        field: 'company',
        colId: 'company',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Email',
        sortable: true,
        field: 'email',
        colId: 'email',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Tags',
        field: 'tags',
        sortable: true,
        colId: 'tags',
        minWidth: 200,
        cellRenderer: ({ value }) => <Tags tags={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Location',
        field: 'location',
        colId: 'location',
        sortable: true,
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Source',
        field: 'source',
        colId: 'source',
        sortable: true,
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Off-limit(s)',
        field: 'offlimits',
        colId: 'offlimits',
        sortable: false,
        minWidth: 200,
        cellRenderer: ({ value }) => <OfflimitRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        visible: true,
        filter: false
      }
    ];
  }

  if (selectedTabId === 'ign-companies') {
    return [
      {
        headerName: '',
        field: 'check_box',
        colId: 'check_box',
        sortable: false,
        resizable: false,
        minWidth: 60,
        headerComponent: 'HeaderCheckbox',
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        pinned: 'left',
        lockPinned: true,
        lockPosition: true,
        visible: true,
        filter: false,
        suppressColumnsToolPanel: true
      },
      {
        ...defaultColDef,
        headerName: '',
        field: 'company_id',
        colId: 'company_id',
        resizable: false,
        sortable: false,
        minWidth: 80,
        cellRenderer: ({ value }) => <CustomAvatar companyId={value} />,
        headerComponentFramework: CustomHeader,
        // headerComponent: CustomHeader,
        visible: true,
        filter: 'agMultiColumnFilter',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true
      },
      {
        ...defaultColDef,
        headerName: 'Company Name',
        field: 'name',
        colId: 'name',
        sortable: true,
        minWidth: 250,
        cellRenderer: ({ value }) => <TitleRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Industry',
        field: 'industry_name',
        colId: 'industry_name',
        sortable: true,
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Location',
        field: 'location',
        colId: 'location',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Capital Structure',
        sortable: true,
        field: 'capital_structure',
        colId: 'capital_structure',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Company Size',
        sortable: true,
        field: 'company_size',
        colId: 'company_size',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Tags',
        field: 'tags',
        colId: 'tags',
        minWidth: 200,
        cellRenderer: ({ value }) => <Tags tags={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Off-limit(s)',
        field: 'offlimits',
        colId: 'offlimits',
        sortable: false,
        minWidth: 200,
        cellRenderer: ({ value }) => <OfflimitRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filter: false
      }
    ];
  }

  if (selectedTabId === 'ign-projects') {
    return [
      {
        headerName: '',
        field: 'check_box',
        colId: 'check_box',
        sortable: false,
        resizable: false,
        minWidth: 60,
        headerComponent: 'HeaderCheckbox',
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        pinned: 'left',
        lockPinned: true,
        lockPosition: true,
        visible: true,
        filter: false,
        suppressColumnsToolPanel: true,
        cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
      },
      {
        ...defaultColDef,
        headerName: '',
        field: 'company_id',
        colId: 'company_id',
        resizable: false,
        sortable: false,
        minWidth: 80,
        cellRenderer: ({ value }) => <CustomAvatar isProject projectId={value?.projectId} companyId={value?.companyId} />,
        headerComponentFramework: CustomHeader,
        visible: true,
        filter: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true
      },
      {
        ...defaultColDef,
        headerName: 'Project Name',
        field: 'name',
        colId: 'name',
        sortable: true,
        minWidth: 250,
        cellRenderer: ({ value }) => <TitleRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Company',
        field: 'company_name',
        colId: 'company_name',
        sortable: true,
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Project Number',
        field: 'project_no',
        colId: 'project_no',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        sortable: false,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Stage',
        sortable: true,
        field: 'stage',
        colId: 'stage',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Location',
        sortable: true,
        field: 'location',
        colId: 'location',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Industry',
        field: 'industries',
        colId: 'industries',
        minWidth: 200,
        cellRenderer: ({ value }) => <TextRenderer value={value} multiple />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      },
      {
        ...defaultColDef,
        headerName: 'Tags',
        field: 'tags',
        colId: 'tags',
        minWidth: 200,
        cellRenderer: ({ value }) => <Tags tags={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        visible: true,
        filter: 'agMultiColumnFilter'
      }
    ];
  }

  if (selectedTabId === 'ign-contact-notes-all') {
    return [
      // FOR FUTURE REFERRENCE
      // {
      //   headerName: '',
      //   field: 'check_box',
      //   colId: 'check_box',
      //   sortable: false,
      //   resizable: false,
      //   minWidth: 60,
      //   headerComponent: 'HeaderCheckbox',
      //   headerCheckboxSelectionFilteredOnly: false,
      //   checkboxSelection: true,
      //   pinned: 'left',
      //   lockPinned: true,
      //   lockPosition: true
      // },
      {
        ...defaultColDef,
        headerName: 'Activity Name',
        field: 'item',
        colId: 'activity_name',
        sortable: false,
        minWidth: 500,
        cellRenderer: ({ value }) => <LogItem item={value} />,
        headerComponentFramework: CustomHeader,
        width: 500,
        filter: false
      },
      {
        ...defaultColDef,
        headerName: 'Note Text',
        field: 'item',
        colId: 'note_text',
        sortable: true,
        minWidth: 500,
        cellRenderer: ({ value }) => <NoteTextRenderer value={value} />,
        headerComponentFramework: CustomHeader,
        filterParams: {
          filterOptions: ['contains'],
          buttons: ['reset', 'apply'],
          suppressAndOrCondition: true,
          closeOnApply: true
        },
        width: 500,
        filter: 'agMultiColumnFilter'
      }
      // {
      //   ...defaultColDef,
      //   headerName: 'Tags',
      //   field: 'tags',
      //   colId: 'tags',
      //   minWidth: 300,
      //   cellRendererFramework: ({ value }) => <Tags tags={value} />,
      //   headerComponentFramework: CustomHeader
      // },
    ];
  }

  return [];
};

export const getFilterParamStringForTranslateValue = data => {
  return getFilterQuery(data, {}, {}, '');
};
