import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CloseIcon from '@mui/icons-material/Close';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Box, Button, Grid, InputLabel, Link, Menu, MenuItem, Paper, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { withStyles } from '@mui/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyAssignTag from '../../../../Containers/Companies/CompanyAssignTag';
import { ignCompanyDataApi } from '../../../../services/ApiService';
import { DEFAULT_COMPANY_STATUS, ERROR, GET, PATCH, ROUTES, SUCCESS } from '../../../../services/constantService';
import { addHttps } from '../../../../utils/string';
import variables from '../../../../variables.scss';
import CustomPopover from '../../../common/CustomPopover';
import './index.scss';
// import contact_image from "../../../assets/images/default_contact.png";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import contact_image from '../../../../assets/images/default_contact.png';
import { convertArrayBufferToBlobUrl } from '../../../../utils/common';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { useOfflimit } from '../../../../utils/Hooks/useOfflimit';
import MarkOffLimits from '../../../common/MarkOffLimits';
import ShowOffLimits from '../../../common/ShowOffLimits';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));

const AvatarStyle = withStyles({
  root: {
    height: '150px',
    width: '140px'
  }
})(Avatar);
const ViewCompanyHeader = props => {
  const {
    data,
    avatarSizeXs,
    avatarSizeMd,
    avatarSizeSm,
    mainContentWidthXs,
    mainContentWidthMd,
    mainContentWidthSm,
    buttonSizeXs,
    buttonSizeMd,
    buttonSizeSm,
    register,
    unregister,
    setValue = () => {},
    editingField,
    setEditingField = () => {},
    headerDrawer = () => {},
    getHeaderDetails = () => {},
    setIsEdited = () => {},
    setIsIconOpen = () => {},
    setIsHeaderNav = () => {},
    isHeaderNav,
    isIconOpen,
    setIsHeaderOpen = () => {},
    isHeaderOpen,
    options,
    updateField,
    companyId,
    setLoading,
    refreshImg,
    handleProjectClick,
    setOpenAddContactDrawer,
    handleBDClick = () => {},
    handleScrollToId = () => {}
  } = props;
  // const url = useRef();
  const [anchorElType, setAnchorElType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(DEFAULT_COMPANY_STATUS);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { companyOffLimit, createCompanyOffLimit, getCompanyOffLimit, updateCompanyOffLimit, deleteCompanyOffLimit } = useOfflimit();
  const [anchorElAddByHoverPopup, setAnchorElAddByHoverPopup] = useState(null);
  const [isCompanyOffLimitPopopOpen, setIsCompanyOffLimitPopopOpen] = useState(false);
  const [showCompanyOffLimit, setShowCompanyOfflimit] = useState(false);
  const isCompanyOffLimitApplied = !!companyOffLimit.length > 0;
  const offLimitClass = useMemo(() => {
    const isHardLimitPresent = companyOffLimit?.find(offlimit => offlimit?.severity === 'hard');
    return isHardLimitPresent ? 'hard' : 'soft';
  }, [companyOffLimit]);
  const [profileUrl, setProfileUrl] = useState('');
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);

  const [isShowAddedBy, setIsShowAddedBy] = useState(true);
  const navigate = useNavigate();
  const { translateMessage } = useCustomMessageHook();
  const [_, setCompanyData] = useState({});
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  // const [editing, setEditing] = useState(edit);
  const maxVisibleTags = 1;

  const tr = useTranslation();
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  const getCompanyData = async id => {
    const res = await ignCompanyDataApi(GET, null, id);
    setCompanyData(res);
    setSelectedItem(res.data?.company_status_trans_value?.short_desc);
  };

  const handleCloseCompanyOffLimitPopup = () => {
    setShowCompanyOfflimit(false);
    setIsCompanyOffLimitPopopOpen(false);
  };

  const handleOffLimitInfo = () => {
    setShowCompanyOfflimit(false);
  };

  // const getLimitedString = (dataString, limit) => {
  //   if (typeof dataString === 'string' && dataString.length <= limit) {
  //     return dataString;
  //   } else if (typeof dataString === 'string') {
  //     const limitString = dataString.substring(0, limit) + '...';
  //     return <Tooltip title={dataString}>{limitString}</Tooltip>;
  //   } else {
  //     return '-';
  //   }
  // };

  useEffect(() => {
    if (data?.id) {
      getCompanyData(data?.id);
    }
  }, [data?.id]);

  useEffect(() => {
    if (!companyId) return;
    getCompanyOffLimit(companyId);
  }, [companyId]);

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };
  useEffect(() => {
    register('profile_img');
    register('additionalDetails');
    register('first_name');
    register('last_name');
    register('contact_name');
    // register("company_status");
    return () => {
      unregister('profile_img');
      unregister('additionalDetails');
      unregister('first_name');
      unregister('last_name');
      unregister('contact_name');
      // unregister("company_status");
    };
  }, [register, unregister]);

  const open = Boolean(anchorEl);
  const openType = Boolean(anchorElType);

  const handleOffLimitClick = event => {
    setAnchorElType(event.currentTarget);
  };

  const handleOffLimitClose = () => {
    setAnchorElType(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  //const maxVisibleDescType = 1;
  const maxVisibleAddedBy = 1;

  const toggleHeader = () => {
    if (isHeaderOpen) {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-open');
      setIsHeaderNav('closed');
      headerDrawer('closed');
    } else {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-closed');
      setIsHeaderNav('open');
      headerDrawer('open');
    }
  };
  const handleEditClick = () => {
    // setEditing((prevState) => ({ ...prevState, [type]: true }));
  };

  const openAddedByPopup = event => {
    setAnchorElAddByHoverPopup(event.currentTarget);
    if (isShowAddedBy) {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(event.currentTarget);
    } else {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(null);
    }
  };

  const closeAddedByPopup = () => {
    setAnchorElAddByHoverPopup(null);
  };

  const handleMenuItemClick = item => {
    if (item === 'Business Development') {
      navigate(`/companies/${data?.name}/${companyId}/business-development/add`);
    } else if (item === 'Add Contact') {
      setOpenAddContactDrawer(true);
      handleClose();
    } else {
      // navigate(`/projects/add-project`);
      navigate(`${ROUTES.addProject}?companyId=${companyId}&companyName=${data?.name}`, { state: { ign_companies: { id: companyId, name: data?.name }, companyDropdownDisabled: true } });
      setValue('company_status', item?.field_value);
      // updateField("company_status");
      // handleOffLimitClose();
    }
  };

  const handleMenuItemClickStatus = async item => {
    const fieldValue = get(item, 'field_value');

    if (fieldValue === 'off_limits') {
      if (isCompanyOffLimitApplied) {
        setShowCompanyOfflimit(true);
      } else {
        setIsCompanyOffLimitPopopOpen(true);
      }
      handleOffLimitClose();
      return;
    }
    setSelectedItem(item?.short_desc);
    handleOffLimitClose();
    setLoading(true);
    const { status } = await ignCompanyDataApi(PATCH, { company_status: item?.field_value }, data?.id);
    if (status === 200) {
      setLoading(false);
      const message = translateMessage('Successfully', false, 'Company Status', 'updated');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      setLoading(false);
      const message = translateMessage('UnableMessage', false, 'Update', 'Company Status');
      enqueueSnackbar(message, { variant: ERROR });
    }
  };

  const handleProfileImage = e => {
    const file = e.target.files[0];
    if (file) {
      setValue('profile_img', e.target.files);
      const fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      };
      fr.readAsDataURL(file);
    }
  };
  const handleCloseClickProfileImg = () => {
    setEditingField('');
  };
  const openAddedbyViewMore = Boolean(anchorElAddByHoverPopup);

  useEffect(() => {
    if (companyId) {
      const fetchImages = async () => {
        const response = await ignCompanyDataApi(GET, null, companyId, 'image');
        if (response.status === 200) {
          const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
          setProfileUrl(url);
        } else {
          setProfileUrl(contact_image);
        }
      };

      fetchImages();
    }
  }, [companyId, refreshImg]);

  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };

  const onSubmitOffLimit = async (data, setIsLoading) => {
    const obj = { ...data, companyID: companyId };
    const isEditMode = showCompanyOffLimit;
    const companyOffLimitAction = isEditMode ? updateCompanyOffLimit : createCompanyOffLimit;
    await companyOffLimitAction(obj);
    setIsLoading(false);
    handleCloseCompanyOffLimitPopup();
  };

  const onDeleteCompanyOffLimit = async setIsLoading => {
    await deleteCompanyOffLimit(companyId);
    setIsLoading(false);
    handleCloseCompanyOffLimitPopup();
    handleOffLimitInfo();
  };

  const primaryColor = themeColor ? themeColor.primary_color : '#009d81';
  const textColor = themeColor ? themeColor.text_color : '#333';

  return (
    <>
      <Box id='view-company-header-container' pb={2}>
        <Paper elevation={3} className={`activity-bars-head ${data?.disableEditing === false ? `header${isHeaderNav}` : 'logan-activity-head'}`}>
          {isHeaderNav === 'closed' ? (
            <Stack direction='row' spacing={0} className='main-sub-content-closed d-flex align-items-center px-2 mt-1'>
              <Box className='main-container d-flex align-items-center' gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Box className={`avatar-preview ${!isCompanyOffLimitApplied && selectedItem === DEFAULT_COMPANY_STATUS ? 'border-green' : 'border-red'} ml-2 mr-3`}>
                    <Box>
                      <Avatar sx={{ width: '34px', height: '34px' }} src={profileUrl} />
                    </Box>
                  </Box>
                  <Box component='span' className='header-font header-text fs-16 mr-3'>
                    {`${data?.name}`}
                  </Box>
                  <Box>
                    {data?.linkedin_url ? (
                      <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                        <Tooltip
                          title={'LinkedIn URL'}
                          placement='top'
                          TransitionComponent={Zoom}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: { offset: [0, -5] }
                                }
                              ]
                            }
                          }}
                        >
                          <LinkedInIcon className='linkedin-icon-header fs-18' />
                        </Tooltip>
                      </a>
                    ) : (
                      <Tooltip
                        title={'LinkedIn URL'}
                        placement='top'
                        TransitionComponent={Zoom}
                        arrow
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: { offset: [0, -5] }
                              }
                            ]
                          }
                        }}
                      >
                        {' '}
                        <LinkedInIcon className='disabled-link fs-18' />
                      </Tooltip>
                    )}
                  </Box>
                  <Box display='flex' justifyContent='center' alignItems='center' pl={1}>
                    {options && options.length > 0 && (
                      <>
                        <Button
                          className={`off-limit-text-container ${isCompanyOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem?.short_desc === 'Active' ? 'button-green' : 'button-red'}`}
                          size='small'
                          aria-controls='offlimit-menu'
                          aria-haspopup='true'
                          onClick={handleOffLimitClick}
                          endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                        >
                          <Box component='span' className='off-limit-text fs-13'>
                            {selectedItem || options[0]?.short_desc}
                          </Box>
                        </Button>

                        <Button className='p-0'>
                          {isCompanyOffLimitApplied && (
                            <Box onClick={() => setShowCompanyOfflimit(true)}>
                              <Box>
                                <WarningIcon
                                  className={`offlimit-caution ${isCompanyOffLimitApplied ? offLimitClass : ''}`}
                                  sx={{
                                    fontSize: 20
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </Button>
                      </>
                    )}
                  </Box>
                  {options && options.length > 1 && (
                    <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                      {options.map((item, index) => {
                        return (
                          <MenuItem key={index} onClick={() => handleMenuItemClickStatus(item)} className='fs-12'>
                            {item?.short_desc === 'Off-Limits' && isCompanyOffLimitApplied ? `View ${item?.short_desc}` : item?.short_desc}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  )}
                </Box>
                <Box sx={{ flexGrow: 1, paddingLeft: '100px', paddingRight: '50px' }}>
                  <Box sx={{ flexGrow: 1 }} justifyContent='space-evenly' className='mt-1 d-flex flex-row align-items-center hide-1300'>
                    {data?.additionalContent &&
                      Object.keys(data?.additionalContent)
                        .slice(0, 2)
                        .map(contentKey => (
                          <Box key={contentKey} className='mr-1 d-flex flex-row align-items-center'>
                            {data?.additionalContent[contentKey]?.icon || null}
                            <Box component='span' className='test-ellipsis-small-3 fs-13'>
                              {data?.additionalContent[contentKey]?.text}
                            </Box>
                          </Box>
                        ))}
                  </Box>
                </Box>
                <Box className='d-flex flex-row'>
                  <Button
                    variant='outlined'
                    className='add-to-button'
                    size='small'
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                    endIcon={<ExpandMoreIcon className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                  >
                    <Typography>{data?.addButtonLabel || ''}</Typography>
                  </Button>
                  {data?.actionItems ? (
                    <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                      {data?.actionItems &&
                        data?.actionItems.map((item, index) => (
                          <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                            {item}
                          </MenuItem>
                        ))}
                    </Menu>
                  ) : null}
                  <IconButton onClick={handleClick2} size='small' sx={{ ml: 2 }} aria-controls={open2 ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open2 ? 'true' : undefined}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl2}
                    id='account-menu'
                    open={open2}
                    onClose={handleClose2}
                    onClick={handleClose2}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                          },
                          '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                          }
                        }
                      }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {data?.editIcon || null}
                    {data?.deleteIcon || null}
                  </Menu>
                </Box>
              </Box>
            </Stack>
          ) : (
            <Stack direction='row' spacing={0} className='main-sub-content ml-2'>
              <Grid container className={isCompanyOffLimitApplied ? 'p-2 pl-4 padding-left-30 relative' : 'p-2 pl-4 relative'}>
                {isCompanyOffLimitApplied && (
                  <span className={`isOffLimitAppliedCompany ${isCompanyOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === 'Active' ? 'button-green' : 'button-red'}`}>
                    Off Limit Applied
                  </span>
                )}
                <Box className='d-flex flex-row w-100 mt-1'>
                  <Box sx={{ flexGrow: 1 }}>
                    <Stack alignItems={'center'} direction={'row'} sx={{ height: '36px', width: '100%' }}>
                      <Box component='span' className='header-font header-text fs-18 mr-2' onDoubleClick={() => handleEditClick('first_name')}>
                        {`${data?.name}`}
                      </Box>
                      {data?.linkedin_url ? (
                        <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                          <Tooltip
                            title={'LinkedIn URL'}
                            placement='top'
                            TransitionComponent={Zoom}
                            arrow
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: { offset: [0, -5] }
                                  }
                                ]
                              }
                            }}
                          >
                            <LinkedInIcon className='linkedin-icon-header fs-18' />
                          </Tooltip>
                        </a>
                      ) : (
                        <Tooltip
                          title={'LinkedIn URL'}
                          placement='top'
                          TransitionComponent={Zoom}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                            }
                          }}
                        >
                          <LinkedInIcon className='disabled-link fs-18' />
                        </Tooltip>
                      )}
                      {options && options.length > 0 && (
                        <>
                          <Button
                            className={`off-limit-text-container-collapse ml-2 ${isCompanyOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === 'Active' ? 'button-green' : 'button-red'}`}
                            size='small'
                            aria-controls='offlimit-menu'
                            aria-haspopup='true'
                            onClick={handleOffLimitClick}
                            endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                          >
                            <Box component='span' className='off-limit-text fs-13'>
                              {selectedItem || options[0]?.short_desc}
                            </Box>
                          </Button>
                          <Button>
                            {isCompanyOffLimitApplied && (
                              <Box onClick={() => setShowCompanyOfflimit(true)}>
                                <Box>
                                  <WarningIcon className={`offlimit-caution ${isCompanyOffLimitApplied ? offLimitClass : ''}`} size='10px' />
                                </Box>
                              </Box>
                            )}
                          </Button>
                        </>
                      )}
                      {options && options.length > 1 && (
                        <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                          {options.map((item, index) => (
                            <MenuItem key={index} onClick={() => handleMenuItemClickStatus(item)} className='fs-12'>
                              {item?.short_desc === 'Off-Limits' && isCompanyOffLimitApplied ? `View ${item?.short_desc}` : item?.short_desc}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </Stack>
                  </Box>
                  <Box className='d-flex flex-row'>
                    <Box>
                      <Button
                        variant='outlined'
                        className='add-to-button'
                        size='small'
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        onClick={handleClick}
                        endIcon={<ExpandMoreIcon className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                      >
                        <Typography>{data?.addButtonLabel || ''}</Typography>
                      </Button>
                      {data?.actionItems ? (
                        <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                          {data?.actionItems &&
                            data?.actionItems.map((item, index) => (
                              <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                                {item}
                              </MenuItem>
                            ))}
                        </Menu>
                      ) : null}

                      <IconButton onClick={handleClick2} size='small' sx={{ ml: 2 }} aria-controls={open2 ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open2 ? 'true' : undefined}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl2}
                        id='account-menu'
                        open={open2}
                        onClose={handleClose2}
                        onClick={handleClose2}
                        slotProps={{
                          paper: {
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                              },
                              '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0
                              }
                            }
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        {data?.editIcon || null}
                        {data?.deleteIcon || null}
                      </Menu>
                    </Box>
                  </Box>
                </Box>
                <Box className='d-flex flex-row w-100 mt-2 mb-2'>
                  <Box className='d-flex mr-2 project-Avatar-contact'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {data?.avatar && data?.disableEditing ? (
                        <Box className={`avatar-preview ${!isCompanyOffLimitApplied && selectedItem === 'Active' ? 'border-green' : 'border-red'} mr-1`}>
                          <Box id='imagePreview'>
                            <AvatarStyle className='log-an-activity-profile-image' src={profileUrl} />
                          </Box>
                        </Box>
                      ) : (
                        <Box className='avatar-upload'>
                          <Box className='avatar-edit'>
                            <input
                              type='file'
                              id='imageUpload'
                              accept='.png, .jpg, .jpeg'
                              onClick={() => {
                                setEditingField('profile_img');
                              }}
                              onChange={e => handleProfileImage(e)}
                              className='d-none'
                            />
                            <InputLabel htmlFor='imageUpload'>
                              <Box>
                                <EditIcon className='editIcon fs-40' />
                              </Box>
                            </InputLabel>
                          </Box>
                          <Box position={'relative'}>
                            <Box className={`avatar-preview ${!isCompanyOffLimitApplied && selectedItem === 'Active' ? 'border-green' : 'border-red'} mr-1`}>
                              <Box id='imagePreview'>
                                <AvatarStyle className='image-size' src={profileUrl} />
                              </Box>
                            </Box>
                            {editingField === 'profile_img' && (
                              <Box position={'absolute'} top={0} right={-12}>
                                {renderActions('profile_img', true, handleCloseClickProfileImg)}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </div>
                  </Box>
                  <Box className='d-flex w-100'>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 4 }} direction={'row'} className='ml-4 mr-2 w-100'>
                      <Grid container item xs={4} sm={4} md={4} lg={4} className='small-screen-padding-left'>
                        <Stack direction={'column'} spacing={0.5} className='w-100 margin-top-992'>
                          {data?.additionalContent &&
                            Object.keys(data?.additionalContent).map(contentKey => (
                              <Box key={contentKey} className='d-flex flex-row align-items-center w-100'>
                                <>
                                  {data?.additionalContent[contentKey]?.icon || null}
                                  <Typography component='div' className='pt-0 pb-0 fs-13 '>
                                    {contentKey !== 'content3' ? (
                                      <>
                                        {contentKey === 'content2' ? (
                                          <Tooltip title={data?.additionalContent[contentKey]?.text}>
                                            <Typography sx={{ display: 'inline-block' }} onClick={() => handleScrollToId('company-address-section')} className='fs-13 w-100 test-ellipsis-1'>
                                              {data?.additionalContent[contentKey]?.text}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title={data?.additionalContent[contentKey]?.text}>
                                            <Typography sx={{ display: 'inline-block' }} className='fs-13 w-100 test-ellipsis-1'>
                                              {data?.additionalContent[contentKey]?.text}
                                            </Typography>
                                          </Tooltip>
                                        )}
                                      </>
                                    ) : data?.additionalContent[contentKey]?.text ? (
                                      <Link target='_blank' rel='noopener noreferrer' href={addHttps(data?.additionalContent[contentKey]?.text)} className='link w-100'>
                                        {' '}
                                        <Tooltip title={data?.additionalContent[contentKey]?.text}>
                                          <Typography component='span' className='fs-13 content-color test-ellipsis-1'>
                                            {data?.additionalContent[contentKey]?.text}
                                          </Typography>
                                        </Tooltip>
                                      </Link>
                                    ) : (
                                      '-'
                                    )}
                                  </Typography>
                                </>
                              </Box>
                            ))}
                        </Stack>
                      </Grid>
                      <Grid container item xs={4} sm={4} md={4} lg={4} className='small-screen-padding-left'>
                        <Stack direction={'column'} spacing={0.5} className='w-100 margin-top-992'>
                          {data?.mainContent ? null : (
                            <>
                              <Box className='d-flex flex-row align-items-center  w-100'>
                                <Tooltip placement='top' title={t('filterContacts.tags')}>
                                  <BookmarkIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                                </Tooltip>
                                {data?.company_tags?.length !== 0 ? (
                                  data?.company_tags?.slice(0, maxVisibleTags).map((tag, index) => (
                                    <Box key={index} className={`tag-border tag${tag?.ign_tag?.color}`}>
                                      <p className='fs-12 text-black mr-1'>{tag?.ign_tag?.name}</p>
                                    </Box>
                                  ))
                                ) : (
                                  <p className='fs-12 font-normal mr-1'>N/A</p>
                                )}
                                <CustomTooltip
                                  title={
                                    data?.company_tags?.slice(1)?.length !== 0 ? (
                                      <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                                        {data?.company_tags?.slice(1)?.map((tag, index) => (
                                          <Box key={index} className={`tag-border mr-2 tag${tag?.ign_tag?.color}`}>
                                            <p style={{ fontSize: 12, fontWeight: 600 }}>{tag?.ign_tag?.name}</p>
                                          </Box>
                                        ))}
                                      </Box>
                                    ) : (
                                      ''
                                    )
                                  }
                                  placement='top'
                                >
                                  <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600 }}>
                                    {data?.company_tags?.slice(1)?.length ? `+${data?.company_tags?.length - 1}` : ''}
                                  </p>
                                </CustomTooltip>

                                <Link component='button' onClick={handleAssignTag}>
                                  <p style={{ fontSize: 12, fontWeight: 600, color: primaryColor }}>+Tag</p>
                                </Link>
                              </Box>

                              {data?.addedByTitle && (
                                <Box className='mt-2 d-flex  w-100'>
                                  <Tooltip placement='top' title={t('project.projectHeader.contactAddedBy')}>
                                    <PersonAddAlt1Icon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                                  </Tooltip>

                                  {data?.contact_added_by?.length !== 0 ? (
                                    <Box className='d-flex'>
                                      {data?.company_added_by.slice(0, maxVisibleAddedBy).map((addedBy, index) => (
                                        <Box key={index} component='span' className='fs-12 mt-1 mr-2'>
                                          {addedBy?.added_user?.name || ''}
                                        </Box>
                                      ))}
                                      {data.company_added_by.length > maxVisibleAddedBy && (
                                        <Box className='fs-12 d-flex align-items-center'>
                                          <Box
                                            aria-owns={openAddedbyViewMore ? 'mouse-over-popover-added-by' : undefined}
                                            aria-haspopup='true'
                                            onClick={openAddedByPopup}
                                            sx={{
                                              textDecoration: 'underline',
                                              cursor: 'pointer'
                                            }}
                                          >
                                            {`+${data?.company_added_by.length - maxVisibleAddedBy}`}
                                            <CustomPopover
                                              id='mouse-over-popover-added-by'
                                              open={openAddedbyViewMore}
                                              anchorEl={anchorElAddByHoverPopup}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                              }}
                                              onClose={closeAddedByPopup}
                                            >
                                              <Box onClick={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
                                                {data?.company_added_by && (
                                                  <Box
                                                    className='d-flex flex-column align-items-center overflow-auto'
                                                    sx={{
                                                      maxHeight: '75px',
                                                      height: 'fit-content'
                                                    }}
                                                  >
                                                    {data?.company_added_by.slice(maxVisibleAddedBy).map((addedBy, index) => (
                                                      <Box
                                                        key={index}
                                                        component='span'
                                                        className='fs-12 p-1 m-1 rectangle-blue'
                                                        sx={{
                                                          borderRadius: '4px',
                                                          backgroundColor: variables.heading,
                                                          boxSizing: 'border-box',
                                                          color: variables.titleBarBackground
                                                        }}
                                                      >
                                                        <Box component='span' pr={1}>
                                                          {data?.addedBy?.icon ? data?.addedBy?.icon : ''}
                                                        </Box>
                                                        {addedBy?.added_user?.name || ''}
                                                      </Box>
                                                    ))}
                                                  </Box>
                                                )}
                                              </Box>
                                            </CustomPopover>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  ) : (
                                    '-'
                                  )}
                                </Box>
                              )}
                              <CompanyAssignTag
                                tagPopUp={data?.company_tags}
                                companyId={data?.id}
                                setIsPopupOpen={setIsTagPopupOpen}
                                isPopupOpen={isTagPopupOpen}
                                label={'select Tag'}
                                getHeaderDetails={getHeaderDetails}
                                setIsEdited={setIsEdited}
                              />
                            </>
                          )}
                        </Stack>
                      </Grid>
                      <Grid container item xs={4} sm={4} md={4} lg={4} className='small-screen-padding-left'>
                        <Stack direction={'column'} spacing={0.5} className='w-100 margin-top-992'>
                          {data?.mainContent ? null : (
                            <>
                              <Box className='d-flex flex-row align-items-center  w-100'>
                                <Tooltip placement='top' title={data?.businessDevelopment.title}>
                                  <CorporateFareIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                                </Tooltip>
                                <Typography component={'span'} variant='caption'>
                                  <Typography component={'span'} variant='caption' sx={{ color: textColor, fontWeight: 'bold' }}>
                                    <Link component='button' variant='body2' onClick={handleBDClick} className='project-count mr-2 test-ellipsis-1 cursor-pointer' sx={{ textDecoration: 'none' }}>
                                      {data?.businessDevelopment.text || ''}
                                    </Link>
                                  </Typography>
                                </Typography>
                              </Box>
                              <Box className='d-flex flex-row align-items-center  w-100'>
                                <Tooltip placement='top' title={data?.projects.title}>
                                  <BusinessCenterIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                                </Tooltip>
                                <Typography component={'span'} variant='caption'>
                                  <Typography component={'span'} variant='caption' sx={{ color: textColor, fontWeight: 'bold' }}>
                                    <Link
                                      component='button'
                                      variant='body2'
                                      onClick={handleProjectClick}
                                      className='project-count mr-2 test-ellipsis-1'
                                      sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                      {data?.projects.text || ''}
                                    </Link>
                                  </Typography>
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Stack>
          )}
        </Paper>
      </Box>

      {showCompanyOffLimit && (
        <ShowOffLimits
          showInherited={true}
          companyOnly={true}
          isPopupOpen={true}
          handleClose={handleOffLimitInfo}
          onEdit={() => {
            setIsCompanyOffLimitPopopOpen(true);
          }}
          data={companyOffLimit.map(entry => ({ ...entry, type: 'Inherited' }))}
        />
      )}

      {isCompanyOffLimitPopopOpen && (
        <MarkOffLimits
          data={companyOffLimit}
          isPopupOpen={true}
          editMode={showCompanyOffLimit}
          handleClose={handleCloseCompanyOffLimitPopup}
          onSubmit={onSubmitOffLimit}
          onDelete={onDeleteCompanyOffLimit}
        />
      )}

      {data?.disableEditing === false && (
        <Box id='view-header-icon' className='toggle-header' onClick={toggleHeader}>
          <ExpandMoreRoundedIcon cursor='pointer' className={`header-arrow-icon fs-20 ${isIconOpen}`} />
        </Box>
      )}
    </>
  );
};
ViewCompanyHeader.propTypes = {
  data: PropTypes.object,
  refreshImg: PropTypes.bool,
  avatarSizeXs: PropTypes.number,
  avatarSizeMd: PropTypes.number,
  avatarSizeSm: PropTypes.number,
  mainContentWidthXs: PropTypes.number,
  mainContentWidthMd: PropTypes.number,
  mainContentWidthSm: PropTypes.number,
  buttonSizeXs: PropTypes.number,
  buttonSizeMd: PropTypes.number,
  buttonSizeSm: PropTypes.number,
  updateField: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  headerDrawer: PropTypes.func,
  watch: PropTypes.func,
  editingField: PropTypes.string,
  setEditingField: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  setIsEdited: PropTypes.func,
  setIsHeaderBar: PropTypes.func,
  isHeaderBar: PropTypes.string,
  setIsIconOpen: PropTypes.func,
  isIconOpen: PropTypes.string,
  setIsHeaderOpen: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
  setIsHeaderNav: PropTypes.func,
  isHeaderNav: PropTypes.string,
  options: PropTypes.object,
  companyId: PropTypes.string,
  setLoading: PropTypes.func,
  handleProjectClick: PropTypes.func,
  setOpenAddContactDrawer: PropTypes.func,
  openAddContactDrawer: PropTypes.bool,
  handleBDClick: PropTypes.func,
  handleScrollToId: PropTypes.func
};

export default ViewCompanyHeader;
