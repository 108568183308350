import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { LocationSetupApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import CustomDropdown from '../CustomDropdown';

function GeoLocationInput(props) {
  const { label = 'Enter Location', onChange = () => {}, val = '', onValueChange = () => {}, ...rest } = props;
  const [input, setInput] = useState(val);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    try {
      const res = await LocationSetupApi(GET, input);
      if (res?.data?.predictions) setOptions(res?.data?.predictions);
    } catch (err) {
      console.log('err', err);
    }
  };
  useEffect(() => {
    if (input?.length > 0) {
      fetchData();
    }
  }, [input]);

  useEffect(() => {
    setInput(val);
  }, [val]);

  const onChangeValue = (event, newValue) => {
    console.log({ location: 'onChange', event, newValue });
    onChange(event, newValue);
  };
  return (
    <CustomDropdown
      {...rest}
      options={options}
      multiple={false}
      value={input}
      onInputChange={(event, newValue) => {
        setInput(newValue);
        onValueChange(newValue);
      }}
      onChange={onChangeValue}
      label={label}
    />
  );
}

GeoLocationInput.propTypes = {
  label: PropTypes.string,
  val: PropTypes.string,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func
};

export default GeoLocationInput;
