import Drawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ValidatePermit from '../../security/ValidatePermit';
import { getUserSelector } from '../../selectors';
import {
  CandidateStageSetupAPi,
  createIgnCountry,
  createIgnLanguage,
  createIgnState,
  getMethodWithCancelTokenApi,
  ignCompanyDataApi,
  ignContactDataApi,
  ProjectCandidateApi
} from '../../services/ApiService';
import { ERROR, GET, IGN_API, POST } from '../../services/constantService';
import ContactDrawerUI from './ContactDrawerUI';
import { ensurePrimaryExists } from './component';

export const createLanguage = async (name, code) => {
  const reqBody = {
    name: name || '',
    language_code: code || name?.slice(0, 2) || ''
  };
  const response = await createIgnLanguage(reqBody);
  return response;
};
export const createCountry = async name => {
  if (!name) return;
  const reqBody = {
    name: name,
    iso_2: name?.slice(0, 2) || '',
    iso_3: name?.slice(0, 3) || ''
  };
  const response = await createIgnCountry(reqBody);
  return response;
};
export const createState = async (name, countryId) => {
  if (!name || !countryId) return;
  const response = await createIgnState({ name, country_id: countryId });
  return response;
};

/**
 * AddContactDrawer - A complex form component for creating contact information
 *
 * @component
 * @description
 * A drawer component that handles creation of contact information through multiple sections:
 * - Section 1: Basic contact info (name, email, etc.)
 * - Section 3: Professional experience
 * - Section 4: Education history
 * - Section 5: Certifications & licenses
 * - Section 6: Contact details (phone, email, address)
 * - Section 7: Languages
 * - Section 9: Industry & highlights
 * - Section 10: Relationships
 * - Section 12: Personal info (DOB, gender, etc.)
 *
 *
 * @prop {Function} setValue - React Hook Form function to set form values
 * @prop {Function} watch - React Hook Form function to watch form changes
 * @prop {Function} register - React Hook Form function to register form fields
 * @prop {Function} unregister - React Hook Form function to unregister form fields
 * @prop {Object} errors - Form validation errors object
 * @prop {Boolean} open - Controls drawer visibility
 * @prop {Function} onClose - Handler for closing the drawer
 *
 *
 * -----------------------------------------HOW TO USE-----------------------------------------
 * - check `sections` array for the list of sections and their respective components.
 * - check `AddContactDrawerHeader` for the header of the drawer.
 * - check `AddContactDrawerSubHeader` for the 2 header.
 * - check `AddContactDrawerAccordion` for the accordion of the all the sections.
 * - just check internal props and just pass them to use and replicate them in other components.
 * - Contact me if you got any questions. - @ayan .. enjoy :)
 */
function AddContactDrawer(props) {
  const { openDrawer, setOpenDrawer, defaultValues } = props;
  // -----------------------------------------------------------------------------------------------------------------------------
  const [jobFunctionList, setJobFunctionList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectStageList, setProjectStageList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companyNameList, setCompanyNameList] = useState([]);
  const [levelOfEducationList, setLevelOfEducationList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [loadingDropdownData, setLoadingDropdownData] = useState(false);
  const userData = useSelector(getUserSelector);
  const [duplicateContact, setDuplicateContact] = useState({
    isDuplicate: false,
    string: 'Duplicate Contact Found',
    data: []
  });
  const [recordTypeDropdownOptions, setRecordTypeDropdownOptions] = useState([]);
  const [certificationTypeList, setCertificationTypeList] = useState([]);
  const [phoneTypeList, setPhoneTypeList] = useState([]);
  const [emailTypeList, setEmailTypeList] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [RelationTypeList, setRelationTypeList] = useState([]);
  const [levelOfProficiencyList, setLevelOfProficiencyList] = useState([]);
  const getDropdownData = async type => {
    const url = `${IGN_API.picklists}/${type}`;
    //   if (!openDrawer) return [];
    const res = await getMethodWithCancelTokenApi(
      url,
      {
        limit: 10000
      },
      {}
    ).then(response => {
      const { status, data } = response;
      if (status === 200) {
        if (type === 'project?includeBd=true') {
          const list = data?.data;
          setProjectList(list);
        } else if (type === 'industries-picklist') {
          const list = data;
          setIndustryList(list);
        } else if (type === 'job-function-picklist') {
          const list = data;
          setJobFunctionList(list);
        } else if (type === 'countries') {
          const list = data?.data;
          setCountryList(list);
          setNationalityList(list);
        } else if (type === 'companies-picklist') {
          const list = data?.data;
          setCompanyNameList(list);
        } else if (type === 'countries/states') {
          return data;
        } else if (type === 'degree-picklist?name=') {
          const list = data?.data;
          setLevelOfEducationList(list);
        } else if (type === 'languages-picklist?name=') {
          const list = data?.data;
          setLanguageList(list);
        } else if (type === 'translate-values/RECORD_TYPE') {
          const list = data;
          setRecordTypeDropdownOptions(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/PHONE_TYPE') {
          const list = data;
          setPhoneTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/EMAIL_TYPE') {
          const list = data;
          setEmailTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/ADDRESS_TYPE') {
          const list = data;
          setAddressTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/RELATION_TYPE') {
          const list = data;
          setRelationTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/LEVEL_OF_EXPERTISE') {
          const list = data;
          setLevelOfProficiencyList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else if (type === 'translate-values/CERTIFICATION_TYPE') {
          const list = data;
          setCertificationTypeList(list?.map(item => ({ name: item.short_desc, id: item.field_value })));
        } else {
          return { status, data };
        }
      }
    });
    return res?.data;
  };
  const fetchCandidateStages = async () => {
    const res = await CandidateStageSetupAPi(GET, '/all?showLogicalStages=true');
    if (res.status === 200) {
      const list = res.data?.map(item => ({ name: item.stage_label, id: item.stage_name }));
      setProjectStageList(list);
    }
  };
  const getAllDropdownData = async () => {
    setLoadingDropdownData(true);
    const dropdownPromises = [];
    dropdownPromises.push(getDropdownData('project?includeBd=true&limit=20'));
    dropdownPromises.push(getDropdownData('industries-picklist'));
    dropdownPromises.push(getDropdownData('job-function-picklist'));
    dropdownPromises.push(getDropdownData('languages-picklist?name='));
    dropdownPromises.push(getDropdownData('translate-values/RECORD_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/PHONE_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/EMAIL_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/ADDRESS_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/RELATION_TYPE'));
    dropdownPromises.push(getDropdownData('translate-values/LEVEL_OF_EXPERTISE'));
    dropdownPromises.push(getDropdownData('translate-values/CERTIFICATION_TYPE'));
    dropdownPromises.push(getDropdownData('countries'));
    dropdownPromises.push(fetchCandidateStages());
    await Promise.allSettled(dropdownPromises);
    setLoadingDropdownData(false);
  };
  useEffect(() => {
    if (openDrawer) {
      getAllDropdownData();
      setDuplicateContact({
        isDuplicate: false,
        string: 'Duplicate Contact Found',
        data: []
      });
    }
    return () => {
      setDuplicateContact({
        isDuplicate: false,
        string: 'Duplicate Contact Found',
        data: []
      });
    };
  }, [openDrawer]);
  // const { mutate: saveContactMutation, isPending: isSavingContact } = useMutation({
  //   // mutationFn: ignContactDataApi(POST, null),
  //   onSuccess: res => {
  //     console.log('success', res);
  //   }
  // });

  const prepareDate = date => {
    return date ? dayjs(date).startOf('day').utc(true).format() : null;
  };
  const setContactDataForAPI = async values => {
    try {
      // Transform work experience entries
      const createdCompanies = [];

      const workExperienceResults = values?.contact_work_experience
        ? await Promise.allSettled(
            values?.contact_work_experience?.map(async item => {
              const company = item?.company;
              // delete item?.company;
              // delete item?.id;
              let i = {
                ...item,
                start_date: prepareDate(item?.start_date),
                end_date: prepareDate(item?.end_date),
                board_start_date: prepareDate(item?.board_start_date),
                board_end_date: prepareDate(item?.board_end_date)
              };

              delete i.id;
              try {
                if (company?.exists || company?.id) {
                  return {
                    ...i,
                    company_id: company?.id || '',
                    country_id: item?.country?.id || null,
                    state_id: item?.state?.id || null,
                    is_board_member: item?.is_board_member || false,
                    board_is_present: dayjs(item?.board_end_date).isAfter(dayjs()) || (!item?.board_is_present && item?.is_board_member) ? true : false
                  };
                } else {
                  if (company?.name === '-' || company?.name === null || company?.name === undefined) {
                    return {
                      ...i,
                      company_id: null,
                      country_id: item?.country?.id || null,
                      state_id: item?.state?.id || null
                    };
                  }
                  const res = await ignCompanyDataApi(POST, {
                    name: company?.name,
                    comments: '',
                    company_tags: [],
                    company_industries: [],
                    company_status: 'active'
                  });
                  createdCompanies.push({
                    id: res.data.id,
                    name: company?.name
                  });

                  return {
                    ...i,
                    company_id: res?.data?.id || createdCompanies?.find(item => item?.name === company?.name)?.id,
                    country_id: item?.country?.id || null,
                    state_id: item?.state?.id || null,
                    is_board_member: item?.is_board_member || false
                  };
                }
              } catch (error) {
                console.error('Error processing work experience:', error);
                throw error;
              }
            })
          )
        : [];

      const transformedWorkExperience = workExperienceResults?.filter(result => result.status === 'fulfilled').map(result => result.value);
      const transformedEducation = values?.education?.map(item => {
        const i = {
          ...item,
          start_date: prepareDate(item?.start_date),
          end_date: prepareDate(item?.end_date)
        };
        delete i.id;
        return {
          country_id: i?.country?.id || i?.country?.name,
          school_name: i?.school_name,
          degree_name: i?.degree_name,
          start_date: i?.start_date,
          end_date: i?.end_date,
          is_present: i?.is_present
        };
      });
      const transformedCertificationsAndLicenses = values?.certifications_and_licenses?.map(item => {
        const i = {
          ...item,
          expire_on: prepareDate(item?.expires)
        };
        delete i.id;
        return {
          certification_type: i?.type?.id,
          certifications: i?.title,
          expire_on: i?.expire_on
        };
      });
      const transformedLanguages = values?.contact_languages
        ? await Promise.allSettled(
            values?.contact_languages?.map(async item => {
              if (!item?.contact_languages?.id) {
                const language = await createLanguage(item?.contact_languages?.name, item?.contact_languages?.code);
                return {
                  language_id: language?.id || '',
                  expertise: item?.level_of_expertise?.id
                };
              }
              return {
                language_id: item?.contact_languages?.id || '',
                expertise: item?.level_of_expertise?.id
              };
            })
          )
        : [];
      const transformedLanguagesRes = transformedLanguages?.filter(result => result.status === 'fulfilled').map(result => result.value);
      const contactPhone = values?.contact_phones?.map(item => {
        if (item.edit) return;
        delete item.id;
        delete item.edit;
        return {
          phone_type: item?.phone_type?.id || '',
          phone_number: item?.phone_number,
          is_primary: item?.is_primary || false
        };
      });
      const contactEmail = values?.contact_emails?.map(item => {
        if (item.edit) return;
        delete item.id;
        delete item.edit;
        return {
          email_type: item?.email_type?.id,
          email: item?.email,
          is_primary: item?.is_primary || false
        };
      });
      const transformedContactAddress = values?.contact_address
        ? await Promise.allSettled(
            values?.contact_address?.map(async item => {
              if (item.edit) return;
              delete item.edit;
              delete item.id;
              if (!item?.country?.id) {
                const country = await createCountry(item?.country?.name);
                item.country.id = country?.id || null;
              }
              if (!item?.state?.id) {
                const state = await createState(item?.state?.name, item?.country?.id);
                item.state.id = state?.id || null;
              }
              return {
                address_type: item?.address_type?.id || '',
                metropolitan_area: item?.metropolitan_area || '',
                address: item?.address_lines || '',
                address_lines: item?.address_lines || '',
                city: item?.city || '',
                state_id: item?.state?.id || null,
                country_id: item?.country?.id || null,
                zip_code: item?.zip_code || '',
                is_primary: item?.is_primary || false
              };
            })
          )
        : [];
      const transformedContactAddressRes = transformedContactAddress?.filter(result => result.status === 'fulfilled').map(result => result.value);
      const transformJobFunction = values?.contact_job_functions?.map(item => {
        delete item.edit;
        return {
          job_function_id: item?.id
        };
      });
      const transformIndustry = values?.contact_industries?.map(item => {
        return {
          industry_id: item?.id
        };
      });
      const transformRelationShip = values?.contact_relationship_type?.map(item => {
        delete item.id;
        return {
          type: item?.relation_type?.id,
          contact_ref_id: item?.contact?.id
        };
      });
      //-------------------------------------------------------------------------------------------------
      const transformRecordType = values?.record_type?.map(item => {
        delete item.id;
        return {
          type: item.name
        };
      });
      //-------------------------------------------------------------------------------------------------

      // Process the results and handle any failures

      // Return the complete transformed data
      delete values.contact_languages;
      delete values.certifications_and_licenses;
      delete values.education;
      delete values.contact_work_experience;
      delete values.contact_emails;
      delete values.contact_phones;
      // delete values.contact_relationship_type;
      delete values.contact_job_functions;
      delete values.contact_industries;
      const getCurrentWorkExperience = transformedWorkExperience.sort((a, b) => dayjs(b?.start_date).diff(dayjs(a?.start_date)))?.[0];
      return {
        ...values,
        birth_date: prepareDate(values?.birth_date),
        current_company_id: getCurrentWorkExperience?.company_id || null,
        current_job_title: getCurrentWorkExperience?.title || null,
        contact_work_experience: transformedWorkExperience,
        skip_current_company_to_work_experience: true,
        contact_education_details: transformedEducation,
        contact_certificate: transformedCertificationsAndLicenses,
        contact_languages: transformedLanguagesRes,
        contact_phones: ensurePrimaryExists(contactPhone || []),
        contact_emails: ensurePrimaryExists(contactEmail || []),
        contact_address: ensurePrimaryExists(transformedContactAddressRes || []),
        contact_job_functions: transformJobFunction,
        contact_industries: transformIndustry,
        contact_relationship_type: transformRelationShip,
        contact_relationship: transformRelationShip,
        contact_record_types: transformRecordType,
        nationality: values?.nationality?.id || null
      };
    } catch (error) {
      enqueueSnackbar(`Error saving contact: ${error?.message}`, { variant: ERROR });
      return false;
    }
  };
  const addContactImage = async (file, id) => {
    if (!file) return;
    const sub_route = 'image';
    const { status, data } = await ignContactDataApi(POST, id, file, sub_route);
    if (status === 200) {
      enqueueSnackbar(data?.message || 'Profile image uploaded successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(data?.message || 'Profile image upload failed', { variant: 'error' });
    }
  };
  const saveContact = async values => {
    try {
      // // Transform the data
      if (!values?.first_name || !values?.last_name || (!values?.email && !values?.phone && !values?.linkedin_url)) {
        enqueueSnackbar('Please fill all the required fields', { variant: 'error' });
        return false;
      }

      const image = values?.avatar;
      delete values.avatar;
      const transformedData = await setContactDataForAPI(values);
      // Make the API call with transformed data
      const response = await ignContactDataApi(POST, null, {
        ...transformedData,
        contact_added_by: [
          {
            user_id: userData?.id,
            created_by: userData?.id,
            updated_by: userData?.id
          }
        ],
        created_by: userData?.id
      });

      if (response?.data?.data?.potentialMatch) {
        setDuplicateContact({
          isDuplicate: true,
          string: 'Duplicate Contact Found',
          data: response?.data?.data?.potentialMatch || response?.data?.data?.existMatch
        });
        throw new Error('Duplicate Contact Found');
      }
      if (response?.data?.data?.exactMatch) {
        setDuplicateContact({
          isDuplicate: true,
          string: 'Duplicate Contact Found',
          data: response?.data?.data?.exactMatch
        });
        throw new Error('Duplicate Contact Found');
      }
      if (!response?.data?.id) {
        throw new Error('Error saving contact');
      }

      if (image) {
        await addContactImage(image, response?.data?.id);
      }

      if (values?.linkedin_avatar) {
        // Convert binary/base64 data to Blob first

        const file = new File([values?.linkedin_avatar], 'linkedin-avatar.jpg', {
          type: values?.linkedin_avatar.type || 'image/jpeg',
          lastModified: new Date().getTime()
        });

        // Create FormData to properly handle the file upload
        const formData = new FormData();
        formData.append('file', file);

        // Create FileList-like object

        await addContactImage(formData, response?.data?.id);
      }
      if (values?.project_name && values?.project_stage) {
        await ProjectCandidateApi(POST, '', {
          project_id: values?.project_name?.id,
          contact_id: response?.data?.id,
          project: values?.project_name,
          stage: values?.project_stage?.id
        });
      }
      if (response?.data?.id) {
        enqueueSnackbar('Contact saved successfully', { variant: 'success' });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error saving contact:', error);
      enqueueSnackbar(`Error saving contact: ${error?.message}`, { variant: ERROR });
      return false;
    }
  };
  const toggleDrawer = open => {
    setOpenDrawer(open);
  };
  const contactDrawerProps = {
    openDrawer,
    setOpenDrawer,
    recordTypeDropdownOptions,
    projectList,
    projectStageList,
    countryList,
    companyNameList,
    levelOfEducationList,
    certificationTypeList,
    phoneTypeList,
    emailTypeList,
    addressTypeList,
    languageList,
    levelOfProficiencyList,
    jobFunctionList,
    industryList,
    nationalityList,
    getDropdownData,
    RelationTypeList,
    loadingDropdownData,
    saveContact,
    toggleDrawer,
    includeHeader: true,
    includeSubHeader: true,
    duplicateContact,
    setDuplicateContact,
    defaultValues,
    type: 'create'
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={() => toggleDrawer(false)}
      anchor='right'
      PaperProps={{
        sx: {
          width: '80vw'
        }
      }}
      sx={{
        background: 'background.default'
      }}
    >
      <ValidatePermit parent={'contact'} type='overlay' permissionType={'create'}>
        <ContactDrawerUI {...contactDrawerProps} />
      </ValidatePermit>
    </Drawer>
  );
}
export default AddContactDrawer;

AddContactDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  defaultValues: PropTypes.object
};
