import { Box, Slider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import './styles.scss';
// import CustomMuiDropdown from '../../../common/CustomMuiDropdown';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { RenderSummary } from '../../../../Containers/Searches/ViewProject/Comman';
import { FitScoreDataApi } from '../../../../services/ApiService';
import { ERROR, POST, SUCCESS } from '../../../../services/constantService';
import CustomButton from '../../../common/CustomButton';
import Loader from '../../../common/Loader';
import RichTextCustomPopup from './RichTextCustomPopup';

const ViewCompetencyScoreHeader = props => {
  const { averageRating = 0 } = props;
  return (
    <Box container>
      <Box className='row align-items-baseline justify-content-around'>
        <Box className='col-5 p-2 align-items-center'>Leadership Attributes</Box>
        <Box className='col-5 p-2 row'>
          <Box className='row-10 p-2 bd-lb' sx={{ backgroundColor: '#fefefe' }}>
            Average Rating
          </Box>
          <Box className='row-2 text-center p-2 average-rating-value bd-rb'>{averageRating}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const ViewCompetencyScoreBox = props => {
  const [isRichTextCustomPopupOpen, setIsRichTextCustomPopupOpen] = useState(false);
  const [richTextCustomPopupDefaultValue, setRichTextCustomPopupDefaultValue] = useState(null);
  const [richTextCustomPopupOnChangeCallback, setRichTextCustomPopupOnChangeCallback] = useState(() => () => {});
  const { competencies = [], setCompetencies = () => {} } = props;
  const handleChange = (index, field, value) => {
    const updatedData = [...competencies];
    if (!updatedData[index]['ratings']) {
      updatedData[index]['ratings'] = {};
    }
    updatedData[index]['ratings'][field] = value;
    setCompetencies(updatedData);
  };
  return (
    <>
      {competencies.map((competency, no) => (
        <>
          <RichTextCustomPopup
            isOpen={isRichTextCustomPopupOpen}
            title='Add Comments'
            setIsOpen={setIsRichTextCustomPopupOpen}
            defaultValue={richTextCustomPopupDefaultValue}
            onChange={richTextCustomPopupOnChangeCallback}
          />
          <Box className='border shadow mx-2 my-2 p-2 row align-items-center competency-items'>
            <Box className='col-6 p-2'>
              <CustomInputField type='text' value={competency?.competency?.name} label={`Leadership Competency ${no ? no + 1 : ''}`} disabled />
              <ul style={{ listStyleType: 'disc', listStylePosition: 'outside' }} className='p-3'>
                {competency?.insights?.split(';').map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Box>
            <Box className='col-6 p-2'>
              <Box>Consultant Rating</Box>
              <Box className='mt-4'>
                <Box className='mx-2'>
                  <Slider
                    step={0.1}
                    min={0}
                    max={5}
                    value={competency?.ratings?.project_competency_rating}
                    onChange={(_, v) => {
                      handleChange(no, 'project_competency_rating', v);
                    }}
                    marks={[
                      { value: 0, label: '0' },
                      { value: 1, label: '1' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' }
                    ]}
                    track={false}
                    className='rating-slider'
                    valueLabelDisplay='on'
                    size='small'
                  />
                </Box>
                <Box
                  className='border p-2 my-2'
                  style={{ borderRadius: '4px' }}
                  onClick={() => {
                    setRichTextCustomPopupDefaultValue(competency?.ratings?.project_competency_comments);
                    setRichTextCustomPopupOnChangeCallback(() => v => {
                      handleChange(no, 'project_competency_comments', v);
                      setRichTextCustomPopupDefaultValue(null);
                      setRichTextCustomPopupOnChangeCallback(() => {});
                    });
                    setIsRichTextCustomPopupOpen(true);
                  }}
                >
                  {competency?.ratings?.project_competency_comments ? <RenderSummary value={competency?.ratings?.project_competency_comments} /> : 'Comments'}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </>
  );
};

const ViewCompetencyScore = props => {
  const [averageRating, setAverageRating] = useState(null);
  const [competencies, setCompetencies] = useState([]);
  const { t } = useTranslation();
  const { competency_data = [], project_id = null, candidate_id = null, getFitScoreDetails = () => {}, getCandidateScoreDetails = () => {} } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getFitScoreDetails();
    getCandidateScoreDetails();
  }, []);
  const handleSave = async () => {
    try {
      setLoading(true);
      const sub_route = '';
      // let competency_rating_list = competencies.map(item => item.ratings);
      const { status, data } = await FitScoreDataApi(POST, sub_route, { candidate_id, project_id, skills: [], competencies: competencies });
      if (status === 200) {
        enqueueSnackbar(data?.message || 'Fit data saved successfully', { variant: SUCCESS });
      } else {
        enqueueSnackbar(data?.message || 'Failed to save fit data', { variant: ERROR });
      }
      setLoading(false);
      return data;
    } catch (err) {
      console.log('Error in Save Fit Score Data', err);
      enqueueSnackbar(err?.message || 'Failed to save fit data', { variant: ERROR });
      setLoading(false);
    }
  };

  useEffect(() => {
    setCompetencies(competency_data);
  }, [competency_data]);
  useEffect(() => {
    if (competency_data && competency_data?.length > 0) {
      let average_rating = 0;
      competency_data.map(item => {
        average_rating += parseFloat(item?.ratings?.project_competency_rating ? item?.ratings?.project_competency_rating : 0);
      });
      setAverageRating((average_rating / competency_data.length).toFixed(1));
    }
  }, [competencies]);
  return (
    <>
      <Loader show={loading} />
      <Box>
        <Stack className='p-2' direction='row' justifyContent={'flex-end'} sx={{ borderBottom: '1px solid #eee' }}>
          <CustomButton onClick={handleSave} type={'primary'} variant='outlined' size={'small'} buttonText={t('actions.save')} />
        </Stack>
        <Box className='fitscore-scroll-container' sx={{ background: '#e9e9ed' }}>
          <ViewCompetencyScoreHeader averageRating={averageRating} />
          <ViewCompetencyScoreBox competencies={competencies} setCompetencies={setCompetencies} />
        </Box>
      </Box>
    </>
  );
};

ViewCompetencyScore.propTypes = {
  competency_data: PropTypes.array,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string,
  getFitScoreDetails: PropTypes.func,
  getCandidateScoreDetails: PropTypes.func
};

ViewCompetencyScoreBox.propTypes = {
  competencies: PropTypes.array,
  setCompetencies: PropTypes.func
};

ViewCompetencyScoreHeader.propTypes = {
  averageRating: PropTypes.number
};

export default ViewCompetencyScore;
