import { Box, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import DropdownWithCustomOptions from '../../CustomMuiDropdown/DropdownWithCustomOptions';

const JobFunctionSelection = props => {
  const {
    //value,
    name = '',
    value,
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    placeholder,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    label = '',
    size = 'small',
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const jobFunction = useSelector(state => state.commonReducer.jobFunction);
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (!jobFunction) {
      dispatch(fetchPickListData('job-function-picklist', 'jobFunction'));
    } else {
      setOptions(jobFunction);
    }
  }, [jobFunction, dispatch]);

  const handleChange = (e, item) => {
    setValues(item);
    onChange(item);
  };

  useEffect(() => {
    setValues(value);
  }, [value]);

  const jobFunctionOptions = options?.map(i => i?.name)?.filter(i => i);

  return (
    <>
      <DropdownWithCustomOptions
        options={jobFunctionOptions}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        isDrawer={isDrawer}
        multiple={multiple}
        placeholder={placeholder}
        disableCloseOnSelect={disableCloseOnSelect}
        className={className}
        isCheckBox={isCheckBox}
        label={label}
        getOptionLabel={option => option}
        isOptionEqualToValue={(option, value) => option === value}
        value={values}
        size={size}
        renderOption={(props, option, { selected }) => (
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} {...props}>
            <Checkbox size='small' checked={selected} />
            <Typography fontSize={14}>{option}</Typography>
          </Box>
        )}
        useLocalInputState
        maxVisibleTags={4}
      ></DropdownWithCustomOptions>
    </>
  );
};
JobFunctionSelection.propTypes = {
  defaultValue: PropTypes.array,
  value: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  selectedList: PropTypes.array,
  size: PropTypes.string
};
export default JobFunctionSelection;
