//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useTranslation } from 'react-i18next';
import TeamInfoTable from './TeamInfoTable';
const ResearcherInformation = props => {
  const { setValue, currentValues = {}, isEditing = true, option, message } = props;

  const fields = {
    execution_credit: 100
  };
  const { t } = useTranslation();
  const [researchers, setResearchers] = useState([]);
  const [selectedResearchers, setSelectedReachers] = useState([]);

  useEffect(() => {
    setValue('researchers', researchers, { shouldDirty: true });
  }, [researchers, setValue]);

  useEffect(() => {
    if (researchers && researchers.length > 0) {
      const options = option.filter(array_el => {
        return researchers.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id);
      });
      setSelectedReachers(options);
    } else {
      setSelectedReachers(option);
    }
  }, [researchers, setValue]);

  useEffect(() => {
    setValue('researchers', researchers, { shouldDirty: true });
    setResearchers(currentValues.researchers);
  }, [currentValues.researchers]);

  return (
    <>
      <TeamInfoTable
        readOnlyMessage={message}
        title={t('utils.researcher')}
        label={t('utils.researcher')}
        inputConfig={{
          list: researchers,
          fields,
          setter: value => {
            setResearchers(value);
            setValue('researchers', value);
          }
        }}
        isEditing={isEditing}
        option={selectedResearchers}
      />
    </>
  );
};

ResearcherInformation.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  message: PropTypes.string
};

export default ResearcherInformation;
