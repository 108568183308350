import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListItemComponent, TableColumn } from '.';
import useDebounce from '../../../utils/Hooks/useDebounce';
import { FormGrid } from './FormGrid';

function AddContactDrawerSectionTen(props) {
  const { setValue, watch, newRelationshipOpen, RelationTypeList, loadingDropdownData, getDropdownData, type, addNewColumnBySection } = props;
  const navigate = useNavigate();
  const columnList = [
    {
      field: 'relation_type',
      headerName: 'addContactDrawer.relationType',
      xs: 4
    },
    {
      field: 'contact',
      headerName: 'addContactDrawer.contact',
      xs: 6.7
    },
    {
      field: newRelationshipOpen ? 'action' : '',
      headerName: 'addContactDrawer.action',
      xs: 1.3
    }
  ];
  const { t } = useTranslation();

  const addNewColumn = () => {
    addNewColumnBySection('contact_relationship_type');
  };
  const deleteColumn = id => {
    const list = watch('contact_relationship_type') || [];
    setValue(
      'contact_relationship_type',
      list.filter(item => item.id !== id)
    );
  };
  const editColumn = (id, key, value) => {
    const list = watch('contact_relationship_type') || [];
    setValue(
      'contact_relationship_type',
      list.map(item => (item.id === id ? { ...item, [key]: value } : item))
    );
  };
  const onSave = () => {
    const list = watch('contact_relationship_type') || [];
    const newList = list.map(item => {
      return {
        ...item,
        edit: false,
        new: false
      };
    });
    setValue('contact_relationship_type', newList);
  };
  const onCancel = () => {
    const list = watch('contact_relationship_type') || [];
    const newList = list.filter(item => item?.new === false);
    setValue('contact_relationship_type', newList);
  };
  const onEdit = id => {
    const list = watch('contact_relationship_type') || [];
    setValue(
      'contact_relationship_type',
      list.map(item => (item.id === id ? { ...item, edit: true } : item))
    );
  };

  return (
    <>
      <Grid
        container
        border={'1px solid'}
        borderColor={'primary.main'}
        sx={{
          width: '100%',
          minHeight: '100px',
          borderRadius: '8px'
        }}
      >
        <Grid item xs={12} alignItems={'center'} justifyContent={'center'} sx={{ bgcolor: 'primary.light1', height: '50px', borderBottom: '1px solid', borderColor: 'primary.main' }}>
          <TableColumn columnList={columnList} />
        </Grid>
        {watch('contact_relationship_type') && watch('contact_relationship_type')?.length > 0 ? (
          <>
            {watch('contact_relationship_type')?.map((item, index) => {
              return (
                <>
                  {item?.edit ? (
                    <Grid item key={index} xs={12} px={1} pb={1} pt={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                      <AddRelationship
                        value={item}
                        setValue={editColumn}
                        id={item?.id}
                        onDelete={deleteColumn}
                        RelationTypeList={RelationTypeList}
                        loadingDropdownData={loadingDropdownData}
                        getDropdownData={getDropdownData}
                      />
                    </Grid>
                  ) : (
                    <Grid item key={index} xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                      <ListItemComponent
                        sx={{
                          mb: 0,
                          borderRadius: '0px',
                          p: 0,
                          px: 1,
                          minHeight: '40px',
                          bgcolor: 'text.white3'
                        }}
                        icon1={<></>}
                        icon2={<></>}
                        icon3={<></>}
                        // title1={item?.relation_type?.name || ''}
                        // title2={item?.contact?.name || ''}
                        customList={[
                          {
                            id: 1,
                            icon: <></>,
                            title: item?.relation_type?.name || '',
                            value: '',
                            xs: 4
                          },
                          {
                            id: 2,
                            icon: <></>,
                            title: item?.contact?.name || '',
                            value: '',
                            xs: 6.7,
                            onClick: () => window.open(`/contacts/list/all-contacts/${item?.contact?.id}`, '_blank'),
                            sx: {
                              cursor: 'pointer',
                              textDecoration: 'underline'
                            }
                          }
                        ]}
                        actionBtnXs={1.2}
                        onDelete={() => deleteColumn(item?.id)}
                        onEdit={() => onEdit(item?.id)}
                      />
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <Stack
            item
            xs={12}
            px={1}
            pb={1}
            pt={1}
            gap={1}
            sx={{
              borderTop: '1px solid',
              borderColor: 'text.white1',
              height: '100px',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Typography variant='body1' textAlign={'center'}>
              No Contact Relationship Type Data Found
            </Typography>
            <Button variant='contained' onClick={addNewColumn} p={0} m={0}>
              Add Contact Relationship Type
            </Button>
          </Stack>
        )}

        <Grid item xs={12} px={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
          {watch('contact_relationship_type')?.length > 0 && (
            <Button
              variant='text'
              onClick={addNewColumn}
              p={0}
              m={0}
              sx={{
                display: watch('contact_relationship_type')?.some(item => item?.edit === true) ? 'flex' : 'none'
              }}
            >
              +{t('addContactDrawer.add')}
            </Button>
          )}
        </Grid>
      </Grid>
      {watch('contact_relationship_type')?.length > 0 && (
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          width={'100%'}
          gap={1}
          mt={1}
          mr={1}
          sx={{
            display: type === 'edit' ? 'none' : watch('contact_relationship_type')?.some(item => item?.edit) ? 'flex' : 'none'
          }}
        >
          <Button variant='outlined' onClick={onCancel}>
            {t('addContactDrawer.cancel')}
          </Button>
          <Button variant='contained' onClick={onSave} startIcon={<SaveIcon fontSize='small' />}>
            {t('addContactDrawer.save')}
          </Button>
        </Stack>
      )}
    </>
  );
}
function AddRelationship(props) {
  const { value, setValue, id, onDelete, RelationTypeList, loadingDropdownData, getDropdownData } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [contactDropDownValues, setContactDropDownValues] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const contactDropDownValuesAPI = async () => {
    if (!inputValue || inputValue === '' || inputValue === null || inputValue === undefined || inputValue?.length < 3) return [];
    setLoading(true);
    const res = await getDropdownData(`/contact?name=${inputValue}`);
    setLoading(false);
    console.log(res);
    setContactDropDownValues(res?.data || []);
  };
  const debounce = useDebounce(inputValue, 1000);
  useEffect(() => {
    contactDropDownValuesAPI();
  }, [debounce]);
  const sections = [
    {
      id: 'relation_type',
      label: 'Relation Type',
      type: 'dropdown',
      required: false,
      error: false,
      value: value?.relation_type,
      options: RelationTypeList,
      getOptionLabel: option => option.name,
      onChange: (e, option) => {
        setValue(id, 'relation_type', option);
      },
      xs: 4,
      loading: loadingDropdownData
    },
    {
      id: 'contact',
      label: 'Contact',
      type: 'dropdown',
      required: false,
      error: false,
      value: value?.contact,
      options: contactDropDownValues || [],
      getOptionLabel: option => option?.name || '',
      onChange: (e, option) => {
        setValue(id, 'contact', option);
      },
      inputValue: inputValue || '',
      onInputChange: (e, option) => {
        setInputValue(option);
      },
      xs: 6.7,
      loading: loadingDropdownData,
      tooltipTitle: 'addContactDrawer.pleaseEnter3Characters'
    },
    {
      id: 'custom',
      type: 'custom',
      customComponent: () => {
        return (
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1'
              }}
              variant='text'
              onClick={() => onDelete(id)}
            >
              <CloseIcon color='error' fontSize='small' />
            </Button>
          </Stack>
        );
      },
      xs: 1
    }
  ];
  return <FormGrid columns={sections} height={'100%'} width={'100%'} px={0} columnGap={1} />;
}
export default AddContactDrawerSectionTen;

AddContactDrawerSectionTen.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  newRelationshipOpen: PropTypes.bool.isRequired,
  setNewRelationshipOpen: PropTypes.func.isRequired,
  RelationTypeList: PropTypes.array.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  getDropdownData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};

AddRelationship.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  RelationTypeList: PropTypes.array.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  getDropdownData: PropTypes.func.isRequired
};
