import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { isEmpty, round } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import propTypes from 'prop-types';
import React from 'react';
import { productOneTaskCandidateApi } from '../../../services/ApiService';
import { PATCH } from '../../../services/constantService';
import { ColorDropdown } from './CandidatePipeline/CustomDropdownFilter';
import { OfflimitRenderer } from './CandidatePipeline/OfflimitRenderer';
import { AddToPipelineBtn, ColorBasedscore, getEnumColumnParams, RenderCheckbox, RenderCompanyName, RenderLiffer, RenderRejectReasons, RenderSummary, RenderTags } from './Comman';
import style from './productOne.module.scss';

const RenderAction = props => {
  return <AddToPipelineBtn id={props?.data?.id} stage={props?.data?.stage} refetch={props.refetchData} />;
};
RenderAction.propTypes = {
  data: propTypes.object,
  refetchData: propTypes.func
};

const RenderBookmark = props => {
  const [loading, setLoading] = React.useState(false);
  const [newState, setNewState] = React.useState(props?.data?.is_bookmarked);
  const handleAddToBookmark = async () => {
    setLoading(true);
    await productOneTaskCandidateApi(PATCH, { id: props?.data?.id, is_bookmarked: !newState })
      .then(async () => {
        setLoading(false);
        // debouncedRefetch();
        setNewState(!newState);
        enqueueSnackbar(`${newState ? 'Removed from Bookmark' : 'Added to Bookmark'}`, {
          variant: 'success',
          preventDuplicate: true
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to add to Bookmark', {
          variant: 'error',
          preventDuplicate: true
        });
        setLoading(false);
      });
  };
  return (
    <Stack>
      <Button
        type='button'
        variant='text'
        style={{
          padding: '0px'
        }}
        className={` ${style.btn_drop}`}
        // disabled={projectData.record_type !== 'Project'}

        onClick={handleAddToBookmark}
      >
        {loading ? <CircularProgress size={20} color='inherit' /> : newState ? <StarOutlinedIcon /> : <StarOutlineOutlinedIcon />}
      </Button>
    </Stack>
  );
};
RenderBookmark.propTypes = {
  data: propTypes.object,
  value: propTypes.number
};

const RenderColor = ({ data, value }) => {
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState(data?.color_code || '');
  const handleCandidateColorChange = candidateId => async colorId => {
    setLoading(true);

    await productOneTaskCandidateApi(PATCH, { id: candidateId, color_id: colorId })
      .then(() => {
        enqueueSnackbar('Color Updated Successfully', {
          variant: 'success'
        });
        setLoading(false);
        setState({
          id: colorId
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to update color', {
          variant: 'error'
        });
        setLoading(false);
      });
  };
  return loading ? (
    <CircularProgress size={20} color='inherit' />
  ) : (
    <ColorDropdown onChange={async colorId => await handleCandidateColorChange(data?.id)(colorId)} selectedColor={state?.id || state} fromCandidate />
  );
};

RenderColor.propTypes = {
  data: propTypes.object,
  value: propTypes.object
};

const RenderLocation = props => {
  return (
    <Typography className='fs-12'>
      {props?.data?.contact?.contact_address[0]?.contact_country?.name}
      {props?.data?.contact?.contact_address[0]?.contact_state?.name ? `, ${props?.data?.contact?.contact_address[0]?.contact_state?.name}` : ''}
    </Typography>
  );
};
RenderLocation.propTypes = {
  data: propTypes.object,
  value: propTypes.object
};

const OfflimitSummary = ({ data }) => (
  <OfflimitRenderer
    value={{
      contactId: data?.contact?.id,
      companyId: data?.company?.id,
      offlimits: data?.contact?.offlimits_summaries
    }}
  />
);
OfflimitSummary.propTypes = {
  data: propTypes.object,
  value: propTypes.object
};

const boolEnum = {
  true: 'true',
  false: 'false'
};

const enumMap = {
  0: '0-64',
  1: '65-84',
  2: '85-100'
};
const experienceEnum = {
  0: '<5',
  1: '>5 & <10',
  2: '>10'
};

// const sourceEnum = {
//   0: 'State',
//   1: 'Country',
//   2: 'Both'
// };
const sourceEnum = {
  State: 0,
  Country: 1,
  Both: 2
};
const customValidateExperienc = (a, b) => {
  switch (a) {
    case '0':
      if (+b < 5) return true;
      break;
    case '1':
      if (+b >= 5 && +b < 10) return true;
      break;
    case '2':
      if (+b >= 10) return true;
      break;
    default:
      return false;
  }
};
const containValueEnum = {
  1: 'Contains value',
  0: 'Does not contain any value'
};
const customScoreFilter = (a, b) => {
  switch (a) {
    case '0':
      if (+b <= 0.64) return true;
      break;
    case '1':
      if (+b >= 0.65 && +b <= 0.84) return true;
      break;
    case '2':
      if (+b >= 0.85) return true;
      break;
    default:
      return false;
  }
};
const customValueFilter = (a, b) => {
  switch (a) {
    case '1':
      if (b) return true;
      break;
    case '0':
      if (!b) return true;
      break;
    default:
      return false;
  }
};

const decimalValueFormatter = ({ value }) => {
  if (!isEmpty(value) && value.value) {
    return round(value.value, 1);
  }
  return value;
};

const p1DefaultColDef = {
  minHeight: 300,
  editable: false,
  resizable: true,
  sortable: true,
  visible: true,
  enableRowGroup: true,
  enableValue: true,
  filter: 'agMultiColumnFilter',
  cellStyle: {
    display: 'flex',
    alignItems: 'center', // This vertically centers the content
    height: '100%'
  },
  filterParams: {
    buttons: ['reset', 'apply'],
    closeOnApply: true,
    suppressAndOrCondition: true
  }
};
const p1ColDefs = [
  {
    ...p1DefaultColDef,
    minWidth: 50,
    maxWidth: 200,
    colId: 'is_bookmarked',
    field: 'is_bookmarked',
    headerName: 'Bookmark',
    pinned: 'left',
    lockPinned: true,
    ...getEnumColumnParams(boolEnum, RenderBookmark),
    filter: false,
    suppressHeaderMenuButton: true
  },
  {
    ...p1DefaultColDef,
    field: 'color_id',
    headerName: 'Color',
    colId: 'color_id',
    filter: false,
    flex: 1,
    pinned: 'left',
    suppressSizeToFit: true,
    // cellRendererFramework: RenderColor,
    cellRenderer: RenderColor,
    minWidth: 100,
    maxWidth: 100
  },
  {
    ...p1DefaultColDef,
    minWidth: 250,
    field: 'contact.name',
    colId: 'name',
    headerName: 'Name',
    cellRenderer: 'renderName',
    filter: 'agMultiColumnFilter',
    pinned: 'left'
  },
  {
    ...p1DefaultColDef,
    minWidth: 200,
    field: 'contact.current_job_title',
    headerName: 'Job Title',
    colId: 'current_job_title',
    // cellRendererFramework: RenderCompanyName,
    cellRenderer: RenderCompanyName,
    filter: 'agMultiColumnFilter'
  },
  {
    ...p1DefaultColDef,
    minWidth: 250,
    field: 'contact.contact_company.name',
    headerName: 'Company Name',
    colId: 'contact_company_name',
    // cellRendererFramework: RenderCompanyName,
    cellRenderer: RenderCompanyName,
    filter: 'agMultiColumnFilter'
  },
  {
    ...p1DefaultColDef,
    field: 'contact.contact_address.contact_country.name',
    headerName: 'Location',
    colId: 'contact.contact_address.contact_country.name',
    filter: false,
    flex: 1,
    suppressSizeToFit: true,
    cellRenderer: RenderLocation,
    minWidth: 250,
    visible: true
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'exact_company_match',
    colId: 'exact_company_match',
    headerName: 'Exact Company Match',
    cellRenderer: RenderCheckbox,
    // cellRendererFramework: RenderCheckbox,
    ...getEnumColumnParams(boolEnum, RenderCheckbox)
  },
  {
    ...p1DefaultColDef,
    minWidth: 300,
    field: 'summary_html',
    colId: 'summary_html',
    headerName: 'Rationale',
    filter: 'agTextColumnFilter',
    // cellRendererFramework: RenderSummary,
    cellRenderer: RenderSummary,
    hide: false
    // valueGetter: params => {
    //   if (!params?.data?.summary_html) return '';
    //   const temp = document.createElement('div');
    //   temp.innerHTML = params?.data?.summary_html;
    //   return temp?.textContent || temp?.innerText || '';
    // }
  },
  {
    ...p1DefaultColDef,
    minWidth: 250,
    field: 'tags',
    colId: 'tags',
    headerName: 'Tags',
    cellRenderer: RenderTags,
    hide: false,
    filter: false,
    // valueGetter: params => {
    //   console.log('check==>>>', params.data);
    //   if (params?.data?.tags && params?.data?.tags?.length > 0) {
    //     return params?.data?.tags?.tag_value?.join('');
    //   }
    //   return '';
    // },
    // supressCsvExport: true
    valueGetter: params => {
      if (params?.data?.tags && Array.isArray(params?.data?.tags)) {
        // Handle array of tag objects
        return params?.data?.tags?.map(tag => tag?.tag_value)?.join(', ');
      } else if (params?.data?.tags?.tag_value) {
        // Handle single tag object
        return Array.isArray(params?.data?.tags?.tag_value) ? params?.data?.tags?.tag_value?.join(', ') : params?.data?.tags?.tag_value;
      }
      return '';
    }
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'contact.offlimits_summaries',
    colId: 'offlimits_summaries',
    headerName: 'Off-limit(s)',
    ...getEnumColumnParams(boolEnum, OfflimitSummary)
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'lifer',
    colId: 'lifer',
    headerName: 'Lifer',
    ...getEnumColumnParams(boolEnum, RenderLiffer)
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'title',
    colId: 'title',
    headerName: 'Title',
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.title * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 275,
    field: 'job_function',
    colId: 'job_function',
    headerName: 'Job Functions',
    // cellRendererFramework: ColorBasedscore,
    cellRenderer: ColorBasedscore,
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.job_function * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'industry',
    colId: 'industry',
    headerName: 'Industry',
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.industry * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 170,
    field: 'company',
    colId: 'company',
    headerName: 'Company',
    // cellRendererFramework: ColorBasedscore,
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.company * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'company_industry',
    colId: 'company_industry',
    headerName: 'Company industry',
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.company_industry * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'company_revenue_range',
    colId: 'company_revenue_range',
    headerName: 'Company Revenue Range',
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.company_revenue_range * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'company_employee_range',
    colId: 'company_employee_range',
    headerName: 'Company Employee Range',
    // cellRendererFramework: ColorBasedscore,
    cellRenderer: ColorBasedscore,
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.company_employee_range * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'career_experience',
    colId: 'career_experience',
    headerName: 'Career experience',
    filter: 'agNumberColumnFilter',
    cellRenderer: ColorBasedscore,
    valueGetter: params => {
      return Number(Math.floor(params?.data?.career_experience * 100)) || '';
    },
    hide: false,
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'job_movement',
    colId: 'job_movement',
    headerName: 'Job movement',
    cellRenderer: ColorBasedscore,
    valueGetter: params => {
      return Number(Math.floor(params?.data?.job_movement * 100)) || '';
    },
    hide: false,
    filter: 'agNumberColumnFilter',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'current_tenure',
    colId: 'current_tenure',
    headerName: 'Current tenure',
    cellRenderer: ColorBasedscore,
    valueGetter: params => {
      return Number(Math.floor(params?.data?.current_tenure * 100)) || '';
    },
    hide: false,
    filter: 'agNumberColumnFilter',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'total_experience',
    colId: 'total_experience',
    headerName: 'Total Experience',
    ...getEnumColumnParams(experienceEnum, ColorBasedscore, customValidateExperienc),
    hide: false,
    filter: 'agNumberColumnFilter',
    valueGetter: params => {
      return Number(Math.floor(params?.data?.total_experience * 100)) || '';
    },
    aggFunc: 'sum',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'proximity_location',
    colId: 'proximity_location',
    headerName: 'Proximity location',
    cellRenderer: params => params.value,
    hide: false,
    filter: 'agMultiColumnFilter',
    valueGetter: params => {
      if (parseInt(params?.data?.proximity_location) === 0) {
        return 'State';
      }
      if (parseInt(params?.data?.proximity_location) === 1) {
        return 'Country';
      }
      if (parseInt(params?.data?.proximity_location) === 2) {
        return 'Both';
      }
    }
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'source',
    colId: 'source',
    headerName: 'Source',
    editable: false,
    filter: 'agMultiColumnFilter',
    visible: true
  },
  {
    ...p1DefaultColDef,
    minWidth: 150,
    field: 'relevancy',
    colId: 'relevancy',
    headerName: 'Relevancy',
    ...getEnumColumnParams(enumMap, ColorBasedscore, customScoreFilter),
    valueGetter: params => {
      return Number(Math.floor(params?.data?.relevancy * 100)) || '';
    },
    hide: false,
    filter: 'agNumberColumnFilter',
    valueFormatter: decimalValueFormatter
  },
  {
    ...p1DefaultColDef,
    minWidth: 250,
    field: 'reason_codes',
    colId: 'reason_codes',
    headerName: 'Reject Reasons',
    // cellRendererFramework: RenderRejectReasons,
    cellRenderer: RenderRejectReasons,
    ...getEnumColumnParams(containValueEnum, RenderRejectReasons, customValueFilter)
  },
  {
    minWidth: 210,
    pinned: 'right',
    colId: 'actions',
    headerName: 'Actions',
    lockPinned: true,
    cellRenderer: RenderAction,
    field: 'id',
    editable: false,
    sortable: false,
    filter: false,
    visible: true,
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: true,
    cellStyle: params => {
      return params.node.group ? { display: 'none' } : {};
    }
  }
];

export { p1ColDefs, RenderAction, RenderBookmark };
