import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Alert, Button, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import propTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import Loader from '../../../components/common/Loader';
import ViewContactDrawer from '../../../components/ViewContactDrawer';
import {
  CandidateColorLabelDataApi,
  GetCandidateTagsApi,
  productOneApi,
  productOneTaskApi,
  productOneTaskCandidateApi,
  requestExtendedSearchApi,
  requestQuickSearchApi
} from '../../../services/ApiService';
import { GET, POST } from '../../../services/constantService';
import AgGridWrapper from '../../../utils/AgGridWrapper';
// import useApi from '../../../utils/Hooks/useApiHook';
import { omit } from 'lodash';
import { fetchGridData } from '../../../actions';
import { i18nInterpolator } from '../../../translations';
import { ColorLegend } from './CandidatePipeline/ColorLegend';
import {
  ColorBasedscore,
  CustomizedDialogs,
  FilterOption,
  LocationScore,
  RenderCheckbox,
  RenderCompanyName,
  RenderLiffer,
  RenderName,
  RenderOffLimits,
  RenderRejectReasons,
  RenderSummary,
  RenderTags,
  TagFilter
} from './Comman';
import SuccessPopup from './Comman/SuccessPopup';
import { filterIndividualInitialValue, filterType } from './Constant';
import style from './productOne.module.scss';
import { p1ColDefs, RenderAction, RenderBookmark } from './ProductOne.utils';

const useUrlQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function ProductOne() {
  let query = useUrlQuery();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [filteredGridData, setFilteredGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tagValue, setTagValue] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [filterValue, setFilterValue] = useState(filterIndividualInitialValue);
  const [type, setType] = useState('Individual');
  const [openFilter, setOpenFilter] = React.useState(false);
  const [quickLoadingPopup, setQuickLoadingPopup] = React.useState(false);
  const [isQuickLoading, setIsQuickLoading] = React.useState(false);
  const [autoClose, setAutoClose] = React.useState(false);
  const [runId, setRunId] = useState();
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [eligibleSearch, setEligibleSearch] = useState(false);
  const [cols, setCols] = useState([]);
  const [tags, setTags] = useState([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const { id } = useParams();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});

  const { data: gridState, status: gridStateStatus } = useQuery({
    queryKey: ['gridState', 'productOneGrid'],
    queryFn: async () => {
      let gridData = null;
      await fetchGridData('', 'productOneGrid', ({ status, data }) => {
        gridData = data;
      });
      return gridData;
    }
  });

  const getGridSettings = () => {
    if (gridStateStatus === 'success' && gridState) {
      const sortOrder = JSON.parse(gridState?.settings || '[]');
      const sortModel = JSON.parse(gridState?.sort || '[]');
      const filterModel = JSON.parse(gridState?.filter || '{}');

      setSavedSortModel([...sortModel]);
      setSavedFilterModel({ ...filterModel });
      if (sortOrder.length) {
        const savedConfigMap = sortOrder.reduce((acc, column) => {
          acc[column.field] = column;
          return acc;
        }, {});

        const updatedColDefs = p1ColDefs.map(col => {
          const savedConfig = savedConfigMap[col.field];
          if (savedConfig) {
            const filteredOutConfig = omit(savedConfig, ['sort', 'sortIndex']);
            return {
              ...col,
              ...filteredOutConfig
            };
          }
          return col;
        });

        updatedColDefs.sort((a, b) => {
          const indexA = sortOrder.findIndex(column => column.field === a.field);
          const indexB = sortOrder.findIndex(column => column.field === b.field);
          return indexA - indexB;
        });
        return setCols(updatedColDefs);
      } else {
        return setCols(p1ColDefs);
      }
    } else {
      console.error('Error:', gridState);
      return setCols(p1ColDefs);
    }
  };

  useEffect(() => {
    if (isGridReady) {
      getGridSettings();
    }
  }, [isGridReady]);

  //* API call for getting the product one task list
  const { data: getTasks, refetch: getTasksRefetch } = useQuery({
    queryKey: ['getTasks', id],
    queryFn: async () => {
      setIsLoadingHistory(true);
      const res = await productOneTaskApi({ projectId: id });
      const data = res?.data?.reduce((acc, item) => {
        return [
          ...acc,
          {
            id: item.id,
            title: `${t('productoneRun.' + item?.run_type)} ${t('productoneRun.ranOn')} ${item?.created_at ? new Date(item?.created_at || '').toLocaleString() : ''} ${t('productoneRun.withStatus')} ${item.run_status ? t('productoneRun.' + item?.run_status) : 'N/A'} by ${item?.requested_user?.first_name} ${item?.requested_user?.last_name}`,
            type: item.run_type,
            run_status: item.run_status,
            projectId: item.project_id
          }
        ];
      }, []);

      setIsLoadingHistory(false);
      return data;
    }
  });

  //* API call for getting the product one candidate list
  const { data: getProductOneCandidateList, refetch: productOneNewGetRefetch } = useQuery({
    queryKey: ['productOneResults', id, runId, tagValue],
    queryFn: async () => {
      setIsLoading(true);
      let filter = createQueryParams(filterValue) || {};
      const validation = runId?.projectId === id;

      const res = await productOneTaskCandidateApi(GET, {
        project_id: id,
        run_id: runId && validation ? runId?.id : null,
        tags: tagValue,
        ...filter
      }).then(response => {
        // setShowGlobalLoader(false);
        return response;
      });
      // setShowGlobalLoader(false);
      return res;
    },
    enabled: () => {
      if (!runId) {
        return false;
      }
      return true;
    },
    cancelRefetch: true
  });

  //* API call for validating the eligible search
  const { data: validateEligibleSearch } = useQuery({
    queryKey: ['validateEligibleSearch', id],
    queryFn: async () => {
      const res = await productOneApi(GET, {}, 'validate-eligible-search', { id });
      if (res.status === 200) {
        return res.data.data;
      }
      return res.data;
    },
    enabled: !!id,
    staleTime: 0
  });

  //* API call for getting the candidate tags
  const { data: candidateTags } = useQuery({
    queryKey: ['candidateTags'],
    queryFn: async () => {
      const res = await GetCandidateTagsApi();
      return res.data.map(item => item.tag_value);
    }
  });

  useEffect(() => {
    if (candidateTags) {
      setTags(candidateTags);
    }
  }, [candidateTags]);

  const updateRunId = value => {
    setRunId(value);
  };

  //* useEffect for setting the runId
  useEffect(() => {
    if (getTasks?.length > 0) {
      if (runId) {
        const validation = runId.projectId === id && getTasks.find(item => item.id === runId.id);
        if (!validation) {
          updateRunId(getTasks && getTasks[0]);
        } else {
          updateRunId(runId);
        }
      }
      if (!runId) {
        updateRunId(getTasks && getTasks[0]);
      }
    }
  }, [getTasks]);

  //* useEffect for setting the column visibility for Quick and Extended search
  useEffect(() => {
    if (getProductOneCandidateList) {
      updateGridData();
    }
    setIsLoading(false);
  }, [getProductOneCandidateList]);

  //* useEffect for checking the P1 search eligibility
  useEffect(() => {
    if (validateEligibleSearch) {
      setEligibleSearch(checkSearchEligible(validateEligibleSearch, t));
    }
  }, [validateEligibleSearch]);

  //* API call for requesting the extended search
  const RequestExtendedSearch = async () => {
    setQuickLoadingPopup(true);
    setIsQuickLoading(true);
    setAutoClose(true);
    await requestExtendedSearchApi({
      projectId: id
    })
      .then(res => {
        if (res.status !== 200) {
          enqueueSnackbar(res?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
          return;
        }

        snackbar.enqueueSnackbar('Successfully requested for Extended Search', {
          variant: 'success'
        });
        getTasksRefetch();
        setIsQuickLoading(false);
      })
      .catch(e => {
        snackbar.enqueueSnackbar('Failed to Request for Extended Search', {
          variant: 'error'
        });

        setIsQuickLoading(false);
        console.error(e);
      });
  };

  //* API call for requesting the quick search
  const RequestQuickSearch = async () => {
    try {
      setQuickLoadingPopup(true);
      setIsQuickLoading(true);
      await requestQuickSearchApi({
        projectId: id
      })
        .then(response => {
          if (response.status !== 200) {
            if (response?.data?.message?.includes('No candidate')) {
              snackbar.enqueueSnackbar(response?.data?.message, {
                variant: 'info'
              });
            } else {
              snackbar.enqueueSnackbar(response?.data?.message, {
                variant: 'error'
              });
            }
            setQuickLoadingPopup(false);
          }
          getTasksRefetch();
        })
        .catch(e => {
          snackbar.enqueueSnackbar(e?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
          console.error(e);
        });
      setIsQuickLoading(false);
    } catch (e) {
      console.error(e);
      setIsQuickLoading(false);
      setQuickLoadingPopup(false);
      snackbar.enqueueSnackbar(e, {
        variant: 'error'
      });
    }
  };

  const refetchData = async () => {
    setIsLoading(true);
    await productOneNewGetRefetch();

    setIsLoading(false);
  };

  const updateGridData = () => {
    setRowData(getProductOneCandidateList?.rows || []);
  };

  const setInitialValue = async () => {
    setTagValue([]);
    setFilterValue(prev => {
      return prev.map(item => {
        if (filterValue[item.id]) {
          return {
            ...item,
            value: filterValue[item.id],
            type: filterType[0].id
          };
        }
        if (item.id === 'tags') {
          return { ...item, value: [] };
        } else {
          return { ...item, value: null, type: filterType[0].id };
        }
      });
    });
  };
  const handleSave = async () => {
    await refetchData();
    setOpenFilter(false);
  };

  useEffect(() => {
    refetchData();
  }, [runId]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCandidateColorLabelChange = async (label, colorId) => {
    await CandidateColorLabelDataApi(POST, { projectId: id, label, colorId })
      .then(() => {
        enqueueSnackbar('Color label updated successfully', {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to update color label', {
          variant: 'error'
        });
      });
  };

  const handleOnGridReady = useCallback(() => {
    setIsGridReady(true);
  }, []);

  const handleFilteredGridData = useCallback(data => {
    setFilteredGridData(data);
  }, []);

  return (
    <div className={`${style.white_bg}`}>
      <Stack>
        {eligibleSearch && (
          <Alert
            severity='error'
            icon={false}
            hidden={eligibleSearch?.isEligible}
            sx={{
              borderLeft: '3px solid #ef5350',
              marginBottom: '10px'
            }}
          >
            {i18nInterpolator(t('productoneRun.searchisnotvalid'), { type: eligibleSearch?.message?.join(', ') })}
          </Alert>
        )}
        <Stack className={style.product_one_body}>
          {isLoading && <div className={style.loadLoaderSkeleton}></div>}

          {!isLoading && (
            <>
              <Stack
                direction='row'
                alignItems={'center'}
                gap={3}
                sx={{
                  width: '50%'
                }}
              >
                {getTasks && (
                  <Box sx={{ flexGrow: 1 }}>
                    <CustomMuiDropdown
                      options={getTasks}
                      value={runId}
                      isLoading={isLoadingHistory}
                      onChange={(e, val) => {
                        updateRunId(val);
                      }}
                      autoCompleteProps={{
                        fullWidth: true,
                        size: 'small',
                        disableCloseOnSelect: true,
                        multiple: false,
                        disableClearable: true,
                        componentsProps: {
                          paper: {
                            sx: {
                              width: 800
                            }
                          }
                        }
                      }}
                      getOptionLabel={option => option.title}
                      label='Run History'
                      textFieldProps={{
                        fullWidth: true
                      }}
                    />
                  </Box>
                )}
                <Button
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    minWidth: 'auto'
                  }}
                  size='small'
                  onClick={RequestQuickSearch}
                  className={style.extButton + ' fs-12'}
                  disabled={!eligibleSearch?.isEligible}
                >
                  Quick Search
                </Button>
                <Button
                  variant={'contained'}
                  sx={{
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    minWidth: 'auto'
                  }}
                  size='small'
                  className={style.extButton + ' fs-12'}
                  onClick={RequestExtendedSearch}
                  disabled={!eligibleSearch?.isEligible}
                >
                  Extended Search
                </Button>
              </Stack>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <TagFilter customClassName={'productTag'} tagValue={tagValue} setTagValue={setTagValue} refetch={refetchData} tags={tags} setTags={setTags} />
                <Box sx={{ display: { lg: 'none', md: 'none', xl: 'block' } }}>
                  <ColorLegend
                    handleCandidateColorLabelChange={handleCandidateColorLabelChange}
                    btnprops={{
                      variant: 'text',
                      sx: {
                        textDecoration: 'underline'
                      }
                    }}
                  />
                </Box>

                <FilterOption
                  setFilterValue={setFilterValue}
                  filterValue={filterValue}
                  onSave={handleSave}
                  open={openFilter}
                  setOpen={setOpenFilter}
                  type={type}
                  setType={setType}
                  reset={setInitialValue}
                />
                <Box sx={{ display: { lg: 'none', md: 'none', xl: 'block' } }}>
                  <CustomizedDialogs />
                  <Button
                    variant='text'
                    className={style.btn_prop + ' fs-12'}
                    onClick={() => {
                      setOpenFilter(true);
                      handleClose();
                    }}
                  >
                    Advance Filters
                  </Button>
                </Box>

                <Box sx={{ display: { lg: 'block', xl: 'none' } }}>
                  <IconButton id='basic-button' aria-controls={open ? 'basic-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    classes={{ paper: style.customPositionPaper }}
                  >
                    <Box className={style.actionDropdownContainer} sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                      <ColorLegend
                        handleCandidateColorLabelChange={handleCandidateColorLabelChange}
                        btnprops={{
                          variant: 'text',
                          sx: {
                            textDecoration: 'none',
                            textAlign: 'left'
                          },
                          className: style.btn_prop + ' fs-12',
                          fullWidth: true
                        }}
                      />
                      <CustomizedDialogs />
                      <Button
                        variant='text'
                        className={style.btn_prop + ' fs-12'}
                        onClick={() => {
                          setOpenFilter(true);
                          handleClose();
                        }}
                      >
                        Advance Filters
                      </Button>

                      <Button
                        variant='text'
                        className={style.btn_prop + ' fs-12'}
                        onClick={() => {
                          setInitialValue();
                          refetchData();
                          handleClose();
                        }}
                      >
                        Reset Filters
                      </Button>
                      <Button
                        variant='text'
                        className={style.btn_prop + ' fs-12'}
                        // onClick={resetSorting} //!TODO reset sorting
                      >
                        Reset Sort
                      </Button>
                    </Box>
                  </Menu>
                </Box>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>

      <div
        id='myGrid'
        className={`${style.table_body} ag-theme-alpine mt-2`}
        style={{
          height: 'calc(100vh - 200px)'
        }}
      >
        {isLoading && <Loader color={'primary'} className='loader-class' show={true} />}
        {isDrawerOpen && (
          <ViewContactDrawer
            isCandidate={true}
            project_id={query.get('id')}
            candidate_stage={selectedCandidate?.stage}
            isCandidateProductOne={true}
            candidate_id={selectedCandidate?.id}
            navigateToAllContacts={false}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            id={selectedCandidate?.contact_id}
            baseRoute={`/projects/view-project/${query.get('id')}`}
            isNavigationButtons={true}
            currentGridData={filteredGridData.length > 0 ? filteredGridData : rowData}
            setSelectedCandidate={setSelectedCandidate}
            selectedCandidate={selectedCandidate}
            refetchProductOneCandidates={refetchData}
          />
        )}
        <AgGridWrapper
          gridType={'productOneGrid'}
          sortModel={savedSortModel}
          filterModel={savedFilterModel}
          autoSizeStrategy={{ type: 'fitColumns' }}
          rowStyle={{ justifyContent: 'start' }}
          columnDefs={cols}
          rowData={rowData || []}
          // rowSelection='multiple'
          rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
          onGridReady={handleOnGridReady}
          components={{
            renderName: useCallback(props => <RenderName {...props} setSelectedCandidate={setSelectedCandidate} setIsDrawerOpen={setIsDrawerOpen} />, []),
            renderCompanyName: RenderCompanyName,
            renderOffLimits: RenderOffLimits,
            renderLiffer: RenderLiffer,
            colorBasedscore: ColorBasedscore,
            renderAction: RenderAction,
            renderRejectReasons: RenderRejectReasons,
            renderSummary: RenderSummary,
            renderTags: RenderTags,
            locationScore: LocationScore,
            renderCheckbox: RenderCheckbox,
            renderBookmark: RenderBookmark
          }}
          rowHeight={70}
          suppressScrollOnNewData={true}
          pagination={true}
          animateRows={true}
          rowGroupPanelShow='always'
          saveGridSetting={true}
          paginationPageSize={50}
          defaultColumnDefs={p1ColDefs}
          setFilteredGridData={handleFilteredGridData}
          noDoubleReset
        />
      </div>
      {quickLoadingPopup && (
        <SuccessPopup
          open={quickLoadingPopup}
          setOpen={setQuickLoadingPopup}
          autoClose={autoClose}
          setAutoClose={setAutoClose}
          isLoading={isQuickLoading}
          btn={true}
          onViewCandidate={() => {
            setQuickLoadingPopup(false);
            refetchData();
          }}
        />
      )}
    </div>
  );
}

ProductOne.propTypes = {
  projectData: propTypes.object.isRequired,
  sort: propTypes.object,
  setSort: propTypes.func
};

export default ProductOne;

const createQueryParams = filters => {
  const params = {};
  filters.forEach(filter => {
    if (filter.value !== null || filter.value !== '' || filter.value !== 0 || filter.value !== undefined || isNaN(filter.value)) {
      if (filter.id !== 'tags') {
        if (filter.type === 'gte' || filter.type === 'lte') {
          params[`filters[${filter.id}][${filter.type}]`] = parseInt(filter.value) / 100;
        } else {
          params[`filters[${filter.id}][${filter.type}]`] = parseInt(filter.value) / 100;
        }
      }
    }
  });

  filters.forEach(filter => {
    if (filter.value === null || filter.value === '' || filter.value === 0) {
      params[`filters[${filter.id}][${filter.type}]`] = undefined;
    }
  });

  return params;
};

const checkSearchEligible = (projectData, t) => {
  let searchEligible = {
    isEligible: true,
    message: []
  };

  if (!projectData?.targetCompanies?.length > 0) {
    searchEligible.isEligible = false;
    searchEligible.message.push(t('utils.targetCompanies'));
  }

  if (!projectData?.targetIndustries?.length > 0) {
    searchEligible.isEligible = false;
    searchEligible.message.push(t('utils.targetIndustries'));
  }

  if (!projectData?.targetJobFunctions?.length > 0) {
    searchEligible.isEligible = false;
    searchEligible.message.push(t('utils.targetFunction'));
  }

  if (!projectData?.targetLocation?.length > 0) {
    searchEligible.isEligible = false;
    searchEligible.message.push(t('utils.targetLocation'));
  }

  if (!projectData?.targetTitles?.length > 0) {
    searchEligible.isEligible = false;
    searchEligible.message.push(t('utils.targetTitle'));
  }

  return searchEligible;
};
