import { alpha } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

import { AllCommunityModule, ClientSideRowModelApiModule, ClientSideRowModelModule, ModuleRegistry, colorSchemeVariable, iconSetMaterial, themeQuartz } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AllEnterpriseModule, CheckboxEditorModule, CsvExportModule, ExcelExportModule, RangeSelectionModule, RowGroupingModule, ServerSideRowModelModule, TextFilterModule } from 'ag-grid-enterprise';

import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import GridActions from './AgGridAction';
// import './AgGridDefault.scss';
import { Tooltip } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { gridSettingsApi } from '../services/ApiService';
import { DELETE } from '../services/constantService';
import './AgGridIcon.scss';
import attachEventListenersToGrid from './SaveGridSetting';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  CheckboxEditorModule,
  AllCommunityModule,
  AllEnterpriseModule,
  ClientSideRowModelModule,
  RowGroupingModule,
  ExcelExportModule,
  CsvExportModule,
  TextFilterModule,
  RangeSelectionModule,
  ClientSideRowModelApiModule
]);

// If required use this col def on your columnDefs
export const defaultDefaultColDef = {
  sortable: true,
  resizable: true,
  enableRowGroup: true,
  floatingFilter: false,
  maxWidth: 600,
  sortingOrder: ['asc', 'desc', null],
  flex: 1,
  suppressHeaderMenuButton: false, // Disable column menu (three dots),
  filterParams: {
    debounceMs: 800, // Wait 800ms before applying the filter
    applyButton: false, // No Apply button needed
    clearButton: true // Show a clear button if needed
  },
  filter: 'agTextColumnFilter',
  enableValue: true
};

const AgGridWrapper = ({
  columnDefs = [],
  defaultColumnDefs = [],
  rowData,
  rowHeight = 50,
  gridOptions = {
    headerHeight: 49,
    animateRows: true,
    suppressContextMenu: true
  },
  defaultColDef,
  onGridReady: onGridReadyCallback,
  rowSelection = {},
  pagination = true,
  paginationPageSize = 50, // Default records to show set to 200
  suppressRowClickSelection = true,
  animateRows = true,
  blockLoadDebounceMillis = 500,
  gridType,
  filterModel = {},
  sortModel = [],
  enableAdvancedFilter = false,
  saveGridSetting = false,
  showSidebar = true,
  onCellClicked,
  groupDefaultExpanded = -1,
  noDoubleReset = false,
  autoSizStrategy = { type: 'fitCellContents' },
  sideBarColumnToolPanelParams = {
    suppressRowGroups: false,
    suppressValues: false,
    suppressPivotMode: false
  }, //for enabling or disabling pivot , row grouping , values in sidebar
  showFilters = true,
  gridDisclaimer,
  setFilteredGridData,
  ...restProps
}) => {
  const { t } = useTranslation();
  const gridApiRef = useRef(null);
  const [isGridReady, setIsGridReady] = useState(false);
  const [columnDefsState, setColumnDefsState] = useState(columnDefs);
  const [advancedFilters, setAdvancedFilters] = useState(false);
  const [themeColor, setThemeColor] = useState({});

  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  const myTheme = useMemo(
    () =>
      themeQuartz
        .withPart(iconSetMaterial)
        .withPart(colorSchemeVariable)
        .withParams({
          fontFamily: {
            googleFont: 'Open Sans'
          },
          accentColor: themeColor.primary_color ? themeColor.primary_color : '#23CEA7',
          advancedFilterBuilderButtonBarBorder: true,
          backgroundColor: '#FFFFFF',
          columnBorder: false,
          fontSize: '13px',
          foregroundColor: themeColor.text_color ? themeColor.text_color : '#222',
          headerBackgroundColor: themeColor.primary_color ? alpha(themeColor.primary_color, 0.3) : '#23CEA7',
          headerColumnBorder: false,
          headerFontSize: 14,
          headerFontWeight: 700,
          borderRadius: '6px',
          headerTextColor: themeColor.heading_color ? themeColor.heading_color : '#222',
          menuTextColor: themeColor.heading_color ? themeColor.heading_color : '#222',
          iconSize: 18,
          checkboxUncheckedBorderColor: themeColor.primary_color ? themeColor.primary_color : '#23CEA7',
          footerRowBorder: true,
          sideBarBackgroundColor: '#FFFFFF'
        }),
    [themeColor]
  );

  const addDisclaimerToPagingPanel = useCallback(() => {
    const pagingPanel = document.querySelector('.ag-paging-panel');
    if (pagingPanel) {
      const disclaimerText = gridDisclaimer ? gridDisclaimer : t('grid.gridDisclaimer');
      const pagingPanelWidth = pagingPanel.offsetWidth;

      // if pagingPanel is less than 970 px use tooltip else P tag
      if (pagingPanelWidth < 970) {
        const tooltipContainer = document.createElement('div');
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.left = '10px';
        tooltipContainer.style.maxWidth = '45%';
        tooltipContainer.style.whiteSpace = 'nowrap';
        tooltipContainer.style.textOverflow = 'ellipsis';
        tooltipContainer.style.overflow = 'hidden';
        pagingPanel.appendChild(tooltipContainer);

        const tooltipElement = (
          <Tooltip title={disclaimerText} arrow placement='top'>
            <span>{disclaimerText}</span>
          </Tooltip>
        );

        const root = createRoot(tooltipContainer);
        root.render(tooltipElement);
      } else {
        const paragraph = document.createElement('p');
        paragraph.textContent = disclaimerText;
        paragraph.style.position = 'absolute';
        paragraph.style.left = '10px';
        pagingPanel.appendChild(paragraph);
      }
    }

    return null;
  }, []);

  const onGridReady = useCallback(
    params => {
      gridApiRef.current = params.api;
      setIsGridReady(true);
      if (onGridReadyCallback) {
        const obj = {
          api: params.api,
          columnApi: params.columnApi
        };
        onGridReadyCallback(obj);
      }

      params.api.sizeColumnsToFit();
      if (saveGridSetting) {
        attachEventListenersToGrid(params, gridType);
        addDisclaimerToPagingPanel();
      }
    },
    [addDisclaimerToPagingPanel]
  );

  useEffect(() => {
    setColumnDefsState(columnDefs);
  }, [columnDefs]);

  // useEffect(() => {
  //   if (columnDefsState.length && gridApiRef.current) {
  //     columnDefsState.forEach(col => {
  //       if (!col.colId || (!'visible') in col) return;
  //       console.log('setting col=>>', col.colId, col.visible);
  //       gridApiRef.current?.setColumnsVisible([col.colId], col.visible);
  //     });
  //   }
  // }, [columnDefsState, gridApiRef.current]);

  const applyGridState = useCallback(() => {
    if (!isGridReady) return;

    try {
      if (Object.keys(filterModel).length) {
        gridApiRef.current.setFilterModel(filterModel);
        gridApiRef.current.onFilterChanged();
      }
      if (sortModel.length) {
        gridApiRef.current.applyColumnState({
          state: sortModel.map(({ colId, sort }) => ({ colId, sort })),
          applyOrder: false
        });
      }
    } catch (err) {
      console.error('Error restoring grid state:', err);
    }
  }, [filterModel, sortModel, isGridReady]);

  const getVisibleRows = useCallback(e => {
    if (!gridApiRef.current) return;

    const rows = [];
    gridApiRef.current.forEachNodeAfterFilterAndSort(node => {
      if (!isEmpty(node.data)) {
        rows.push(node.data);
      }
    });

    if (setFilteredGridData) {
      setFilteredGridData(rows);
    }
  }, []);

  // Use useEffect to get the latest grid state after filter/sort change
  useEffect(() => {
    if (!gridApiRef.current) return;

    // Call getVisibleRows() after a filter or sort is applied
    const api = gridApiRef.current;
    api.addEventListener('columnRowGroupChanged', getVisibleRows);
    api.addEventListener('filterChanged', getVisibleRows);
    api.addEventListener('sortChanged', getVisibleRows);

    return () => {
      api.removeEventListener('columnRowGroupChanged', getVisibleRows);
      api.removeEventListener('filterChanged', getVisibleRows);
      api.removeEventListener('sortChanged', getVisibleRows);
    };
  }, [gridApiRef.current]);

  useEffect(() => {
    const timer = setTimeout(() => {
      applyGridState();
    }, 500);

    return () => clearTimeout(timer); // Clean up timeout on unmount or dependency change
  }, [applyGridState]);

  const exportToCSV = useCallback(() => {
    if (!gridApiRef.current) return;
    gridApiRef.current.exportDataAsCsv();
  }, []);

  const exportToExcel = useCallback(() => {
    if (!gridApiRef.current) return;
    gridApiRef.current.exportDataAsExcel();
  }, []);

  const resetFilters = useCallback(() => {
    if (!gridApiRef.current) return;
    gridApiRef.current.setFilterModel(null);
    gridApiRef.current.onFilterChanged();
    // saveGridState();
  }, []);

  const resetSorting = useCallback(() => {
    if (!gridApiRef.current) return;
    // Clear all sorting by setting an empty sort state
    gridApiRef.current.applyColumnState({
      state: gridApiRef.current.getColumnState().map(col => ({ ...col, sort: null }))
    });
    // saveGridState();
  }, []);

  const resetPersonalization = useCallback(
    exit => {
      if (!gridApiRef.current) return;
      if (saveGridSetting) {
        gridSettingsApi(DELETE, null, null, `?grid_key=${gridType}`)
          .then(res => {
            if (res?.status !== 200) console.error('grid settings not deleted', res);
          })
          .catch(err => {
            console.error('error in deleting grid setting', err);
          });
      }
      gridApiRef.current.setColumnFilterModel(null);
      gridApiRef.current.setFilterModel(null);
      gridApiRef.current.applyColumnState({
        defaultState: { sort: null }
      });
      const groupCols = gridApiRef.current.getRowGroupColumns();
      gridApiRef.current.removeRowGroupColumns(groupCols);
      gridApiRef.current.setGridOption('pivotMode', false);
      gridApiRef.current.resetColumnState();
      if (defaultColumnDefs) {
        gridApiRef.current.updateGridOptions('columnDefs', defaultColumnDefs);
        gridApiRef.current.setGridOption('columnDefs', defaultColumnDefs);
      }
      if (!noDoubleReset) {
        if (exit) {
          return;
        } else {
          setTimeout(() => resetPersonalization(true), 100);
        }
      }
    },
    [gridApiRef.current]
  );

  const refreshGrid = useCallback(() => {
    if (!gridApiRef.current) return;
    gridApiRef.current.refreshCells({ force: true });
  }, []);

  const sizeColumnsToFit = useCallback(() => {
    if (!gridApiRef.current) return;
    gridApiRef.current.sizeColumnsToFit();
  }, []);

  const getSelectedRows = useCallback(() => {
    if (!gridApiRef.current) return;
    const selectedNodes = gridApiRef.current.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    // console.log('Selected Rows:', selectedData);
  }, []);

  //for column chooser
  const showColumnChooser = useCallback(() => {
    gridApiRef.current?.showColumnChooser();
  }, []);

  // commented for later use
  // const saveGridState = useCallback(() => {
  //   if (gridApiRef.current) return;

  //   // Get current state
  //   const latestColumnState = gridApiRef.current.getColumnState();
  //   const sortingLatestState = latestColumnState.map(col => col.colId);
  //   let columnState = gridApiRef?.current?.getColumns().map(col => ({ ...col.colDef, visible: col.visible, colId: col.colId }));
  //   const sortThis = JSON.parse(JSON.stringify(columnState));
  //   let updateColumnColumns = _.sortBy(sortThis, col => sortingLatestState.indexOf(col.colId));

  //   const state = {
  //     settings: updateColumnColumns,
  //     filter: gridApiRef?.current?.getFilterModel(),
  //     sort: gridApiRef?.current
  //       ?.getColumnState()
  //       ?.filter(col => col.sort)
  //       .map(col => ({ colId: col.colId, sort: col.sort })),
  //     grid_key: gridType
  //   };

  //   // Save to localStorage and show current state
  //   // localStorage.setItem('gridState', JSON.stringify(state));

  //   // save to backend
  //   if (state)
  //     console.log('Saved grid state:', {
  //       columnState: state.settings, // Shows column widths, order, visibility
  //       filterModel: state.filter, // Shows active filters
  //       sortModel: state.sort // Shows active sorting
  //     });
  //   saveGridApi(state);
  // }, []);

  const onColumnResized = useCallback(params => {
    if (params.column && params.column.colId) {
      // debounce(saveGridState(), 1000);
    }
  }, []);

  const onColumnVisible = useCallback(params => {
    if (params.column && params.column.colId) {
      // debounce(saveGridState(), 1000);
    }
  }, []);

  const onColumnMoved = useCallback(params => {
    if (params.column && params.column.colId) {
      // saveGridState();
    }
  }, []);

  // const onSelectionChanged = useCallback(params => {
  //   getSelectedRows();
  // }, []);

  const onColumnMenuVisibleChanged = useCallback(event => {
    // console.log('columnMenuVisibleChanged', event);
  }, []);

  //show/hide advanced filters in ag grid
  const showAdvancedFilter = useCallback(() => {
    setAdvancedFilters(prev => !prev);
  }, []);

  //for group column which shows up when grouping is applied
  const autoGroupColumnDef = useMemo(
    () => ({
      minWidth: 150, // Set minimum width for the group column
      width: 180, // Optional: Set default width
      headerName: 'Group', // Optional: Custom header name
      cellRendererParams: {
        innerRenderer: ({ node }) => node?.key,
        suppressCount: false // Show count of grouped rows,
      },
      cellRenderer: 'agGroupCellRenderer'
    }),
    []
  );

  //options to be included in column menu(there dots)
  const mainMenuItems = useCallback(params => {
    const allowedItems = ['sortAscending', 'sortDescending', 'pinSubMenu', 'autoSizeThis', 'autoSizeAll'];
    return params.defaultItems.filter(item => allowedItems.includes(item));
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {/* <div style={{ height: '100%', width: '100%' }}> */}
      <AgGridReact
        onCellClicked={onCellClicked}
        rowData={rowData}
        theme={myTheme}
        columnDefs={columnDefsState}
        maintainColumnOrder
        // Removing "defaultColDef" as this is causing the column to revert back on changes
        // default Columns defs should be passed along with the columnDefs
        getMainMenuItems={mainMenuItems}
        onGridReady={onGridReady}
        selectionColumnDef={{
          lockPinned: true,
          pinned: 'left',
          minWidth: 120,
          maxWidth: 100,
          suppressHeaderMenuButton: true
        }}
        rowSelection={rowSelection}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        pagination={pagination}
        suppressRowClickSelection={suppressRowClickSelection}
        animateRows={animateRows}
        suppressHorizontalScroll={false}
        blockLoadDebounceMillis={blockLoadDebounceMillis}
        getRowId={data => data?.data?.id}
        enableAdvancedFilter={advancedFilters}
        onColumnMenuVisibleChanged={onColumnMenuVisibleChanged}
        rowHeight={rowHeight}
        onColumnResized={onColumnResized}
        onColumnVisible={onColumnVisible}
        onColumnMoved={onColumnMoved}
        autoGroupColumnDef={autoGroupColumnDef}
        suppressHeaderMenuButton={true}
        groupDefaultExpanded={groupDefaultExpanded}
        autoSizeStrategy={autoSizStrategy}
        sideBar={
          showSidebar
            ? {
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: sideBarColumnToolPanelParams
                  },
                  // 'filters',
                  ...(showFilters ? ['filters'] : []),
                  {
                    id: 'actions',
                    labelDefault: 'Actions',
                    labelKey: 'actions',
                    iconKey: 'custom-stats',
                    toolPanel: GridActions,
                    toolPanelParams: {
                      title: 'Actions',
                      resetFilters: resetFilters,
                      resetPersonalization: resetPersonalization,
                      onExport: exportToCSV,
                      resetSorting: resetSorting,
                      onExcelExport: exportToExcel,
                      toggleAdvancedFilter: showAdvancedFilter,
                      enableAdvancedFilter: enableAdvancedFilter,
                      advancedFilters: advancedFilters,
                      showColumnChooser
                    }
                  }
                ],
                defaultToolPanel: '',
                hiddenByDefault: false
              }
            : false
        }
        {...gridOptions}
        {...restProps}
      />
    </div>
    // </div>
  );
};

AgGridWrapper.propTypes = {
  columnDefs: PropTypes.array,
  onCellClicked: PropTypes.func,
  rowData: PropTypes.array,
  gridOptions: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  theme: PropTypes.string,
  defaultColDef: PropTypes.object,
  onGridReady: PropTypes.func,
  rowSelection: PropTypes.object,
  pagination: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  suppressRowClickSelection: PropTypes.bool,
  animateRows: PropTypes.bool,
  blockLoadDebounceMillis: PropTypes.number,
  rowHeight: PropTypes.number,
  showHeaderMenu: PropTypes.bool,
  gridType: PropTypes.string.isRequired,
  filterModel: PropTypes.object,
  sortModel: PropTypes.array,
  enableAdvancedFilter: PropTypes.bool,
  saveGridSetting: PropTypes.bool,
  //total count props
  rowCount: PropTypes.number,
  isRowSelected: PropTypes.bool,
  selectedRowsCount: PropTypes.number,
  loadedContactCount: PropTypes.number,
  showTotalCount: PropTypes.bool,
  selectedRows: PropTypes.array,
  showAddContactToWorkbench: PropTypes.bool,
  showSidebar: PropTypes.bool,
  sideBarColumnToolPanelParams: PropTypes.object,
  showFilters: PropTypes.bool,
  setFilteredGridData: PropTypes.func,
  groupDefaultExpanded: PropTypes.number,
  autoSizStrategy: PropTypes.object,
  defaultColumnDefs: PropTypes.array,
  gridDisclaimer: PropTypes.string,
  noDoubleReset: PropTypes.bool
};

const StageTableDataUnchanged = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default React.memo(AgGridWrapper, StageTableDataUnchanged);
