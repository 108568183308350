import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Checkbox, Chip, Grid, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDropDown } from '../../../common/ButtonDropdown';
import CustomValueSelection from '../../../common/FunctionalComponents/CustomValueSelection';
import PersonalityFilterModal from './PersonalityFilter';

const FilterMenu = ({
  PersonalityTabCreateOptions,
  projectFilterOptions,
  groupByOptions,
  filterByProject,
  groupBy,
  filterOptions,
  isPublished,
  isFilterModalOpen,
  filterAnchorEl,
  handlePersonalityField,
  setFilterByProject,
  filterByProjectCallback,
  setGroupBy,
  handleFilterClick,
  handleFilterModalClose,
  handleFilterUpdate,
  setIsFilterApplied,
  setIsPublished,
  setFilterOptions,
  isFilterApplied
}) => {
  const { t } = useTranslation();

  const removeFilter = key => {
    const updatedFilterOptions = {
      ...filterOptions,
      [key]: {
        ...filterOptions[key],
        value: !filterOptions[key].value
      }
    };

    // Check if any filters are still active
    const hasActiveFilters = Object.values(updatedFilterOptions).some(option => option.value);

    // Update filter options
    setFilterOptions(updatedFilterOptions);
    handleFilterUpdate(updatedFilterOptions);

    // If no active filters and not published, reset the filter state
    if (!hasActiveFilters && !isPublished) {
      setIsFilterApplied(false);
      filterByProjectCallback(filterByProject);
    }
  };
  const removePublishFilter = () => {
    setIsPublished(false);

    // Check if any other filters arise active
    const hasActiveFilters = Object.values(filterOptions).some(option => option.value === true);

    // If no other filters are active, reset the filter state
    if (!hasActiveFilters) {
      setIsFilterApplied(false);
      filterByProjectCallback(filterByProject);
    }
  };

  return (
    <>
      <Box className='menu-container my-1 w-100'>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <ButtonDropDown
              ellipsis
              show
              iconRight={<ArrowDropDownIcon />}
              options={PersonalityTabCreateOptions}
              renderPropertyName='label'
              buttonStyle={{
                color: '#fff'
              }}
              className='menu-container-button-dropdown'
              onClick={val => handlePersonalityField(val.value, '', false)}
              buttonText={t('actions.new')}
              ellipsisWidth='110px'
            />
          </Grid>

          <Grid item xs={5}>
            <CustomValueSelection
              options={projectFilterOptions || []}
              labelProperty='label'
              placeholder={t('project.searchProject')}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              multiple
              value={filterByProject || []}
              disableCloseOnSelect
              textFieldStyle={{
                background: 'white',
                borderRadius: '5px'
              }}
              onChange={(e, value) => {
                setFilterByProject(value);
                filterByProjectCallback(value);
              }}
              renderOption={(props, option, { selected }) => (
                <Box {...props} display='flex' alignItems='center'>
                  <Checkbox size='small' checked={selected} />
                  <Tooltip title={option?.label}>
                    <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' color='primary' fontSize={14} fontWeight={500}>
                      {option?.label}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <CustomValueSelection
              options={groupByOptions || []}
              placeholder={`${t('utils.groupBy')} : ${groupBy?.label}`}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              value={groupBy}
              textFieldStyle={{
                background: 'white',
                borderRadius: '5px'
              }}
              onChange={(e, value) => (!value ? setGroupBy(groupByOptions[0]) : setGroupBy(value))}
              renderOption={(props, option, { selected }) => (
                <Box {...props} display='flex' alignItems='center'>
                  <Checkbox size='small' checked={selected} />
                  <Tooltip title={option?.label}>
                    <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' color='primary' fontSize={14} fontWeight={500}>
                      {option?.label}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={2}>
            <Box className='w-100' onClick={handleFilterClick}>
              <Typography
                className='button-label'
                sx={{
                  cursor: 'pointer',
                  width: '100%',
                  height: '33px',
                  textTransform: 'none',
                  fontWeight: 500,
                  color: '#fff',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {t('project.candidateScore.filterText')}
              </Typography>
            </Box>
            <PersonalityFilterModal
              setIsPublished={setIsPublished}
              isPublished={isPublished}
              open={isFilterModalOpen}
              onClose={handleFilterModalClose}
              anchorEl={filterAnchorEl}
              filterOptions={filterOptions}
              onFilterUpdate={handleFilterUpdate}
              setIsFilterApplied={setIsFilterApplied}
            />
          </Grid>
        </Grid>
      </Box>

      {isFilterApplied && (
        <Box className='apply-filter-container' sx={{ minWidth: '100%' }}>
          <Typography className='apply-filter-label fs-12'>{t('contacts.filterContacts.appliedFilters')}:</Typography>
          {Object.keys(filterOptions)
            .filter(key => filterOptions[key]?.value)
            .map((key, index) => (
              <Chip key={index} label={`${t('utils.notes')}: ${filterOptions[key]?.label}`} variant='outlined' onDelete={() => removeFilter(key)} className='apply-filter-chip' />
            ))}
          {isPublished && <Chip label={`${t('actions.status')}: ${t('project.published')}`} variant='outlined' onDelete={() => removePublishFilter()} className='apply-filter-chip' />}
        </Box>
      )}
    </>
  );
};

FilterMenu.propTypes = {
  PersonalityTabCreateOptions: PropTypes.array,
  projectFilterOptions: PropTypes.array,
  groupByOptions: PropTypes.array,
  filterByProject: PropTypes.array,
  groupBy: PropTypes.object,
  filterOptions: PropTypes.object,
  isPublished: PropTypes.bool,
  isFilterModalOpen: PropTypes.bool,
  filterAnchorEl: PropTypes.object,
  handlePersonalityField: PropTypes.func,
  setFilterByProject: PropTypes.func,
  filterByProjectCallback: PropTypes.func,
  setGroupBy: PropTypes.func,
  handleFilterClick: PropTypes.func,
  handleFilterModalClose: PropTypes.func,
  handleFilterUpdate: PropTypes.func,
  setIsFilterApplied: PropTypes.func,
  setIsPublished: PropTypes.func,
  setFilterOptions: PropTypes.func,
  isFilterApplied: PropTypes.bool
};

export default FilterMenu;
