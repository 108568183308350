import AvTimerIcon from '@mui/icons-material/AvTimer';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../../../services/constantService';
import { convertToLocalTimeAndDate } from '../../../../utils/date';

const TranscriptionInProcessMessage = props => {
  const { isFromLogAnActivity = false, analyzeTranscript, downloadReport, subRoute, processingList = [], completedList = [], fileType = 'transcription', handleClose } = props;
  const { dateFormat, timeZoneFormat, timeFormats } = useSelector(state => state.profileFormatSlice);
  const { t } = useTranslation();
  const isTranscribe = useMemo(() => subRoute === 'transcribe', [subRoute]);
  if (!processingList.length && !completedList.length) {
    return <></>;
  }

  return (
    <Box className='col-12'>
      {processingList.length > 0 ? (
        <Box className={'col-12'} mb={2}>
          <Box className='text-center bold fs-18'>{t('echoSense.inProgress', { type: isTranscribe ? 'Transcribe' : 'Analysis' })}</Box>
          <Box className='text-center'>{t('echoSense.progressTiming', { type: isTranscribe ? 'Transcribe' : 'Analysis', method: fileType == 'transcript' ? 'transcribe' : 'analyze' })}</Box>
          {isFromLogAnActivity ? (
            <Box className='text-center'>
              {t('echoSense.visitLink')}
              <a href={ROUTES.ignyteIntelligence} className='custom-link'>
                Click here
              </a>
            </Box>
          ) : (
            <Box className='text-center'>
              {t('echoSense.visitProgress', { type: fileType == 'transcript' ? '"Transcribe"' : '"Analysis"', method: fileType == 'transcript' ? 'Transcript' : 'Analyzed' })}
            </Box>
          )}
        </Box>
      ) : completedList.length > 0 ? (
        <Box className={'col-12'}>
          <Box className='text-center bold fs-18'>{t('echoSense.progress', { type: isTranscribe ? 'Transcribe' : 'Analysis' })}</Box>
        </Box>
      ) : (
        <></>
      )}
      <Box display='flex' flexDirection='column' gap='20px' maxHeight='350px' sx={{ overflowY: 'auto' }}>
        {processingList.map((item, index) => {
          const addedTime = dayjs(item.created_at).add(isTranscribe ? 10 : 20, 'minutes');
          const remainingMins = addedTime.diff(dayjs(), 'minutes');
          return (
            <Box
              key={index}
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              className={'col-6'}
              margin='auto'
              bgcolor='#F0F0F0'
              borderRadius='6px'
              paddingX='10px'
              paddingY='15px'
              style={{ border: '1px solid #CACACA' }}
            >
              <Box display='inherit' flexDirection='row' alignItems='center'>
                <Box bgcolor='#DBDBDB' borderRadius='26px' height='36px' width='36px' display='inherit' alignItems='center'>
                  <AvTimerIcon sx={{ fill: '#fff' }} className='mx-1' />
                </Box>
                <Box ml={1} display='flex' flexDirection='column' maxWidth={220}>
                  <Typography variant='caption' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                    {item.friendly_name}
                  </Typography>
                  <Typography variant='label' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                    {subRoute === 'transcribe' && !item.analysis_type ? item.audio_file_s3_key?.split('/').pop() : item.transcript_file_s3_key?.split('/').pop()}
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' flexDirection='column' alignItems='flex-end' mr='10px'>
                <Typography variant='caption'>@ {convertToLocalTimeAndDate(new Date(item.created_at), false, timeZoneFormat?.value, dateFormat?.value, timeFormats?.value)}</Typography>
                {remainingMins > 0 && (
                  <Typography variant='caption' color='#1833BEB2'>
                    {remainingMins} {t('echoSense.minRemaining')}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
        {completedList.length > 0 && (
          <>
            {isTranscribe && (
              <Box display='flex' flexDirection='column' alignItems={'center'}>
                <Typography className='bold' fontSize='14px' color='#333333'>
                  {t('common.completed')}
                </Typography>
                <Typography fontSize='12px' color='#888888'>
                  {t('echoSense.analyzeOrDownload')}
                </Typography>
              </Box>
            )}
            {completedList.map((item, index) => {
              return (
                <Box
                  key={index}
                  display='flex'
                  flexDirection='row'
                  justifyContent='space-between'
                  className={'col-6'}
                  margin='auto'
                  bgcolor='#F0F0F0'
                  borderRadius='6px'
                  paddingX='10px'
                  paddingY='15px'
                  style={{ border: '1px solid #CACACA' }}
                >
                  <Box display='inherit' flexDirection='row' alignItems='center'>
                    <Box bgcolor='#23CEA7' borderRadius='26px' height='36px' width='36px' display='inherit' alignItems='center'>
                      <CheckIcon sx={{ fill: '#fff' }} className='mx-1' />
                    </Box>
                    <Box ml={1} display='flex' flexDirection='column' maxWidth={220}>
                      <Typography variant='caption' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                        {item.friendly_name}
                      </Typography>
                      <Typography vxariant='label' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                        {subRoute === 'transcribe' && !item.analysis_type ? item.audio_file_s3_key?.split('/').pop() : item.transcript_file_s3_key?.split('/').pop()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='column' alignItems='flex-end' justifyContent={analyzeTranscript || downloadReport ? 'space-between' : 'center'} mr='10px'>
                    <Typography variant='caption'>@ {convertToLocalTimeAndDate(new Date(item.created_at), false, timeZoneFormat?.value, dateFormat?.value, timeFormats?.value)}</Typography>
                    {subRoute === 'transcribe' && !item.analysis_type && (
                      <Box display='flex' flexDirection='row' gap={3}>
                        {analyzeTranscript && (
                          <Typography
                            style={{ fontSize: 12 }}
                            onClick={() => analyzeTranscript(item)}
                            display='flex'
                            alignItems='center'
                            fontWeight='600'
                            fontSize='12px'
                            color='#BE185D'
                            sx={{ cursor: 'pointer' }}
                          >
                            <ShowChartIcon style={{ height: 14, width: 14, marginRight: 5 }} /> {t('actions.analyzeNow')}
                          </Typography>
                        )}
                        {downloadReport && (
                          <Typography
                            style={{ fontSize: 12 }}
                            onClick={() => downloadReport(item.transcript_file_s3_key)}
                            display='flex'
                            alignItems='center'
                            fontWeight='600'
                            fontSize='12px'
                            color='#23CEA7'
                            sx={{ cursor: 'pointer' }}
                          >
                            <SaveAltOutlinedIcon style={{ height: 14, width: 14, marginRight: 5 }} />
                            {t('actions.downloadFile')}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                  <IconButton
                    sx={{
                      fontSize: 12,
                      position: 'absolute',
                      right: 5,
                      top: 0,
                      padding: 0
                    }}
                    onClick={() => handleClose(item.id)}
                  >
                    <CloseIcon style={{ height: 20, width: 20 }} />
                  </IconButton>
                </Box>
              );
            })}
          </>
        )}
      </Box>
      <hr />
    </Box>
  );
};

TranscriptionInProcessMessage.propTypes = {
  analyzeTranscript: PropTypes.func,
  downloadReport: PropTypes.func,
  subRoute: PropTypes.string,
  fileType: PropTypes.string,
  processingList: PropTypes.array,
  completedList: PropTypes.array,
  handleClose: PropTypes.func,
  isFromLogAnActivity: PropTypes.bool
};

export default TranscriptionInProcessMessage;
