import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemComponent } from '.';
import { FormGrid } from './FormGrid';

function AddContactDrawerSectionFive(props) {
  const { setValue, watch, certificationTypeList, loadingDropdownData, type, addNewColumnBySection } = props;
  const { t } = useTranslation();
  const columnList = [
    {
      field: 'type',
      headerName: t('addContactDrawer.certificateType'),
      xs: 2.9
    },
    {
      field: 'title',
      headerName: t('addContactDrawer.certificateTitle'),
      xs: 5.8
    },
    {
      field: 'expires',
      headerName: t('addContactDrawer.certificateExpiresOn'),
      xs: 2.5
    },
    {
      field: '',
      headerName: '',
      xs: 0.5
    }
  ];
  const Columns = (
    <Grid container alignItems={'center'} height={'100%'} width={'100%'} pl={1.5}>
      {columnList.map((column, index) => {
        return (
          <Grid
            item
            xs={column.xs}
            key={index}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              width: '100%',
              fontSize: '14px',
              fontWeight: '500',
              color: 'primary.main',
              textAlign: 'left'
            }}
          >
            {column.headerName}
          </Grid>
        );
      })}
    </Grid>
  );

  const addNewColumn = () => {
    addNewColumnBySection('certifications_and_licenses');
  };
  const deleteColumn = id => {
    const list = watch('certifications_and_licenses') || [];
    setValue(
      'certifications_and_licenses',
      list.filter(item => item.id !== id)
    );
  };
  const editColumn = (id, key, value) => {
    const list = watch('certifications_and_licenses') || [];
    setValue(
      'certifications_and_licenses',
      list.map(item => (item.id === id ? { ...item, [key]: value, edit: true } : item))
    );
  };
  const onSave = () => {
    const list = watch('certifications_and_licenses') || [];
    const newList = list.map(item => {
      return {
        ...item,
        edit: false,
        new: false
      };
    });
    setValue('certifications_and_licenses', newList);
  };
  const onCancel = () => {
    const list = watch('certifications_and_licenses') || [];
    const filteredList = list.filter(item => !item?.new);
    const newList = filteredList.map(item => {
      return {
        ...item,
        edit: false,
        new: false
      };
    });
    setValue('certifications_and_licenses', newList);
  };

  return (
    <>
      <Grid
        container
        border={'1px solid'}
        borderColor={'primary.main'}
        sx={{
          width: '100%',
          minHeight: '100px',
          borderRadius: '8px'
        }}
      >
        <Grid item xs={12} alignItems={'center'} justifyContent={'center'} sx={{ bgcolor: 'primary.light1', height: '50px', borderBottom: '1px solid', borderColor: 'primary.main' }}>
          {Columns}
        </Grid>
        {watch('certifications_and_licenses') && watch('certifications_and_licenses')?.length > 0 ? (
          <>
            {watch('certifications_and_licenses')?.map((item, index) => {
              return (
                <>
                  {item?.edit ? (
                    <Grid item key={index} xs={12} px={1} pb={1} pt={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                      <AddCertificateAndLicenses
                        value={item}
                        setValue={editColumn}
                        onDelete={deleteColumn}
                        id={item?.id}
                        certificationTypeList={certificationTypeList}
                        loadingDropdownData={loadingDropdownData}
                      />
                    </Grid>
                  ) : (
                    <Grid item key={index} xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                      <ListItemComponent
                        sx={{
                          mb: 0,
                          borderRadius: '0px',
                          p: 0,
                          px: 1,
                          minHeight: '40px',
                          bgcolor: 'text.white3'
                        }}
                        customList={[
                          {
                            id: 1,
                            title: item?.type?.name || '',
                            value: '',
                            xs: 2.9
                          },
                          {
                            id: 2,
                            title: item?.title || '',
                            value: '',
                            xs: 5.9
                          },
                          {
                            id: 3,
                            title: item?.expires ? (dayjs(item?.expires).isValid() ? dayjs(item?.expires).format('MMM YYYY') : '') : '',
                            value: '',
                            xs: 2
                          }
                        ]}
                        onDelete={() => deleteColumn(item?.id)}
                        onEdit={() => editColumn(item?.id)}
                        actionBtnXs={1}
                      />
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <Stack
            item
            xs={12}
            px={1}
            pb={1}
            pt={1}
            gap={1}
            sx={{
              borderTop: '1px solid',
              borderColor: 'text.white1',
              height: '100px',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Typography variant='body1' textAlign={'center'}>
              {t('addContactDrawer.noCertificationsAndLicenseDataFound')}
            </Typography>
            <Button variant='contained' onClick={addNewColumn} p={0} m={0}>
              {t('addContactDrawer.addCertificationsAndLicenses')}
            </Button>
          </Stack>
        )}

        <Grid item xs={12} px={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
          {watch('certifications_and_licenses')?.length > 0 && (
            <Button variant='text' onClick={addNewColumn} p={0} m={0} sx={{ display: watch('certifications_and_licenses')?.some(item => item?.edit) ? 'flex' : 'none' }}>
              + {t('addContactDrawer.add')}
            </Button>
          )}
        </Grid>
      </Grid>
      {watch('certifications_and_licenses')?.length > 0 && (
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          width={'100%'}
          gap={1}
          mt={1}
          mr={1}
          sx={{
            display: type === 'edit' ? 'none' : watch('certifications_and_licenses')?.some(item => item?.edit) ? 'flex' : 'none'
          }}
        >
          <Button variant='outlined' onClick={onCancel}>
            {t('addContactDrawer.cancel')}
          </Button>
          <Button variant='contained' onClick={onSave} startIcon={<SaveIcon fontSize='small' />}>
            {t('addContactDrawer.save')}
          </Button>
        </Stack>
      )}
    </>
  );
}
function AddCertificateAndLicenses(props) {
  const { value, setValue, id, onDelete, certificationTypeList, loadingDropdownData } = props;

  const sections = [
    {
      id: 'type',
      label: 'Type',
      type: 'dropdown',
      required: false,
      error: false,
      value: value?.type,
      options: certificationTypeList,
      getOptionLabel: option => option.name,
      loading: loadingDropdownData,
      onChange: (e, option) => {
        setValue(id, 'type', option);
      },
      xs: 2.8
    },
    {
      id: 'title',
      label: 'Title',
      type: 'text',
      required: false,
      error: false,
      value: value?.title,
      onChange: e => {
        setValue(id, 'title', e.target.value);
      },
      xs: 5.6
    },
    {
      id: 'date',
      label: 'Expires On',
      type: 'date',
      required: false,
      error: false,
      value: value?.expires,
      onChange: e => {
        setValue(id, 'expires', e);
      },
      xs: 2.5
    },
    {
      id: 'custom',
      type: 'custom',
      customComponent: () => {
        return (
          <Stack direction={'row'} justifyContent={'center'} alignItems={'baseline'}>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1'
              }}
              variant='text'
              onClick={() => onDelete(id)}
            >
              <CloseIcon color='error' />
            </Button>
          </Stack>
        );
      },
      xs: 0.5
    }
  ];
  return <FormGrid columns={sections} height={'100%'} width={'100%'} px={0} columnGap={1} />;
}
export default AddContactDrawerSectionFive;

AddContactDrawerSectionFive.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  newCertificateAndLicensesOpen: PropTypes.bool.isRequired,
  setNewCertificateAndLicensesOpen: PropTypes.func.isRequired,
  certificationTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};

AddCertificateAndLicenses.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  certificationTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired
};
