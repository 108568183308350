import { Box, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import CustomDropdown from '../../../components/common/CustomDropdown';
import FileDragUpload from '../../../components/common/FileUpload';
import RichText from '../../../components/common/RichText';

const PositionProfile = props => {
  //Translation
  const { t } = useTranslation();

  const {
    handleUniversalState,
    getValueFromUniversalState,
    reviewAndPublish = true,
    onClickReview = () => {},
    hideOptions = [],
    handleGenerate = () => {},
    handleHideOptions = () => {},
    isHideOption = false,
    handleChangeDocument = () => {},
    fileUploaded = false
  } = props;

  const handleRichText = value => {
    const event = {
      target: {
        value: value,
        name: 'description'
      }
    };
    handleUniversalState(event);
  };

  const handleReviewAndPublish = () => {
    onClickReview();
  };

  const [generateDone, setGenerateDone] = useState(false);
  const handleGenerateLocal = () => {
    handleGenerate(getValueFromUniversalState('description'));
    setGenerateDone(true);
  };
  return (
    <Box fullWidth className='p-2'>
      <Stack direction='row' justifyContent='space-between' alignItems={'baseline'}>
        <TextField
          size='small'
          label={t('project.projectNumber')}
          value={getValueFromUniversalState('projectId')}
          variant='outlined'
          color='secondary'
          type='text'
          name='projectId'
          disabled={true}
          onChange={handleUniversalState}
        />
        {isHideOption && (
          <CustomDropdown
            options={hideOptions ?? []}
            multiple={true}
            disableCloseOnSelect={true}
            isCheckBox={true}
            onChange={(event, newValue) => {
              handleHideOptions(newValue);
            }}
            label={t('utils.hideInJobPosting')}
            name='hideOption'
            value={getValueFromUniversalState('hideOption') && getValueFromUniversalState('hideOption').length ? getValueFromUniversalState('hideOption') : []}
          />
        )}
      </Stack>
      <Stack direction='row' justifyContent={'space-between'} alignItems={'baseline'}>
        <Box className='custom-personality-input my-2'>
          <Box className='formParent flex justify-center align-center resume'>
            <FileDragUpload
              className={'position-profile-transcript'}
              label={t('utils.clientIntakeCall')}
              handleChangeDocument={handleChangeDocument}
              width={'12vw'}
              showFileName={fileUploaded}
              horizontal={true}
            />
          </Box>
        </Box>
        <Stack direction='row' justifyContent={'flex-end'}>
          <CustomButton variant='text' type='btn btn-outlined-secondary mr-1' buttonText={!generateDone ? t('actions.generate') : t('actions.reGenerate')} onClick={handleGenerateLocal} />

          {reviewAndPublish && <CustomButton variant='contained' buttonText={t('utils.reviewAndPublish')} onClick={handleReviewAndPublish} />}
        </Stack>
      </Stack>
      <div className='f-12 ml-2 font-bold mt-2'>{t('utils.positionProfile')}</div>
      <Box className='education-input-base' sx={{ margin: '10px 0px' }}>
        <RichText defaultValue={getValueFromUniversalState('description')} onChange={handleRichText} style={{ height: '60vh', maxHeight: '60vh' }} customClassName={'position-profile-headers'} />
      </Box>
    </Box>
  );
};

PositionProfile.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired,
  reviewAndPublish: PropTypes.bool,
  hideOptions: PropTypes.array,
  onClickReview: PropTypes.func,
  handleGenerate: PropTypes.func,
  handleHideOptions: PropTypes.func,
  isHideOption: PropTypes.bool,
  handleChangeDocument: PropTypes.func,
  fileUploaded: PropTypes.bool
};

export default PositionProfile;
