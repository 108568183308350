//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useMemo, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

// import AddIcon from "@mui/icons-material/Add";
// import IosShareIcon from "@mui/icons-material/IosShare";
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import {
  AppBar,
  Box,
  Button,
  // IconButton,
  // Menu,
  // MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  // useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import withRouter from '../../utils/withRouter';
import '../Commons/index.scss';
// import style from "./index.module.scss"
//-----------------------------------------------------------// Internal Imports // ------------------------------
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSnackbar } from 'notistack';
import { Stack } from 'react-bootstrap';
import DynamicTabLayout from '../../components/DynamicTabs/TabLayout';
import Loader from '../../components/common/Loader';
import { ProjectDataApi } from '../../services/ApiService';
import { POST, PROJECT_SCREEN, ROUTES } from '../../services/constantService';
import HeaderWithSearch from '../Commons/Header/index';
// import ExportSideNav from "../Contacts/ExportSideNav";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useTranslation } from 'react-i18next';
import PopUpOnClick from '../Contacts/PopUpOnClick';
import CardViewContainer from './CardViewContainer';
import GridViewContainer from './GridViewContainer';
import SearchFilters from './SearchFilters';
import { PAGE_LIMIT } from './Utils';

const MySearchPage = props => {
  // const { match } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const TabData = [
    {
      label: t('module.projects'),
      content: '',
      subTabs: []
    }
  ];

  //second part
  const GRID_VIEW = 'GRID_VIEW';
  const CARD_VIEW = 'CARD_VIEW';
  // const location = useLocation();
  const [isRowSelected, setIsRowSelected] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchValue, setSearchValue] = useState(null);
  const [searchID, setSearchId] = useState('');
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [viewType, setViewType] = useState(CARD_VIEW);
  const [activeButton, setActiveButton] = useState(PROJECT_SCREEN.MY_PROJECT);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(null);
  const [initialFetch, setInitialFetch] = useState(true);
  const [hasNext, setHasNext] = useState(true);
  const { type, filter } = useParams();

  const [allProjects, setAllProjects] = useState([]);
  const [projectsLoader, setProjectsLoader] = useState(false);
  const [viewButton, setViewButton] = useState(PROJECT_SCREEN.PROJECT);
  const [stats, setStats] = useState([]);
  const [totalCountSearch, setTotalCountSearch] = useState(0);
  const searchValueRef = useRef();
  const searchIdRef = useRef();
  searchIdRef.current = searchID;
  searchValueRef.current = searchValue;

  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const [apiQuery, setApiQuery] = useState({
    query: {
      order: [
        ['updated_at', 'DESC'],
        ['job_number', 'DESC']
      ],
      page,
      limit: PAGE_LIMIT,
      bd_status: type === PROJECT_SCREEN.PROJECT ? undefined : ['target_identified'],
      stage: type === PROJECT_SCREEN.PROJECT ? ['Open'] : undefined
    }
  });

  const listForActionSelection = [
    {
      label: 'Add To Search'
      // onClick: () => handleAddToSearch(params?.data?.id),
    },
    {
      label: 'Add To Workbench'
      // onClick: () => handleAddToWorkbench(params.data?.id),
    },
    {
      label: 'Log An Activity'
    },
    {
      label: 'Assign Tag'
      // onClick: () => handleAddAsClient(params?.data?.id),
    },
    {
      label: 'Invite To Athena'
      // onClick: async () => { }
    }
  ];

  const handleSearchChange = (e, value) => {
    if (value === null && !apiQuery.query.searchValue) return;
    setHasNext(true);
    if (typeof value === 'string') {
      setSearchId(null);
      setSearchValue(value);
      setApiQuery(prev => {
        delete prev.query.id;
        return { ...prev, query: { ...prev.query, searchValue: value } };
      });
    } else if (value && value.id) {
      setSearchValue(value.job_title);
      setSearchId(value.id);

      setApiQuery(prev => {
        return { ...prev, query: { ...prev.query, id: [value.id], searchValue: value?.job_title } };
      });
    } else if (!value) {
      setSearchValue(null);
      setSearchId(null);
      setApiQuery(prev => {
        delete prev.query.id;
        delete prev.query.searchValue;
        return { ...prev, query: { ...prev.query } };
      });
    }

    if (page === 1) {
      setRefetch(!refetch);
    } else {
      setPage(1);
    }
  };

  const handleClose = (route = '') => {
    if (route?.length) {
      navigate(route);
    }
  };

  const handleParamsQuery = async () => {
    if (type === PROJECT_SCREEN.PROJECT) {
      if (filter === PROJECT_SCREEN.MY_PROJECT || filter === PROJECT_SCREEN.ALL_PROJECT) {
        setViewButton(type);
        setActiveButton(filter);
        setApiQuery({ ...apiQuery, type: filter });
      } else {
        navigate(`${ROUTES?.mySearches}/${type}/${PROJECT_SCREEN.MY_PROJECT}`);
      }
    } else if (type === PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
      if (filter === PROJECT_SCREEN.MY_BD || filter === PROJECT_SCREEN.ALL_BD) {
        setViewButton(type);
        setActiveButton(filter);
        setApiQuery({ ...apiQuery, type: filter });
      } else {
        navigate(`${ROUTES?.mySearches}/${type}/${PROJECT_SCREEN.MY_BD}`);
      }
    } else {
      navigate(`${ROUTES?.mySearches}/${PROJECT_SCREEN.PROJECT}/${PROJECT_SCREEN.MY_PROJECT}`);
    }
  };

  useEffect(() => {
    if (type) {
      const updatedApiQuery = { ...apiQuery };
      if (type === PROJECT_SCREEN.PROJECT) {
        delete updatedApiQuery.query.bd_status;
        updatedApiQuery.query.stage = ['Open'];
      }
      if (type === PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
        delete updatedApiQuery.query.stage;
        updatedApiQuery.query.bd_status = ['target_identified'];
      }
      setApiQuery(updatedApiQuery);
    }
  }, [type]);

  useEffect(() => {
    if (page === -1) return;

    if (page === 1) {
      fetchProjects();
    } else {
      fetchProjects({}, page);
    }
  }, [page]);

  useEffect(() => {
    if (refetch !== null) {
      fetchProjects();
    }
  }, [refetch]);

  useEffect(() => {
    handleParamsQuery();

    if (!initialFetch) {
      setHasNext(true);
      if (page === 1) {
        setRefetch(!refetch);
      } else {
        setPage(1);
      }
    }
  }, [type, filter]);

  const fetchProjects = async (update = {}, updatedPage = 1) => {
    if (!type || !filter) {
      setInitialFetch(true);
      setRefetch(!refetch);
      return;
    }
    if (Object?.keys(update)?.length !== 0) {
      setPage(1);
      setHasNext(true);
      if (page === 1) {
        setRefetch(!refetch);
      }
      return;
    }

    if (projectsLoader || !hasNext) return;
    try {
      setProjectsLoader(true);

      const apiKey = {
        'my-projects': 'project',
        'all-projects': 'project',
        'my-bd': 'business_development',
        'all-bd': 'business_development'
      };

      if (apiKey[filter] && type && apiKey[filter].toLowerCase() === type.toLowerCase()) {
        let sub_route = 'all';
        const method = POST;

        let payload = {
          ...apiQuery,
          ...update,
          type: filter,
          query: {
            ...apiQuery.query,
            ...update.query,
            page: updatedPage
          }
        };

        if (searchIdRef.current && searchIdRef.current !== payload?.query?.id?.[0]) {
          payload.query.id = [searchIdRef.current];
        }

        if (searchValueRef.current && searchValueRef.current !== payload?.query?.searchValue) {
          payload.query.searchValue = searchValueRef.current;
        }

        const projectData = await ProjectDataApi(method, '', payload, sub_route);
        if (projectData?.data) {
          const projects = projectData?.data?.data?.data || [];
          if (page === 1) {
            setAllProjects(projects);
            setHasNext(true);
          } else {
            setAllProjects(prev => [...prev, ...projects]);
          }
          setStats(projectData?.data?.counts || []);
          if (projects?.length < PAGE_LIMIT) {
            setHasNext(false);
          }
          setTotalCountSearch(projectData?.data?.data?.paging?.totalCount);
        }
      }
      if (initialFetch) {
        setInitialFetch(false);
      }
    } catch (err) {
      enqueueSnackbar(t('common.somethingWentWrong'));
    } finally {
      setProjectsLoader(false);
    }
  };

  const handleQuery = async update => {
    setApiQuery({ ...apiQuery, ...update });
    fetchProjects(update);
  };

  const handleViewButtonChange = value => {
    navigate(`${ROUTES.mySearches}/${value}/${filter}`);
  };
  const handleActiveButton = value => {
    navigate(`${ROUTES.mySearches}/${type}/${value}`);
  };

  const viewCount = useMemo(() => {
    return allProjects?.length;
  }, [allProjects]);

  const CountComponent = () => {
    return (
      <Typography sx={{ opacity: 0.8 }} color={'var(--palette-text-main)'} marginLeft={2.5} marginY={0.8} fontWeight={600} fontSize={13}>
        {`${totalCountSearch === 0 || allProjects?.length === 0 ? '0' : '1'}-${viewCount} of ${totalCountSearch || 0} results`}
      </Typography>
    );
  };

  return (
    <Box className='my-search d-flex flex-column my-3' position={'relative'}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          mr: 4,
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 10
        }}
      >
        {viewButton == PROJECT_SCREEN.PROJECT ? (
          <Button
            variant='contained'
            // href="/searches/add/details"
            className='add-button'
            id='basic-button'
            onClick={() => handleClose(ROUTES.addProject)}
            startIcon={<AddOutlinedIcon />}
          >
            {t('actions.add')} {t('module.project')}
          </Button>
        ) : (
          <Button
            variant='contained'
            // href="/searches/add/details"
            className='add-button'
            id='basic-button'
            onClick={() => navigate(ROUTES.createBd, { state: {} })}
            startIcon={<AddOutlinedIcon />}
          >
            Add BD
          </Button>
        )}
      </Box>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={'/searches/my-searches'}
        isDrawer={true}
        isDrawerCss={true}
        style={{ paddingTop: '100px', padding: '30px' }}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <React.Fragment index={t('module.projects')}>
          <Box>
            <Loader show={projectsLoader} />
            <HeaderWithSearch
              handleQuery={handleQuery}
              viewButton={type || PROJECT_SCREEN.PROJECT}
              setViewButton={handleViewButtonChange}
              currentQuery={apiQuery}
              activeButton={filter || activeButton}
              setActiveButton={handleActiveButton}
              handleSearchChange={handleSearchChange}
              searchValue={searchValue}
              {...props}
            />

            {/* second part */}
            <Box id='contact-tab-container' sx={{ minHeight: '75vh !important', height: '100% !important' }} className='list-contacts d-flex flex-column'>
              <Box className='tool-bar'>
                <AppBar position='static' sx={{ margin: '0 1rem', width: 'calc( 100% - 2rem )' }}>
                  <Toolbar sx={{ flexWrap: 'wrap' }} className='border shadow rounded'>
                    <SearchFilters initialFetch={initialFetch} projectsLoader={projectsLoader} currentQuery={apiQuery} handleQuery={handleQuery} projectStages={stats} />
                    <Box className='tool-bar' />
                    {/* <div>
                    <ExportSideNav
                      setIsMenuOpen={setIsMenuOpen}
                      isMenuOpen={isMenuOpen}
                      location={location}
                      navItems={navItems}
                    ></ExportSideNav>
                  </div> */}
                    <Stack direction='row' spacing={3}>
                      {isRowSelected ? <PopUpOnClick list={listForActionSelection} title={'Action'} /> : null}
                      {/* <IconButton
                      className="export-icon m-1"
                      onClick={toggleMenu}
                      >
                      <IosShareIcon className="export-icon-size" />
                    </IconButton> */}

                      {/* {viewButton == PROJECT_SCREEN.BUSINESS_DEVELOPMENT && (
                      <Button
                        variant="contained"
                        // href="/searches/add/details"
                        className="add-button"
                        id="basic-button"
                        onClick={() => handleClose(ROUTES.addProject)}
                        //todo route with contact
                      >
                        Add BD
                      </Button>
                    )} */}
                      {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => handleClose()}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      sx={{ width: "100%" }}
                    >
                      <MenuItem onClick={() => handleClose(ROUTES.addProject)}>
                        Add Project
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose(ROUTES.cloneProject)}
                      >
                        Clone Project
                      </MenuItem>
                    </Menu> */}
                      {/* {isActionDropDown && <></>}s */}
                      {viewType !== CARD_VIEW && (
                        <Button variant='outlined' className='m-1' startIcon={<ViewDayOutlinedIcon className='icon-size' />} onClick={() => setViewType(CARD_VIEW)}>
                          Card View
                        </Button>
                      )}
                      {/* {viewType !== GRID_VIEW && (
                      <Button
                        variant="outlined"
                        className="m-1"
                        startIcon={
                          <GridViewOutlinedIcon className="icon-size" />
                        }
                        onClick={() => setViewType(GRID_VIEW)}
                      >
                        Grid View
                      </Button>
                    )} */}
                    </Stack>
                  </Toolbar>
                </AppBar>
                <CountComponent />
                {viewType === CARD_VIEW && (
                  <CardViewContainer
                    initialFetch={initialFetch}
                    projectsLoader={projectsLoader}
                    setPage={setPage}
                    allProjects={allProjects}
                    viewButton={viewButton}
                    {...props}
                    setIsRowSelected={setIsRowSelected}
                  />
                )}
                {viewType === GRID_VIEW && <GridViewContainer {...props} setIsRowSelected={setIsRowSelected} />}
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      </DynamicTabLayout>
    </Box>
  );
};

MySearchPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(MySearchPage);
