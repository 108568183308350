import { initialSFPA } from '../components/ViewSearch/Components/SFPA/utils';
import {
  activityInfoApi,
  currencyInfoApi,
  documentTypeListApis,
  fetchBrandConfigsDataApi,
  fetchTagByModule,
  gridSettingsApi,
  ignRevenueMarketDataApi,
  ignWorkbenchDataApi,
  messageDataApi,
  picklistDropDownApis,
  picklistsDataApi,
  picklistsDataApis,
  picklistsSearchDataApi,
  picklistsTypeDataApi,
  revenueMarketDataApi,
  searchDataApi,
  searchDocumentApi,
  userDataApi,
  userSearchDataApi
} from '../services/ApiService';
import { CLIENTS, ERROR, GET, KGP_TEAM } from '../services/constantService';

// import httpService from "../services/httpService";
import { unableMessage } from '../services/MessageService';
import { commonActions } from '../store/commonSlice';
import { GET_CLIENT_LIST, GET_KGP_TEAM_LIST, UPDATE_ATTACHMENTS_LIST, UPDATE_LOES_LIST, UPDATE_SFPADATA, UPDATE_TOKEN, UPDATE_USER_LIST } from '../types';
import { clarityTracker, shouldTrackOnClarity } from '../utils/clarityJs';

export const fetchUserDetails = () => {
  return async function (dispatch) {
    try {
      const sub_route = 'profile';
      const { status, data } = await userDataApi(GET, '', {}, sub_route);

      if (status === 200) {
        localStorage.setItem('isAdmin', data?.roles?.includes('admin') || data?.roles?.includes('finance'));

        if (shouldTrackOnClarity()) {
          clarityTracker.identify(data.email);
          clarityTracker.setTag('email', data.email);
          clarityTracker.setTag('firstName', data.first_name);
          clarityTracker.setTag('lastName', data.last_name);
        }

        dispatch(
          commonActions.updateUserData({
            ...data,
            isAdmin: data.roles.includes('admin') || data.roles.includes('finance')
          })
        );
        return true;
      }

      const url = window.location.origin;
      const subdomain = url.split('.')[0];
      const restOfDomain = url.substring(url.indexOf('.') + 1);

      // Handle different status codes
      switch (status) {
        case 404:
          alert('User is not available. Contact Support for more details.');
          break;
        case 450:
          window.location.href = '/unauthorized';
          break;
        case 401:
          window.location.href = `${subdomain}.app.${restOfDomain}/login?url=/`;
          break;
      }
      return false;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return false;
    }
  };
};

export const fetchAccessToken = () => {
  return async function (dispatch) {
    try {
      // const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
      const token = localStorage.getItem('token');
      dispatch({
        type: UPDATE_TOKEN,
        payload: {
          token
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchPickList = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await picklistsDataApi(GET, type);
    if (status === 200) {
      if (key === 'jobFunctions' || key === 'industries') {
        // dispatch({ type: UPDATE_PICK_LIST, payload: { key: key || type, data: data.data } })
        dispatch(
          commonActions.updatePicklistData({
            key: key || type,
            data: data.data
          })
        );
      } else {
        // dispatch({ type: UPDATE_PICK_LIST, payload: { key: key || type, data } })
        dispatch(commonActions.updatePicklistData({ key: key || type, data }));
      }
    }
  };
};

export const fetchMessageList = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await messageDataApi(GET, type);
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key || type, data: data }));
    }
  };
};

export const fetchRevenueMarket = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await revenueMarketDataApi(GET, type);
    if (status === 200) {
      if (key === 'market') {
        if (data && data?.length) {
          const sortAscMarket = (a, b) => (a?.market.toLowerCase() > b?.market.toLowerCase() ? 1 : -1);
          data.sort(sortAscMarket);
        }
      }
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};

export const ignFetchRevenueMarket = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await ignRevenueMarketDataApi(GET, type);
    if (status === 200) {
      if (key === 'market') {
        if (data && data?.length) {
          const sortAscMarket = (a, b) => (a?.market.toLowerCase() > b?.market.toLowerCase() ? 1 : -1);
          data.sort(sortAscMarket);
        }
      }
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};

export const fetchTypeList = (type, key) => {
  let sub_route;
  if (type === 'feetypes') {
    sub_route = 'find?org_id=KGP&field_name=ADM_FEE_TYPE&lang_cd=ENG';
  } else {
    sub_route = `find?org_id=KGP&field_name=${type}&lang_cd=ENG`;
  }
  return async function (dispatch) {
    const { status, data } = await picklistsTypeDataApi(GET, sub_route);
    if (status === 200) {
      if (type === 'INTERVAL_TIME' || type === 'INTERVAL_MILE') {
        data.push({
          short_desc: 'Other Interval',
          field_value: 'Other Interval'
        });
      }
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};

export const fetchGroupIndustryPickList = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await picklistsDataApi(GET, '', type);
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};

export const fetchClientList = (type, searchId) => {
  return async function (dispatch) {
    const { status, data } = await picklistsSearchDataApi(GET, searchId, CLIENTS);
    if (status === 200) {
      dispatch({ type: GET_CLIENT_LIST, payload: { key: type, data } });
    }
  };
};

export const fetchKGPTeam = (type, searchId) => {
  return async function (dispatch) {
    const { status, data } = await picklistsSearchDataApi(GET, searchId, KGP_TEAM);
    if (status === 200) {
      dispatch({ type: GET_KGP_TEAM_LIST, payload: { key: type, data } });
    }
  };
};

export const fetchCurrencyDetails = async (code, dispatch) => {
  const { status, data } = await currencyInfoApi(GET, code);
  if (status === 200) {
    // dispatch({ type: UPDATE_PICK_LIST, payload: { key: code, data: data } })
    dispatch(commonActions.updatePicklistData({ key: code, data: data }));
    return true;
  } else {
    return false;
  }
};

export const fetchUserList = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await userSearchDataApi(GET);
    if (status === 200) {
      dispatch({ type: UPDATE_USER_LIST, payload: { key: key || type, data } });
    }
  };
};

export const fetchAttachmentsList = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await searchDocumentApi(GET, type);
    if (status === 200) {
      dispatch({
        type: UPDATE_ATTACHMENTS_LIST,
        payload: { key: key || type, data }
      });
    }
  };
};

export const fetchLoesList = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await searchDocumentApi(GET, type);
    if (status === 200) {
      dispatch({ type: UPDATE_LOES_LIST, payload: { key: key || type, data } });
    }
  };
};
/* Generic function for sfpa data get and set indo redux state */
export const fetchSfpaData = async (dispatch, id, enqueueSnackbar) => {
  const { status, data } = await searchDataApi(GET, id, {}, 'sfpa');
  if (status && status === 200) {
    if (data) {
      if (data.skills && data.skills?.length === 0) {
        data.skills = [{}];
      }
      if (data.off_limits === null) {
        data.off_limits = [];
      }
      if (data.target_industries === null) {
        data.target_industries = [];
      }
      if (data.target_locations === null) {
        data.target_locations = [];
      }
      if (data.target_companies === null) {
        data.target_companies = [];
      }
      dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data } });
      return { data, initial: false };
    } else {
      return { data: initialSFPA, initial: true };
    }
  } else {
    const message = unableMessage('SFPA', 'fetch');
    enqueueSnackbar(data?.message || message, { variant: ERROR });
    return false;
  }
};

//--------------------IGN Integration----------------------

export const fetchLogAnActivityTypes = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await activityInfoApi(GET, '', 'all-activity-types');
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};

export const fetchPickLists = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await picklistsDataApis(GET, type);
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};

export const fetchPickListData = (type, key) => {
  return async function (dispatch) {
    const { status, data } = await picklistDropDownApis(GET, type);
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key || type, data }));
    }
  };
};
export const documentTypeListData = () => {
  return async function (dispatch) {
    const { status, data } = await documentTypeListApis();
    if (status === 200) {
      dispatch(commonActions.setDocumentTypesList(data?.data?.rows));
    }
  };
};
export const fetchCompanyContactData = (key, id) => {
  return async function (dispatch) {
    const sub_route = `get-by-company/${id}`;
    const { status, data } = await ignWorkbenchDataApi(GET, '', '', sub_route);
    data.data = data.rows;
    delete data.rows;
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key, data }));
    }
  };
};
export const fetchTagData = (type, key, subroute) => {
  return async function (dispatch) {
    const { status, data } = await fetchTagByModule(GET, subroute);
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key: key, data }));
    }
  };
};

export const fetchBrandConfigsData = (key, query) => {
  return async function (dispatch) {
    const sub_route = `get?${query}`;
    const { status, data } = await fetchBrandConfigsDataApi(GET, sub_route);
    if (status === 200) {
      dispatch(commonActions.updatePicklistData({ key, data }));
    }
  };
};

export const fetchGridData = async (key, query, callback) => {
  const queryKey = { key: query };
  await gridSettingsApi(GET, {}, queryKey, '')
    .then(({ status, data }) => {
      if (status === 200) {
        callback({ status: true, data: data?.data });
      } else {
        callback({ status: false, data: {} });
      }
    })
    .catch(error => {
      callback({ status: false, data: error });
    });
};
