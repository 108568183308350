import { SearchRounded } from '@mui/icons-material';

import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Box, Drawer, Grid, Switch, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import CustomInputField from '../../components/common/StyledComponents/CustomInputField';
import { getUserSelector } from '../../selectors';
import { CandidateStageGroupAPi, ClientDispositionMap, ignContactDataApi, ProjectCandidateApi, ProjectCandidateDataApi, ProjectDataApi } from '../../services/ApiService';
import { CLIENT_SUITE_FEEDBACL_SUCCESS, GET, PATCH, POST } from '../../services/constantService';
import CustomModalWithHeader from '../Commons/CustomModalWithHeader';
import styles from './../../variables.scss';
import CandidateCard from './components/CandidateCard/CandidateCard';
import ClientSuiteHeader from './components/Header/ClientSuiteHeader';
import Modal from './components/Modal/Modal';
import ClientSuiteJobDetails from './containers/ClientSuiteJobDetails/ClientSuiteJobDetails';
import './index.scss';

const ClientSuite = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderProjects, setLoaderProjects] = useState(true);
  const [loaderFeedback, setLoaderFeedback] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [candidateStats, setCandidateStats] = useState([]);
  const [selectedGroupStat, setSelectedGroupStat] = useState('pending_review');
  const [searchTextProject, setSearchTextProject] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [searchTextCandidates, setSearchTextCandidates] = useState('');
  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState('');
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const user = useSelector(getUserSelector);
  const [projectToggle, setProjectToggle] = useState(searchParams?.get('show') === 'all' || false);
  const [clientID, setClientID] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [disableProjectSearch, setDisableProjectSearch] = useState(false);
  const [isInvalidProject, setIsInvalidProject] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) setIsMenuOpen(false);
    setState({ ...state, [anchor]: open });
  };

  const isClient = useMemo(() => {
    return user?.all_roles?.includes('client') && user?.all_roles?.length == 1;
  }, [user?.roles]);

  const fetchProjects = useCallback(async () => {
    try {
      setIsInvalidProject(false);
      setLoaderProjects(true);

      const projectId = searchParams?.get('project');
      const showAll = searchParams?.get('show') === 'all';
      const queryType = projectToggle ? 'all' : 'my';

      const projectResponse = await ProjectDataApi(GET, '', {}, `client-portal/?type=${queryType}&limit=300`);
      const projects = projectResponse?.data || [];

      if (!projects.length) {
        setProjects([]);
        setSelectedProject(null);
        setIsInvalidProject(true);
        setDisableProjectSearch(true);
        return;
      }

      const currentProject = projects.find(project => project.id === projectId);

      if (projectId && !currentProject) {
        setIsInvalidProject(true);
        setSelectedProject(null);
        setProjects(showAll ? [] : projects);
        setDisableProjectSearch(showAll);
        return;
      }

      const selectedProject = currentProject || (showAll ? null : projects[0]);

      setProjects(showAll ? (currentProject ? [currentProject] : []) : projects);
      setSelectedProject(selectedProject);
      setIsInvalidProject(!selectedProject);
      setDisableProjectSearch(showAll);

      // Update searchParams if projectId is missing
      if (!projectId && projects.length) {
        const updatedParams = new URLSearchParams(searchParams);
        updatedParams.set('project', projects[0].id);
        setSearchParams(updatedParams);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || 'An unexpected error occurred', { variant: 'error' });
    } finally {
      setLoaderProjects(false);
    }
  }, [projectToggle]);

  const onClickCandidate = candidate => {
    searchParams.set('candidate', candidate?.id);
    setSearchParams(searchParams);
    setSelectedCandidate(candidate);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    searchParams.delete('candidate');
    setSearchParams(searchParams);
    setDrawerOpen(false);
    setSelectedCandidate({});
  };

  const fetchCandidate = useCallback(async () => {
    if (isInvalidProject) return;
    try {
      const group = selectedGroupStat;

      setLoader(true);
      const projectId = searchParams?.get('project');
      const candidateId = searchParams?.get('candidate');
      if (projectId) {
        let apiUrl = group.length > 0 ? `all-by-stage-group?project_id=${projectId}&stage_group=${group}` : `all?onlyGroupedCandidates=true&projectId=${projectId}&hideHiddenCandidate=true`;

        const candidates = await ProjectCandidateDataApi(GET, '', '', apiUrl);
        setCandidates(candidates?.data?.rows);
        if (candidateId && candidates?.data?.rows?.length > 0) {
          const candidate = candidates?.data?.rows?.find(candidate => candidate?.id === candidateId);
          if (candidate) {
            setSelectedCandidate(candidate);
            setDrawerOpen(true);
          } else {
            searchParams.delete('candidate');
            setSearchParams(searchParams);
          }
        }
      }
      // TODO: Implement Pagination
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  }, [searchParams, selectedGroupStat]);

  const handleFeedBackSave = async data => {
    try {
      setLoaderFeedback(true);
      let payload = {};
      if (data?.type === 'rejected') {
        let listOfReasons = data.reason ? JSON.stringify(data.reason.map(item => item.short_desc)) : '[]';
        payload = {
          project_id: selectedProject?.id,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          // "created_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430", //if error new Data()
          // "updated_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430",// same
          internal_attendees: null,
          external_attendees: null,
          contact_id: selectedCandidate?.contact_id ?? '',
          rating: data?.rating,
          outcome_reason: listOfReasons,
          client_feedback: 'rejected'
        };
      } else {
        payload = {
          project_id: selectedProject?.id,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          // "created_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430", //if error new Data()
          // "updated_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430",// same
          internal_attendees: null,
          external_attendees: null,
          contact_id: selectedCandidate?.contact_id ?? '',
          rating: data?.rating,
          outcome_reason: data?.nextStep,
          client_feedback: 'approved'
        };
      }

      const res = await ignContactDataApi(POST, '', payload, 'create-activity');
      //candidate disposition
      const disposition = await ClientDispositionMap(GET, `?project_id=${selectedProject?.id}&disposition_name=${data?.type}`);

      const dispositionStageArray = disposition?.data;
      if (dispositionStageArray.length > 0) {
        const stage = dispositionStageArray[0];
        const updateStagePayload = {
          id: selectedCandidate?.id,
          stage: stage?.stage?.stage_name
        };

        const candidateStageResponse = await ProjectCandidateApi(PATCH, '', updateStagePayload);
      }

      // setFeedbackModal(false);
      enqueueSnackbar(CLIENT_SUITE_FEEDBACL_SUCCESS, 'success');
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoaderFeedback(false);
    }
  };

  const fetchStats = useCallback(async () => {
    if (isInvalidProject) return;

    try {
      setLoader(true);
      const projectId = searchParams?.get('project');

      if (projectId) {
        const candidateStats = await CandidateStageGroupAPi(GET, `/all-candidates-count-by-stage-group?projectId=${projectId}`);
        const stages = candidateStats?.data?.stageGroups ?? [];
        const sortedStages = stages.sort((a, b) => +a.long_desc - +b.long_desc);
        setCandidateStats(sortedStages);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  }, [searchParams?.get('project')]);

  const handleStatClick = data => {
    if (selectedGroupStat === data?.field_value) {
      setSelectedGroupStat('');
    } else {
      setSelectedGroupStat(data?.field_value);
    }
  };

  const handleSearchValue = value => {
    setSearchTextProject(value);
    if (value.length > 0) {
      const filteredProjects = projects.filter(project => project.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredProjects(filteredProjects);
    }
  };

  const handleSearchValueCandidate = value => {
    setSearchTextCandidates(value);
    if (value.length > 0) {
      const filteredCandidates = candidates.filter(
        candidate => candidate.contact.first_name.toLowerCase().includes(value.toLowerCase()) || candidate.contact.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCandidates(filteredCandidates);
    }
  };

  const handleOnClickMenuItems = key => {
    setSelectedMenuItemKey(key);
  };

  const openFeedbackModall = data => {
    setFeedbackModal(true);
    setClientID(data);
  };

  const closeFeedbackModall = () => {
    setClientID(null);
    setFeedbackModal(false);
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    setProjects([]);
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (isMenuOpen) setState({ ...state, ['left']: open });
  }, [isMenuOpen]);

  useEffect(() => {
    fetchCandidate();
    fetchStats();
  }, [selectedProject, fetchCandidate, fetchStats]);

  const openWidget = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: styles.error,
          button: styles.error
        }
      }
    });
  };

  const handleProjectSelect = project => {
    if (disableProjectSearch) return;
    setIsInvalidProject(false);
    setCandidateStats([]);
    setCandidates([]);
    setSelectedGroupStat('pending_review');
    searchParams.delete('candidate');
    searchParams.set('project', project?.id);
    setSearchParams(searchParams);
    setSelectedProject(project);
  };
  return (
    <Grid container className='w-100 pad-top-container pl-4 pb-0' spacing={2} sx={{ flexGrow: 1 }}>
      <Loader className='custome-loader' show={loaderProjects || loader} />
      <Grid item xs={2} md={2}>
        <Drawer anchor='left' open={true} onClose={toggleDrawer('left', false)} hideBackdrop id={'client-side-nav-container'}>
          <Box className='side-nav-bar' width={'17vw'}>
            <div className='module-header side-nav-header'>
              <div className='flex justify-content-between align-items-center'>
                <div className='module-header fs-12 m-1'>{t('module.projects')}</div>

                {!isClient && (
                  <div className='flex align-items-center m-1'>
                    <Switch
                      className='Switch-component'
                      onChange={(e, v) => {
                        setProjectToggle(v);
                      }}
                      checked={projectToggle}
                      disabled={disableProjectSearch}
                    />
                    <div className='fs-10'>{t('metrics.allProjects')}</div>
                  </div>
                )}
              </div>
              <div className='module-search'>
                <CustomInputField
                  id='outlined-basic'
                  className=''
                  hiddenLabel={true}
                  // label="Search"
                  fullWidth={true}
                  size={'small'}
                  startIcon={<SearchRounded />}
                  value={searchTextProject}
                  onChange={e => handleSearchValue(e.target.value)}
                  disabled={disableProjectSearch}
                />
              </div>
            </div>
            <div className='side-bar-projects scroll-style'>
              {searchTextProject?.length > 0 &&
                filteredProjects.map(project => (
                  <Box
                    className={`m-1 p-2 cursor-pointer client-list ${selectedProject?.id == project?.id ? 'selected-project border shadow rounded w-98' : ''}`}
                    key={project.id}
                    onClick={() => {
                      handleProjectSelect(project);
                    }}
                    style={{ backgroundColor: selectedProject?.id == project?.id ? themeColor.secondary_color : '' }}
                  >
                    <div className='fs-12 d-flex'>
                      <div className='fs-12 project-title'>{project.job_title}</div>
                      <Tooltip title={`Pending Review ${project?.pending_review_count}`}>
                        <div className='mr-1 project-count'>&nbsp;{project?.pending_review_count && project?.pending_review_count > 0 ? `(${project?.pending_review_count})` : ''}</div>
                      </Tooltip>
                    </div>
                    <div className='fs-10 project-subtitle'>{project?.ign_companies?.name}</div>
                  </Box>
                ))}
              {searchTextProject?.length == 0 &&
                projects?.length > 0 &&
                projects?.map(project => {
                  if (project?.ign_companies?.name && project?.job_title)
                    return (
                      <Box
                        className={`m-1 p-2 client-list cursor-pointer ${selectedProject?.id == project?.id ? 'selected-project border shadow rounded w-98' : ''}`}
                        key={project.id}
                        onClick={() => {
                          handleProjectSelect(project);
                        }}
                        style={{ backgroundColor: selectedProject?.id == project?.id ? themeColor.secondary_color : '' }}
                      >
                        <div className='fs-12 d-flex justify-content-between'>
                          <div className='fs-12 project-title'>{project.job_title}</div>
                          <Tooltip title={`Pending Review ${project?.pending_review_count}`}>
                            <div className='mr-1 project-count'>&nbsp;{project?.pending_review_count && project?.pending_review_count > 0 ? `(${project?.pending_review_count})` : ''}</div>
                          </Tooltip>
                        </div>
                        <div className='fs-10 project-subtitle'>{project?.ign_companies?.name}</div>
                      </Box>
                    );
                })}
            </div>
          </Box>
        </Drawer>
      </Grid>
      <Grid item xs={10} md={10} zIndex={999} className='w-100'>
        <div className='client-setup'>
          {!loaderProjects && !loader && !selectedProject?.id && searchTextCandidates?.length == 0 && candidates && candidates?.length == 0}
          <ClientSuiteHeader projectId={selectedProject?.id} projectJobTitle={selectedProject?.job_title} onChangeSearch={handleSearchValueCandidate} onClickMenuItem={handleOnClickMenuItems} />
          <div className='d-flex justify-content-end'>
            {!loaderProjects &&
              candidateStats &&
              candidateStats.map(data => (
                <div
                  key={data.name}
                  className='mt-4 ml-4 rounded-left filter-chip'
                  style={{
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '14px',
                    borderLeftColor: data?.color,
                    backgroundColor: `${data?.field_value === selectedGroupStat ? alpha(data?.color, 0.2) : '#f9f9f9'}`
                  }}
                >
                  <div
                    className='d-flex flex-row rounded-right justify-content-between cursor-pointer'
                    style={{
                      border: `${data?.field_value === selectedGroupStat ? '1px solid ' + data?.color : '1px solid #eee'}`,
                      height: '100%'
                    }}
                    onClick={() => handleStatClick(data)}
                  >
                    <div className='fs-14 p-2 d-flex align-items-center justify-content-start'>{data?.custom_label?.label || data?.short_desc}</div>
                    <div
                      className='fs-14 ml-2 bold p-2 w-40 text-center d-flex align-items-center justify-content-center'
                      style={{
                        backgroundColor: `${data?.field_value === selectedGroupStat ? data?.color : '#eee'}`
                      }}
                    >
                      {data?.candidate_count}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Grid container fullWidth spacing={2} rowGap={2} className='mt-2 auto-height-client-portal'>
            {!loaderProjects &&
              !loader &&
              searchTextCandidates?.length > 0 &&
              filteredCandidates &&
              filteredCandidates?.length > 0 &&
              filteredCandidates?.map(data => <CandidateCard key={data?.id} candidate={data} theme={themeColor} onClick={onClickCandidate} />)}
            {!loaderProjects &&
              !loader &&
              searchTextCandidates.length == 0 &&
              candidates &&
              candidates?.length > 0 &&
              candidates.map(data => <CandidateCard key={data?.id} candidate={data} theme={themeColor} onClick={onClickCandidate} />)}
            {!loaderProjects && !loader && selectedProject?.id && searchTextCandidates?.length == 0 && candidates && candidates?.length == 0 && (
              <div className=' mt-5 w-100 d-flex justify-content-center align-items-center'>{t('utils.noCandidatesAdded')}</div>
            )}
            {isInvalidProject && <div className=' mt-5 w-100 d-flex justify-content-center align-items-center'>{t('utils.invalidProject')}</div>}
          </Grid>
        </div>
      </Grid>
      {!loaderProjects && selectedCandidate?.id && (
        <ClientSuiteJobDetails drawerOpen={drawerOpen} setDrawerOpen={handleDrawerClose} data={selectedCandidate} onClickFeedback={openFeedbackModall} allCandidates={candidates} />
      )}
      <CustomModalWithHeader closeIcon={true} label={'Share Feedback'} onClose={closeFeedbackModall} isOpen={feedbackModal} style={{ color: themeColor ? themeColor.secondary_color : '' }}>
        <Modal
          isLoader={loaderFeedback}
          theme={themeColor}
          onCancel={closeFeedbackModall}
          clientId={clientID}
          selectedProject={selectedProject?.id}
          selectedCandidate={selectedCandidate?.contact_id}
          onSave={handleFeedBackSave}
        />
      </CustomModalWithHeader>
      <CustomModalWithHeader
        closeIcon={true}
        label={'Search Status'}
        onClose={() => setSelectedMenuItemKey('')}
        isOpen={selectedMenuItemKey === 'search_status'}
        style={{ color: themeColor ? themeColor.secondary_color : '' }}
      >
        <div className='temp-modal d-flex justify-content-center align-items-center'>Search Status feature is currently Under development</div>
      </CustomModalWithHeader>
      <CustomModalWithHeader
        closeIcon={true}
        label={'Search Reports'}
        onClose={() => setSelectedMenuItemKey('')}
        isOpen={selectedMenuItemKey === 'search_reports'}
        style={{ color: themeColor ? themeColor.secondary_color : '' }}
      >
        <div className='temp-modal d-flex justify-content-center align-items-center'>Search Reports feature is currently Under development</div>
      </CustomModalWithHeader>
      <CustomModalWithHeader
        closeIcon={true}
        label={'Team Info'}
        onClose={() => setSelectedMenuItemKey('')}
        isOpen={selectedMenuItemKey === 'team_info'}
        style={{ color: themeColor ? themeColor.secondary_color : '' }}
      >
        <div className='temp-modal d-flex justify-content-center align-items-center'>Team Info feature is currently Under development</div>
      </CustomModalWithHeader>
      <CustomModalWithHeader
        closeIcon={true}
        label={'Candidate Compare'}
        onClose={() => setSelectedMenuItemKey('')}
        isOpen={selectedMenuItemKey === 'candidate_compare'}
        style={{ color: themeColor ? themeColor.secondary_color : '' }}
      >
        <div className='temp-modal d-flex justify-content-center align-items-center'>Candidate Compare feature is currently Under development</div>
      </CustomModalWithHeader>
      <HelpRoundedIcon className={'help-desk'} onClick={openWidget} />
    </Grid>
  );
};

export default ClientSuite;
