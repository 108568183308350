//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useTranslation } from 'react-i18next';
import TeamInfoTable from './TeamInfoTable';
const RecruiterInformation = props => {
  const { setValue, currentValues = {}, isEditing = true, option, message } = props;
  const fields = {
    execution_credit: 100
  };
  const { t } = useTranslation();
  const [recruiters, setRecruiters] = useState([]);
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);

  useEffect(() => {
    setValue('recruiters', recruiters, { shouldDirty: true });
  }, [recruiters, setValue]);

  useEffect(() => {
    setValue('recruiters', recruiters, { shouldDirty: true });
    setRecruiters(currentValues.recruiters);
  }, [currentValues.recruiters]);

  useEffect(() => {
    if (recruiters && recruiters.length > 0) {
      const options = option.filter(array_el => {
        return recruiters.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id);
      });
      setSelectedRecruiters(options);
    } else {
      setSelectedRecruiters(option);
    }
  }, [recruiters, setValue]);

  return (
    <>
      <TeamInfoTable
        readOnlyMessage={message}
        title={t('utils.recruiter')}
        label={t('utils.recruiter')}
        inputConfig={{
          list: recruiters,
          fields,
          setter: value => {
            setRecruiters(value);
            setValue('recruiters', value);
          }
        }}
        isEditing={isEditing}
        option={selectedRecruiters}
      />
    </>
  );
};

RecruiterInformation.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  message: PropTypes.string
};

export default RecruiterInformation;
