import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDataContext } from '../../../Containers/Searches/ViewProject/Context';
import { IGNYTE_INTELLIGENCE } from '../../../utils/common';
import EditContactDrawer from '../../AddContactDrawer/EditContactDrawer';
import MuiTabLayout from '../../MuiTabLayout/MuiTabLayout';
import ActiveProjects from '../ActivityLogsIgnyte/ActivityHistorys/ActiveProjectDetails';
import ActivityHistoryDetail from '../ActivityLogsIgnyte/ActivityHistorys/ActivityHistoryDetail';
import AthenaDecision from './AthenaDecision';
import AthenaScore from './AthenaScore';
import CandidateScore from './CandidateScore';
import ViewCompetencyScore from './fit-score/ViewCompetencyScore';
import ViewExperience from './fit-score/ViewExperience';
import ViewCompensation from './ViewCompensation';
import ViewDocuments from './ViewDocuments';
import ViewPersonality from './ViewPersonality';
import AlertDialog from '../../AddContactDrawer/AlertDialog';

const ContactDetails = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    id: contactId = null,
    /* id, */ data,
    register,
    defaultValues,
    unregister,
    setValue = () => {},
    watch = () => {},
    updateField = () => {},
    getContactDetails = () => {},
    onTabChange,
    popupClose,
    isHeaderNav,
    activityDetails,
    getHeaderDetails = () => {},
    projectAssociatedData,
    isCandidate = false,
    isLogAnActivityOpen = false,
    fitScoreData = null,
    project_id = null,
    candidate_id = null,
    closeSideAndTopBar = () => {},
    contactName,
    control,
    reset,
    setPersonalityField,
    getValues,
    getFitScoreDetails = () => {},
    setIsLogAnActivityOpen
  } = props;
  const context = useContext(ProjectDataContext);
  const projectData = context?.projectData;
  const [selectedAssessment, setSelectedAssessment] = useState();

  const TabData = [
    {
      label: `${t('utils.details')}`,
      content: '',

      subTabs: []
    },
    {
      label: `${t('reports.candidate_details_information_compensation')}`,
      content: 'Content for Compensation',
      subTabs: []
    },
    {
      label: `${t('utils.personality')}`,
      content: 'Content for Personality',
      subTabs: []
    },
    {
      label: `${t('utils.documents')}`,
      content: 'Content for Documents',
      subTabs: []
    },
    {
      label: t('project.projectAndActivities'),
      content: 'Content for Projects and Activities',
      subTabs: [
        {
          label: t('module.projects'),
          content: 'Content for Projects'
        },
        {
          label: `${t('contacts.recentActivity')}`,
          content: 'Content for Recent Activity'
        }
      ]
    },
    {
      label: `${t('utils.athena')}`,
      content: 'Content for Athena',
      subTabs: [
        {
          label: `${t('utils.scores')}`,
          content: 'Content for Scores'
        },
        {
          label: `${t('utils.decisionFramework')}`,
          content: 'Content for Decision Framework'
        }
      ]
    }
  ];

  const handleTabClick = async (event, tabIndex, subTabLabel, skipValidation = false, eventTarget = null) => {
    setValue('activity_type', null);
    setPersonalityField(false);
    if (subTabLabel) {
      onTabChange(subTabLabel);
    } else {
      if (event?.target?.textContent !== t('utils.details') && ref.current?.validateCheck && !skipValidation) {
        const isValid = ref.current?.validateCheck();
        if (!isValid) {
          return Promise.reject(t('addContactDrawer.unsavedChanges'));
        } else {
          onTabChange(event.target.textContent);
        }
        return;
      } else {
        onTabChange(event.target.textContent || eventTarget || tabIndex);
      }
    }
    onTabChange(tabIndex);
  };
  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };

  const tabs = [
    {
      label: `${t('utils.details')}`,
      content: (
        <Box sx={{ height: '100%', width: '100%' }}>
          <EditContactDrawer ref={ref} contactId={contactId} index='Details' callback={getHeaderDetails} />
        </Box>
      ),
      subTabs: []
    },
    {
      label: `${t('reports.candidate_details_information_compensation')}`,
      content: (
        <ViewCompensation
          index='Compensation'
          renderActions={renderActions}
          data={{ ...data, id: contactId }}
          register={register}
          unregister={unregister}
          watch={watch}
          setValue={setValue}
          defaultValues={defaultValues}
          updateField={updateField}
          isHeaderNav={isHeaderNav}
        />
      ),
      subTabs: []
    },
    {
      label: `${t('utils.personality')}`,
      content: (
        <ViewPersonality
          currentValues={data}
          updateField={updateField}
          isContactView={true}
          index='Personality'
          setValue={setValue}
          register={register}
          unregister={unregister}
          getContactDetails={getContactDetails}
          popupClose={popupClose}
          isHeaderNav={isHeaderNav}
          watch={watch}
          getValues={getValues}
          reset={reset}
          project_id={project_id}
          gotoRecentActivity={() => {}}
          control={control}
          setPersonalityField={setPersonalityField}
          setIsLogAnActivityOpen={setIsLogAnActivityOpen}
        />
      ),
      subTabs: []
    },
    {
      label: `${t('utils.documents')}`,
      content: (
        <ViewDocuments
          index='Documents'
          data={data}
          setValue={setValue}
          register={register}
          unregister={unregister}
          getContactDetails={getContactDetails}
          isHeaderNav={isHeaderNav}
          getHeaderDetails={getHeaderDetails}
        ></ViewDocuments>
      ),
      subTabs: []
    },
    {
      label: `${t('project.projectAndActivities')}`,
      content: 'Content for Projects and Activities',
      subTabs: [
        {
          label: `${t('module.projects')}`,
          content: <ActiveProjects index={t('project.projectAndActivities')} childIndex={t('module.projects')} rowData={projectAssociatedData} />
        },
        {
          label: `${t('contacts.recentActivity')}`,
          content: (
            <Box sx={{ py: 2 }}>
              <ActivityHistoryDetail index={t('project.projectAndActivities')} childIndex='Recent Activity' activityDetails={activityDetails} isHeaderNav={props.isHeaderNav} />
            </Box>
          )
        }
      ]
    },
    {
      label: `${t('utils.athena')}`,
      content: 'Content for Athena',
      subTabs: [
        {
          label: `${t('utils.scores')}`,
          content: <AthenaScore index='Athena' childIndex='Scores' data={data} contactId={contactId} preSelectedAssessment={selectedAssessment} onAssessmentChange={setSelectedAssessment} />
        },
        {
          label: `${t('utils.decisionFramework')}`,
          content: <AthenaDecision index='Athena' childIndex='Decision Framework' data={data} contactId={contactId} selectedAssessment={selectedAssessment} />
        }
      ]
    }
  ];

  if (isCandidate && projectData?.framework_type !== IGNYTE_INTELLIGENCE) {
    TabData.push({
      label: `${t('utils.fitScore')}`,
      content: 'Content for Fit Score',
      subTabs: [
        {
          label: `${t('project.candidateScore.experienceLabelScore')}`,
          content: 'Content for Fit Score - Experience Score'
        },
        {
          label: `${t('utils.competencyScore')}`,
          content: 'Content for Fit Score - Competency Score'
        }
      ]
    });

    tabs.push({
      label: `${t('utils.fitScore')}`,
      content: 'Content for Fit Score',
      subTabs: [
        {
          label: `${t('project.candidateScore.experienceLabelScore')}`,
          content: (
            <ViewExperience
              index='Fit Score'
              childIndex='Experience Score'
              isLogAnActivityOpen={isLogAnActivityOpen}
              experience_data={fitScoreData?.experience ?? []}
              project_id={project_id}
              candidate_id={candidate_id}
              getFitScoreDetails={getFitScoreDetails}
            />
          )
        },
        {
          label: `${t('utils.competencyScore')}`,
          content: (
            <ViewCompetencyScore
              getFitScoreDetails={getFitScoreDetails}
              index='Fit Score'
              childIndex='Competency Score'
              competency_data={fitScoreData?.competencies ?? []}
              project_id={project_id}
              candidate_id={candidate_id}
            />
          )
        }
      ]
    });
  }
  if (isCandidate && projectData?.framework_type === IGNYTE_INTELLIGENCE) {
    TabData.push({
      label: 'Candidate Scorecard',
      content: 'Content for Score',
      subTabs: [],
      onClick: closeSideAndTopBar
    });

    tabs.push({
      label: 'Candidate Scorecard',
      content: isCandidate && (
        <CandidateScore index='Candidate Scorecard' contactName={contactName} contact_id={contactId} candidate_id={candidate_id} project_id={project_id} isLogAnActivityOpen={isLogAnActivityOpen} />
      ),
      subTabs: [],
      onClick: closeSideAndTopBar
    });
  }

  return (
    <>
      <MuiTabLayout tabs={tabs} handleTabChange={handleTabClick} />
    </>
  );
});

ContactDetails.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  isHeaderNav: PropTypes.string,
  handleSave: PropTypes.func,
  defaultValues: PropTypes.object,
  updateField: PropTypes.func,
  getContactDetails: PropTypes.func,
  isPopupOpen: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  onTabChange: PropTypes.func,
  popupClose: PropTypes.bool,
  activityDetails: PropTypes.object,
  getHeaderDetails: PropTypes.func,
  baseRoute: PropTypes.string,
  fetchData: PropTypes.func,
  projectAssociatedData: PropTypes.object,
  isCandidate: PropTypes.bool,
  isLogAnActivityOpen: PropTypes.bool,
  fitScoreData: PropTypes.object,
  getFitScoreDetails: PropTypes.func,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string,
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  closeSideAndTopBar: PropTypes.func,
  contactName: PropTypes.string,
  control: PropTypes.func,
  reset: PropTypes.func,
  setPersonalityField: PropTypes.func,
  setIsLogAnActivityOpen: PropTypes.func,
  drawerUIRef: PropTypes.object
};
export default ContactDetails;
