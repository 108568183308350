import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, CircularProgress, Typography } from '@mui/material';
import omit from 'lodash/omit';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchGridData } from '../../../../../actions';
import Loader from '../../../../../components/common/Loader';
import { ignWorkbenchQueryApi } from '../../../../../services/ApiService';
import { ERROR, GET, PUT } from '../../../../../services/constantService';
import { getDateFormatFromLocale } from '../../../../../utils/date';
import { WorkBenchContext } from '../../../Context';
import '../index.scss';
import { QueryCandidateGrid } from './QueryCandidateGrid';
import { QueryCompanyGrid } from './QueryCompanyGrid';
import { QueryContactGrid } from './QueryContactGrid';
import { QueryProjectGrid } from './QueryProjectGrid';
export default function QueryContactComponent() {
  const { t } = useTranslation();

  const { setQueryId, setArchiveId, setWorkbenchId, refetch } = useContext(WorkBenchContext);
  const { queryId, archiveId } = useParams();
  const [queryResponse, setQueryResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});
  const [gridState, setGridState] = useState([]);
  const [gridType, setGridType] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [description, setDescription] = useState('');
  const { dateFormat } = useSelector(state => state.profileFormatSlice);

  const handleSaveClick = async () => {
    setIsSaving(true);
    const filterOptionPayload = {
      description: description
    };
    const updateResponse = await ignWorkbenchQueryApi(PUT, filterOptionPayload, `${queryId || archiveId}`);
    setQueryResponse(updateResponse?.data);
    setIsSaving(false);
    setIsEditing(false);
  };

  const fetchQueryData = async () => {
    setLoading(true);
    try {
      const res = await ignWorkbenchQueryApi(GET, {}, `?id=${queryId || archiveId}`);
      if (res?.success) {
        setQueryResponse(res.data);
        setDescription(res.data?.query_description);
      } else {
        enqueueSnackbar('Queries not found', { variant: ERROR });
      }
    } catch (error) {
      enqueueSnackbar('Error fetching data', { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueryData();
    if (queryId) {
      setQueryId(queryId);
      setWorkbenchId(null);
      setArchiveId(null);
    }
    if (archiveId) {
      setQueryId(null);
      setWorkbenchId(null);
      setArchiveId(archiveId);
    }
  }, [queryId, archiveId, refetch]);

  const getGridSettings = useCallback(() => {
    setGridType(`${queryResponse?.query_type}QueryGrid`);
    fetchGridData('', `${queryResponse?.query_type}QueryGrid`, ({ status, data }) => {
      if (status) {
        const gridSettings = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');
        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (gridSettings.length) {
          const gridSettingToUse = gridSettings.map(col => {
            return omit(col, ['sort', 'sortIndex']);
          });
          setGridState(gridSettingToUse);
        }
      }
      setLoading(false);
    });
  }, [queryResponse]);

  useEffect(() => {
    if (queryResponse && queryResponse) getGridSettings();
  }, [queryResponse]);

  if (loading) {
    return <Loader show={true} />;
  }

  if (!queryResponse) {
    return null;
  }

  let content;
  switch (queryResponse?.query_type?.toLowerCase()) {
    case 'contact':
      content = <QueryContactGrid data={queryResponse} sortModel={savedSortModel} filterModel={savedFilterModel} gridState={gridState || []} gridType={gridType} setQueryResponse={setQueryResponse} />;
      break;
    case 'project':
      content = <QueryProjectGrid data={queryResponse} sortModel={savedSortModel} filterModel={savedFilterModel} gridState={gridState || []} gridType={gridType} setQueryResponse={setQueryResponse} />;
      break;
    case 'company':
      content = <QueryCompanyGrid data={queryResponse} sortModel={savedSortModel} filterModel={savedFilterModel} gridState={gridState || []} gridType={gridType} setQueryResponse={setQueryResponse} />;
      break;
    case 'candidate':
      content = (
        <QueryCandidateGrid data={queryResponse} sortModel={savedSortModel} filterModel={savedFilterModel} gridState={gridState || []} gridType={gridType} setQueryResponse={setQueryResponse} />
      );
      break;
  }

  return (
    <div>
      <Box className='query-info'>
        <Box className='query-details'>
          <Typography variant='body2'>
            <strong>{t('utils.queryName')}:</strong> {queryResponse.query_name}
          </Typography>
          <Typography variant='body2'>
            <strong>{t('utils.createdBy')}:</strong> {queryResponse.created_by}
          </Typography>
          <Typography variant='body2'>
            <strong>{t('utils.createdAt')}: </strong>
            {queryResponse.created_at ? getDateFormatFromLocale({ dateFormat: dateFormat?.value, date: queryResponse.created_at }) : '--'}
          </Typography>
          <Typography variant='body2'>
            <strong>{t('utils.updatedAt')}: </strong>
            {queryResponse.updated_at || queryResponse.created_at ? getDateFormatFromLocale({ dateFormat: dateFormat?.value, date: queryResponse.updated_at || queryResponse.created_at }) : '--'}
          </Typography>
        </Box>
        <Box className='query-description'>
          <Box display='flex' alignItems='center' gap={1}>
            <strong>{t('utils.description')}:</strong>
            {isEditing ? (
              <>
                <input type='text' value={description} onChange={e => setDescription(e.target.value)} className='editable-description' />
                {isSaving ? (
                  <CircularProgress className='icon' style={{ marginLeft: '10px' }} />
                ) : (
                  <>
                    <CheckOutlinedIcon className='icon' onClick={handleSaveClick} style={{ cursor: 'pointer' }} />
                    <ClearIcon
                      className='icon'
                      onClick={() => {
                        setIsEditing(false);
                        setDescription(queryResponse?.query_description);
                      }}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Typography variant='body2'>{description}</Typography>
                <EditOutlinedIcon className='icon' onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }} />
              </>
            )}
          </Box>
        </Box>
      </Box>

      {content}
    </div>
  );
}
