//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, DialogContent, DialogContentText } from '@mui/material';

export default function AlertDialog(props) {
  const { open, handleClose, handleSubmit, title, description, cancelText, submitText } = props;
  const [loading, setLoading] = useState(false);
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' sx={{ minWidth: '500px' }}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' disabled={loading}>
          {cancelText}
        </Button>
        <Button
          onClick={async () => {
            setLoading(true);
            await handleSubmit();
            setLoading(false);
          }}
          variant='contained'
          color='primary'
          disabled={loading}
          autoFocus
          endIcon={loading ? <CircularProgress size={16} color='text' /> : null}
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
};
