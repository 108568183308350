import { Box } from '@mui/material';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ignAthenaDataApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import { showToast } from '../../../utils/common';
import { DEFAULT_DATE_FORMAT } from '../../../utils/date';
import CustomDropdown from '../../common/CustomDropdown';
import Loader from '../../common/Loader';
import DecisionMatrixChart from '../../DecisionMatrixChart';

function AthenaDecision(props) {
  const { contactId, selectedAssessment } = props;
  const [loader, setLoader] = useState(false);
  const [assessmentsIds, setAssessmentIds] = useState([]);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState([]);
  const [decisionGraphItem, setDecisionGraphItem] = useState({});

  const fetchAssessmentsByContactId = async id => {
    try {
      setLoader(true);
      const contactAssessments = await ignAthenaDataApi(GET, '', '', `get-assessments/${id}`);
      const assessments = contactAssessments?.data;
      let assessmentOptions = [{ name: 'Select All', value: true }];
      if (!isEmpty(assessments)) {
        assessments.map((assessment, index) => {
          const reversedIndex = assessments.length - index;
          assessmentOptions.push({
            name: assessment.completed_at ? `Completed on ${dayjs(assessment.completed_at).format(DEFAULT_DATE_FORMAT)}` : `Assessment ${reversedIndex}`,
            value: assessment?.id,
            ...assessment
          });
        });
      }
      setAssessmentIds(assessmentOptions);
      if (selectedAssessment) {
        handleAssessmentDropdownChange('', [selectedAssessment], assessmentOptions);
      } else if (assessmentOptions.length > 0) {
        handleAssessmentDropdownChange('', [assessmentOptions[0]], assessmentOptions);
      }
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setLoader(false);
    }
  };
  const handleAssessmentDropdownChange = async (e, value, assessmentOptions = []) => {
    try {
      let val = [];

      if (value.length > selectedAssessmentId.length) {
        val = value.filter(val => val.value == true).length > 0 ? assessmentOptions : value;
      }

      if (value.length < selectedAssessmentId.length) {
        val = value.filter(val => val.value != true);
      }

      setLoader(true);
      setSelectedAssessmentId(val);
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setLoader(false);
    }
  };

  const mapCandidateCoordinates = useCallback(data => {
    const coordinates = data.map(assessment => {
      return {
        x: assessment.x_coordinate,
        y: assessment.y_coordinate,
        label: assessment.name
      };
    });
    return coordinates;
  }, []);
  useEffect(() => {
    fetchAssessmentsByContactId(contactId);
  }, [contactId]);

  useEffect(() => {
    const item = {
      scatter_data: mapCandidateCoordinates(selectedAssessmentId),
      sfpa: true
    };

    setDecisionGraphItem(item);
  }, [selectedAssessmentId]);
  return (
    <Box className='p-3' sx={{ height: '100%' }}>
      <Loader show={loader} />
      <div className='d-flex justify-content-between align-items-center'>
        <CustomDropdown multiple={true} isCheckBox={true} options={assessmentsIds} value={selectedAssessmentId} onChange={(e, value) => handleAssessmentDropdownChange(e, value, assessmentsIds)} />
      </div>
      <Box className='athena-score-component'>
        <DecisionMatrixChart
          item={decisionGraphItem}
          dataPoints={{
            item_1: 0,
            item_2: 0,
            item_3: 0,
            item_4: 0,
            item_5: 0,
            item_6: 0,
            item_7: 0
          }}
          isAuraVisible={false}
          //   projectId={'41612bed-67e2-4572-8de2-4fb79b5aff4d'}
        />
      </Box>
    </Box>
  );
}

AthenaDecision.propTypes = {
  contactId: PropTypes.string,
  selectedAssessment: PropTypes.object
};

export default AthenaDecision;
