import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, DialogContent, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertToLocalTimeAndDate } from '../../../../utils/date';
import CustomPopup from '../../../common/CustomPopup';
import '../../index.scss';

const PersonalityCard = ({
  currentValues,
  filterType = '',
  setSelectedItem,
  setIsConfirmPopupOpen,
  isLoading,
  groupBy,
  onPublishHandler,
  filterOptions,
  PersonalityTabCreateOptions,
  isPublished,
  filterByProject = [],
  isFilterApplied,
  onEditHandler
}) => {
  const { t } = useTranslation();
  const [isViewMore, setIsViewMore] = useState(false);
  const [selectedViewMoreItem, setSelectedViewMoreItem] = useState({});
  const { dateFormat, timeZoneFormat, timeFormats } = useSelector(state => state.profileFormatSlice);

  const handleDelete = item => {
    setSelectedItem(item);
    setIsConfirmPopupOpen(true);
  };

  const filteredNotes = currentValues?.filter(item => {
    const isApproved = isFilterApplied && isPublished ? item.is_partner_approved : !isPublished;

    const projectFilterIds = filterByProject.length > 0 ? filterByProject.map(project => project.project_id) : [];

    const hasMatchingProjectAndType = projectFilterIds.length > 0 && item.notes_type === filterType && projectFilterIds.includes(item.project_id);

    const isApprovedWithFilterOptions = isApproved && filterOptions[item?.notes_type]?.value && (item.project_id === filterType || item.notes_type === filterType);

    const hasNoProjectFilterAndMatchingType = projectFilterIds.length === 0 && item.notes_type === filterType;

    const hasNoProjectFilterAndMatchingTypeForProject = projectFilterIds.length === 0 && item.project_id === filterType && filterOptions[item?.notes_type]?.value;

    const hasFilterOptionAndMatchingProject = filterOptions[item?.notes_type]?.value && item.project_id === filterType;

    const hasNoFilterAppliedAndMatchingProject = !isFilterApplied && item.project_id === filterType;

    const filterInProjectType = filterOptions[item?.notes_type]?.value && item.project_id === filterType;

    const matchesFilterType =
      hasMatchingProjectAndType ||
      hasNoProjectFilterAndMatchingTypeForProject ||
      filterInProjectType ||
      isApprovedWithFilterOptions ||
      hasNoProjectFilterAndMatchingType ||
      hasFilterOptionAndMatchingProject ||
      hasNoFilterAppliedAndMatchingProject;

    return item.notes_type && matchesFilterType;
  });
  const onViewMoreHandler = item => {
    setSelectedViewMoreItem(item);
    setIsViewMore(true);
  };
  return (
    <Box className='personality-container'>
      <Box className='personality-grid'>
        {filteredNotes?.length > 0 ? (
          filteredNotes.map((elem, parentIndex) => (
            <Box className='personality-card-wrapper' key={parentIndex}>
              <Box className='personality-card'>
                <Box className='personality-header'>
                  <Typography className='personality-role'>
                    {groupBy === 'project' && <DescriptionIcon className='personality-icon fs-12 mb-1' />}
                    {groupBy === 'project' ? PersonalityTabCreateOptions.find(d => d.value === elem?.notes_type)?.label : elem?.project?.name}
                  </Typography>
                </Box>

                <Box className='personality-description'>
                  <Box
                    className='text-break fs-12 overflow-hidden text-ellipsis whitespace-nowrap'
                    sx={{
                      display: '-webkit-box',
                      flex: 1,
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: elem?.notes.substring(0, 160) || '-'
                    }}
                  />
                  {elem?.notes.length > 160 && (
                    <Link component='button' variant='body2' className='show-more-link fs-12' sx={{ whiteSpace: 'nowrap' }} onClick={() => onViewMoreHandler(elem)}>
                      {t('common.viewMore')}
                    </Link>
                  )}
                </Box>
                <Box className='personality-timestamp'>
                  <Typography className='timestamp-text'>
                    {elem?.updated_at && convertToLocalTimeAndDate(elem?.updated_at, false, timeZoneFormat?.value, dateFormat?.value, timeFormats?.value)}
                  </Typography>
                </Box>
                <Box className='personality-actions'>
                  <Button className='personality-publish-button' disabled={isLoading} onClick={() => onPublishHandler(elem, !elem?.is_partner_approved)}>
                    <Typography className='button-text'>{elem?.is_partner_approved ? t('project.projectUnPublish') : t('project.publish')}</Typography>
                    <InfoOutlinedIcon className='personality-info-icon' />
                  </Button>
                  <Box className='personality-action-icons'>
                    <VisibilityIcon disabled={isLoading} className='personality-action-icon' fontSize='small' onClick={e => onEditHandler(e, elem)} />
                    <DeleteOutlineIcon disabled={isLoading} className='personality-action-icon' color='error' fontSize='small' onClick={() => handleDelete(elem)} />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box className='d-flex justify-content-center align-items-center'>
            <Typography className='no-data-text fs-12'>{t('utils.noData')}</Typography>
          </Box>
        )}
      </Box>
      {isViewMore && (
        <CustomPopup onClose={() => setIsViewMore(false)} aria-labelledby='customized-dialog-title' open={isViewMore} title={t('utils.notes')}>
          <DialogContent className='content-value'>
            <Typography variant='body2' className='card-containt view-text' dangerouslySetInnerHTML={{ __html: selectedViewMoreItem?.notes || '-' }}></Typography>
          </DialogContent>
        </CustomPopup>
      )}
    </Box>
  );
};

PersonalityCard.propTypes = {
  currentValues: PropTypes.array,
  filterType: PropTypes.string,
  setSelectedItem: PropTypes.func,
  setIsConfirmPopupOpen: PropTypes.func,
  isLoading: PropTypes.bool,
  groupBy: PropTypes.string,
  onPublishHandler: PropTypes.func,
  filterOptions: PropTypes.object,
  PersonalityTabCreateOptions: PropTypes.array,
  isPublished: PropTypes.bool,
  filterByProject: PropTypes.array,
  isFilterApplied: PropTypes.bool,
  onEditHandler: PropTypes.func
};

export default PersonalityCard;
