import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ERROR } from '../../../services/constantService';
import { formatDate, formatDateUtcLocal } from '../../../utils/date';
import CustomPopup, { POPUP_CLOSE_REASONS } from '../CustomPopup';
import DatePickerCommon from '../DatePicker/DatePicker';
import Loader from '../Loader';
import RichText from '../RichText';
import { CONSANTS_MARK_OFF_LIMITS } from './constant';
import { BpCheckedIcon, BpIcon } from './Icon';
import './index.scss';

const MarkOffLimits = ({ isPopupOpen, handleClose = () => {}, onSubmit = () => {}, editMode = false, data = [], onDelete = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { dateFormat } = useSelector(state => state.profileFormatSlice);
  const offLimitsDetails = editMode ? data?.[0] : null;

  const [showCalender, setShowCalender] = useState(!!offLimitsDetails?.end_date);

  useEffect(() => {
    setValue(CONSANTS_MARK_OFF_LIMITS.DATE, offLimitsDetails?.end_date ?? null);
    setValue(CONSANTS_MARK_OFF_LIMITS.DURATION, offLimitsDetails?.end_date ? CONSANTS_MARK_OFF_LIMITS.SPECIFIC : CONSANTS_MARK_OFF_LIMITS.INDEFINITE);
    setValue(CONSANTS_MARK_OFF_LIMITS.SEVERITY, offLimitsDetails?.severity ?? CONSANTS_MARK_OFF_LIMITS.HARD);
    setValue(CONSANTS_MARK_OFF_LIMITS.REASON, offLimitsDetails?.reason ?? '');
  }, []);

  const { setValue, getValues } = useForm();

  const handleSave = async () => {
    const formValue = getValues();

    if (!formValue?.duration || !formValue?.severity || !formValue?.reason || (formValue?.duration === CONSANTS_MARK_OFF_LIMITS.SPECIFIC && !formValue?.date)) {
      enqueueSnackbar('All fields are required', { variant: ERROR });
      return;
    }
    const payload = {
      severity: formValue.severity,
      endDate: formValue.date,
      reason: formValue.reason
    };
    setIsLoading(true);
    const newValue = { ...data?.[0], severity: formValue.severity, end_date: formValue.date, reason: formValue.reason };
    onSubmit?.(payload, setIsLoading, newValue);
  };

  function onEditorChange(text) {
    setValue(CONSANTS_MARK_OFF_LIMITS.REASON, text);
  }

  const titlePopup = editMode ? t('utils.editOffLimits') : t('utils.markOffLimitsAgain');

  return (
    <CustomPopup
      onClose={(_, reason) => {
        if (reason && reason === POPUP_CLOSE_REASONS.BACKDROP_CLICK && isLoading) return;
        handleClose();
      }}
      open={isPopupOpen}
      title={titlePopup}
      showAction
      handleSubmit={handleSave}
      paperStyles={CONSANTS_MARK_OFF_LIMITS.CLASSNAMES.CUSTOM_PAPERE}
      titleClassName={CONSANTS_MARK_OFF_LIMITS.CLASSNAMES.OFF_LIMIT_TITLE}
      titleIcon={
        editMode ? (
          <DeleteIcon
            id='deleteIcon'
            onClick={() => {
              setIsLoading(true);
              onDelete(setIsLoading);
            }}
          />
        ) : null
      }
    >
      <Loader show={isLoading} className='abolute-position' />
      <Box className='d-flex flex flex-column' gap={4} id='mark-off-limits-popup'>
        {/* Duration Selection */}
        <Box>
          <FormLabel>
            <Typography fontSize={16}>{t('utils.duration')}</Typography>
          </FormLabel>
          <Box display={'flex'} alignItems={'center'} gap={4}>
            <RadioGroup
              className='d-flex flex flex-row'
              defaultValue={offLimitsDetails?.end_date ? CONSANTS_MARK_OFF_LIMITS.SPECIFIC : CONSANTS_MARK_OFF_LIMITS.INDEFINITE}
              onChange={e => {
                setValue(CONSANTS_MARK_OFF_LIMITS.DURATION, e.target.value);
                if (e.target.value === CONSANTS_MARK_OFF_LIMITS.INDEFINITE) {
                  setValue(CONSANTS_MARK_OFF_LIMITS.DATE, null);
                } else {
                  setValue(CONSANTS_MARK_OFF_LIMITS.DATE, offLimitsDetails?.end_date ? formatDateUtcLocal(offLimitsDetails?.end_date, 'YYYY-MM-DD') : null, { shouldDirty: true });
                }
                setShowCalender(e.target.value === CONSANTS_MARK_OFF_LIMITS.SPECIFIC);
              }}
            >
              <FormControlLabel value={CONSANTS_MARK_OFF_LIMITS.INDEFINITE} control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label={t('utils.indefinite')} />
              <FormControlLabel value={CONSANTS_MARK_OFF_LIMITS.SPECIFIC} control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label={t('utils.specificEndDate')} />
            </RadioGroup>

            {showCalender && (
              <DatePickerCommon
                hideLabel
                value={offLimitsDetails?.end_date ? formatDate(offLimitsDetails?.end_date, dateFormat?.value) : null}
                slots={{
                  textField: params => (
                    <TextField
                      InputLabelProps={{
                        size: 'normal',
                        color: 'error'
                      }}
                      variant='standard'
                      {...params}
                      label={t('utils.pickADate')}
                    />
                  ),
                  openPickerIcon: () => <CalendarMonthIcon fontSize='small' />
                }}
                onChange={e => {
                  const selectedDate = e?.$d;
                  setValue(CONSANTS_MARK_OFF_LIMITS.DATE, selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                }}
              />
            )}
          </Box>
        </Box>

        {/* Severity Selection */}
        <Box>
          <FormLabel>{t('utils.severity')}</FormLabel>
          <RadioGroup
            className='d-flex flex flex-row'
            defaultValue={offLimitsDetails?.severity ?? CONSANTS_MARK_OFF_LIMITS.SOFT}
            onChange={e => setValue(CONSANTS_MARK_OFF_LIMITS.SEVERITY, e.target.value)}
          >
            <FormControlLabel value={CONSANTS_MARK_OFF_LIMITS.HARD} control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label={t('utils.hard')} />
            <FormControlLabel value={CONSANTS_MARK_OFF_LIMITS.SOFT} control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label={t('utils.soft')} />
          </RadioGroup>
        </Box>

        {/* Off-limits Reason */}
        <Box className='off-limit-richbox-container'>
          <FormLabel id='off-limit-reason'>{t('utils.offLimitsReason')}</FormLabel>
          <RichText skipFirstRender defaultValue={offLimitsDetails?.reason} onChange={onEditorChange} />
        </Box>
      </Box>
    </CustomPopup>
  );
};

MarkOffLimits.propTypes = {
  isPopupOpen: PropTypes.bool,
  editMode: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.any
};

export default MarkOffLimits;
