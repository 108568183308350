//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useTranslation } from 'react-i18next';
import TeamInfoTable from './TeamInfoTable';
const PartnerInformation = props => {
  const { setValue, currentValues = {}, isEditing, option, bd } = props;
  const fields = {
    origination_credit: 30,
    selling_credit: 20,
    execution_credit: 50
  };
  const { t } = useTranslation();
  const [partners, setPartners] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);

  useEffect(() => {
    if (partners && partners.length > 0) {
      const options = option.filter(array_el => {
        return partners.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id);
      });
      setSelectedPartners(options);
    } else {
      setSelectedPartners(option);
    }
    setValue('partners', partners, { shouldDirty: true });
  }, [partners, setValue]);

  useEffect(() => {
    setValue('partners', partners, { shouldDirty: true });
  }, [partners, setValue]);

  useEffect(() => {
    setValue('partners', partners, { shouldDirty: true });
    setPartners(currentValues.partners);
  }, [currentValues.partners]);

  return (
    <>
      <TeamInfoTable
        title={t('common.consultant')}
        label={t('common.consultant')}
        inputConfig={{
          list: partners,
          fields,
          setter: setPartners
        }}
        isEditing={isEditing}
        option={selectedPartners}
        currentValues={currentValues}
        bd={bd}
        isPartner
      />
    </>
  );
};

PartnerInformation.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  bd: PropTypes.string
};

export default PartnerInformation;
