//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { Box, Checkbox, Chip, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogAnActivityTypes } from '../../../../../actions';
import ResonTags from '../../../../../Containers/ClientSuite/components/Modal/ResonTags';
import SetContent from '../../../../../Containers/ClientSuite/components/Modal/SetContent';
import Search from '../../../../../Containers/Contacts/Search';
import { getUserSelector } from '../../../../../selectors';
import { logAnActivityApi } from '../../../../../services/ApiService';
import * as AttachmentsService from '../../../../../services/AttachmentsService';
import { DELETE, ERROR } from '../../../../../services/constantService';
import { logAnActivityActions } from '../../../../../store/logAnActivitySlice';
import { convertToLocalTimeAndDate } from '../../../../../utils/date';
import CustomPopup from '../../../../common/CustomPopup';
import '../../index.scss';
//----------------------------------------------// Internal Imports // -------------------------------------------------

const ActivityHistoryDetail = props => {
  const { activityDetails, setActivityComponent = () => {}, getActivityDetails = () => {}, activityState } = props;
  const { dateFormat, timeZoneFormat, timeFormats } = useSelector(state => state.profileFormatSlice);

  const [_, setLoadingAttachments] = useState(false);
  const { t } = useTranslation();
  const { t: _tv2 } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(getUserSelector);
  const activityType = useSelector(state => state.commonReducer.activityType);
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [_themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    const activityData = activityDetails?.activity ? activityDetails?.activity : [];
    const sortedActivities = activityData.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB - dateA;
    });
    setActivityData(sortedActivities);
  }, [activityDetails]);

  useEffect(() => {
    if (!activityType) {
      dispatch(fetchLogAnActivityTypes('ACTIVITY_TYPE', 'activityType'));
    } else {
      setOptions(activityType);
    }
  }, [activityType, dispatch]);

  const [isFilterOpen, setFilterPopup] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [notes, setNotes] = useState('');
  const labels = ['Calls', 'Emails', 'Messages', 'Documents', 'Date'];

  const OnViewMore = notes => {
    setIsViewMore(true);
    setNotes(notes);
  };

  const handleClose = () => {
    setIsViewMore(false);
  };

  const onFilterClose = () => {
    setFilterPopup(false);
  };

  const getActivityFields = (type, activityType) => {
    return activityType?.filter(activity => activity.activity_type_code === type)?.[0]?.fields || [];
  };

  const handleDelete = async id => {
    try {
      await logAnActivityApi(DELETE, id, null, 'activity/remove');
      enqueueSnackbar(t('utils.activityDeletedSuccessfully'), { variant: 'success' });
      await getActivityDetails();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const downloadAttachment = async id => {
    let attachmentId = id;
    try {
      setLoadingAttachments(true);
      const url = await AttachmentsService.documentDownload(attachmentId);
      setLoadingAttachments(false);
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);

        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        enqueueSnackbar('Unable to download attachment', { variant: ERROR });
      }
    } catch (e) {
      setLoadingAttachments(false);
    }
  };
  return (
    <>
      <Box id='ActivityLogs_container'>
        <Box id='ActivityHistoryDetails' className='custom-scrollbar'>
          <Box className={props.isHeaderNav === 'closed' ? 'activity-details-collapse' : 'activity-details'} px={0.5}>
            {activityData?.map((item, index) => (
              <Box
                key={index}
                border={'1px solid'}
                bgcolor={activityState === 'recent_activity' ? 'primary.light025' : 'secondary.light025'}
                borderColor={'primary.light1'}
                sx={{ width: '100%', minHeight: '100px', borderRadius: '8px' }}
                p={2}
                mb={2}
              >
                <Stack direction={'row'}>
                  <Box width={'100%'}>
                    <Stack direction={'row'}>
                      <Chip
                        label={item?.activity_type_label}
                        size='small'
                        sx={{
                          bgcolor: activityState === 'recent_activity' ? 'primary.light1' : 'secondary.light1',
                          color: activityState === 'recent_activity' ? 'primary.main' : 'secondary.main',
                          fontWeight: '600',
                          borderRadius: '5px'
                        }}
                        className='fs-12'
                      />
                      {item?.notes_type_label && (
                        <>
                          <Typography className='fs-14' mx={1}>
                            {t('common.asa')}
                          </Typography>
                          <Chip
                            label={item?.notes_type_label}
                            size='small'
                            sx={{
                              bgcolor: 'gray',
                              color: 'text.text',
                              fontWeight: '600',
                              borderRadius: '5px'
                            }}
                            className='fs-12'
                          />
                        </>
                      )}
                    </Stack>

                    <Box>
                      <Typography className='fs-12 mt-1 sub-typo'>
                        <span>{t('common.lastUpdatedOn')}</span> {convertToLocalTimeAndDate(item?.updated_at, false, timeZoneFormat?.value, dateFormat?.value, timeFormats?.value)}{' '}
                        <span>{t('common.by')}</span> {item?.created_user?.name}
                      </Typography>
                    </Box>
                  </Box>
                  {item?.activity_type_label === 'Client Feedback' && (
                    <Box className='d-flex flex-row'>
                      <Rating name='half-rating' defaultValue={item.rating} precision={0.5} readOnly />
                      <Chip
                        className='ml-3'
                        label={item?.client_feedback}
                        size='small'
                        color='primary'
                        key={index}
                        sx={{
                          textTransform: 'capitalize',
                          fontSize: '13px'
                        }}
                      />
                    </Box>
                  )}
                  <Box>
                    <Stack direction={'row'} justifyContent={'flex-end'}>
                      {item?.attachment && (
                        <Tooltip title={item?.attachment?.file_name}>
                          <IconButton onClick={() => downloadAttachment(item?.attachment?.id)}>
                            <SaveAltOutlinedIcon className='img' sx={{ fill: 'black' }} fontSize={'small'} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {options.find(option => option.labels[0]?.label === item?.activity_type_label) ? (
                        <>
                          <IconButton
                            onClick={() => {
                              dispatch(logAnActivityActions.updateEditId(item?.id));
                              setActivityComponent('add_activity');
                            }}
                          >
                            <EditIcon className='img' sx={{ fill: 'black' }} fontSize={'small'} />
                          </IconButton>
                          {item.created_by === userData?.id && (
                            <IconButton className='delete-icons' onClick={() => setDeletePopup(item?.id)}>
                              <DeleteOutlineIcon fontSize={'small'} />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </Stack>
                  </Box>
                </Stack>
                {item?.client_feedback == 'rejected' ? (
                  <>
                    <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                      {t('common.reason')}
                    </Typography>
                    <ResonTags data={item?.outcome_reason} />
                  </>
                ) : (
                  <>
                    {item?.outcome_reason && (
                      <>
                        <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                          {t('common.nextStep')}
                        </Typography>
                        <Typography variant='body2' className='fs-13' sx={{ mb: 2, mt: 1 }}>
                          {item?.outcome_reason}
                        </Typography>
                      </>
                    )}
                  </>
                )}
                <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                  {t('common.comment')}
                </Typography>
                <SetContent note={item?.notes} />

                <Stack width={'100%'} mt={1} gap={1}>
                  <Stack direction='row' flexWrap='wrap' width='100%'>
                    {getActivityFields(item?.activity_type, activityType)?.map((fields, index) => {
                      if (fields.field === 'attendees') {
                        return (
                          <Box width='50%' key={index}>
                            <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                              {t('utils.attendees')}:{' '}
                            </Typography>
                            <Typography className='fs-12'>
                              {item?.attendees
                                ?.map(attendee => {
                                  if (attendee?.client_contacts?.contact?.first_name) {
                                    return `${attendee?.client_contacts?.contact?.first_name} ${attendee?.client_contacts?.contact?.last_name}`;
                                  } else {
                                    return `${attendee?.contact_user?.first_name} ${attendee?.contact_user?.last_name}`;
                                  }
                                })
                                .join(', ') || '-'}
                            </Typography>
                          </Box>
                        );
                      }

                      if (fields.field === 'project-id') {
                        return (
                          <Box width='50%' key={index}>
                            <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                              {t('module.project')}:{' '}
                            </Typography>
                            <Typography className='fs-12'>
                              {item?.project?.job_number} - {item?.project?.job_title}
                            </Typography>
                          </Box>
                        );
                      }
                      if (fields.field === 'date-time') {
                        return (
                          <Box width='50%' key={index}>
                            <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                              {t('common.date')}:{' '}
                            </Typography>
                            <Typography className='fs-12'>{convertToLocalTimeAndDate(item?.interview_date, false, item?.interview_date_timezone, dateFormat?.value, timeFormats?.value)}</Typography>
                          </Box>
                        );
                      }

                      if (fields.field === 'due-date') {
                        return (
                          <Box width='50%' key={index}>
                            <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                              {t('contacts.logActivity.dueDate')}:{' '}
                            </Typography>
                            <Typography className='fs-12'>{convertToLocalTimeAndDate(item?.due_date, true, timeZoneFormat?.value, dateFormat?.value, timeFormats?.value)}</Typography>
                          </Box>
                        );
                      }

                      if (fields.field === 'timezone') {
                        return (
                          <Box width='50%' key={index}>
                            <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                              {t('fields.location')}:{' '}
                            </Typography>
                            <Typography className='fs-12'>{item?.interview_date_timezone}</Typography>
                          </Box>
                        );
                      }
                    })}
                    <Box width='100%' className='d-flex flex-row'>
                      {item?.from_stage && (
                        <Box width='50%'>
                          <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                            {t('fields.form_stage')}:{' '}
                          </Typography>
                          <Typography className='fs-12'>{item?.from_stage}</Typography>
                        </Box>
                      )}
                      {item?.to_stage && (
                        <Box width='50%'>
                          <Typography variant='body2' sx={{ color: '#777', mt: 1 }} className='fs-10'>
                            {t('fields.to_stage')}:{' '}
                          </Typography>
                          <Typography className='fs-12'>{item?.to_stage}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Box>
          <CustomPopup title={t('utils.filters')} open={isFilterOpen} onClose={onFilterClose} showAction={true} confirmText={'Apply'} cancelText={'Close'}>
            <form>
              <Stack className='filter-popup'>
                <Box className='fs-12 filter-text-heading pb-2'> {t('utils.filterBy')}</Box>
                <Box className='search-bar flex'>
                  <Search placeholder='Calls' isAddContactToProject={true} />
                </Box>
                {labels.map((label, index) => (
                  <Box key={index} className='d-flex align-items-center'>
                    <Checkbox className='checkboxStyle' size='small' name={`checkbox_${label}`} />
                    <Box component='span' className='fs-12 filter-text'>
                      {label}
                    </Box>
                  </Box>
                ))}
              </Stack>
            </form>
          </CustomPopup>
        </Box>
        {isViewMore && (
          <CustomPopup onClose={handleClose} aria-labelledby='customized-dialog-title' open={isViewMore} title='Notes'>
            <DialogContent className='content-value'>
              <Typography variant='body2' className='card-containt view-text' dangerouslySetInnerHTML={{ __html: notes || '-' }}></Typography>
            </DialogContent>
          </CustomPopup>
        )}
      </Box>
      <CustomPopup
        open={isDeletePopup}
        handleSubmit={() => {
          handleDelete(isDeletePopup);
          setDeletePopup(false);
        }}
        onClose={() => setDeletePopup(false)}
        title='Are you sure you want to delete this activity?'
        confirmText='Delete'
        cancelText='Cancel'
        showAction={true}
      />
    </>
  );
};

ActivityHistoryDetail.propTypes = {
  activityDetails: PropTypes.object,
  isHeaderNav: PropTypes.string,
  setActivityComponent: PropTypes.func,
  getActivityDetails: PropTypes.func,
  activityState: PropTypes.string
};
export default ActivityHistoryDetail;
