/* eslint-disable no-unused-vars */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import UpdateIcon from '@mui/icons-material/Update';
import WorkIcon from '@mui/icons-material/Work';
import { Box, MenuItem, Slide, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchPickLists } from '../../../actions';
import profileUrl from '../../../assets/images/profile-image.png';
import { createRequiredField, createSchema } from '../../../Containers/Companies/Utils';
import ValidatePermit from '../../../security/ValidatePermit';
import { companyRelationshipApi, ignCompanyDataApi, logAnActivityApi } from '../../../services/ApiService';
import { DELETE, EMPLOYEE_MAX_LIMIT, ERROR, FIELDS, GET, PATCH, POST, PUT, REVENUE_MAX_LIMIT, SUCCESS } from '../../../services/constantService';
import { commonActions } from '../../../store/commonSlice';
import { customFormValidator, splitLastIfMultiple } from '../../../utils/common';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import AddCompany from '../../AddCompany';
import AddContactDrawer from '../../AddContactDrawer/AddContactDrawer';
import CustomConfirmationPopup from '../../common/CustomConfirmationPopup';
import DrawerComponent from '../../common/Drawer';
import Loader from '../../common/Loader';
import ActivityLogs from '../../ViewContactDrawer/ActivityLogsIgnyte';
import { getUpdatedPayload, removeUnusedField } from '../utils';
import CompanyDetails from './CompanyDetails';
import './index.scss';
import ViewCompanyHeader from './ViewCompanyHeader';

const ViewCompanyDrawer = props => {
  const { baseRoute, isDrawerOpen, setIsDrawerOpen, setIsEdited, id, options, gridApi, isUpdateCompany, setIsUpdateCompany } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { translateMessage } = useCustomMessageHook();
  const [state, setState] = useState({ top: false, left: false, bottom: false, right: false });
  const [drawerWidth, setDrawerWidth] = useState('normal');
  const [contact, setContact] = useState(null);
  const contactRef = useRef();
  contactRef.current = contact;
  const [isHeaderNav, setIsHeaderNav] = useState('open');
  const [openAddContactDrawer, setOpenAddContactDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState('PROFILE_TAB');
  const selectedTabRef = useRef();
  selectedTabRef.current = selectedTab;
  const [isHeaderBar, setIsHeaderBar] = useState('open');
  const [isIconOpen, setIsIconOpen] = useState('icon-closed');
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);
  const [contactData, setContactData] = useState();
  const [isWidthApply, setIsWidthApply] = useState(false);
  const [editingField, setEditingField] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState();
  const [detailData, setDetailData] = useState();
  const [isLogAnActivityOpen, setIsLogAnActivityOpen] = useState(true);

  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  // const [numberOfProjects, setNumberOfProjects] = useState();
  // const [numberOfBd, setNumberOfBd] = useState();
  const changeImage = useRef();

  const [openConfirmationPopup, setOpenConfirmationPopup] = useState();
  const [defaultEngagementTab, setDefaultEngagementTab] = useState('projects');

  const {
    register,
    unregister,
    setValue,
    reset,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm({});
  const dispatch = useDispatch();
  const onDeleteConfirm = async () => {
    setLoading(true);
    const { status } = await ignCompanyDataApi(DELETE, '', contact.data.id);
    if (status === 200) {
      setLoading(false);
      setIsDrawerOpen(false);
      setState({ ...state, ['right']: false });
      navigate('/companies/all-companies');
      gridApi && gridApi.onFilterChanged();
      const message = translateMessage('Successfully', false, 'Company', 'deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
    }
  };
  const handleEditCompany = () => {
    setIsPopupOpen(true);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  const handleDeleteCompany = () => {
    setOpenConfirmationPopup(true);
  };
  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (!open) setIsDrawerOpen(false);
    setState({ ...state, [anchor]: open });
    navigate(baseRoute || '/companies/all-companies');
    if (isUpdateCompany) {
      gridApi && gridApi.onFilterChanged();
    }
    const data = { data: null };
    dispatch(commonActions.updatePicklistData({ key: 'companyContacts', data }));
  };

  const getIndustryNames = company_industries => {
    let industryNames = company_industries.map(industry => industry.industries.name);
    let industryString = industryNames.join(', ');
    return industryString;
  };

  useEffect(() => {
    if (isDrawerOpen) {
      setState(prevState => ({ ...prevState, ['right']: open }));
    }
  }, [isDrawerOpen]);
  const getHeaderDetails = async () => {
    try {
      setLoading(true);
      const sub_route = '?tabName=COMPANY_HEADER_TAB';
      const { status, data } = await ignCompanyDataApi(GET, '', id, sub_route);
      dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
      if (status === 200 && data) {
        setTabData(data);
        const HeaderData = {
          ...data,
          company_added_by: [{ added_user: { name: data?.created_user?.name || '-' } }]
        };
        const result = cleanupData(HeaderData);
        setContact({
          data: result,
          avatarSizeXs: 2,
          avatarSizeMd: 1.3,
          avatarSizeSm: 2,
          mainContentWidthXs: 7,
          mainContentWidthMd: 8.5,
          mainContentWidthSm: 7,
          buttonSizeXs: 3,
          buttonSizeMd: 2.2,
          buttonSizeSm: 3,
          height: '24vh'
        });
      }
      setLoading(false);
    } catch (err) {
      console.error('error in getHeaderDetails::', err);
    }
  };

  const cleanupData = data => {
    // console.log(parse(data?.comments), "PARSE STRING");
    let contactObject = {};
    const primaryAddress = detailData?.company_address?.find(addr => addr.is_primary);
    contactObject = {
      ...data,
      avatar: profileUrl,
      offLimitItems: options,
      resume: true,
      viewResumeButton: 'View Resume',
      descText: true,
      sourceTitle: 'Source' + ':',
      tagsTitle: 'Tags' + ':',
      addedByTitle: 'Added By' + ':',
      projectsTitle: t('module.projects') + ':',
      linkedinIcon: <LinkedInIcon className='linkedin-icon-header fs-14' />,
      editIcon: (
        <ValidatePermit parent={'company'} type='btn-disabled' permissionType={'update'}>
          <MenuItem onClick={() => handleEditCompany()} className='pannel-menu-items d-flex align-items-center'>
            <Box className='pannel-menu-icon mr-1'>
              <EditIcon className='fs-14' />
            </Box>
            <Typography variant='body2' className='p-2 fs-14'>
              {t('actions.edit')}
            </Typography>
          </MenuItem>
        </ValidatePermit>
      ),
      deleteIcon: (
        <ValidatePermit parent={'company'} type='btn-disabled' permissionType={'delete'}>
          <MenuItem onClick={() => handleDeleteCompany()} className='pannel-menu-items d-flex align-items-center'>
            <Box className='pannel-menu-icon mr-1'>
              <DeleteIcon className='fs-14' />
            </Box>
            <Typography variant='body2' className='p-2 fs-14'>
              {t('actions.delete')}
            </Typography>
          </MenuItem>
        </ValidatePermit>
      ),
      addButtonLabel: 'Create New',
      additionalContent: {
        content1: {
          icon: <WorkIcon className='p-1 icon-font-size' />,
          text: getIndustryNames(data?.company_industries) || '-'
        },
        content2: {
          icon: <LocationOnIcon className='p-1 icon-font-size' />,
          text: primaryAddress?.metropolitan_area || '-'
        },
        content3: {
          icon: <UpdateIcon className='p-1 icon-font-size' />,
          text: data?.website_url
        }
      },
      addedBy: {
        name: 'Anirudh Gupta',
        icon: <CheckCircleIcon className='circle-icon fs-14' />
      },
      projects: {
        title: t('module.projects') + ':',
        text: data?.company_project?.length || '-'
      },
      actionItems: ['Business Development', t('module.project'), 'Add Contact'],
      disableEditing: false,
      businessDevelopment: {
        title: 'Business Development' + ':',
        text: data?.company_bd?.length || '-'
      }
      //TO DO : this code will required in future
      // lastProject: {
      //   title: "Last Project" + ":",
      //   text: "CTO Project on 24/11/24"

      // }
    };
    return contactObject;
  };
  const getCompanyDetails = async from => {
    try {
      if (!id) {
        return;
      }
      setLoading(true);
      const sub_route = `?tabName=${selectedTabRef.current}`;
      const { status, data } = await ignCompanyDataApi(GET, '', id, sub_route);

      if (status === 200 && data) {
        if (selectedTabRef.current === 'ASSOCIATED_CONTACTS_TAB') {
          setContactData(data);
        } else {
          setDetailData(data);
        }
        setLoading(false);
      }
      if (status === 500) {
        setLoading(false);
      }
    } catch (err) {
      console.error('error in getCompanyDetails:', err);
    }
  };
  useEffect(() => {
    getCompanyDetails();
  }, [selectedTabRef.current]);

  useEffect(() => {
    if (id) {
      getHeaderDetails();
      getCompanyDetails();
      getActivityDetails();
    }
    // Note from Arun: Not sure why this logic is here
    const data = { data: null };
    dispatch(commonActions.updatePicklistData({ key: 'companyContacts', data }));
  }, [id]);

  const headerDrawer = headerState => {
    setIsHeaderNav(headerState);
  };
  const handleTabChange = async tabName => {
    const cleanedTabName = tabName.replace(/-/g, '_');
    const formattedTabName = cleanedTabName.toUpperCase();
    const newTabName = `${formattedTabName}_TAB`;
    setSelectedTab(newTabName);
  };
  const updateImg = async (file, id) => {
    if (!file) return;
    const sub_route = 'image';
    const { status, data } = await ignCompanyDataApi(POST, file, id, sub_route);
    if (status === 200) {
      setLoading(false);

      const message = translateMessage('Successfully', false, 'Profile image', 'uploaded');

      enqueueSnackbar(data?.message || message, { variant: SUCCESS });
    } else {
      const message = translateMessage('UnableMessage', false, 'Profile image', 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const updateContactDetails = async (field, required) => {
    setLoading(true);
    let statusCode, companyData;
    const formData = { ...getValues() };
    if (id && changeImage.current && field === 'profile_img') {
      await updateImg(formData?.profile_img, id);
    } else if (field === 'company_associated') {
      const { status, data } = await handleUpdateAssociatedCompany();
      statusCode = status;
    } else {
      const payload = getUpdatedPayload(field, formData, id, contact);
      const { status, data } = await ignCompanyDataApi(PATCH, payload, contact.data.id);
      statusCode = status;
      companyData = data;
    }

    if (statusCode === 200) {
      setLoading(false);
      setIsUpdateCompany(true);
      await getCompanyDetails();
      if (selectedTabRef.current === 'PROFILE_TAB') {
        await getHeaderDetails();
      }
      const message = translateMessage('Successfully', false, 'Company', 'Updated');

      enqueueSnackbar(message, { variant: SUCCESS });
      return true;
    }
    if (statusCode === 500 && field !== 'company_associated') {
      //setRecordData(data)
      //setIsConfirmationPopup(true)
      setLoading(false);
      enqueueSnackbar(`${companyData.message}:${companyData.reason}`, { variant: ERROR });
    }
  };

  const handleCompanyRelationshipRequests = async (payload, typeOfRequest) => {
    const { related_company, relation_type_desc, relationship, comment } = payload;
    const requestPayload = {
      company_id: id,
      related_company_id: related_company?.id,
      relation_type: relation_type_desc,
      relationship,
      comment
    };
    const { status, data } = await companyRelationshipApi(typeOfRequest, '', requestPayload, '');
    return { status, data };
  };

  const handleUpdateAssociatedCompany = async () => {
    try {
      const associatedCompanyData = watch('company_associated');

      const newAssociatedCompanyData = associatedCompanyData.filter(data => data?.new && data.new === true);
      const updatedAssociatedCompanyData = associatedCompanyData.filter(data => data?.id && data?.id.length > 0 && data?.isUpdated == true);

      //handle post request for new company relations
      for (let i = 0; i < newAssociatedCompanyData.length; i++) {
        await handleCompanyRelationshipRequests(newAssociatedCompanyData[i], POST);
      }

      //handle put request for new company relations
      for (let i = 0; i < updatedAssociatedCompanyData.length; i++) {
        await handleCompanyRelationshipRequests(updatedAssociatedCompanyData[i], PUT);
      }
      return { status: 200, data: [] };
    } catch (err) {
      return { status: 500, data: null };
    }
  };
  const updateField = async (field, required, onCloseClick) => {
    try {
      if (field === 'profile_img') {
        changeImage.current = true;
        setEditingField('');
      }
      // setAddAnother(false);
      let result = await updateContactDetails(field, required);

      /* Refresh contact grid only if contact edited */
      setIsEdited(true);
      onCloseClick(field);

      if (result) onCloseClick(field);
      return result;
    } catch (e) {
      console.error('Error found in updateContact::', e);
    }
  };
  const getActivityDetails = async () => {
    try {
      setLoading(true);
      const sub_route = 'company-activity';
      const { status, data } = await logAnActivityApi(GET, id, null, sub_route);
      if (status === 200) {
        setLoading(false);
      }
      return data;
    } catch (error) {
      console.error('error in getActivityDetails::', error);
    }
  };
  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries ? data?.company_industries.map(industry => ({ industry_id: industry.id })) : [],
      capital_structure: data?.capital_structure?.field_value || '',
      currency_unit: data?.currency_unit?.field_value || ''
    };
    return addCompanyPayload;
  };
  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const isValidatedRevenue = async formValues => {
    if (formValues.revenue_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.revenue_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_to_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_from >= EMPLOYEE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_to >= EMPLOYEE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_to_massage, { variant: ERROR });
      return false;
    }
    return true;
  };
  const saveAddCompanyPopup = async (isUpdate = false) => {
    let formValues = { ...getValues() };
    const IsValidRequestBody = await isValidated(formValues);
    const IsValidRevenueRange = await isValidatedRevenue(formValues);
    if (IsValidRequestBody && IsValidRevenueRange) {
      setLoading(true);
      const requestBody = getPayload(formValues);
      const removeUnused = removeUnusedField(requestBody, dirtyFields);

      const { status, data } = await ignCompanyDataApi(PATCH, removeUnused, contact.data.id);

      if (status === 200) {
        setIsUpdateCompany(true);
        setIsPopupOpen(false);
        const message = translateMessage('Successfully', false, 'Company', 'updated');
        enqueueSnackbar(message, { variant: SUCCESS });
        await getHeaderDetails();
        await getCompanyDetails();
        if (!isUpdate) reset();
        setLoading(false);
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Company');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        if (status === 500) {
          enqueueSnackbar(data.reason, { variant: ERROR });
        } else {
          const message = translateMessage('UnableMessage', false, 'Update', 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        }
        setLoading(false);
      }
    }
  };
  const closeAddCompanyPopup = () => {
    setIsPopupOpen(false);
  };

  const handleProjectClick = () => {
    setActiveTab(3);
    setTabIndex(0);
    setDefaultEngagementTab('projects');
  };

  const handleBDClick = () => {
    setActiveTab(3);
    setTabIndex(1);
    setDefaultEngagementTab('businessDevelopment');
  };

  const handleScrollToId = id => {
    setActiveTab(0);
    setTabIndex(0);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <DrawerComponent
        setIsMenuOpen={setIsDrawerOpen}
        isMenuOpen={isDrawerOpen}
        id={'company_view_drawer'}
        anchor={'right'}
        open={state['right']}
        isViewDrawer={false}
        onClose={toggleDrawer('right', false)}
        closeIconPosition='close-rectangle-position'
        width='87vw'
        drawerWidth={drawerWidth}
        setDrawerWidth={setDrawerWidth}
        isWidthApply={isWidthApply}
        setIsWidthApply={setIsWidthApply}
        transitionType={Slide}
      >
        <>
          <Loader show={loading} />
          {contact && (
            <Box className='d-flex company-drawer-body'>
              <Box className='company-details-head'>
                <Box className='sub-company-details'>
                  <ViewCompanyHeader
                    {...contact}
                    refreshImg={isUpdateCompany}
                    updateField={updateField}
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    setValue={setValue}
                    editingField={editingField}
                    setEditingField={setEditingField}
                    headerDrawer={headerDrawer}
                    setIsHeaderBar={setIsHeaderBar}
                    isHeaderBar={isHeaderBar}
                    isIconOpen={isIconOpen}
                    setIsIconOpen={setIsIconOpen}
                    setIsHeaderOpen={setIsHeaderOpen}
                    isHeaderOpen={isHeaderOpen}
                    setIsHeaderNav={setIsHeaderNav}
                    isHeaderNav={isHeaderNav}
                    getHeaderDetails={getHeaderDetails}
                    options={options}
                    companyId={id}
                    setLoading={setLoading}
                    handleProjectClick={handleProjectClick}
                    setOpenAddContactDrawer={setOpenAddContactDrawer}
                    openAddContactDrawer={openAddContactDrawer}
                    handleBDClick={handleBDClick}
                    handleScrollToId={handleScrollToId}
                  ></ViewCompanyHeader>
                  <motion.div
                    // style={{
                    //   display: 'flex',
                    //   justifyContent: 'space-evenly',
                    //   height: '100%'
                    // }}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      marginTop: '15px'
                      // height: 'calc(100vh - 200px)',
                      // overflow: 'hidden'
                    }}
                  >
                    <motion.div
                      animate={{
                        width: isLogAnActivityOpen ? '63%' : '95%'
                      }}
                      style={{
                        height: '100%',
                        overflow: 'auto'
                      }}
                    >
                      <CompanyDetails
                        id={id}
                        data={tabData}
                        detailsData={detailData}
                        contactData={contactData}
                        // activityDetails={activityDetail}
                        register={register}
                        unregister={unregister}
                        // control={control}
                        watch={watch}
                        setLoading={setLoading}
                        getValues={getValues}
                        setValue={setValue}
                        isHeaderNav={isHeaderNav}
                        // handleSave={createContactData}
                        updateField={updateField}
                        getContactDetails={getCompanyDetails}
                        // isPopupOpen={isPopupOpen}
                        // setIsPopupOpen={setIsPopupOpen}
                        onTabChange={handleTabChange}
                        // popupClose={popupClose}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        defaultEngagementTab={defaultEngagementTab}
                      />
                    </motion.div>
                    <motion.div
                      animate={{
                        width: isLogAnActivityOpen ? '32%' : '1%'
                      }}
                      style={{
                        background: isLogAnActivityOpen ? '#fff' : '',
                        position: 'relative'
                        // overflow: 'auto',
                        // height: '100%'
                      }}
                    >
                      <motion.div
                        animate={{
                          rotate: isLogAnActivityOpen ? 180 : 0,
                          left: isLogAnActivityOpen ? '10px' : '-10px'
                        }}
                        className='toggle-log-an-activity'
                        onClick={() => {
                          setIsLogAnActivityOpen(!isLogAnActivityOpen);
                        }}
                      >
                        <ExpandMoreRoundedIcon cursor='pointer' className={`log-an-activity-arrow-icon fs-20 ${!isIconOpen}`} />
                      </motion.div>

                      <motion.div
                        animate={{
                          display: isLogAnActivityOpen ? 'block' : 'none'
                        }}
                        style={
                          {
                            // height: '100%',
                            // overflow: 'auto'
                          }
                        }
                        className={isHeaderOpen ? 'open-company-header-drawer' : 'close-company-header-drawer'}
                      >
                        <ActivityLogs id={id} setDrawerWidth={setDrawerWidth} getHeaderDetails={getHeaderDetails} isHeaderNav={isHeaderNav} ifForCompany={true} />
                        {/* <div style={{ height: '100%' }}></div> */}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </Box>
              </Box>
            </Box>
          )}
        </>
      </DrawerComponent>
      <CustomConfirmationPopup
        open={openConfirmationPopup}
        onClose={closeConfirmationPopup}
        type='delete'
        deletingItem='Company'
        deletingItemName={contact?.data?.name}
        cancelText='No'
        confirmText='Yes'
        onConfirm={onDeleteConfirm}
        setOpen={setOpenConfirmationPopup}
      />
      <AddCompany
        isPopupOpen={isPopupOpen}
        handleClose={closeAddCompanyPopup}
        handleSubmit={() => {
          saveAddCompanyPopup(true);
        }}
        setIsLoading={setLoading}
        setValue={setValue}
        isUpdate={true}
        register={register}
        reset={reset}
        loading={loading}
        setIsPopupOpen={setIsPopupOpen}
        unregister={unregister}
        watch={watch}
        companyData={contactRef.current}
      ></AddCompany>
      <AddContactDrawer openDrawer={openAddContactDrawer} setOpenDrawer={setOpenAddContactDrawer} />
    </>
  );
};
ViewCompanyDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  setIsEdited: PropTypes.func,
  id: PropTypes.func,
  options: PropTypes.object,
  setIsUpdateCompany: PropTypes.func,
  isUpdateCompany: PropTypes.bool,
  gridApi: PropTypes.func,
  baseRoute: PropTypes.string
};
export default ViewCompanyDrawer;
