import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ignCompanyDataApi } from '../../../../services/ApiService';
import { ERROR, GET, ROUTES } from '../../../../services/constantService';
import { formatDateUtcLocal, getDateFormatFromLocale } from '../../../../utils/date';
import ViewContactDrawer from '../../../ViewContactDrawer';
import './index.scss';

const ViewAssociatedContacts = props => {
  const { companyData, contactData, updateField, register, unregister, setValue, isHeaderNav } = props;
  const [clientContact, setClientContact] = useState([{ name: '', email: '', phone_number: '', job_title: '' }]);
  const [associatedContactsByCompany, setAssociatedContactsByCompany] = useState();
  const headerData = ['Name', 'Job Title', 'Board Member', 'Time Period', 'Phone Number', 'Email'];
  const [currentFilter, setCurrentFilter] = useState('all');
  const { dateFormat } = useSelector(state => state.profileFormatSlice);

  useEffect(() => {
    register('company_associated_contact');
    return () => {
      unregister('company_associated_contact');
    };
  }, [register, unregister]);
  useEffect(() => {
    setValue('company_associated_contact', clientContact);
  }, [clientContact]);

  useEffect(() => {
    if (contactData) {
      const updatedContact = contactData?.map(ele => {
        const primaryEmail = ele.contact_emails.find(emailObj => emailObj.is_primary === true);
        const primaryPhone = ele.contact_phones.find(emailObj => emailObj.is_primary === true);
        let contact = {
          id: ele.id,
          name: `${ele.first_name} ${ele.last_name}`,
          email: primaryEmail?.email,
          phone_number: primaryPhone?.phone_number,
          job_title: ele?.current_job_title
        };
        return contact;
      });
      if (updatedContact && updatedContact.length) {
        setClientContact(updatedContact);
      }
    }
  }, [contactData]);

  const getAssocaitedContactsByCompanyId = async () => {
    try {
      const id = 'associated-contact';
      const sub_route = `${companyData.id}`;
      const { status, data } = await ignCompanyDataApi(GET, null, id, sub_route);
      if (status === 200) {
        setAssociatedContactsByCompany(data || []);
      } else {
        enqueueSnackbar(`${data.message}`, { variant: ERROR });
      }
      return data;
    } catch (error) {
      console.log('error in getActivityDetails::', error);
    }
  };
  const showDate = dateString => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const formatDate = getDateFormatFromLocale({ dateFormat: dateFormat?.value, date: date });
    return formatDateUtcLocal(date, formatDate);
  };

  useEffect(() => {
    getAssocaitedContactsByCompanyId();
  }, []);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const openContactDrawer = id => {
    setIsDrawerOpen(true);
    setContactId(id);
  };
  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={isHeaderNav === 'closed' ? 'details-container-close drawer-tab-layout-close' : 'details-container'}>
        <Box className={`scroll-content ${isHeaderNav === 'closed' ? 'details-container-subtab-close' : 'details-container-subtab'}`}>
          {/* <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'> */}
          <Box className='section-details mr-3'>
            <Box className='communication-section'>
              <Box className='head-container'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input'>
                    <Box className='d-flex my-3 border' style={{ borderRadius: 4 }}>
                      <Box
                        role='button'
                        style={{ borderRadius: 4, textAlign: 'center' }}
                        className={`px-4 py-2 ${currentFilter == 'all' ? 'table-header-background text-white' : 'bg-light text-dark'}`}
                        onClick={() => setCurrentFilter('all')}
                      >
                        All
                      </Box>
                      <Box
                        role='button'
                        style={{ borderRadius: 4, textAlign: 'center' }}
                        className={`px-4 py-2 ${currentFilter == 'current' ? 'table-header-background text-white' : 'bg-light text-dark'}`}
                        onClick={() => setCurrentFilter('current')}
                      >
                        Current{'  '}
                      </Box>
                      <Box
                        role='button'
                        style={{ borderRadius: 4, textAlign: 'center' }}
                        className={`px-3 py-2 ${currentFilter == 'previous' ? 'table-header-background text-white' : 'bg-light text-dark'}`}
                        onClick={() => setCurrentFilter('previous')}
                      >
                        Previous
                      </Box>
                    </Box>
                    <TableContainer component={Paper} className='paper-border'>
                      <Table aria-label='customized table'>
                        <TableHead>
                          <TableRow className='table-header-background'>
                            {headerData?.map((header, index) => (
                              <TableCell style={{ minWidth: '200px' }} align='center' key={index} className='p-2 title-content viewContent'>
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {associatedContactsByCompany && associatedContactsByCompany.length > 0 ? (
                            associatedContactsByCompany.map((associatedContacts, index) => {
                              // optimization needed here
                              return currentFilter == 'all' ? (
                                <TableRow
                                  key={index}
                                  onClick={() => {
                                    openContactDrawer(associatedContacts?.ign_contacts?.id);
                                  }}
                                >
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box sx={{ cursor: 'pointer', textDecoration: 'underline' }}>{associatedContacts?.ign_contacts?.name}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.title || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.board_is_present ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>
                                      {showDate(associatedContacts?.start_date)} - {showDate(associatedContacts?.end_date)}
                                    </Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.ign_contacts?.contact_phones.filter(phone => phone?.is_primary)[0]?.phone_number || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.ign_contacts?.contact_emails.filter(email => email?.is_primary)[0]?.email || '-'}</Box>
                                  </TableCell>
                                </TableRow>
                              ) : currentFilter == 'current' && associatedContacts?.is_present ? (
                                <TableRow
                                  key={index}
                                  onClick={() => {
                                    openContactDrawer(associatedContacts?.ign_contacts?.id);
                                  }}
                                >
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className='' sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                      {associatedContacts?.ign_contacts?.name}
                                    </Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.title || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.board_is_present ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>
                                      {showDate(associatedContacts?.start_date)} - {showDate(associatedContacts?.end_date)}
                                    </Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.ign_contacts?.contact_phones.filter(phone => phone?.is_primary)[0]?.phone_number || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.ign_contacts?.contact_emails.filter(email => email?.is_primary)[0]?.email || '-'}</Box>
                                  </TableCell>
                                </TableRow>
                              ) : currentFilter == 'previous' && !associatedContacts?.is_present ? (
                                <TableRow
                                  key={index}
                                  onClick={() => {
                                    openContactDrawer(associatedContacts?.ign_contacts?.id);
                                  }}
                                >
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className='' sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                      {associatedContacts?.ign_contacts?.name}
                                    </Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.title || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.board_is_present ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>
                                      {showDate(associatedContacts?.start_date)} - {showDate(associatedContacts?.end_date)}
                                    </Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.ign_contacts?.contact_phones.filter(phone => phone?.is_primary)[0]?.phone_number || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2 fs-12'>
                                    <Box className=''>{associatedContacts?.ign_contacts?.contact_emails.filter(email => email?.is_primary)[0]?.email || '-'}</Box>
                                  </TableCell>
                                </TableRow>
                              ) : null;
                            })
                          ) : (
                            <TableRow>
                              <TableCell align='center' className='p-2 fs-12'>
                                -
                              </TableCell>
                              <TableCell align='center' className='p-2 fs-12'>
                                -
                              </TableCell>
                              <TableCell align='center' className='p-2 fs-12'>
                                -
                              </TableCell>
                              <TableCell align='center' className='p-2 fs-12'>
                                -{' '}
                              </TableCell>
                              <TableCell align='center' className='p-2 fs-12'>
                                -
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {isDrawerOpen && <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={contactId} baseRoute={ROUTES.allCompanies} />}
    </div>
  );
};
ViewAssociatedContacts.propTypes = {
  companyData: PropTypes.object,
  contactData: PropTypes.object,
  getContactDetails: PropTypes.func,
  updateField: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  isHeaderNav: PropTypes.string
};
export default ViewAssociatedContacts;
