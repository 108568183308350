import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../store/contactSlice';

//import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <Box role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} style={{ height: '100%' }} {...other}>
      {value === index && (
        <Box
          sx={{
            height: '100%',
            overflow: 'auto'
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

/**
 *
 * @param {Array} tabs - The tabs to display.
 * @param {string} orientation - The orientation of the tabs.
 * @param {string} variant - The variant of the tabs.
 * @param {boolean} centered - Whether the tabs are centered.
 * @param {boolean} scrollButtons - Whether the tabs have scroll buttons.
 * @param {boolean} allowScrollButtonsMobile - Whether the tabs allow scroll buttons on mobile.
 * @param {function} handleTabChange - Callback function when tab changes
 * @param {event} event - The event that triggered the tab change.
 * @param {number} newValue - The index of the new tab.
 * @param {string} subTabLabel - Optional parameter passed if the root tab has subtabs(if applicable)
 * @returns {JSX.Element} The MuiTabLayout component.
 */
const MuiTabLayout = ({ tabs, orientation = 'horizontal', variant = 'standard', centered = false, scrollButtons = 'auto', allowScrollButtonsMobile = false, handleTabChange }) => {
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const isClicked = state.contactReducer ? state.contactReducer.isButtonClicked : false;

  useEffect(() => {
    if (isClicked) {
      handleChange(event, 4);
    }
  }, [isClicked]);

  const handleChange = async (event, newValue) => {
    try {
      if (tabs[newValue]?.subTabs?.length > 0) {
        // If there are subtabs, pass the first subtab's index
        await handleTabChange?.(event, newValue, tabs[newValue]?.subTabs[0]?.label);
      } else {
        // If no subtabs, pass the main tab index
        await handleTabChange?.(event, newValue);
      }
      dispatch(contactActions.toggleButton(false));
      setValue(newValue);
      setSubValue(0); // Reset subtab when main tab changes
      // Call onClick handler if it exists
      if (tabs[newValue]?.onClick) {
        tabs[newValue].onClick();
      }
      // Call handleTabChange for main tab change
    } catch (error) {
      console.error('Error in handleChange', error);
    }
  };

  const handleSubChange = (event, newValue) => {
    setSubValue(newValue);
    handleTabChange?.(event, newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          orientation={orientation}
          variant={variant}
          centered={centered}
          scrollButtons={scrollButtons}
          allowScrollButtonsMobile={allowScrollButtonsMobile}
          aria-label='main tabs'
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none'
            }
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              sx={{
                fontWeight: value === index ? 'bold' : 'normal',
                backgroundColor: value === index ? 'secondary.main' : 'transparent',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                '&.Mui-selected': {
                  color: 'white'
                }
              }}
            />
          ))}
        </Tabs>
      </Box>

      {/* Render subtabs if they exist */}
      {tabs[value]?.subTabs?.length > 0 && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={subValue}
            onChange={handleSubChange}
            variant='standard'
            aria-label='sub tabs'
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'secondary.main'
              }
            }}
          >
            {tabs[value].subTabs.map((subTab, index) => (
              <Tab
                key={index}
                label={subTab.label}
                sx={{
                  '&.Mui-selected': {
                    color: 'secondary.main'
                  }
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}

      <Box
        sx={{
          flexGrow: 1,
          height: 0,
          overflow: 'hidden'
        }}
      >
        {tabs.map((tab, index) => (
          <CustomTabPanel
            key={index}
            value={value}
            index={index}
            className={tab.label === 'Fit Score' ? 'fit-score-container' : ''}
            sx={{
              background: '#fff',
              boxShadow: ' 0 0 10px 0 rgb(0 0 0 / 10%)',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }}
          >
            {tab.subTabs?.length > 0
              ? // Render subtab content
                tab.subTabs.map((subTab, subIndex) => (
                  <CustomTabPanel key={subIndex} value={subValue} index={subIndex} className={tab.label === 'Fit Score' ? 'fit-score-sub-container' : ''}>
                    {subTab.content}
                  </CustomTabPanel>
                ))
              : // Render main tab content
                tab.content}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

MuiTabLayout.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      subTabs: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          content: PropTypes.node.isRequired
        })
      ),
      onClick: PropTypes.func
    })
  ).isRequired,
  handleTabChange: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  variant: PropTypes.oneOf(['standard', 'scrollable', 'fullWidth']),
  centered: PropTypes.bool,
  scrollButtons: PropTypes.oneOf(['auto', true, false]),
  allowScrollButtonsMobile: PropTypes.bool
};

export default MuiTabLayout;
