import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import omit from 'lodash/omit';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchGridData } from '../../../../actions';
import ColorLegends from '../../../../components/ColorLegends/Workbenches';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import { ignWorkbenchDataApi, WorkbenchColorsDataApi } from '../../../../services/ApiService';
import { DELETE, ERROR, GET } from '../../../../services/constantService';
import { getDateFormatFromLocale } from '../../../../utils/date';
import { WorkBenchContext } from '../../Context';
import NoMyListComponent from './Component/NoMyListComponent';
import ContactGrid from './Grid/ContactGrid';
import ProjectGrid from './Grid/ProjectGrid';
import QueryComponent from './QueryComponent/index';

const ButtonDropDown = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = option => {
    setAnchorEl(null);
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick(option);
    }
  };

  return (
    <div>
      <Button color={props.color || 'primary'} variant='contained' onClick={handleClick} size='small' disabled={props.disabled ? props.disabled : false}>
        {props.buttonText}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.options.map((option, index) => (
          <MenuItem onClick={() => handleClose(option)} key={index}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ButtonDropDown.propTypes = {
  buttonText: PropTypes.string,
  options: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string
};

export default function WorkbenchComponent() {
  const { workbenchId, setWorkbenchId, setQueryId, setArchiveId, refetch, setAddContactFromExistingModal, setAddProjectFromExistingModal, refetchFunc, types, noMyList, handleAreYouSureOpen } =
    useContext(WorkBenchContext);
  const { t } = useTranslation();

  const { id, queryId, listType, archiveId } = useParams();
  const [displayData, setDisplayData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [gridType, setGridType] = useState('');
  const [workbenchType, setWorkbenchType] = useState();
  const [savedSortModel, setSavedSortModel] = useState([]);
  const [savedFilterModel, setSavedFilterModel] = useState({});
  const [colors, setColors] = useState([]);
  const [refetchColors, setRefetchColors] = useState(false);
  const [gridState, setGridState] = useState([]);
  const { dateFormat, timeZoneFormat, timeFormats } = useSelector(state => state.profileFormatSlice);

  const gridRef = useRef();

  const fetchWorkbenchData = useCallback(
    async id => {
      try {
        if (id) {
          setLoading(true);
          const subRoute = `records?workbenchId=${id}`;
          const res = await ignWorkbenchDataApi(GET, '', {}, subRoute);
          if (res && res?.data) {
            const { data } = res;
            setWorkbenchType(data?.workbench?.type);
            setDisplayData(data);
            if (archiveId && listType === 'myList') {
              setArchiveId(archiveId);
              setWorkbenchId(null);
            } else {
              setWorkbenchId(id);
              setArchiveId(null);
            }
            setQueryId(null);
          }
        }
      } catch (err) {
        enqueueSnackbar(err, 'error');
      } finally {
        setLoading(false);
      }
    },
    [archiveId]
  );

  const handleAddContactButton = () => {
    if (archiveId && listType === 'myList') {
      setArchiveId(archiveId);
      setWorkbenchId(null);
    } else {
      setWorkbenchId(id);
      setArchiveId(null);
    }
    setQueryId(null);
    setAddContactFromExistingModal(true);
  };

  const onSelectionChanged = useCallback(event => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => {
      return {
        ...node.data,
        contact_id: node?.data?.contact?.id,
        project_id: node?.data?.project?.id
      };
    });
    setSelectedIds(selectedData);
  }, []);

  const handleRecordDelete = async (selectedIds, bulk = false) => {
    try {
      setLoading(true);
      for (let data of selectedIds) {
        let payload = {
          workbenchId: id
        };
        if (workbenchType == types.CONTACT) {
          payload = { ...payload, contactId: data.contact_id };
        }
        if (workbenchType == types.PROJECT) {
          payload = { ...payload, projectId: data.project_id };
        }

        await ignWorkbenchDataApi(DELETE, '', payload, 'remove-record');
      }
      setLoading(false);
      if (bulk) {
        setSelectedIds([]);
      }
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const handleDeleteList = async id => {
    try {
      setLoading(true);
      const payload = {
        id: id
      };
      const res = await ignWorkbenchDataApi(DELETE, '', payload, 'delete-workbench');
      if (res && res?.data) {
        enqueueSnackbar('Workbench is Delete successfully', 'success');
        setWorkbenchId(undefined);
        refetchFunc();
        // onClose();
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
      // onClose();
    }
  };

  const onClickDeleteWorkbench = () => {
    handleAreYouSureOpen({ func: handleDeleteList, data: workbenchId || archiveId });
  };

  const getGridSettings = useCallback(() => {
    setLoading(true);
    setGridType(`${workbenchType}WorkbenchGrid`);
    fetchGridData('', `${workbenchType}WorkbenchGrid`, ({ status, data }) => {
      if (status) {
        const gridSettings = JSON.parse(data?.settings || '[]');
        const sortModel = JSON.parse(data?.sort || '[]');
        const filterModel = JSON.parse(data?.filter || '{}');

        setSavedSortModel([...sortModel]);
        setSavedFilterModel({ ...filterModel });
        if (gridSettings.length) {
          const gridSettingToUse = gridSettings.map(col => {
            return omit(col, ['sort', 'sortIndex']);
          });
          setGridState(gridSettingToUse);
        }
      }
      setLoading(false);
    });
  }, [workbenchType]);

  useEffect(() => {
    if (workbenchType) getGridSettings();
  }, [workbenchType]);

  useEffect(() => {
    if (id) {
      fetchColorsData();
      fetchWorkbenchData(id);
      setWorkbenchId(id);
      setQueryId(null);
    }
  }, [id, refetch]);

  const fetchColorsData = useCallback(async () => {
    try {
      const targetId = id || (archiveId && listType === 'myList' ? archiveId : null);

      if (!targetId) {
        return;
      }

      const res = await WorkbenchColorsDataApi(GET, targetId);
      if (res?.data) {
        setColors(res.data);
      }
    } catch (error) {
      console.error('Workbench colors fetch error:', error);
      enqueueSnackbar(error.message || 'Error occurred while fetching workbench colors', { variant: ERROR });
    }
  }, [id, archiveId, listType, refetchColors]);

  useEffect(() => {
    if (refetchColors) {
      fetchColorsData();
      setRefetchColors(false);
    }
  }, [refetchColors, fetchColorsData]);

  useEffect(() => {
    if (archiveId && listType === 'myList') {
      fetchWorkbenchData(archiveId);
      fetchColorsData();
      setArchiveId(id);
      setWorkbenchId(null);
      setQueryId(null);
    }
  }, [archiveId, refetch]);

  const onGridReady = useCallback(params => {
    gridRef.current = params;
  }, []);

  return (
    <div className='w-100 border background-white custom-vh-80 rounded p-4'>
      <Loader show={isLoading} />
      {queryId && <QueryComponent />}
      {listType && archiveId && <QueryComponent />}
      {(id != undefined || (archiveId !== undefined && listType === 'myList')) && displayData ? (
        <>
          {/* header */}
          <Box className='list-info'>
            <Box className='list-details'>
              <Typography variant='body2'>
                <strong>{t('utils.listName')}:</strong> {displayData?.workbench?.name}
              </Typography>
              <Typography variant='body2'>
                <strong>{t('utils.createdBy')}:</strong> {displayData?.workbench?.owner?.name}
              </Typography>
              <Typography variant='body2'>
                <strong>{t('utils.createdAt')}: </strong>
                {displayData?.workbench?.created_at ? getDateFormatFromLocale({ dateFormat: dateFormat?.value, date: displayData?.workbench?.created_at }) : '--'}
              </Typography>
              <Typography variant='body2'>
                <strong>{t('utils.updatedAt')}: </strong>{' '}
                {displayData?.workbench?.updated_at || displayData?.workbench?.created_at
                  ? getDateFormatFromLocale({ dateFormat: dateFormat?.value, date: displayData?.workbench?.updated_at || displayData?.workbench?.created_at })
                  : '--'}
              </Typography>
            </Box>
            <Box className='list-description'>
              <Box display='flex' alignItems='center' gap={1}>
                <strong>{t('utils.description')}:</strong>
                <Typography variant='body2'>{displayData?.workbench?.description}</Typography>
              </Box>
            </Box>
          </Box>

          {/* button section */}

          <div className='d-flex justify-content-between align-items-center mt-2 p-4'>
            {workbenchType == types.PROJECT && (
              <>
                <CustomButton buttonText={t('project.addProject')} onClick={() => setAddProjectFromExistingModal(true)} />
                <div className='d-flex' style={{ gap: '1rem' }}>
                  <ColorLegends colors={colors} labelMatch={'workbench_labels'} workbenchId={id} setRefetchColors={setRefetchColors} />
                  <CustomButton
                    disabled={selectedIds.length == 0}
                    buttonText={t('actions.removeProject')}
                    type={'remove-workbench-button ml-1 mr-1'}
                    onClick={() => handleRecordDelete(selectedIds, true)}
                  />
                </div>
              </>
            )}
            {workbenchType == types.CONTACT && (
              <>
                <CustomButton buttonText={t('actions.addContact')} color='primary' onClick={handleAddContactButton} />
                <div className='d-flex' style={{ gap: '1rem' }}>
                  <ColorLegends colors={colors} labelMatch={'workbench_labels'} workbenchId={id} setRefetchColors={setRefetchColors} />
                  <CustomButton
                    disabled={selectedIds.length == 0}
                    buttonText={t('actions.removeContact')}
                    type={'remove-workbench-button mr-1'}
                    onClick={() => handleRecordDelete(selectedIds, true)}
                  />
                </div>
              </>
            )}
          </div>

          {/* table */}
          {!isLoading && (
            <div className='pl-4 pr-4'>
              {workbenchType == types.PROJECT && (
                <ProjectGrid
                  colors={colors}
                  data={displayData?.records}
                  onSelect={onSelectionChanged}
                  handleDeleteContact={data => handleAreYouSureOpen({ func: handleRecordDelete, data })}
                  gridType={gridType}
                  savedSortModel={savedSortModel}
                  savedFilterModel={savedFilterModel}
                  gridState={gridState || []}
                  gridApiRef={onGridReady}
                />
              )}
              {workbenchType == types.CONTACT && (
                <ContactGrid
                  colors={colors}
                  data={displayData?.records}
                  onSelect={onSelectionChanged}
                  handleDeleteContact={data => handleAreYouSureOpen({ func: handleRecordDelete, data })}
                  gridType={gridType}
                  savedSortModel={savedSortModel}
                  savedFilterModel={savedFilterModel}
                  gridState={gridState || []}
                  gridApiRef={onGridReady}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <>{noMyList && !queryId && !archiveId && <NoMyListComponent />}</>
      )}
    </div>
  );
}
