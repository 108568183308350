import InfoIcon from '@mui/icons-material/Info';
import { Alert, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { createRequiredField, createSchema } from '../../../Containers/Companies/Utils';
import { ignCompanyDataApi, picklistDropDownApis } from '../../../services/ApiService';
import { ERROR, GET, POST, SUCCESS } from '../../../services/constantService';
import { customFormValidator, splitLastIfMultiple } from '../../../utils/common';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import AddCompany from '../../AddCompany';
import CustomDropdown from '../CustomDropdown';

const StatusMessage = ({ type, message }) => {
  const severityMap = {
    exact: 'success',
    similar: 'warning',
    notFound: 'error'
  };

  return (
    <Alert severity={severityMap[type]} icon={<InfoIcon />} sx={{ mt: 1, py: 0 }}>
      <Typography variant='body2'>{message}</Typography>
    </Alert>
  );
};

StatusMessage.propTypes = {
  type: PropTypes.oneOf(['exact', 'similar', 'notFound']).isRequired,
  message: PropTypes.string.isRequired
};

const ResumeCompanySelection = ({ label = '', placeholder = '', onChange, defaultValue, required = false, isForAddCompany = false, companyList = [], ...rest }) => {
  const { translateMessage } = useCustomMessageHook();
  const [options, setOptions] = useState(companyList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [prevInputValue, setPrevInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);

  const { getValues, setValue, register, unregister, watch, reset } = useForm({});
  // const filter = useMemo(() => createFilterOptions(), []);
  const fetchCompaniesCB = async query => {
    setLoading(true);
    setError(null);
    try {
      const { status, data } = await picklistDropDownApis(GET, `companies?name=${encodeURIComponent(query)}`);
      if (status === 200) {
        const fetchedOptions = data.data || [];

        setOptions(fetchedOptions);

        const exactMatch = fetchedOptions.find(option => option?.name?.toLowerCase() === query?.toLowerCase());

        if (exactMatch) {
          setSelectedValue(exactMatch);
          onChange(null, exactMatch);
          setIsOpen(false);
          setStatusMessage({ type: 'exact', message: 'Exact company name found' });
        } else if (fetchedOptions.length > 0) {
          setStatusMessage({ type: 'similar', message: 'Similar companies found' });
        } else {
          setStatusMessage({ type: 'notFound', message: 'No matching companies found. Please search or add a new company.' });
        }
      } else {
        throw new Error('Failed to fetch companies');
      }
    } catch (err) {
      setError('Failed to load companies. Please try again.');
      setOptions(companyList);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = useCallback(fetchCompaniesCB, [onChange]);

  const debouncedFetchCompanies = useCallback(debounce(fetchCompanies, 500), []);

  useEffect(() => {
    if (defaultValue === '') return;
    if (defaultValue === null) {
      setStatusMessage({ type: 'notFound', message: 'No matching companies found. Please search or add a new company.' });
      return;
    }
    setSelectedValue(defaultValue);
    if (defaultValue.name) {
      setInputValue(defaultValue.name);
      setPrevInputValue(defaultValue.name);
    }
  }, [defaultValue]);

  const handleInputChange = useCallback(
    (event, newInputValue) => {
      setInputValue(newInputValue);
      if (newInputValue.length > 2) {
        setPrevInputValue(newInputValue);
        if (newInputValue) {
          debouncedFetchCompanies(newInputValue);
        } else {
          setOptions(defaultValue ? [defaultValue, ...companyList.filter(company => company.id !== defaultValue.id)] : companyList);
          setIsOpen(false);
          setStatusMessage({ type: 'notFound', message: 'No matching companies found. Please search or add a new company.' });
        }
      }
    },
    [debouncedFetchCompanies, defaultValue, companyList]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue?.isAddNewOption) {
        handleAddCompanyClick();
      } else {
        setSelectedValue(newValue);
        setIsOpen(false);
        setStatusMessage(null);
        onChange(event, newValue);
      }

      if (!newValue) {
        setStatusMessage({ type: 'notFound', message: 'No company selected. Please search or add a new company.' });
      }
    },
    [onChange]
  );

  const filterOptions = useCallback(() => {
    return [...options, { addNewCompany: true }];
  }, [options]);

  const getPayload = data => ({
    ...data,
    company_tags: data?.company_tags?.map(tag => ({ tag_id: tag.id })) || [],
    company_industries: data?.company_industries?.map(industry => ({ industry_id: industry.id })) || [],
    capital_structure: data?.capital_structure?.field_value,
    currency_unit: data?.currency_unit?.field_value
  });

  const isValidated = async requestBody => {
    const isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      const dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    }
    return true;
  };

  const saveData = async () => {
    const formValues = getValues();
    const requestBody = getPayload(formValues);
    const isValidRequestBody = await isValidated(requestBody);

    if (isValidRequestBody) {
      try {
        const { status, data } = await ignCompanyDataApi(POST, requestBody);
        if (status === 201) {
          const message = translateMessage('Successfully', false, 'Company', 'created');
          enqueueSnackbar(message, { variant: SUCCESS });
          setAddCompanyPopup(false);
          const companyData = await ignCompanyDataApi(GET, {}, data?.id);
          setOptions(prevOptions => [...prevOptions, companyData?.data]);
          setSelectedValue(companyData?.data);
          reset();
        } else if (status === 409) {
          const message = translateMessage('AlreadyExist', false, 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        } else {
          const message = translateMessage('UnableMessage', false, 'Create', 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        }
      } catch (err) {
        enqueueSnackbar('Unable to save company. Please try again.', { variant: ERROR });
      }
    }
  };

  const handleAddCompanyClick = () => {
    setIsOpen(false);
    setAddCompanyPopup(true);
  };

  const handleCloseModal = () => {
    setAddCompanyPopup(false);
  };

  return (
    <>
      <CustomDropdown
        {...rest}
        options={[...options, { addNewCompany: true }]}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        value={selectedValue || ''}
        required={required}
        loading={loading}
        error={error}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        filterOptions={filterOptions}
        setAddCompanyPopup={setAddCompanyPopup}
      />
      {statusMessage && <StatusMessage type={statusMessage.type} message={statusMessage.message} />}
      {addCompanyPopup && (
        <AddCompany
          isPopupOpen={addCompanyPopup}
          handleClose={handleCloseModal}
          handleSubmit={saveData}
          setValue={setValue}
          register={register}
          reset={reset}
          setIsPopupOpen={setAddCompanyPopup}
          unregister={unregister}
          watch={watch}
          companyDefaultValue={prevInputValue}
          noRedirection={true}
        />
      )}
    </>
  );
};

ResumeCompanySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  isForAddCompany: PropTypes.bool,
  companyList: PropTypes.array
};

export default ResumeCompanySelection;
