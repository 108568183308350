import DownloadIcon from '@mui/icons-material/Download';
import { Grid, Slider } from '@mui/material';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ignAthenaDataApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import { showToast } from '../../../utils/common';
import { DEFAULT_DATE_FORMAT } from '../../../utils/date';
import CustomButton from '../../common/CustomButton';
import CustomDropdown from '../../common/CustomDropdown';
import Loader from '../../common/Loader';
import { DownloadAthenaReport } from './../../../components/common/AthenaReport';

function AthenaScore(props) {
  const { contactId, onAssessmentChange, preSelectedAssessment } = props;
  const [loader, setLoader] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [assessmentsIds, setAssessmentIds] = useState([]);
  const [assessmentScores, setAssessmentScore] = useState([]);
  const [dropdownValue, setDropdownValue] = useState({});
  const [selectedAssessment, setSelectedAssessment] = useState();

  const marks = [
    {
      value: 0,
      label: '50'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 50,
      label: '0'
    },
    {
      value: 75,
      label: '25'
    },
    {
      value: 100,
      label: '50'
    }
  ];

  const fetchAssessmentsByContactId = async id => {
    try {
      setLoader(true);
      const contactAssessments = await ignAthenaDataApi(GET, '', '', `get-assessments/${id}`);
      const assessments = contactAssessments?.data;
      if (!isEmpty(assessments)) {
        const assessmentOptions = assessments.map((assessment, index) => {
          const reversedIndex = assessments.length - index;
          return { name: assessment.completed_at ? `Completed on ${dayjs(assessment.completed_at).format(DEFAULT_DATE_FORMAT)}` : `Assessment ${reversedIndex}`, value: assessment?.id, ...assessment };
        });
        setAssessmentIds(assessmentOptions);
        const assessmentFound = assessmentOptions.find(assessment => assessment.value === preSelectedAssessment?.value);
        if (assessmentFound) {
          handleAssessmentDropdownChange('', assessmentFound);
        } else if (assessmentOptions.length > 0) {
          handleAssessmentDropdownChange('', assessmentOptions[0]);
        }
      }
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleAssessmentDropdownChange = async (e, value) => {
    try {
      if (value) {
        setLoader(true);
        const assessmentId = value?.value;
        setSelectedAssessment(value);
        const assessmentScore = await ignAthenaDataApi(GET, '', '', `assessment-score-by-assessment-id/${assessmentId}`);
        setAssessmentScore(assessmentScore?.data);
        setDropdownValue(value);
        onAssessmentChange(value);
      }
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleAthenaReportDownload = async () => {
    try {
      setIsDownloading(true);
      if (selectedAssessment) {
        const { contact_id, project_id, id, language_used = 'ENG' } = selectedAssessment;
        await DownloadAthenaReport(id, contact_id, project_id, language_used);
      } else {
        enqueueSnackbar('Please select an assessment', { variant: 'error' });
      }
    } catch (err) {
      showToast(err.message, 'error');
    } finally {
      setIsDownloading(false);
    }
  };
  useEffect(() => {
    fetchAssessmentsByContactId(contactId);
  }, [contactId]);

  return (
    <div className='p-3 position-relative' style={{ minHeight: '90%' }}>
      <Loader show={loader} />
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex justify-content-between align-items-center'>
          <CustomDropdown options={assessmentsIds} value={dropdownValue} onChange={handleAssessmentDropdownChange} />
          <CustomButton
            disabled={!selectedAssessment}
            variant='tertiary'
            type='tertiary'
            boxClassName='ml-1'
            isLoading={isDownloading}
            buttonText={<DownloadIcon />}
            onClick={handleAthenaReportDownload}
          />
        </div>
        {selectedAssessment && (
          <>
            <div className='d-flex'>
              <div className='fs-16 fw-bold'>Invited on :</div>
              <span className='fs-16 ml-1'>{selectedAssessment.created_at ? dayjs(selectedAssessment.created_at).format(DEFAULT_DATE_FORMAT) : ''}</span>
            </div>
            <div className='d-flex'>
              <div className='fs-16 fw-bold'>Completed on :</div>
              <span className='fs-16 ml-1'>{selectedAssessment.completed_at ? dayjs(selectedAssessment.completed_at).format(DEFAULT_DATE_FORMAT) : ''}</span>
            </div>
          </>
        )}
      </div>

      <div className='athena-score-component mt-2'>
        {assessmentScores.map(score => (
          <>
            <Grid container className='p-1'>
              <Grid item xs={4} className='border p-3 w-100 question rounded-left d-flex align-items-center'>
                <div
                  className='question-font'
                  dangerouslySetInnerHTML={{
                    __html: score?.ign_assessment_questions?.left_question || '-'
                  }}
                ></div>
              </Grid>
              <Grid item xs={4} className='d-flex flex-column border p-3 w-100 align-items-center justify-content-around athena-question-box'>
                <Slider
                  // aria-label="Custom marks"
                  // getAriaValueText={valuetext}
                  step={1}
                  value={score?.score}
                  marks={marks}
                  track={false}
                  className='question-slider'
                  // valueLabelDisplay="on"
                />
              </Grid>
              <Grid item xs={4} className='border p-3 question rounded-right d-flex align-items-center'>
                <div
                  className='question-font'
                  dangerouslySetInnerHTML={{
                    __html: score?.ign_assessment_questions?.right_question || '-'
                  }}
                ></div>
              </Grid>
            </Grid>
          </>
        ))}
      </div>
    </div>
  );
}

AthenaScore.propTypes = {
  contactId: PropTypes.string,
  data: PropTypes.any,
  onAssessmentChange: PropTypes.func,
  preSelectedAssessment: PropTypes.object
};

export default AthenaScore;
