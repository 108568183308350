import { Box, Checkbox, Grid, Typography, createFilterOptions } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/common/CustomButton';
import CustomDropdown from '../../../components/common/CustomDropdown';
import GeoLocationInput from '../../../components/common/GeoLocation';
import TextField from '../../../components/common/InputField';
import RichText from '../../../components/common/RichText';
import CustomNumericField from '../../../components/common/StyledComponents/CustomNumericField';
import { getUserSelector } from '../../../selectors';
import { PositionProfileDataApi, ProjectDataApi, ignCompanyDataApi, ignContactDataApi, picklistsDataApis } from '../../../services/ApiService';
import { ERROR, GET, PICKLISTS, POST, ROUTES, SUB_ROUTES, SUCCESS, VALIDATION_MESSAGE } from '../../../services/constantService';

import { Cancel } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AddCompany from '../../../components/AddCompany';
import { CreateSchemaQuickAddContact, createRequiredFieldQuickAddContact } from '../../../components/AddContact/utils';
import { validateEmail } from '../../../components/MessageTemplatePopup/utils';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import DropdownWithCustomOptions from '../../../components/common/CustomMuiDropdown/DropdownWithCustomOptions';
import CompanySelectionDebounce from '../../../components/common/FunctionalComponents/CompanySelection/CompanySelectionDebounce';
import Loader from '../../../components/common/Loader';
import QuickAddContact from '../../../components/common/QuickAddContact/QuickAddContact';
import useApi from '../../../utils/Hooks/useApiHook';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { customFormValidator, getInputLabelPropsBasicDetails, requireValidMessage, splitLastIfMultiple } from '../../../utils/common';
import { createRequiredField, createSchema } from '../../Companies/Utils';
import Search from '../../Contacts/Search';
import { PROJECT_CONSTANTS } from '../ViewProject/Constant';

const BasicInfo = props => {
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addCompanyLoader, setAddCompanyLoader] = useState(false);
  const [contactName, setContactName] = useState();
  const [openQuickAddContact, setOpenQuickAddContact] = useState();
  const [companyName, setCompanyName] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});
  //Translate
  const { t } = useTranslation();

  const { translateMessage } = useCustomMessageHook();
  const userData = useSelector(getUserSelector);
  const { enqueueSnackbar } = useSnackbar();

  const { data: frameworkTypes } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.PROJECT_FRAMEWORK_TYPE);
    }
  });

  const frameworkOptions = frameworkTypes?.data || [];

  const handleFrameworkTypeChange = (e, value) => {
    handleUniversalStateLocally(e, value, 'framework_type');
  };

  useEffect(() => {
    handleFrameworkTypeChange(null, getValueFromUniversalState('framework_type') || frameworkOptions?.find(i => i?.field_value === 'helia'));
  }, [frameworkOptions]);

  const {
    handleUniversalState,
    getValueFromUniversalState,
    serviceOfferingOptions = [],
    companyOptions = [],
    industryOptions = [],
    jobTypeOptions = [],
    compensationFrequencyOptions = [],
    currencyTypeOptions = [],
    projectOwnerOptions = [],
    stageOptions = [],
    fetchContacts = () => {},
    setCopyPayload = () => {}
  } = props;

  const industries = useMemo(() => {
    return industryOptions?.map(i => i?.name)?.filter(i => i);
  }, [industryOptions]);

  const handleUniversalStateLocally = (event, newValue, name) => {
    const localEvent = {
      target: {
        value: newValue,
        name: name
      }
    };
    handleUniversalState(localEvent);
  };

  const handleRichText = value => {
    const event = {
      target: {
        value: value,
        name: 'additionalInformation'
      }
    };
    handleUniversalState(event);
  };
  const handleClose = () => {
    setOpenQuickAddContact(false);
  };

  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries
        ? data?.company_industries.map(industry => ({
            industry_id: industry.id
          }))
        : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value
    };
    return addCompanyPayload;
  };

  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const updateCompanyOption = val => {
    companyOptions.push(val);
    handleUniversalStateLocally(event, val, 'companyName');
  };

  const saveData = async () => {
    let formValues = { ...getValues() };
    console.log({ formValues });
    const requestBody = getPayload(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody) {
      setLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);
      console.log(data);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        // setCompanyId(data.id);
        setAddCompanyPopup(false);
        reset();

        setLoader(false);
        const companyData = await ignCompanyDataApi(GET, {}, data?.id);
        // addTargetCompany({ ...formValues, ...data });
        console.log({ companyData });
        updateCompanyOption(companyData?.data);
        // return data.id;
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Company');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoader(false);
    }
  };
  const addQuickContact = () => {
    setOpenQuickAddContact(true);
  };
  const createContact = async (allowDuplicate, getValues) => {
    let status, data;
    const contact = getValues;

    let isValid = await CreateSchemaQuickAddContact.isValid(contact);

    if (!isValid) {
      let dirtyField = customFormValidator(contact, createRequiredFieldQuickAddContact);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    if (
      (!contact.contact_emails || contact.contact_emails.length === 0) &&
      (!contact.linkedin_url || contact.linkedin_url.trim() === '') &&
      (!contact.contact_phones || contact.contact_phones.length === 0)
    ) {
      const message = translateMessage('AtLeastOneFieldRequired', false, ...splitLastIfMultiple(['Email', 'LinkedIn URL,', 'Phone Number']));
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    let validateEmailSyntax = contact.contact_emails.length > 0 ? validateEmail(contact.contact_emails.map(emailObj => emailObj.email)) : true;
    if (!validateEmailSyntax) {
      const message = translateMessage('ValidFiled', false, 'Email');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }

    const updatedEmail = contact.contact_emails.map(emailObj => ({
      ...emailObj,
      email_type: emailObj.email_type?.field_value
    }));
    const updatedPhone = contact.contact_phones.map(phoneObj => ({
      ...phoneObj,
      phone_type: phoneObj.phone_type?.field_value
    }));
    const updatedContact = {
      ...contact,
      contact_emails: updatedEmail,
      contact_phones: updatedPhone
    };
    const final = updatedContact;
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    const response = await ignContactDataApi(POST, '', final, sub_route);
    status = response?.status;
    data = response?.data;
    if (status === 201) {
      const company = getValueFromUniversalState('companyName');
      if (company?.id) {
        const associatedContact = {
          contact_id: data.id,
          company_id: company?.id
        };
        const sub_route = SUB_ROUTES.createAssociatedContact;
        const response = await ignCompanyDataApi(POST, associatedContact, '', sub_route);
        status = response?.status;
        data = response?.data;
      }
      setOpenQuickAddContact(false);
      await fetchContacts();

      const message = translateMessage('Successfully', false, 'Contact', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else if (status === 500) {
      if (data.reason === VALIDATION_MESSAGE) {
        enqueueSnackbar(`${data.reason}`, { variant: ERROR });
      } else {
        enqueueSnackbar(`${data.message}`, { variant: ERROR });
      }
    } else {
      const message = translateMessage('Successfully', false, 'Contact', 'create');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  useEffect(
    e => {
      const company = location?.state?.ign_companies;
      const disableDropdown = location?.state?.companyDropdownDisabled ?? false;
      if (company) {
        handleUniversalStateLocally(e, company, 'companyName');
        handleUniversalStateLocally(e, disableDropdown, 'companyDisabled');
      }
    },
    [companyOptions]
  );
  const fetchProjectContactData = async projectId => {
    try {
      setLoader(true);
      const res = await ProjectDataApi(GET, projectId, null, null, null);
      console.log({ projectRes: res });
      if (res?.data) {
        const data = res?.data?.clientContacts || [];

        //setup in history as well
        const hiringManager = data.filter(data => data.hiring_mgr == true)[0];
        handleUniversalStateLocally(res, hiringManager, 'hiringManagerData');

        let clientManager = data.filter(data => data.hiring_mgr != true);
        //setup in history as well
        handleUniversalStateLocally(res, clientManager, 'clientContactData');
        clientManager = clientManager.map(data => data?.contact);

        console.log({ projectHiringManager: hiringManager, projectClientManager: clientManager });
        handleUniversalStateLocally(res, res?.data, 'projectResponse');
        handleUniversalStateLocally(res, hiringManager?.contact, 'hiringManager');
        handleUniversalStateLocally(res, clientManager, 'clientContact');

        //set response as copyPayload to find difference
        setCopyPayload(res?.data);
      } else {
        throw new Error('Not Found');
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const fetchPositionProfile = async projectId => {
    try {
      const res = await PositionProfileDataApi(GET, projectId, null, '', null);

      handleUniversalStateLocally(res, res?.data, 'position_profile_response');
      handleUniversalStateLocally(res, res?.data?.description, 'description');
      if (res?.data?.description && res?.data?.description != null && res?.data?.description?.length) {
        let pathWithTab = ROUTES.viewProjectWithDeepLink;
        pathWithTab = pathWithTab.replace(':id', projectId);
        pathWithTab = pathWithTab.replace(':parentTab', 'Overview');
        pathWithTab = pathWithTab.replace(':subTab', 'Basic Info');
        navigate(pathWithTab);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  useEffect(e => {
    //Default stage as Draft
    handleUniversalStateLocally(e, { name: 'Draft' }, 'stage');
    const isProjectId = location?.state?.id;
    if (isProjectId && location?.state) {
      const {
        id,
        job_title,
        service_offering_value,
        job_type,
        job_number,
        pay_frequency_value,
        bd_currency_values,
        max_experience,
        min_experience,
        max_compensation,
        min_compensation,
        creator,
        industries,
        additional_info,
        location_place_id,
        record_type
      } = location.state;

      //Fetch contacts because that is not coming from card details;

      if (record_type == PROJECT_CONSTANTS.RECORD_TYPE_BUSINESS_DEVELOPMENT) {
        handleUniversalStateLocally(e, id, 'BDProjectId');
      } else {
        //Record type Project
        fetchProjectContactData(id);
        fetchPositionProfile(id);
        handleUniversalStateLocally(e, job_number, 'projectId');
      }

      handleUniversalStateLocally(e, job_title, 'jobTitle');
      handleUniversalStateLocally(e, service_offering_value, 'serviceOffering');
      handleUniversalStateLocally(e, industries, 'industries');
      handleUniversalStateLocally(e, location?.state?.location, 'location');
      handleUniversalStateLocally(e, job_type, 'jobType');
      handleUniversalStateLocally(e, creator, 'projectOwner');
      handleUniversalStateLocally(e, min_experience, 'experienceFrom');
      handleUniversalStateLocally(e, max_experience, 'experienceTo');
      handleUniversalStateLocally(e, bd_currency_values, 'currencyType');
      handleUniversalStateLocally(e, pay_frequency_value, 'compensationFrequency');
      handleUniversalStateLocally(e, min_compensation, 'minCompensation');
      handleUniversalStateLocally(e, max_compensation, 'maxCompensation');
      handleUniversalStateLocally(e, additional_info, 'additionalInformation');
      handleUniversalStateLocally(e, location_place_id, 'location_place_id');
      handleUniversalStateLocally(e, true, 'project-with-stage-draft');
    }
  }, []);
  const addCompanyByCompanyName = async companyName => {
    try {
      if (!companyName || companyName.trim().length == 0) {
        enqueueSnackbar('Please add company name to be added', { variant: ERROR });
        return;
      }
      setAddCompanyLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, {
        name: companyName,
        comments: '',
        company_tags: [],
        company_industries: [],
        company_status: 'active'
      });
      if (status == 201) {
        companyOptions.unshift({
          id: data.id,
          name: companyName
        });
        enqueueSnackbar('Successfully added company to the list', { variant: SUCCESS });
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: ERROR });
    } finally {
      setAddCompanyLoader(false);
    }
  };
  const filter = createFilterOptions();
  return (
    <>
      <Grid container spacing={2} rowSpacing={4} className='p-3'>
        <Grid item xs={6} md={4}>
          <Loader show={addCompanyLoader} />
          <CompanySelectionDebounce
            label={t('addContactDrawer.company')}
            defaultValue={watch('company')?.name}
            // value={watch('company') || {}}
            onChange={(event, newValue) => {
              if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue?.length && newValue[newValue?.length - 1].inputValue))) {
                // window.open(`${window.location.host}/companies/all-companies/add`, '_blank')
              } else {
                handleUniversalStateLocally(event, newValue, 'companyName');
              }
            }}
            required={true}
            isForAddCompany={false}
            name='companyName'
            className={'w-100'}
            addAnotherButton={true}
            addAnotherButtonText={addCompanyLoader ? t('utils.pleaseWait') : t('utils.addCompany')}
            addAnotherButtonOnChange={companysName => {
              addCompanyByCompanyName(companysName);
            }}
            disabled={getValueFromUniversalState('companyDisabled') ?? false}
            // required={true}
            value={getValueFromUniversalState('companyName')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            size='small'
            label={t('contacts.addContact.jobTitle')}
            variant='outlined'
            color='secondary'
            type='text'
            name='jobTitle'
            value={getValueFromUniversalState('jobTitle')}
            onChange={handleUniversalState}
            fullWidth
            required={true}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomDropdown
            options={serviceOfferingOptions ?? []}
            multiple={false}
            value={getValueFromUniversalState('serviceOffering')}
            onChange={(event, newValue) => {
              handleUniversalStateLocally(event, newValue, 'serviceOffering');
            }}
            label={t('utils.serviceOffering')}
            name='serviceOffering'
            className={'w-100'}
            required={true}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <DropdownWithCustomOptions
            className={'w-100'}
            multiple
            options={industries ?? []}
            getOptionLabel={option => option}
            isOptionEqualToValue={(option, value) => option === value}
            value={getValueFromUniversalState('industries') || []}
            onChange={(event, newValue) => handleUniversalStateLocally(event, newValue, 'industries')}
            label={t('contacts.addContact.industry')}
            size='small'
            useLocalInputState
            renderOption={(props, option, { selected }) => (
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} {...props}>
                <Checkbox size='small' checked={selected} />
                <Typography fontSize={14}>{option}</Typography>
              </Box>
            )}
            disableCloseOnSelect
            maxVisibleTags={1}
            required={getValueFromUniversalState('industries')?.length === 0}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <GeoLocationInput
            label={t('fields.location')}
            val={getValueFromUniversalState('location')}
            onChange={(event, location) => {
              handleUniversalStateLocally(event, location?.description, 'location');
              handleUniversalStateLocally(event, location?.place_id, 'location_place_id');
            }}
            className='w-100'
            size='small'
            required={true}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomDropdown
            options={jobTypeOptions ?? []}
            multiple={false}
            value={getValueFromUniversalState('jobType')}
            onChange={(event, newValue) => {
              handleUniversalStateLocally(event, newValue, 'jobType');
            }}
            label={t('utils.jobType')}
            name='jobType'
            className={'w-100'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomMuiDropdown
            className={'w-100'}
            options={frameworkOptions || []}
            getOptionLabel={option => option?.short_desc || ''}
            value={getValueFromUniversalState('framework_type') || null}
            isOptionEqualToValue={(option, value) => option?.field_value === value?.field_value}
            onChange={handleFrameworkTypeChange}
            label={t('fields.framework_type')}
            required={true}
            size='small'
            InputLabelProps={{
              style: getInputLabelPropsBasicDetails()
            }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            size='small'
            label={t('project.candidateScore.experienceLabelFromYears')}
            variant='outlined'
            color='secondary'
            type='number'
            name='experienceFrom'
            value={getValueFromUniversalState('experienceFrom')}
            onChange={handleUniversalState}
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            size='small'
            label={t('project.candidateScore.experienceLabelToYears')}
            variant='outlined'
            color='secondary'
            type='number'
            fullWidth
            name='experienceTo'
            value={getValueFromUniversalState('experienceTo')}
            onChange={handleUniversalState}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
        <Grid item md={4}>
          <CustomDropdown
            options={stageOptions ?? []}
            multiple={false}
            value={getValueFromUniversalState('stage')}
            onChange={(event, newValue) => {
              handleUniversalStateLocally(event, newValue, 'stage');
            }}
            label={t('utils.stage')}
            name='stage'
            className={'w-100'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomDropdown
            options={compensationFrequencyOptions ?? []}
            multiple={false}
            value={getValueFromUniversalState('compensationFrequency')}
            onChange={(event, newValue) => {
              handleUniversalStateLocally(event, newValue, 'compensationFrequency');
            }}
            label={t('reports.candidate_details_information_compensationFrequency')}
            name='compensationFrequency'
            className={'w-100'}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <CustomDropdown
            options={currencyTypeOptions ?? []}
            multiple={false}
            value={getValueFromUniversalState('currencyType')}
            onChange={(event, newValue) => {
              handleUniversalStateLocally(event, newValue, 'currencyType');
            }}
            label={t('utils.currency')}
            name='currencyType'
            className={'w-100'}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomNumericField
            value={getValueFromUniversalState('minCompensation')}
            label={t('utils.minCompensation')}
            onChange={(value, event) => {
              handleUniversalState(event);
            }}
            InputProps={{ inputProps: { min: 0 } }}
            name='minCompensation'
            variant='outlined'
            color='secondary'
            sx={{}}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomNumericField
            value={getValueFromUniversalState('maxCompensation')}
            label={t('utils.maxCompensation')}
            onChange={(value, event) => {
              handleUniversalState(event);
            }}
            InputProps={{ inputProps: { min: 0 } }}
            name='maxCompensation'
            variant='outlined'
            color='secondary'
            sx={{}}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomDropdown
            options={[userData]}
            multiple={true}
            onChange={(event, newValue) => {
              handleUniversalStateLocally(event, newValue, 'projectOwner');
            }}
            label={t('utils.projectOwner')}
            name='projectOwner'
            isAvatar={true}
            avatarKey={'image_id'}
            className={'w-100'}
            disableCloseOnSelect={true}
            noOptionsText={<CustomButton type={'w-100 primary'} variant={'outlined'} buttonText={'+ ' + t('actions.addContact')} />}
            value={getValueFromUniversalState('projectOwner').length ? getValueFromUniversalState('projectOwner') : [userData]}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <OpenSearchSelectHandling
            value={getValueFromUniversalState('hiringManager')}
            placeholder={'Search Hiring Manager'}
            multiple={false}
            onChange={(e, val) => {
              if (!val) return;
              const keyName = 'hiringManager';
              let hiringManager = getValueFromUniversalState(keyName) || null;
              const clientContactArray = getValueFromUniversalState('clientContact') || [];
              const duplicateHiringManager = hiringManager?.id == val.id;
              const duplicateClientContact = clientContactArray.find(contact => contact.id === val.id);
              if (duplicateHiringManager) {
                enqueueSnackbar('Contact already added as Hiring Manager.', { variant: ERROR });
                return;
              }
              if (duplicateClientContact) {
                enqueueSnackbar('Contact already added as Client Contact.', { variant: ERROR });
                return;
              }
              const localEvent = {
                target: {
                  value: val,
                  name: keyName
                }
              };
              handleUniversalState(localEvent);
            }}
            onCancel={id => {
              const keyName = 'hiringManager';
              const hiringManagerArray = getValueFromUniversalState(keyName) || [];
              const localEvent = {
                target: {
                  value: hiringManagerArray.filter(contact => contact.id !== id),
                  name: keyName
                }
              };
              handleUniversalState(localEvent);
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <OpenSearchSelectHandling
            value={getValueFromUniversalState('clientContact')}
            placeholder={'Search Client Contact'}
            multiple={true}
            onChange={(e, val) => {
              if (!val) return;
              const keyName = 'clientContact';
              const clientContactArray = getValueFromUniversalState(keyName) || [];
              const hiringManager = getValueFromUniversalState('hiringManager') || null;
              const duplicateHiringManager = hiringManager?.id == val?.id;
              const duplicateClientContact = clientContactArray.find(contact => contact.id === val.id);
              if (duplicateHiringManager) {
                enqueueSnackbar('Contact already added as Hiring Manager.', { variant: ERROR });
                return;
              }
              if (duplicateClientContact) {
                enqueueSnackbar('Contact already added as Client Contact.', { variant: ERROR });
                return;
              }
              clientContactArray.push(val);
              const localEvent = {
                target: {
                  value: clientContactArray,
                  name: keyName
                }
              };
              handleUniversalState(localEvent);
            }}
            onCancel={id => {
              const keyName = 'clientContact';
              const clientContactArray = getValueFromUniversalState(keyName) || [];
              const localEvent = {
                target: {
                  value: clientContactArray.filter(contact => contact.id !== id),
                  name: keyName
                }
              };
              handleUniversalState(localEvent);
            }}
          />
        </Grid>
        <Grid item md={4} display={{ xs: 'none' }} />
        <Grid item xs={12} md={12}>
          <Box className='education-input-base'>
            <InputLabel>{t('utils.additionalInformation')}</InputLabel>
            <RichText defaultValue={getValueFromUniversalState('additionalInformation')} onChange={handleRichText} style={{ minHeight: '150px' }} />
          </Box>
        </Grid>
      </Grid>
      <AddCompany
        isPopupOpen={addCompanyPopup}
        handleClose={() => setAddCompanyPopup(false)}
        handleSubmit={saveData}
        setIsLoading={loader}
        setValue={setValue}
        register={register}
        reset={reset}
        setIsPopupOpen={setAddCompanyPopup}
        unregister={unregister}
        watch={watch}
      ></AddCompany>
      <QuickAddContact openQuickAddContact={openQuickAddContact} handleClose={handleClose} first_name={contactName} createContact={createContact} setContactName={setContactName} />
    </>
  );
};

const OpenSearchSelectHandling = props => {
  const { value = null, placeholder = 'Search', onChange = () => {}, onCancel = () => {}, multiple = false } = props;
  return (
    <div>
      <Search onChange={onChange} type={'contacts'} placeholder={placeholder} customClassName={'w-100'} value={multiple ? '' : value} />

      {multiple && value && Array.isArray(value) && (
        <Box className='d-flex flex-wrap py-2 bg-light border'>
          {value.map((contact, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: 'secondary.main',
                color: 'white',
                borderRadius: '20px',
                position: 'relative'
              }}
              className='py-1 px-2 m-1 border'
            >
              <Cancel
                sx={{
                  position: 'absolute',
                  top: '-50%',
                  right: '-2%',
                  color: 'primary.main',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  cursor: 'pointer'
                }}
                fontSize='small'
                onClick={() => onCancel(contact?.id)}
              />
              <Typography variant='subtitle2' style={{ fontSize: 11 }}>
                {`${contact?.first_name} ${contact?.last_name}`} {`(${contact?.current_job_title ? contact?.current_job_title : '-'})`}
              </Typography>
            </Box>
            // <div key={index}>{`${contact?.first_name} ${contact?.last_name}`} {`(${contact?.current_job_title})`}</div>
          ))}
        </Box>
      )}
    </div>
  );
};

BasicInfo.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired,
  serviceOfferingOptions: PropTypes.array,
  companyOptions: PropTypes.array,
  industryOptions: PropTypes.array,
  jobTypeOptions: PropTypes.array,
  compensationFrequencyOptions: PropTypes.array,
  currencyTypeOptions: PropTypes.array,
  projectOwnerOptions: PropTypes.array,
  stageOptions: PropTypes.array,
  fetchContacts: PropTypes.func,
  setCopyPayload: PropTypes.func
};

OpenSearchSelectHandling.propTypes = {
  value: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired
};

export default BasicInfo;
