import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMethodWithCancelTokenApi } from '../../../../../services/ApiService';
import { IGN_API } from '../../../../../services/constantService';

const ActivityFilter = props => {
  const { onSelectProjectFilter = () => {}, selectedProject = null, contact } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState();
  const [filterProject, setFilterProject] = useState(null);
  const { t } = useTranslation();

  // React Query for fetching projects
  const { data, isFetching, error } = useQuery({
    queryKey: ['projects', searchTerm],
    queryFn: () =>
      getMethodWithCancelTokenApi(`${IGN_API.picklists}/candidate-projects`, {
        contactId: contact?.data?.id,
        limit: 10,
        page: 1,
        searchValue: searchTerm
      }),
    enabled: !!contact?.data?.id && (!searchTerm || searchTerm.length >= 2), // Ensure contactId is available and searchTerm is valid
    keepPreviousData: true // Keep existing data while fetching new
  });

  // Update options when data changes
  useEffect(() => {
    if (data?.data?.data) {
      const projects = Array.isArray(data.data.data) ? data.data.data.map(opt => opt?.project) : [];
      const filterSelectedProject = projects.find(project => project?.id === selectedProject);
      setFilterProject(filterSelectedProject);
      setOptions(projects);
    }
  }, [data]);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        if (value.length >= 2) {
          setSearchTerm(value);
        } else {
          setSearchTerm(''); // Reset search term for invalid input
          setOptions([]); // Clear options
        }
      }, 300),
    []
  );

  useEffect(() => {
    return () => debouncedSearch.cancel(); // Cleanup debounce on unmount
  }, [debouncedSearch]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: '8px',
        padding: '4px 8px',
        width: '100%',
        marginY: '12px',
        backgroundColor: 'white'
      }}
    >
      {/* Filter Icon and Label */}
      <FilterAltIcon fontSize='small' sx={{ marginRight: '8px' }} />
      <Typography variant='body2' color='textSecondary' sx={{ marginRight: '8px' }}>
        {t('utils.filterBy')} {t('module.project')}
      </Typography>

      {/* Autocomplete Dropdown */}
      <Autocomplete
        //freeSolo
        disableCloseOnSelect={true}
        options={Array.isArray(options) ? options : []}
        getOptionLabel={option => option?.name}
        onChange={(_, value) => {
          setFilterProject(value);
          onSelectProjectFilter(value);
        }}
        noOptionsText={isFetching ? 'Loading...' : 'No Projects found!'}
        value={filterProject}
        renderInput={params => (
          <TextField
            {...params}
            variant='standard'
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
            placeholder='Search project'
            onChange={e => debouncedSearch(e.target.value)}
          />
        )}
        sx={{
          flexGrow: 1,
          '& .MuiAutocomplete-input': {
            padding: '4px 0'
          }
        }}
        loading={isFetching}
      />
    </Box>
  );
};

ActivityFilter.propTypes = {
  onSelectProjectFilter: PropTypes.func,
  selectedProject: PropTypes.object,
  contact: PropTypes.object
};

export default ActivityFilter;
