import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../src/utils/common.scss';
import { ADDRESS_TYPE, EMAIL_TYPE, PHONE_TYPE } from '../../../services/constantService';
import Address from '../../AddContact/Components/ProfileDetails/AddressDetails';
import CustomTable from '../../common/CustomTable';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import CustomButton from '../CustomButton';
import './index.scss';

const Communications = props => {
  const { setValue = () => {}, data, setSpecificValue, onBlurEmail, deleteSpecificValue, onBlurPhoneNumber } = props;
  const [toggleAddress, setSwitchAddress] = useState(false);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [checkboxData] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleRemovePhoneNumber = id => {
    deleteSpecificValue('contact_phones', id);
  };
  const handleRemoveEmail = id => {
    deleteSpecificValue('contact_emails', id);
  };
  const validateIsSinglePrimary = async type => {
    let isSinglePrimary = false;
    let primaryCount = 0;
    const dataArray = data[type];
    for (let i = 0; i < dataArray.length; i++) {
      const data = dataArray[i];
      if (data.primary == true || data.is_primary == true) {
        primaryCount = primaryCount + 1;
      }
    }

    if (primaryCount === 1) {
      isSinglePrimary = true;
    }
    return {
      isSinglePrimary: isSinglePrimary,
      primaryCount: primaryCount
    };
  };
  const updatePrimary = async (type, id, isPrimary) => {
    try {
      if (type === 'contact_phones') {
        // if (!isPrimary && validateIsSinglePrimary('contact_phones').isSinglePrimary) {
        //   enqueueSnackbar('At least one phone number should be primary', { variant: 'error' });
        //   return false;
        // }
        if (isPrimary) {
          const { primaryCount } = await validateIsSinglePrimary('contact_phones');
          if (primaryCount >= 1) {
            enqueueSnackbar('Only one phone number can be primary', { variant: 'error' });
          }
        }
        setSpecificValue(type, id, {
          primary: isPrimary
        });
      }
      if (type === 'contact_emails') {
        // if (!isPrimary && validateIsSinglePrimary('contact_emails').isSinglePrimary) {
        //   enqueueSnackbar('At least one email should be primary', { variant: 'error' });
        //   return false;
        // }
        if (isPrimary) {
          const { primaryCount } = await validateIsSinglePrimary('contact_emails');
          if (primaryCount >= 1) {
            enqueueSnackbar('Only one email can be primary', { variant: 'error' });
          }
        }

        setSpecificValue(type, id, {
          primary: isPrimary
        });
      }
      if (type === 'contact_address') {
        const updatedAddress = [...data['contact_address']];
        updatedAddress.forEach((address, i) => {
          if (i === id) {
            address.is_primary = isPrimary;
          } else {
            address.is_primary = false;
          }
        });
        setSpecificValue(type, updatedAddress);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleAddPhoneNumber = () => {
    setValue('contact_phones', [...data['contact_phones'], { phone_type: PHONE_TYPE.PERSONAL.field_value, phone_number: '', primary: false, id: data['contact_phones']?.length + 1 }]);
  };
  const handleAddEmail = () => {
    setValue('contact_emails', [...data['contact_emails'], { email_type: '', email: '', primary: false, id: data['contact_emails']?.length + 1 }]);
  };
  const handleAddressToggle = () => {
    setSwitchAddress(!toggleAddress);
  };
  // eslint-disable-next-line no-unused-vars
  const updateAddress = async (index, field, value, opts) => {
    if (field == 'is_primary') {
      const { primaryCount } = await validateIsSinglePrimary('contact_address');
      if (primaryCount >= 1) {
        enqueueSnackbar('Only one can be primary', { variant: 'error' });
      }
    }
    setSpecificValue('contact_address', data['contact_address'][index]?.id, {
      ...data['contact_address'][index],
      [field]: value
    });
  };
  const removeAddress = index => {
    deleteSpecificValue('contact_address', data['contact_address'][index]?.id);
  };

  const addAddressRow = () => {
    setValue('contact_address', [
      ...data['contact_address'],
      { address_type: ADDRESS_TYPE.RESIDENTIAL.field_value, city: '', state: '', country: '', zip_code: '', is_primary: false, id: data['contact_address']?.length + 1 }
    ]);
  };
  const handleCheck = index => {
    setSelectedOption(index);
  };
  useEffect(() => {
    if (data['contact_address'] && data['contact_address'].length > 0) {
      setSwitchAddress(true);
      data['contact_address'].forEach((address, index) => {
        if (address.is_primary) {
          setSelectedOption(index);
        }
      });
    }
  }, []);
  return (
    <Box id='container-communication'>
      <Box className='form-section flex flex-column '>
        <Box className='d-flex justify-content-between align-items-center'>
          <Box className='text-label fs-16 pl-3 pb-1 header-font mt-4'>{t('contacts.addContact.basicInfo')}</Box>
        </Box>
        <Box className='communication-section p-11'>
          <Box className='head-container'>
            <Box className='d-flex sub-head-content'>
              <Box className='field-label-input' sx={{ width: '100%' }}>
                <CustomTable
                  headerData={[t('contacts.addContact.phoneType'), t('contacts.addContact.phone'), t('utils.isPrimary')]}
                  title={t('utils.addPhone')}
                  handleAnotherRow={handleAddPhoneNumber}
                  showAddAnotherButton
                >
                  {data &&
                    data['contact_phones']?.map((phoneNumber, index) => {
                      return (
                        <TableRow key={phoneNumber?.id}>
                          <TableCell className='input-field- p-2'>
                            <Select
                              fullWidth
                              value={phoneNumber?.phone_type || ''}
                              onChange={e => {
                                setSpecificValue('contact_phones', phoneNumber?.id, {
                                  ...phoneNumber,
                                  phone_type: e.target.value
                                });
                              }}
                            >
                              <MenuItem value={PHONE_TYPE.PERSONAL.field_value}>{PHONE_TYPE.PERSONAL.short_desc}</MenuItem>
                              <MenuItem value={PHONE_TYPE.WORK.field_value}>{PHONE_TYPE.WORK.short_desc}</MenuItem>
                              <MenuItem value={PHONE_TYPE.DIRECT_LINE.field_value}>{PHONE_TYPE.DIRECT_LINE.short_desc}</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className='p-2'>
                            <CustomInputField
                              // type="number"
                              value={phoneNumber?.phone_number || ''}
                              onChange={e =>
                                setSpecificValue('contact_phones', phoneNumber?.id, {
                                  ...phoneNumber,
                                  phone_number: e.target.value
                                })
                              }
                              onBlur={e => onBlurPhoneNumber(e)}
                              label={t('contacts.addContact.phone')}
                            />
                          </TableCell>
                          <TableCell className='primary-checkbox p-2'>
                            <Checkbox
                              className='checkboxStyle'
                              checked={phoneNumber?.primary}
                              onChange={e => {
                                updatePrimary('contact_phones', phoneNumber?.id, e.target.checked);
                                // setIsPrimaryPhone(index);
                              }}
                              size='small'
                              name={`is_primary_${index}`}
                            />
                          </TableCell>
                          {data['contact_phones']?.length > 1 && (
                            <TableCell className='remove-another-button p-2 ' mt={2}>
                              <CloseIcon onClick={() => handleRemovePhoneNumber(phoneNumber?.id)} className='table-close-icon' />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </CustomTable>
              </Box>
            </Box>
          </Box>
          <Box className='head-container'>
            <Box className='d-flex sub-head-content'>
              <Box className='field-label-input' sx={{ width: '100%' }}>
                <CustomTable
                  headerData={[t('contacts.addContact.emailType'), t('contacts.addContact.email'), t('utils.isPrimary')]}
                  title={t('utils.addEmail')}
                  handleAnotherRow={handleAddEmail}
                  showAddAnotherButton
                >
                  {data['contact_emails']?.map((email, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className='p-2'>
                          <Select
                            fullWidth
                            value={email?.email_type || ''}
                            onChange={e => {
                              setSpecificValue('contact_emails', email?.id, {
                                ...email,
                                email_type: e.target.value
                              });
                            }}
                          >
                            <MenuItem value={EMAIL_TYPE.PERSONAL.field_value}>{EMAIL_TYPE.PERSONAL.short_desc}</MenuItem>
                            <MenuItem value={EMAIL_TYPE.WORK.field_value}>{EMAIL_TYPE.WORK.short_desc}</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell className='p-2'>
                          <CustomInputField
                            value={email?.email || ''}
                            onChange={e => {
                              setSpecificValue('contact_emails', email?.id, {
                                ...email,
                                email: e.target.value
                              });
                            }}
                            onBlur={e => onBlurEmail(e)}
                            label={t('contacts.addContact.email')}
                          />
                        </TableCell>
                        <TableCell className='primary-checkbox p-2'>
                          <Checkbox
                            className='checkboxStyle'
                            checked={email?.primary}
                            onChange={e => updatePrimary('contact_emails', email?.id, e.target.checked)}
                            size='small'
                            name={`is_primary_${index}`}
                          />
                        </TableCell>
                        {data['contact_emails']?.length > 1 && (
                          <TableCell className='remove-another-button p-2' mt={2}>
                            <CloseIcon onClick={() => handleRemoveEmail(email?.id)} className='table-close-icon' />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </CustomTable>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='my-cards d-flex flex-wrap w-100'>
          <Box className='form-section d-flex flex-column'>
            <Box className='section-content-data p-8 d-flex flex-column flex-wrap content-gap'>
              <Box className='d-flex flex-row align-items-center'>
                <Box className='header-font text-label fs-16'>{t('contacts.addContact.address')}</Box>
                <Box className='pt-2 pl-2'>
                  <FormControlLabel control={<Switch className='SwitchStyle' checked={toggleAddress} {...label} onChange={handleAddressToggle}></Switch>} />
                </Box>
              </Box>

              {toggleAddress &&
                data['contact_address']?.map((element, index) => {
                  return (
                    <Address
                      key={index}
                      addressDetails={data['contact_address']}
                      index={index}
                      data={data['contact_address']}
                      element={element}
                      checkboxData={checkboxData}
                      selectedOption={selectedOption}
                      removeAddress={removeAddress}
                      handleCheck={index => {
                        handleCheck(index);
                        updatePrimary('contact_address', index, !element.is_primary);
                      }}
                      setValue={setValue}
                      handleChange={updateAddress}
                      isAddContact={false}
                    />
                  );
                })}
              {data['contact_address']?.length >= 0 && toggleAddress === true ? (
                <Box>
                  <CustomButton type={'tertiary p-0'} onClick={addAddressRow} size={'medium'} buttonText={t('actions.addAnotherActivity')} iconLeft={<AddIcon sx={{ width: 14 }} />} variant='text' />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Communications.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  setIsLoading: PropTypes.func,
  unregister: PropTypes.func,
  data: PropTypes.object,
  duplicateRecords: PropTypes.array,
  duplicationField: PropTypes.string,
  setSpecificValue: PropTypes.func,
  onBlurEmail: PropTypes.func,
  onBlurLinkedInUrl: PropTypes.func,
  deleteSpecificValue: PropTypes.func,
  onBlurPhoneNumber: PropTypes.func
};

export default Communications;
