import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RichText from '../../../components/common/RichText';
import { projectSelector } from '../../../selectors';
import { commonActions } from '../../../store/commonSlice';

const ViewPositionProfile = props => {
  const { positionProfile, setValue, watch } = props;
  const projectState = useSelector(projectSelector);
  const dispatch = useDispatch();

  return (
    <Box fullWidth className='position-profile-rich-text'>
      <Box className='custom-card'>
        <Grid className='justify-content-between w-100'>
          <Box className={'fs-12 header-containt mt-3'}>
            <RichText
              defaultValue={watch('description') || positionProfile?.data?.description || ''}
              onChange={data => {
                setValue('description', data);
                dispatch(
                  commonActions?.updateProjectData?.({
                    data: { IsDataSavedRoadmapPositionProfile: 'unsaved' }
                  })
                );
              }}
              customClassName={'position-profile-headers'}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

ViewPositionProfile.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired,
  reviewAndPublish: PropTypes.bool,
  hideOptions: PropTypes.array,
  onClickReview: PropTypes.func,
  handleGenerate: PropTypes.func,
  handleHideOptions: PropTypes.func,
  isHideOption: PropTypes.bool,
  data: PropTypes.object,
  positionProfile: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func
};

export default ViewPositionProfile;
