import { Box } from '@mui/material';
import React from 'react';
import { ColorDropdown } from '../../../Containers/Searches/ViewProject/CandidatePipeline/CustomDropdownFilter';
import { ButtonDropDown } from '../../common/ButtonDropdown';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

const CandidateActions = ({ isHeaderClose, selectedCandidate, handleCandidateColorChange, changeStage, stagesWithoutLogical }) => {
  return (
    <Box display={'flex'} alignItems={'center'} gap={1}>
      <ColorDropdown onChange={colorId => handleCandidateColorChange(selectedCandidate?.id)(colorId)} selectedColor={selectedCandidate?.color_id} />
      <ButtonDropDown
        ellipsis
        show={true}
        iconRight={<ArrowDropDownIcon />}
        options={stagesWithoutLogical}
        onClick={val => {
          changeStage(val);
        }}
        buttonStyle={{
          textTransform: 'none',
          fontWeight: 500,
          border: '0.1px solid 009d81'
        }}
        buttonText={selectedCandidate?.stage}
        ellipsisWidth={isHeaderClose ? '80px' : '110px'}
      />
    </Box>
  );
};

export default CandidateActions;
CandidateActions.propTypes = {
  selectedCandidate: PropTypes.shape({
    id: PropTypes.string,
    color_id: PropTypes.string,
    stage: PropTypes.string
  }),
  handleCandidateColorChange: PropTypes.func.isRequired,
  changeStage: PropTypes.func.isRequired,
  stagesWithoutLogical: PropTypes.arrayOf(PropTypes.string).isRequired,
  isHeaderClose: PropTypes.bool
};
