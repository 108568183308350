import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { currencyWithCommas, getDateFilterOptions } from '../../Containers/Commons/Utils';
import { getFixedDecimal } from '../../utils/common';
import { formatDate, getDifferenceInDays } from '../../utils/date';

export const getDefaultRetainerFields = retainersArray => {
  return [
    { field: 'Retainer 1', ...findAndDeleteRetainer(retainersArray, 'Retainer 1') },
    { field: 'Retainer 2', ...findAndDeleteRetainer(retainersArray, 'Retainer 2') },
    { field: 'Retainer 3', ...findAndDeleteRetainer(retainersArray, 'Retainer 3') },
    { field: 'Final Retainer', ...findAndDeleteRetainer(retainersArray, 'Final Retainer') },
    { field: 'Contingent Fee', ...findAndDeleteRetainer(retainersArray, 'Contingent Fee') },
    { field: 'Monthly Fee', ...findAndDeleteRetainer(retainersArray, 'Monthly Fee') }
  ];
};

export const findAndDeleteRetainer = (retainersArray, key) => {
  const index = retainersArray.findIndex(item => item.field?.toLowerCase() === key.toLocaleLowerCase());
  if (index !== -1) {
    const retainerInfo = retainersArray[index];
    retainersArray.splice(index, 1);
    return retainerInfo;
  }
  return {};
};

export const getSearchField = field => {
  if (field === 'company') {
    return 'company_id';
  } else if (field === 'next_status_call_date' || field === 'next_status_call_time') {
    return 'next_status_call';
  } else if (field === 'billing_contact') {
    return 'billing_contact_id';
  }
  return field;
};

export const getSingleFieldDataOfSearch = (field, formData, searchData) => {
  const data = {};
  if (field === 'type') {
    data[field] = formData.type;
  }
  if (field === 'company_id') {
    data.company_name = formData.company?.name;
    data.company_id = formData.company?.id;
    data.old = { company_id: searchData.company?.id, company_name: searchData.company?.name };
  } else if (field === 'industries') {
    data.industries = formData.industries.map(item => (typeof item === 'string' ? item : item.name));
    // data.old = {industries: searchData.industries!=null?.searchData.industries.map(item => typeof item === 'string' ? item : item.name)}
    data.old = { industries: searchData.industries.map(item => (typeof item === 'string' ? item : item.name)) };
  } else if (field === 'next_status_call') {
    data.next_status_call = getNextStatusCall({ ...searchData, ...formData });
    data.old = { next_status_call: getNextStatusCall(searchData) };
  } else if (field === 'vc_pe') {
    data.vc_pe = formData.vc_pe?.filter(item => item && item.name).map(item => item.name);
    data.old = { vc_pe: searchData.vc_pe?.filter(item => item && item.name).map(item => item.name) };
  } else if (field === 'billing_contact_id') {
    data.billing_contact_id = formData.billing_contact?.id || null;
    data.old = { billing_contact_id: searchData.billing_contact?.id };
  } else if (field === 'estimated_total_comp' || field === 'fee_type' || field === 'fee_percentage' || field === 'fee_amount' || field === 'actual_total_comp') {
    data.estimated_total_comp = parseFloat(formData.estimated_total_comp);
    data.actual_total_comp = parseFloat(formData.actual_total_comp);
    data.fee_amount = parseFloat(formData.fee_amount);
    data.fee_percentage = parseFloat(formData.fee_percentage);
    data.fee_type = formData.fee_type;
    data.old = {
      estimated_total_comp: searchData.estimated_total_comp,
      actual_total_comp: searchData.actual_total_comp,
      fee_amount: searchData.fee_amount,
      fee_percentage: searchData.fee_percentage,
      fee_type: searchData.fee_type
    };
  } else if (field === 'partners' || field === 'recruiters' || field === 'eas' || field === 'researchers') {
    data[field] = getTeamInfo(formData[field]);
    data.old = { [field]: getTeamInfo(searchData[field]) };
  } else if (field === 'state') {
    data[field] = formData[field];
    data.old = { [field]: searchData[field] };
    data.country = formData.country;
  } else if (field === 'by_product_fee' && formData[field]?.length === 0) {
    data[field] = null;
    data.old = { [field]: searchData[field] };
  } else {
    data[field] = formData[field];
    data.old = { [field]: searchData[field] };
  }
  return data;
};

const getTeamInfo = team => {
  return team
    ?.filter(item => item.user && item.user.id)
    .map((item, position) => {
      let formatted = {};
      if (item.origination_credit) {
        formatted.origination_credit = parseFloat(item.origination_credit);
      }
      if (item.selling_credit) {
        formatted.selling_credit = parseFloat(item.selling_credit);
      }
      if (item.execution_credit) {
        formatted.execution_credit = parseFloat(item.execution_credit);
      }
      return {
        ...formatted,
        user_id: item.user && item.user.id,
        position
      };
    });
};

const getNextStatusCall = data => {
  if (data.next_status_call_date) {
    const nextStatusCallDate = new Date(data.next_status_call_date);
    if (data.next_status_call_time) {
      const nextStatusCallTime = new Date(data.next_status_call_time);
      nextStatusCallDate.setHours(nextStatusCallTime.getHours(), nextStatusCallTime.getMinutes());
    } else {
      nextStatusCallDate.setHours(0, 0);
    }
    return nextStatusCallDate;
  }
};
const getCredits = list => {
  let total_origination_credit = 0;
  let total_selling_credit = 0;
  let total_execution_credit = 0;
  for (let i = 0; i < list?.length; i++) {
    total_origination_credit += parseFloat(list[i].origination_credit || 0);
    total_selling_credit += parseFloat(list[i].selling_credit || 0);
    total_execution_credit += parseFloat(list[i].execution_credit || 0);
  }
  return { total_origination_credit, total_selling_credit, total_execution_credit };
};

export const checkForError = (list, fields) => {
  const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(list);
  let isError = false;
  if (fields.origination_credit) {
    if (total_origination_credit > fields.origination_credit) {
      isError = true;
    }
  }
  if (fields.selling_credit) {
    if (total_selling_credit > fields.selling_credit) {
      isError = true;
    }
  }
  if (fields.execution_credit) {
    if (total_execution_credit > fields.execution_credit) {
      isError = true;
    }
  }
  return isError;
};

const getOutStandingDays = params => {
  const dueByDate = params.data?.due_by_date;
  const invoiceAmt = parseInt(params.data?.invoice_amt) || 0;
  const collectedAmt = parseInt(params.data?.collected_amt) || 0;
  let daysOutstanding;
  if (dueByDate && invoiceAmt - collectedAmt > 0) {
    daysOutstanding = getDifferenceInDays(dueByDate, new Date()); // days should be in +ve
  }
  return daysOutstanding > 0 ? daysOutstanding : 0;
  // const collectedDate = params.data?.collected_date;
  // const billedDate = params.data?.billed_date;
  // let daysOutstanding;
  // if (!collectedDate && !billedDate) {
  //   daysOutstanding = '';
  // } else if (collectedDate) {
  //   daysOutstanding = 0;
  // } else if (billedDate) {
  //   daysOutstanding = getDifferenceInDays(new Date(), billedDate);
  // }
  // return daysOutstanding;
};

const textComparator = (valueA, valueB) => {
  valueA = valueA.toLowerCase();
  valueB = valueB.toLowerCase();
  return valueA == valueB ? 0 : valueA > valueB ? 1 : -1;
};

export const getDateFieldValue = (params, field, values = '') => {
  if (field === 'due_by_date' && params.data && !params.data['due_by_date'] && values) {
    let date = params?.data['billed_date'];
    if (!date) return null;
    let AddDate = moment(date, 'MM-DD-YYYY').add(values, 'days');
    return AddDate.toDate(); // Convert to Date object for editor
  } else if (params?.data) {
    let dateValue = params?.data[field];
    if (!dateValue) return null;
    return params.data[field] ? new Date(params.data[field]) : '';
  }
};
const getValueWithCurrency = (params, currency) => {
  const colId = params?.colDef?.colId;
  return params.data ? `${currency || ''} ${currencyWithCommas(getFixedDecimal(params?.data[colId] || 0).toFixed(2))}` : null;
};

const commonDateOptions = {
  editable: true,
  cellRenderer: 'DateRenderer',
  filter: 'agDateColumnFilter',
  filterParams: {
    filterOptions: getDateFilterOptions(),
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true
  },
  cellEditor: 'GenericCellEditor',
  cellEditorParams: {
    InputComponent: DatePicker,
    label: '',
    placeholder: '',
    autoOk: true,
    variant: 'inline',
    type: 'Date',
    format: 'MM/DD/YYYY',
    helperText: ''
  }
};

const commonTextOptions = {
  filter: 'agTextColumnFilter',
  comparator: textComparator,
  filterParams: {
    filterOptions: ['contains'],
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true
  }
};

const commonNumberOptions = {
  // cellRenderer: getValueWithCurrency,
  filter: 'agNumberColumnFilter',
  filterParams: {
    filterOptions: ['lessThan', 'greaterThan'],
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true
  },
  cellEditor: 'NumberEditor'
};

export const invoiceColumnDefs = (func, updateInfoState, currency) => [
  {
    field: 'invoice_label',
    colId: 'invoice_label',
    headerName: 'Invoice Label',
    editable: true,
    cellEditor: 'agTextCellEditor', // Use built-in text editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex);
      return true;
    },
    filter: 'agMultiColumnFilter',
    pinned: 'left',
    lockPinned: true
  },
  {
    field: 'invoice_id',
    colId: 'invoice_id',
    headerName: 'Invoice ID',
    editable: true,
    cellEditor: 'agTextCellEditor', // Use built-in text editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex);
      return true;
    },
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'expenses',
    colId: 'expenses',
    headerName: 'Expense',
    editable: true,
    width: 130,
    sortable: false,
    cellEditorParams: {
      field: 'expenses',
      updateInfoState
    },
    cellEditor: 'CheckboxSelection',
    valueGetter: params => {
      return params?.data?.expenses ? true : false;
    },
    cellRenderer: params => {
      return params?.data?.expenses ? 'Yes' : '';
    },
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex);
      return true;
    },
    filterValueGetter: params => {
      const data = params?.data?.expenses ? 'Yes' : '';
      return data;
    },
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'retainer',
    colId: 'retainer',
    headerName: 'Retainers',
    cellEditor: 'agNumberCellEditor', // Use built-in number editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex, 'Number');
      return true;
    },
    cellRenderer: params => getValueWithCurrency(params, currency),
    editable: params => {
      return params?.data?.expenses ? false : true;
    },
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'indirect_fee',
    colId: 'indirect_fee',
    headerName: 'Indirect Fee',
    editable: params => {
      return params?.data?.expenses ? false : true;
    },
    cellEditor: 'agNumberCellEditor', // Use built-in number editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex, 'Number');
      return true;
    },
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'invoice_amt',
    colId: 'invoice_amt',
    headerName: 'Invoice Amount',
    editable: params => {
      return params.data?.expenses ? true : false;
    },
    cellEditor: 'agNumberCellEditor', // Use built-in number editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex, 'Number');
      return true;
    },
    filter: 'agMultiColumnFilter',
    cellRenderer: params => getValueWithCurrency(params, currency)
  },
  {
    field: 'projected_bill_date',
    colId: 'projected_bill_date',
    headerName: 'Projected Bill Date',
    editable: true,
    cellRenderer: 'ProjectedDateRenderer',
    valueGetter: params => getDateFieldValue(params, params.colDef.colId), // Use colId dynamically
    cellEditor: 'agDateCellEditor',
    valueSetter: params => {
      func(moment(params.newValue), params.colDef.colId, params.node.rowIndex);
      return true;
    },
    filterValueGetter: params => {
      const date = params?.data?.projected_bill_date ? formatDate(params?.data?.projected_bill_date) : '';
      return date;
    },
    valueFormatter: params => (params.value ? moment(params.value).format('MM-DD-YYYY') : ''),
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'billed_date',
    colId: 'billed_date',
    headerName: 'Date Billed',
    editable: true,
    valueGetter: params => getDateFieldValue(params, 'billed_date'),
    cellEditor: 'agDateCellEditor', // Use built-in date editor
    valueSetter: params => {
      func(params.newValue, params.colDef.colId, params.node.rowIndex);
      return true;
    },
    filterValueGetter: params => {
      const date = params?.data?.billed_date ? formatDate(params?.data?.billed_date) : '';
      return date;
    },
    filter: 'agMultiColumnFilter',
    cellRenderer: 'invoiceDateRenderer'
  },
  {
    field: 'due_by_date',
    colId: 'due_by_date',
    headerName: 'Due By',
    editable: true,
    cellEditor: 'agDateCellEditor', // Use built-in date editor
    valueGetter: params => getDateFieldValue(params, 'due_by_date'),
    valueSetter: params => {
      func(params.newValue, params.colDef.colId, params.node.rowIndex);
      return true;
    },
    filterValueGetter: params => {
      const date = params?.data?.due_by_date ? formatDate(params?.data?.due_by_date) : '';
      return date;
    },
    filter: 'agMultiColumnFilter',
    cellRenderer: 'invoiceDateRenderer'
  },
  {
    field: 'collected_date',
    colId: 'collected_date',
    headerName: 'Date Collected',
    valueFormatter: params => {
      if (!params.value) {
        return '';
      }
      return getDateFieldValue(params, 'collected_date');
    },
    valueGetter: params => getDateFieldValue(params, 'collected_date'),
    editable: true,
    cellEditor: 'agDateCellEditor', // Use built-in date editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex);
      return true;
    },
    filterValueGetter: params => {
      const date = params?.data?.collected_date ? formatDate(params?.data?.collected_date) : '';
      return date;
    },
    filter: 'agMultiColumnFilter',
    cellRenderer: 'invoiceDateRenderer'
  },
  {
    field: 'collected_amt',
    colId: 'collected_amt',
    headerName: 'Collected Amount',
    editable: true,
    cellEditor: 'agNumberCellEditor', // Use built-in number editor
    valueSetter: params => {
      func(params.newValue, params.column.colId, params.node.rowIndex, 'Number');
      return true;
    },
    cellRenderer: params => getValueWithCurrency(params, currency),
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'days',
    colId: 'days',
    headerName: 'Days Outstanding',
    cellRenderer: params => params.value,
    valueGetter: getOutStandingDays,
    width: 200,
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'outstanding_amt',
    colId: 'outstanding_amt',
    headerName: 'Outstanding Amount',
    width: 200,
    cellRenderer: params => getValueWithCurrency(params, currency),
    filter: 'agMultiColumnFilter'
  },
  {
    field: 'Action',
    colId: 'Action',
    headerName: 'Action',
    sortable: false,
    filter: false,
    cellRenderer: 'ActionsRenderer',
    pinned: 'right',
    lockPinned: true,
    // width: 100,
    minWidth: 90,
    // maxWidth: 90
    suppressHeaderMenuButton: true
  }
];

export const validatePercentageFields = formData => {
  if (formData?.project_indirect_fees.find(ele => ele?.percentage > 100)) return false;
  if (formData?.fee_percentage > 100) return false;
  return true;
};
export const validateByProductFeeField = formData => {
  if (formData?.by_product_fee > 100) return false;
  return true;
};
export const validateCalculationAmount = formData => {
  if (formData?.project_indirect_fees.find(ele => ele?.calculation_amt > 1000000000000000)) return false;
  if (formData?.actual_percentage_bases?.find(ele => ele?.calculated_actual_amt > 1000000000000000)) return false;
  if (formData?.estimated_percentage_bases.find(ele => ele?.calculated_estimated_amt > 1000000000000000)) return false;
  return true;
};
