/* eslint-disable react-compiler/react-compiler */
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import DecisionMatrixGraph from './DecisionMatrixGraph';
import './index.scss';

import { ProductSettingsApi, sfpaDecisionApi } from '../../services/ApiService';

import { GET, POST } from '../../services/constantService';
import calculateBoxCoordinates from '../../utils/getBoxCoordinatesForDecisionMatrix';

// eslint-disable-next-line react/display-name
const DecisionMatrixChart = forwardRef((props, ref) => {
  const { item, dataPoints, isAuraVisible } = props;
  const [chartData, setChartData] = useState(null);
  const chartRef = useRef();
  chartRef.current = chartData;
  useEffect(() => {
    const defaultChartDetails = async () => {
      let sub_route = 'find?field_name=SFPA_DECISION_CHART&lang_cd=ENG';
      const { status, data } = await ProductSettingsApi(GET, null, sub_route);
      if (status === 200) {
        if (data) {
          setChartData(data);
        }
      }
    };
    defaultChartDetails();
  }, []);

  const [updatedCoordinates, setUpdatedCoordinates] = useState({
    x_center: 0,
    y_center: 0
  });

  useEffect(() => {
    if (chartRef?.current) {
      const chart_scale = Number(chartRef.current.chart_scale);
      const indexValue = dataPoints ?? {
        item_1: 0,
        item_2: 0,
        item_3: 0,
        item_4: 0,
        item_5: 0,
        item_6: 0,
        item_7: 0
      };

      const data = calculateBoxCoordinates(indexValue, chart_scale);

      if (props?.projectId) {
        updateCoordinate(data?.x, data?.y, props.projectId);
      }

      setUpdatedCoordinates({ x_center: data?.x, y_center: data?.y });
    }
  }, [chartData, dataPoints]);

  const updateCoordinate = async (x, y, projectId) => {
    try {
      await sfpaDecisionApi(
        POST,
        '',
        {
          project_id: projectId,
          x_center: x,
          y_center: y
        },
        'create-update-sfpa-coordinate-v1'
      );
    } catch (err) {
      console.error('Update coordinates failed: ', err);
    }
  };

  return (
    <>
      {chartRef.current && updatedCoordinates && (
        <DecisionMatrixGraph
          isAuraVisible={isAuraVisible}
          centerStyles={item?.centerStyles ? item?.centerStyles : chartRef.current.centerStyles}
          scatter_data={item?.scatter_data}
          quadrantsData={item?.quadrantsData ? item?.quadrantsData : chartRef.current.quadrantsData}
          quadrantLabelData={item?.quadrantLabelData ? item?.quadrantLabelData : chartRef.current.quadrantLabelData}
          mainHeadLabelData={item?.mainHeadLabelData ? item?.mainHeadLabelData : chartRef.current.mainHeadLabelData}
          headLabelData={item?.headLabelData ? item?.headLabelData : chartRef.current.headLabelData}
          input_values={item?.input_values}
          chart_scale={Number(chartRef.current.chart_scale)}
          is_hidden_Legends={item?.sfpa ? item?.sfpa : false}
          fontSize={item?.fontSize ? item?.fontSize : chartRef.current.fontSize}
          box_size={item?.box_size ? item?.box_size : chartRef.current.box_size}
          boxData={item?.boxData ? { ...chartRef.current.boxData, ...item?.boxData } : chartRef.current.boxData}
          pointData={item?.pointData ? item?.pointData : chartRef.current.pointData}
          chartAreaBorderData={item?.chartAreaBorderData ? item?.chartAreaBorderData : chartRef.current.chartAreaBorderData}
          candidateGraphRef={ref}
          client_area_shape_type={chartRef.current.client_area_shape_type}
          clientAreaCircleData={chartRef.current.clientAreaCircleData}
          initialCoords={updatedCoordinates}
        />
      )}
    </>
  );
});

DecisionMatrixChart.propTypes = {
  item: PropTypes.object,
  dataPoints: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  isAuraVisible: PropTypes.bool
};

export default DecisionMatrixChart;
