import { capitalize } from 'lodash';
import React from 'react';
import RouteOpener from '../../../../../components/common/RouteToNewPage';
import { renderRichText } from '../../../../../utils/common';
import { formatDate } from '../../../../../utils/date';

// Common default options for all columns
export const defaultColumnOptions = {
  minWidth: 200,
  flex: 1,
  filter: 'agMultiColumnFilter',
  sortable: true,
  resizable: true,
  menuTabs: ['filterMenuTab'],
  headerComponentParams: {
    menuIcon: 'fa-bars'
  },
  autoSizeStrategy: 'fitGridWidth',
  cellRendererFramework: ({ value }) => value || '--',
  enableRowGroup: true,
  enableValue: true
};

export const queryContactColumn = [
  {
    ...defaultColumnOptions,
    field: 'contact_name',
    headerName: 'Contact Name',
    filter: 'agTextColumnFilter',
    cellRenderer: params => (params?.data?.contact_name ? <RouteOpener type='contact' id={params.data?.id} label={params.data?.contact_name} capitalize={true} /> : null),
    valueGetter: params => params?.data?.contact_name || null
  },
  {
    ...defaultColumnOptions,
    field: 'tags',
    headerName: 'Tags'
  },
  {
    ...defaultColumnOptions,
    field: 'company_name',
    headerName: 'Company Name',
    filter: 'agTextColumnFilter',
    cellRenderer: params => (params?.data?.company_name ? <RouteOpener type='company' id={params.data?.company_id} label={params.data?.company_name} capitalize={true} /> : null),
    valueGetter: params => params?.data?.company_name
  },
  {
    ...defaultColumnOptions,
    field: 'country',
    headerName: 'Country',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'state',
    headerName: 'State',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'city',
    headerName: 'City',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'job_title',
    headerName: 'Job Title',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'job_function',
    headerName: 'Job Function',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'industry',
    headerName: 'Industry',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'status',
    headerName: 'Status',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'type',
    headerName: 'Type',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'lastActivity',
    headerName: 'Last Activity',
    filter: 'agTextColumnFilter'
  }
];

export const queryProjectColumn = [
  {
    ...defaultColumnOptions,
    field: 'project_name',
    headerName: 'Project Name',
    cellRenderer: params => (params?.data?.helia_title ? <RouteOpener type='project' id={params.data?.id} label={params.data?.helia_title} capitalize={true} /> : null),
    valueGetter: params => params?.data?.helia_title || null
  },
  {
    ...defaultColumnOptions,
    field: 'tags',
    headerName: 'Tags'
  },
  {
    ...defaultColumnOptions,
    field: 'project_type',
    headerName: 'Type'
  },
  {
    ...defaultColumnOptions,
    field: 'engagement_type',
    headerName: 'Service Offering'
  },
  {
    ...defaultColumnOptions,
    field: 'geography',
    headerName: 'Geography'
  },
  {
    ...defaultColumnOptions,
    field: 'industry',
    headerName: 'Industry'
  },
  {
    ...defaultColumnOptions,
    field: 'location',
    headerName: 'Location'
  },
  {
    ...defaultColumnOptions,
    field: 'date_opened',
    headerName: 'Date Opened'
  },
  {
    ...defaultColumnOptions,
    field: 'Stage',
    headerName: 'Stage'
  },
  {
    ...defaultColumnOptions,
    field: 'projected_start_date',
    headerName: 'Projected Start Date'
  },
  {
    ...defaultColumnOptions,
    field: 'job_number',
    headerName: 'Job Number'
  },
  {
    ...defaultColumnOptions,
    field: 'company_name',
    headerName: 'Company Name'
  },
  {
    ...defaultColumnOptions,
    field: 'location_description',
    headerName: 'Company Location'
  },
  {
    ...defaultColumnOptions,
    field: 'fee_percentage',
    headerName: 'Fee Percentage'
  },
  {
    ...defaultColumnOptions,
    field: 'billing_region',
    headerName: 'Billing Region'
  },
  {
    ...defaultColumnOptions,
    field: 'estimated_fee_amount',
    headerName: 'Estimated Fee Amount'
  },
  {
    ...defaultColumnOptions,
    field: 'actual_revenue',
    headerName: 'Revenue'
  },
  {
    ...defaultColumnOptions,
    field: 'billing_status',
    headerName: 'Billing Status'
  },
  {
    ...defaultColumnOptions,
    field: 'approval_requested_date',
    headerName: 'Approval Date'
  },
  {
    ...defaultColumnOptions,
    field: 'billing_notes',
    headerName: 'Billing Notes',
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => data?.billing_notes || '--'
  },
  {
    ...defaultColumnOptions,
    field: 'job_type',
    headerName: 'Job Type',
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => data?.job_type || '--'
  },
  {
    ...defaultColumnOptions,
    field: 'location_city',
    headerName: 'Location City',
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => data?.location_city || '--'
  },
  {
    ...defaultColumnOptions,
    minWidth: 140,
    maxWidth: 300,
    flex: 1,
    colId: 'created_at',
    field: 'created_at',
    headerName: 'Date Added',
    cellRendererFramework: ({ value }) => value,
    filter: 'agMultiColumnFilter',
    editable: false,
    valueGetter: ({ data }) => formatDate(data?.created_at)
  },
  {
    ...defaultColumnOptions,
    field: 'updated_at',
    headerName: 'Last Activity Date',
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => formatDate(data?.updated_at)
  }
];

export const queryCompanyColumn = [
  {
    ...defaultColumnOptions,
    field: 'name',
    headerName: 'Name',
    cellRenderer: params => (params?.data?.name ? <RouteOpener type='company' id={params.data?.id} label={params.data?.name} capitalize={true} /> : null),
    valueGetter: params => params?.data?.name || null
  },
  {
    ...defaultColumnOptions,
    field: 'tags',
    headerName: 'Tags'
  },
  {
    ...defaultColumnOptions,
    field: 'industry',
    headerName: 'Industry'
  },
  {
    ...defaultColumnOptions,
    field: 'location',
    headerName: 'Location'
  },
  {
    ...defaultColumnOptions,
    field: 'employee_range',
    headerName: 'Employee Range'
  },
  {
    ...defaultColumnOptions,
    field: 'revenue_range',
    headerName: 'Revenue Range'
  },
  {
    ...defaultColumnOptions,
    field: 'company_status',
    headerName: 'Stage'
  },
  {
    ...defaultColumnOptions,
    field: 'capital_structure',
    headerName: 'Capital Structure',
    cellRendererFramework: ({ value }) => capitalize(value || '--')
  },
  {
    ...defaultColumnOptions,
    field: 'ticker_symbol',
    headerName: 'Ticker Symbol',
    filter: 'agTextColumnFilter',
    cellRendererFramework: ({ value }) => capitalize(value || '--')
  },
  {
    ...defaultColumnOptions,
    field: 'website_url',
    headerName: 'Website',
    filter: 'agTextColumnFilter',
    cellRendererFramework: ({ value }) =>
      value ? (
        <a href={value} target='_blank' rel='noopener noreferrer'>
          {value}
        </a>
      ) : (
        '--'
      )
  },
  {
    ...defaultColumnOptions,
    field: 'linkedin_url',
    headerName: 'LinkedIn',
    filter: 'agTextColumnFilter',
    cellRendererFramework: ({ value }) =>
      value ? (
        <a href={value} target='_blank' rel='noopener noreferrer'>
          {value}
        </a>
      ) : (
        '--'
      )
  },
  {
    ...defaultColumnOptions,
    field: 'currency_unit',
    headerName: 'Currency Unit',
    filter: 'agTextColumnFilter',
    cellRendererFramework: ({ value }) => capitalize(value || '--')
  },
  {
    ...defaultColumnOptions,
    field: 'currency_code',
    headerName: 'Currency Code',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'comments',
    headerName: 'Comments',
    filter: 'agTextColumnFilter',
    cellRenderer: ({ value }) => renderRichText(value || '--')
  },
  {
    ...defaultColumnOptions,
    field: 'company_status',
    headerName: 'Status',
    cellRendererFramework: ({ value }) => capitalize(value || '--'),
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'location',
    colId: 'location_description',
    headerName: 'Location',
    sortingOrder: ['asc', 'desc', null],
    cellRenderer: 'LocationRenderer',
    cellClass: 'industry-content',
    filterParams: { maxNumConditions: 1 },
    maxWidth: 250,
    minWidth: 250,
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.location_description || '--'
  },
  {
    ...defaultColumnOptions,
    field: 'created_at',
    colId: 'created_at',
    headerName: 'Date Added',
    editable: false,
    valueGetter: ({ data }) => formatDate(data?.created_at)
  },
  {
    ...defaultColumnOptions,
    field: 'updated_at',
    headerName: 'Last Activity Date',
    filter: 'agTextColumnFilter',
    valueGetter: ({ data }) => formatDate(data?.updated_at)
  }
];

export const queryCandidateColumn = [
  {
    ...defaultColumnOptions,
    field: 'name',
    headerName: 'Candidate Name',
    filter: 'agTextColumnFilter',
    cellRenderer: params => (params?.data?.name ? <RouteOpener type='contact' id={params.data?.contact?.id} label={params.data?.name} capitalize={true} /> : null),
    valueGetter: params => params?.data?.name || null
  },
  {
    ...defaultColumnOptions,
    field: 'contactTags',
    headerName: 'Contact Tags'
  },
  {
    ...defaultColumnOptions,
    field: 'projectTags',
    headerName: 'Project Tags'
  },
  {
    ...defaultColumnOptions,
    field: 'project_name',
    headerName: 'Project Name',
    filter: 'agTextColumnFilter',
    cellRenderer: params => (params?.data?.project_name ? <RouteOpener type='project' id={params?.data?.project?.id} label={params?.data?.project_name} capitalize={true} /> : null),
    valueGetter: params => params?.data?.project_name || null
  },
  {
    ...defaultColumnOptions,
    field: 'project_industry',
    headerName: 'Project Industry',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'stage',
    headerName: 'Stage',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'current_company_name',
    headerName: 'Current Company Name',
    filter: 'agTextColumnFilter',
    cellRenderer: params =>
      params?.data?.current_company_name ? <RouteOpener type='company' id={params.data?.contact?.contact_company?.id} label={params.data?.current_company_name} capitalize={true} /> : null,
    valueGetter: params => params?.data?.current_company_name || null
  },
  {
    ...defaultColumnOptions,
    field: 'previous_company_name',
    headerName: 'Previous Company Name',
    filter: 'agTextColumnFilter',
    cellRenderer: params => (params?.data?.previous_company_name ? <RouteOpener type='company' id={params.data?.pastCompanyId} label={params.data?.previous_company_name} capitalize={true} /> : null),
    valueGetter: params => params?.data?.previous_company_name || null
  },
  {
    ...defaultColumnOptions,
    field: 'current_job_title',
    headerName: 'Current Job Title',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'previous_job_title',
    headerName: 'Previous Job Title',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'industry',
    headerName: 'Industry',
    filter: 'agTextColumnFilter'
  },
  {
    ...defaultColumnOptions,
    field: 'source',
    headerName: 'Source',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.source || 'Unknown'
  },
  {
    ...defaultColumnOptions,
    field: 'age',
    headerName: 'Age',
    minWidth: 120,
    filter: 'agNumberColumnFilter',
    valueGetter: params => params?.data?.age || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'contact_status',
    headerName: 'Contact Status',
    minWidth: 150,
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.contact_status || 'Unknown'
  },
  {
    ...defaultColumnOptions,
    field: 'company_name_p1',
    headerName: 'Company Name',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.company_name_p1 || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'birth_date',
    headerName: 'Birth Date',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.birth_date || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'company_revenue_range',
    headerName: 'Company Revenue Range',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.company_revenue_range || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'company_employee_range',
    headerName: 'Company Employee Range',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.company_employee_range || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'gender',
    headerName: 'Gender',
    minWidth: 120,
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.gender || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'nationality',
    headerName: 'Nationality',
    minWidth: 150,
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.nationality || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'lastActivity',
    headerName: 'Last Activity Date',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.lastActivity || 'N/A'
  },
  {
    ...defaultColumnOptions,
    field: 'lastActivityBy',
    headerName: 'Last Activity By',
    filter: 'agTextColumnFilter',
    valueGetter: params => params?.data?.lastActivityBy || 'N/A'
  }
];
