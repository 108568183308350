import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemComponent, TableColumn } from '.';
import CountryTypeSelection from '../CountryTypeSelection';
import StateTypeSelection from '../StateTypeSelection';
import { FormGrid } from './FormGrid';
function AddContactDrawerSectionSix(props) {
  const {
    setValue,
    watch,
    countryList,
    stateList,
    phoneTypeList,
    emailTypeList,
    addressTypeList,
    getDropdownData,
    loadingDropdownData,
    type,
    handlePhoneSectionSave,
    handleEmailSectionSave,
    handleAddressSectionSave,
    addNewColumnBySection
  } = props;
  return (
    <Stack direction={'column'} gap={2}>
      <Phones
        setValue={setValue}
        watch={watch}
        phoneTypeList={phoneTypeList}
        loadingDropdownData={loadingDropdownData}
        handlePhoneSectionSave={handlePhoneSectionSave}
        type={type}
        addNewColumnBySection={addNewColumnBySection}
      />
      <Email
        setValue={setValue}
        watch={watch}
        emailTypeList={emailTypeList}
        loadingDropdownData={loadingDropdownData}
        handleEmailSectionSave={handleEmailSectionSave}
        type={type}
        addNewColumnBySection={addNewColumnBySection}
      />
      <Address
        setValue={setValue}
        watch={watch}
        countryList={countryList}
        stateList={stateList}
        addressTypeList={addressTypeList}
        getDropdownData={getDropdownData}
        loadingDropdownData={loadingDropdownData}
        handleAddressSectionSave={handleAddressSectionSave}
        addNewColumnBySection={addNewColumnBySection}
        type={type}
      />
    </Stack>
  );
}
function Address(props) {
  const { setValue, watch, countryList, stateList, addressTypeList, getDropdownData, loadingDropdownData, type, handleAddressSectionSave, addNewColumnBySection } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const addNewColumn = () => {
    addNewColumnBySection('contact_address');
  };
  const columnList = [
    {
      field: 'address_type',
      headerName: 'addContactDrawer.addressType',
      xs: 3
    },
    {
      field: 'address',
      headerName: 'addContactDrawer.address',
      xs: 5.8
    },
    {
      field: 'is_primary',
      headerName: 'addContactDrawer.isPrimary',
      xs: 2
    },
    {
      field: 'action',
      headerName: 'addContactDrawer.action',
      xs: 1.2
    }
  ];
  const deleteColumn = id => {
    const list = watch('contact_address') || [];
    setValue(
      'contact_address',
      list.filter(item => item.id !== id)
    );
  };
  const editColumn = (id, key, value, disableRestValue = false) => {
    const list = watch('contact_address') || [];
    setValue(
      'contact_address',
      list.map(item =>
        item.id === id
          ? { ...item, [key]: value }
          : {
              ...item,
              [key]: !disableRestValue ? item[key] : false
            }
      )
    );
  };
  const enableEdit = (id, edit = true) => {
    const list = watch('contact_address') || [];
    setValue(
      'contact_address',
      list.map(item => (item.id === id ? { ...item, edit: edit } : item))
    );
  };
  const { t } = useTranslation();
  const getAddressString = (item = []) => {
    let returnString = '';
    for (const i of item) {
      if (i === null || i === undefined || i === '') {
        continue;
      } else {
        returnString += `${i}`;
        if (i !== item[item.length - 1]) {
          returnString += ', ';
        }
      }
    }
    // removed the last comma
    return returnString.replace(/,\s*$/, '');
  };
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography className='fs-14' fontWeight={'bold'} color={'primary.main'}>
          {t('addContactDrawer.address')}
          <Button
            variant='text'
            className='fs-14'
            sx={{
              textDecoration: 'underline',
              fontStyle: 'italic'
            }}
            fontWeight={'bold'}
            onClick={addNewColumn}
            p={0}
            m={0}
          >
            + {t('addContactDrawer.add')}
          </Button>
        </Typography>
        <Button
          sx={{
            padding: 0,
            textDecoration: 'underline',
            fontStyle: 'italic',
            zIndex: 10,
            display: type === 'edit' ? 'flex' : 'none'
          }}
          onClick={async e => {
            e.stopPropagation();
            setSaveLoading(true);
            await handleAddressSectionSave?.();
            setSaveLoading(false);
          }}
          disabled={saveLoading}
          startIcon={<SaveIcon fontSize='small' />}
          endIcon={saveLoading ? <CircularProgress size={20} sx={{ marginLeft: 'auto', marginRight: '10px' }} /> : null}
        >
          {t('addContactDrawer.save')}
        </Button>
      </Stack>
      <Grid
        container
        border={'1px solid'}
        borderColor={'primary.main'}
        sx={{
          width: '100%',
          minHeight: '100px',
          borderRadius: '8px'
        }}
      >
        <Grid item xs={12} alignItems={'center'} justifyContent={'center'} sx={{ bgcolor: 'primary.light1', height: '50px', borderBottom: '1px solid', borderColor: 'primary.main' }}>
          <TableColumn columnList={columnList} />
        </Grid>
        {watch('contact_address')?.length > 0 ? (
          watch('contact_address')?.map((item, index) => {
            return (
              <>
                {item?.edit ? (
                  <Grid item key={index} xs={12} px={1} pb={1} pt={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                    <AddAddress
                      value={item}
                      setValue={editColumn}
                      id={item?.id}
                      onDelete={deleteColumn}
                      enableEdit={enableEdit}
                      countryList={countryList}
                      stateList={stateList}
                      addressTypeList={addressTypeList}
                      getDropdownData={getDropdownData}
                      loadingDropdownData={loadingDropdownData}
                      type={type}
                    />
                  </Grid>
                ) : (
                  <Grid item key={index} xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                    <ListItemComponent
                      customList={[
                        {
                          title: item?.address_type?.name || '',

                          icon: <></>,
                          xs: 3
                        },
                        {
                          title: getAddressString([item?.metropolitan_area, item?.city, item?.zip_code, item?.state?.name, item?.country?.name]),
                          icon: <></>,
                          xs: 5.9
                        },
                        {
                          title: item?.is_primary ? 'Yes' : 'No',

                          icon: <></>,
                          xs: 2
                        }
                      ]}
                      actionBtnXs={0.7}
                      sx={{
                        mb: 0,
                        borderRadius: '0px',
                        p: 0,
                        px: 1,

                        bgcolor: 'text.white3'
                      }}
                      onEdit={() => enableEdit(item?.id, true)}
                      onDelete={() => deleteColumn(item?.id)}
                    />
                  </Grid>
                )}
              </>
            );
          })
        ) : (
          <Grid item xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1', height: '100px' }}>
            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={1}>
              <Typography className='fs-14' color={'primary.main'}>
                {t('addContactDrawer.noAddressDataFound')}
              </Typography>
              <Button variant='contained' onClick={addNewColumn}>
                {t('addContactDrawer.addAddress')}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function AddAddress(props) {
  const { value, setValue, id, onDelete, enableEdit, addressTypeList, loadingDropdownData, type } = props;

  const { t } = useTranslation();
  const sections = [
    {
      id: 'address_lines',
      label: 'addContactDrawer.address',
      type: 'geoLocation',
      required: false,
      error: false,
      // value: value?.address_lines,
      val: value?.address_lines,
      onChange: (autoFillFields = false, addressLines = '', country = null, state = null, metropolitanArea = '', city = '', zipCode = '') => {
        setValue(id, 'address_lines', addressLines);
        if (autoFillFields) {
          setValue(id, 'country', country);
          setValue(id, 'state', state);
          setValue(id, 'metropolitan_area', metropolitanArea);
          setValue(id, 'city', city);
          setValue(id, 'zip_code', zipCode);
        }
      },
      size: 'small',
      fullWidth: true,
      xs: 12
    },
    {
      id: 'metropolitan_area',
      label: 'addContactDrawer.metropolitanArea',
      type: 'text',
      required: false,
      error: false,
      value: value?.metropolitan_area,
      onChange: e => {
        setValue(id, 'metropolitan_area', e.target.value);
      },
      xs: 12
    },
    {
      id: 'country',
      label: t('addContactDrawer.country'),
      type: 'custom',
      required: false,
      error: false,
      value: value?.country,
      size: 'small',

      tooltipTitle: t('addContactDrawer.pleaseTypeToSearch'),
      onChange: (e, option) => {
        setValue(id, 'country', option);
      },
      xs: 3.5,
      customComponent: () => (
        <CountryTypeSelection
          onChange={(e, option) => {
            setValue(id, 'country', option);
          }}
          label={t('addContactDrawer.country')}
          value={value?.country || null}
          required={false}
          size={'small'}
          fullWidth={true}
        />
      )
    },
    {
      id: 'state',
      label: t('addContactDrawer.state'),
      type: 'custom',
      required: false,
      error: false,
      value: value?.state,

      size: 'small',
      getOptionLabel: option => option.name,
      onChange: (e, option) => {
        setValue(id, 'state', option);
      },
      xs: 3.9,
      tooltipTitle: value?.country?.id ? t('addContactDrawer.pleaseTypeToSearch') : t('addContactDrawer.pleaseSelectCountryFirst'),
      customComponent: () => (
        <StateTypeSelection
          onChange={(e, option) => {
            setValue(id, 'state', option);
          }}
          label={t('addContactDrawer.state')}
          value={value?.state || null}
          countryId={value?.country?.id || ''}
          countryName={value?.country?.name || ''}
          required={false}
          size={'small'}
          fullWidth={true}
        />
      )
    },

    {
      id: 'city',
      label: t('addContactDrawer.city'),
      type: 'text',
      required: false,
      error: false,
      value: value?.city,

      size: 'small',
      onChange: e => {
        setValue(id, 'city', e.target.value);
      },
      xs: 3.9
    },

    {
      id: 'zip_code',
      label: t('addContactDrawer.zip'),
      type: 'text',
      required: false,
      error: false,
      value: value?.zip_code,

      size: 'small',
      onChange: e => {
        setValue(id, 'zip_code', e.target.value);
      },
      xs: 3.5
    },
    {
      id: 'address_type',
      label: t('addContactDrawer.addressType'),
      type: 'dropdown',
      required: false,
      error: false,
      value: value?.address_type,
      options: addressTypeList,
      getOptionLabel: option => option.name,
      size: 'small',
      onChange: (e, option) => {
        setValue(id, 'address_type', option);
      },
      xs: 3.9,
      loading: loadingDropdownData
    },
    {
      id: 'is_primary',
      label: t('addContactDrawer.isPrimary'),
      type: 'checkbox',
      required: false,
      error: false,
      value: value?.is_primary,
      onChange: e => {
        setValue(id, 'is_primary', e.target.checked, true);
      },
      xs: 2.4
    },
    {
      id: 'custom',
      type: 'custom',
      customComponent: () => {
        return (
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={1} width={'100%'} height={'100%'}>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1',
                display: type === 'edit' ? 'none' : 'block'
              }}
              variant='text'
              onClick={() => enableEdit(id, false)}
            >
              <SaveIcon fontSize='small' />
            </Button>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1'
              }}
              variant='text'
              onClick={() => onDelete(id)}
            >
              <CloseIcon color='error' fontSize='small' />
            </Button>
          </Stack>
        );
      },
      xs: 1.3
    }
  ];
  return <FormGrid columns={sections} height={'100%'} width={'100%'} px={0} columnGap={2} rowGap={3} />;
}
function Email(props) {
  const { setValue, watch, loadingDropdownData, emailTypeList, handleEmailSectionSave, type, addNewColumnBySection } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const { t } = useTranslation();
  const columnList = [
    {
      field: 'email_type',
      headerName: 'addContactDrawer.emailType',
      xs: 3
    },
    {
      field: 'email',
      headerName: 'addContactDrawer.emailAddress',
      xs: 5.8
    },
    {
      field: 'is_primary',
      headerName: 'addContactDrawer.isPrimary',
      xs: 2
    },
    {
      field: 'action',
      headerName: 'addContactDrawer.action',
      xs: 1.2
    }
  ];

  const addNewColumn = () => {
    addNewColumnBySection('contact_emails');
  };

  const deleteColumn = id => {
    const list = watch('contact_emails') || [];
    setValue(
      'contact_emails',
      list.filter(item => item.id !== id)
    );
  };
  const editColumn = (id, key, value, disableRestValue = false) => {
    const list = watch('contact_emails') || [];
    const findId = list.find(item => item.id === id);
    if (key === 'email' && findId.is_primary === true) {
      setValue('email', value);
    }
    setValue(
      'contact_emails',
      list.map(item =>
        item.id === id
          ? { ...item, [key]: value }
          : {
              ...item,
              [key]: !disableRestValue ? item[key] : false
            }
      )
    );
  };
  const enableEdit = (id, edit = true) => {
    const list = watch('contact_emails') || [];
    setValue(
      'contact_emails',
      list.map(item => (item.id === id ? { ...item, edit: edit } : item))
    );
  };

  // const onSave = () => {
  //   const list = watch('certifications_and_licenses') || [];
  //   const newList = list.map(item => {
  //     return {
  //       ...item
  //     };
  //   });
  //   setValue('certifications_and_licenses', newList);

  // };

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography className='fs-14' fontWeight={'bold'} color={'primary.main'}>
          {t('addContactDrawer.email')}
          <Button
            variant='text'
            className='fs-14'
            sx={{
              textDecoration: 'underline',
              fontStyle: 'italic'
            }}
            fontWeight={'bold'}
            onClick={addNewColumn}
            p={0}
            m={0}
          >
            + {t('addContactDrawer.add')}
          </Button>
        </Typography>
        <Button
          sx={{
            padding: 0,
            textDecoration: 'underline',
            fontStyle: 'italic',
            zIndex: 10,
            display: type === 'edit' ? 'flex' : 'none'
          }}
          onClick={async e => {
            e.stopPropagation();
            setSaveLoading(true);
            await handleEmailSectionSave?.();
            setSaveLoading(false);
          }}
          disabled={saveLoading}
          startIcon={<SaveIcon fontSize='small' />}
          endIcon={saveLoading ? <CircularProgress size={20} sx={{ marginLeft: 'auto', marginRight: '10px' }} /> : null}
        >
          {t('addContactDrawer.save')}
        </Button>
      </Stack>
      <Grid
        container
        border={'1px solid'}
        borderColor={'primary.main'}
        sx={{
          width: '100%',
          minHeight: '100px',
          borderRadius: '8px'
        }}
      >
        <Grid item xs={12} alignItems={'center'} justifyContent={'center'} sx={{ bgcolor: 'primary.light1', height: '50px', borderBottom: '1px solid', borderColor: 'primary.main' }}>
          <TableColumn columnList={columnList} />
        </Grid>
        {watch('contact_emails')?.length > 0 ? (
          watch('contact_emails')?.map((item, index) => {
            return (
              <>
                {item?.edit ? (
                  <Grid item key={index} xs={12} px={1} pb={1} pt={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                    <AddEmail
                      value={item}
                      setValue={editColumn}
                      id={item?.id}
                      onDelete={deleteColumn}
                      enableEdit={enableEdit}
                      loadingDropdownData={loadingDropdownData}
                      emailTypeList={emailTypeList}
                      type={type}
                    />
                  </Grid>
                ) : (
                  <Grid item key={index} xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                    <ListItemComponent
                      customList={[
                        {
                          title: item?.email_type?.name || '',

                          icon: <></>,
                          xs: 3
                        },
                        {
                          title: item?.email || '',

                          icon: <></>,
                          xs: 5.9
                        },
                        {
                          title: item?.is_primary ? t('addContactDrawer.yes') : t('addContactDrawer.no'),

                          icon: <></>,
                          xs: 2
                        }
                      ]}
                      actionBtnXs={0.7}
                      sx={{
                        mb: 0,
                        borderRadius: '0px',
                        p: 0,
                        px: 1,

                        bgcolor: 'text.white3'
                      }}
                      onEdit={() => enableEdit(item?.id, true)}
                      onDelete={() => deleteColumn(item?.id)}
                    />
                  </Grid>
                )}
              </>
            );
          })
        ) : (
          <Grid item xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1', height: '100px', width: '100%' }}>
            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={1}>
              <Typography className='fs-14' color={'primary.main'}>
                {t('addContactDrawer.noEmailDataFound')}
              </Typography>
              <Button variant='contained' onClick={addNewColumn}>
                {t('addContactDrawer.addEmail')}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function AddEmail(props) {
  const { value, setValue, id, onDelete, enableEdit, loadingDropdownData, emailTypeList, type } = props;

  const sections = [
    {
      id: 'email_type',
      label: 'addContactDrawer.emailType',
      type: 'dropdown',
      required: false,
      error: false,
      value: value?.email_type,
      options: emailTypeList,
      getOptionLabel: option => option.name,
      onChange: (e, option) => {
        setValue(id, 'email_type', option);
      },
      xs: 3,
      loading: loadingDropdownData
    },
    {
      id: 'email',
      label: 'addContactDrawer.emailAddress',
      type: 'text',
      required: false,
      error: false,
      value: value?.email,
      options: [],
      size: 'small',
      onChange: e => {
        setValue(id, 'email', e.target.value);
      },
      xs: 5.6
    },
    {
      id: 'is_primary',
      label: '',
      type: 'checkbox',
      required: false,
      error: false,
      value: value?.is_primary,
      onChange: e => {
        setValue(id, 'is_primary', e.target.checked, true);
      },
      xs: 1.7,
      sx: {
        marginLeft: '10px'
      }
    },
    {
      id: 'custom',
      type: 'custom',
      customComponent: () => {
        return (
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={1} width={'100%'} height={'100%'}>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1',
                display: type === 'edit' ? 'none' : 'block'
              }}
              variant='text'
              onClick={() => enableEdit(id, false)}
            >
              <SaveIcon fontSize='small' />
            </Button>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1'
              }}
              variant='text'
              onClick={() => onDelete(id)}
            >
              <CloseIcon color='error' fontSize='small' />
            </Button>
          </Stack>
        );
      },
      xs: 1.2
    }
  ];
  return <FormGrid columns={sections} height={'100%'} width={'100%'} px={0} columnGap={1} />;
}
function Phones(props) {
  const { setValue, watch, loadingDropdownData, phoneTypeList, handlePhoneSectionSave, type, addNewColumnBySection } = props;
  const { t } = useTranslation();
  const [saveLoading, setSaveLoading] = useState(false);
  const columnList = [
    {
      field: 'phone_type',
      headerName: 'addContactDrawer.phoneType',
      xs: 3
    },
    {
      field: 'phone_number',
      headerName: 'addContactDrawer.phoneNumber',
      xs: 5.8
    },
    {
      field: 'is_primary',
      headerName: 'addContactDrawer.isPrimary',
      xs: 2
    },
    {
      field: 'action',
      headerName: 'addContactDrawer.action',
      xs: 1.2
    }
  ];

  const addNewColumn = () => {
    addNewColumnBySection('contact_phones');
  };
  const deleteColumn = id => {
    const list = watch('contact_phones') || [];
    setValue(
      'contact_phones',
      list.filter(item => item.id !== id)
    );
  };
  const editColumn = (id, key, value, disableRestValue = false) => {
    const list = watch('contact_phones') || [];
    const findId = list.find(item => item.id === id);
    if (key === 'phone_number' && findId.is_primary === true) {
      setValue('phone', value);
    }
    setValue(
      'contact_phones',
      list.map(item =>
        item.id === id
          ? { ...item, [key]: value }
          : {
              ...item,
              [key]: !disableRestValue ? item[key] : false
            }
      )
    );
  };
  const enableEdit = (id, edit = true) => {
    const list = watch('contact_phones') || [];
    setValue(
      'contact_phones',
      list.map(item => (item.id === id ? { ...item, edit: edit } : item))
    );
  };

  // const onSave = () => {
  //   const list = watch('certifications_and_licenses') || [];
  //   const newList = list.map(item => {
  //     return {
  //       ...item
  //     };
  //   });
  //   setValue('certifications_and_licenses', newList);

  // };

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography className='fs-14' fontWeight={'bold'} color={'primary.main'}>
          {t('addContactDrawer.phone')}
          <Button
            variant='text'
            className='fs-14'
            sx={{
              textDecoration: 'underline',
              fontStyle: 'italic'
            }}
            fontWeight={'bold'}
            onClick={addNewColumn}
            p={0}
            m={0}
          >
            {t('addContactDrawer.addPhone')}
          </Button>
        </Typography>
        <Button
          sx={{
            padding: 0,
            textDecoration: 'underline',
            fontStyle: 'italic',
            zIndex: 10,
            display: type === 'edit' ? 'flex' : 'none'
          }}
          onClick={async e => {
            e.stopPropagation();
            setSaveLoading(true);
            await handlePhoneSectionSave?.();
            setSaveLoading(false);
          }}
          disabled={saveLoading}
          startIcon={<SaveIcon fontSize='small' />}
          endIcon={saveLoading ? <CircularProgress size={20} sx={{ marginLeft: 'auto', marginRight: '10px' }} /> : null}
        >
          {t('addContactDrawer.save')}
        </Button>
      </Stack>
      <Grid
        container
        border={'1px solid'}
        borderColor={'primary.main'}
        sx={{
          width: '100%',
          minHeight: '100px',
          borderRadius: '8px'
        }}
      >
        <Grid item xs={12} alignItems={'center'} justifyContent={'center'} sx={{ bgcolor: 'primary.light1', height: '50px', borderBottom: '1px solid', borderColor: 'primary.main' }}>
          <TableColumn columnList={columnList} />
        </Grid>
        {watch('contact_phones')?.length > 0 ? (
          watch('contact_phones')?.map((item, index) => {
            return (
              <>
                {item?.edit ? (
                  <Grid item key={index} xs={12} px={1} pb={1} pt={1} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                    <AddPhone
                      value={item}
                      setValue={editColumn}
                      id={item?.id}
                      onDelete={deleteColumn}
                      enableEdit={enableEdit}
                      loadingDropdownData={loadingDropdownData}
                      phoneTypeList={phoneTypeList}
                      type={type}
                    />
                  </Grid>
                ) : (
                  <Grid item key={index} xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1' }}>
                    <ListItemComponent
                      customList={[
                        {
                          title: item?.phone_type?.name || '',

                          icon: <></>,
                          xs: 3
                        },
                        {
                          title: item?.phone_number || '',

                          icon: <></>,
                          xs: 6.2
                        },
                        {
                          title: item?.is_primary ? t('addContactDrawer.yes') : t('addContactDrawer.no'),

                          icon: <></>,
                          xs: 1.7
                        }
                      ]}
                      actionBtnXs={1}
                      sx={{
                        mb: 0,
                        borderRadius: '0px',
                        p: 0,
                        px: 1,

                        bgcolor: 'text.white3'
                      }}
                      onEdit={() => enableEdit(item?.id, true)}
                      onDelete={() => deleteColumn(item?.id)}
                    />
                  </Grid>
                )}
              </>
            );
          })
        ) : (
          <Grid item xs={12} sx={{ borderTop: '1px solid', borderColor: 'text.white1', height: '100px', width: '100%' }}>
            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} gap={1}>
              <Typography className='fs-14' color={'primary.main'}>
                {t('addContactDrawer.noPhoneDataFound')}
              </Typography>
              <Button variant='contained' onClick={addNewColumn}>
                {t('addContactDrawer.addPhone')}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function AddPhone(props) {
  const { value, setValue, id, onDelete, enableEdit, loadingDropdownData, phoneTypeList, type } = props;

  const sections = [
    {
      id: 'phone_type',
      label: 'addContactDrawer.phoneType',
      type: 'dropdown',
      required: false,
      error: false,
      value: value?.phone_type,
      options: phoneTypeList,
      getOptionLabel: option => option.name,
      onChange: (e, option) => {
        setValue(id, 'phone_type', option);
      },
      xs: 3,
      loading: loadingDropdownData
    },
    {
      id: 'phone_number',
      label: 'addContactDrawer.phoneNumber',
      type: 'phone',
      required: false,
      error: false,
      value: value?.phone_number,
      options: [],
      size: 'small',
      onChange: e => {
        setValue(id, 'phone_number', e);
      },
      xs: 5.6
    },
    {
      id: 'is_primary',
      label: '',
      type: 'checkbox',
      required: false,
      error: false,
      value: value?.is_primary,
      onChange: e => {
        setValue(id, 'is_primary', e.target.checked, true);
      },
      xs: 1.7,
      sx: {
        marginLeft: '10px'
      }
    },
    {
      id: 'custom',
      type: 'custom',
      customComponent: () => {
        return (
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={1} width={'100%'} height={'100%'}>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1',
                display: type === 'edit' ? 'none' : 'block'
              }}
              variant='text'
              onClick={() => enableEdit(id, false)}
            >
              <SaveIcon fontSize='small' />
            </Button>
            <Button
              sx={{
                borderRadius: '50%',
                minHeight: '20px',
                minWidth: '20px',
                p: 0.5,
                bgcolor: 'primary.light1'
              }}
              variant='text'
              onClick={() => onDelete(id)}
            >
              <CloseIcon color='error' fontSize='small' />
            </Button>
          </Stack>
        );
      },
      xs: 1.2
    }
  ];
  return <FormGrid columns={sections} height={'100%'} width={'100%'} px={0} columnGap={1} />;
}
export default AddContactDrawerSectionSix;

AddContactDrawerSectionSix.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  newCertificateAndLicensesOpen: PropTypes.bool.isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  phoneTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emailTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addressTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handlePhoneSectionSave: PropTypes.func.isRequired,
  handleEmailSectionSave: PropTypes.func.isRequired,
  handleAddressSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};

AddPhone.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  enableEdit: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  phoneTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
  handlePhoneSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};
Phones.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  phoneTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handlePhoneSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};
AddEmail.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  enableEdit: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  emailTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
  handleEmailSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};
Email.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  emailTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleEmailSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};
AddAddress.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  enableEdit: PropTypes.func.isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addressTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleAddressSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};
Address.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addressTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleAddressSectionSave: PropTypes.func.isRequired,
  addNewColumnBySection: PropTypes.func.isRequired
};
