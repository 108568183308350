//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CircularProgress, createFilterOptions, Stack } from '@mui/material';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getMethodWithCancelTokenApi, ignCompanyDataApi } from '../../../../services/ApiService';
import { ERROR, GET, IGN_API, POST, SUCCESS } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';
import Loader from '../../Loader';

const CompanySelectionDebounce = props => {
  const {
    label = '',
    placeholder = '',
    onChange = () => {},
    defaultValue,
    required = false,
    isForAddCompany = false,
    addAnotherButtonOnChange = () => {
      return -1;
    },
    ignoreFiltering = true,
    passValueToParentOnEnter = false,
    showAssociatedCompany = false,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [addCompanyLoader, setAddCompanyLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const filter = createFilterOptions();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(defaultValue);
  const [useInternalState, setUseInternalState] = useState(true);
  const getSearchData = async (initialSearch = false) => {
    try {
      const name = useInternalState ? searchValue : defaultValue;
      if (showAssociatedCompany) {
        const sub_route = `?tabName=ASSOCIATED_COMPANIES_TAB${!initialSearch ? `&associatedCompanySearch=${name?.name ? name?.name : name}` : ''}`;
        const { status, data } = await ignCompanyDataApi(GET, '', defaultValue?.id, sub_route);
        if (status === 200) {
          const records = data?.company_associated?.map(record => ({ ...record.related_company }));
          setOptions([defaultValue, ...records]);
          if (initialSearch) {
            setDefaultOptions([defaultValue, ...records]);
          }
          if (data?.company_associated?.length === 0) {
            setOptions([defaultValue]);
          }
        }
      } else {
        const url = `${IGN_API.picklists}/companies?${initialSearch ? 'initialCompanyFetch=true' : `name=${name}`}`;
        if (!initialSearch && !name) {
          return;
        }
        setLoading(true);
        const response = await getMethodWithCancelTokenApi(url, {}, {});
        const { status, data } = response;
        if (status === 200) {
          const records = data?.data?.map(record => ({ ...record }));
          setOptions(records);
          if (initialSearch) {
            setDefaultOptions(records);
          }
          if (data?.data?.length === 0) {
            setOptions([]);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      console.error('error in getSearchData, error:: ', err);
      setLoading(false);
      setOptions([]);
    }
  };
  useEffect(() => {
    if (props?.onInputChange) {
      setUseInternalState(false);
    }
    getSearchData(true);
  }, []);
  const debounceSearchApiCall = debounce(() => {
    getSearchData();
  }, 400);

  useEffect(() => {
    if ((defaultValue && defaultValue.length >= 3) || (searchValue && searchValue.length >= 3)) {
      debounceSearchApiCall();
    }
    if (!searchValue) {
      setOptions(defaultOptions);
    }
    return () => {
      debounceSearchApiCall.cancel();
    };
  }, [defaultValue, searchValue]);

  const addCompanyByCompanyName = async companyName => {
    try {
      if (!companyName || companyName.trim().length == 0) {
        enqueueSnackbar('Please add company name to be added', { variant: ERROR });
        return;
      }
      setAddCompanyLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, {
        name: companyName,
        comments: '',
        company_tags: [],
        company_industries: [],
        company_status: 'active'
      });
      if (status == 201) {
        setOptions([
          {
            id: data.id,
            name: companyName
          },
          ...options
        ]);
        enqueueSnackbar('Successfully added company to the list', { variant: SUCCESS });
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: ERROR });
    } finally {
      setAddCompanyLoader(false);
    }
  };

  return (
    <>
      <Loader show={addCompanyLoader} />
      <CustomDropdown
        {...rest}
        options={options}
        label={label}
        placeholder={placeholder}
        onInputChange={(event, newValue) => {
          if (useInternalState) {
            setSearchValue(newValue);
          }
          if (props?.onInputChange) {
            props.onInputChange(event, newValue);
          }
        }}
        onChange={(event, newValue) => {
          if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue?.length && newValue[newValue?.length - 1].inputValue))) {
            // window.open(`${window.location.host}/companies/all-companies/add`, '_blank')
          }
          onChange(event, newValue);
          event.preventDefault();
        }}
        onKeyDown={event => {
          if (event.keyCode === 13) {
            event.preventDefault();
            if (passValueToParentOnEnter) {
              onChange(null, event.target.value);
            } else {
              getSearchData();
            }
          }
        }}
        defaultValue={useInternalState ? searchValue : defaultValue}
        required={required}
        addAnotherButton={!isForAddCompany}
        filterOptions={(options, params) => {
          if (ignoreFiltering) {
            return [...options, { inputValue: params.inputValue }];
          }
          const filtered = filter(options, params);
          if (params.inputValue) {
            filtered.push({
              inputValue: params.inputValue
            });
          }
          return filtered;
        }}
        addAnotherButtonText={addCompanyLoader ? t('utils.pleaseWait') : t('utils.addCompany')}
        isToAddCompany={!isForAddCompany}
        addAnotherButtonOnChange={() => {
          if (addAnotherButtonOnChange(searchValue) == -1) {
            addCompanyByCompanyName(searchValue);
          }
        }} //! By adding searchValue, we are not requiring parent component to maintain the state
      />
      <Stack position={'absolute'} right={'56px'} top={0} bottom={0} justifyContent={'center'} alignItems={'center'} sx={{ display: 'flex', width: 40 }}>
        {loading && <CircularProgress size={20} />}
      </Stack>
    </>
  );
};

CompanySelectionDebounce.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  isForAddCompany: PropTypes.bool,
  onInputChange: PropTypes.func,
  addAnotherButtonOnChange: PropTypes.func,
  passValueToParentOnEnter: PropTypes.bool,
  ignoreFiltering: PropTypes.bool,
  showAssociatedCompany: PropTypes.bool
};

export default CompanySelectionDebounce;
