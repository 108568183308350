import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { getMethodWithCancelTokenApi } from '../../services/ApiService';
import { IGN_API } from '../../services/constantService';
import { createCountry, createState } from '../AddContactDrawer/AddContactDrawer';
import CustomMuiDropdown from '../common/CustomMuiDropdown';

/**
 * GeoLocationWithAutoComplete component provides an autocomplete dropdown for geographic locations
 * with the ability to auto-fill address details.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.val - The current value of the input field
 * @param {Function} props.onChange - Callback function triggered on value change
 *   @param {boolean} props.onChange.autoFillFields - Whether to auto-fill other address fields
 *   @param {string} props.onChange.addressLines - Street address
 *   @param {Object|null} props.onChange.country - Country object with id and name
 *   @param {Object|null} props.onChange.state - State object with id and name
 *   @param {string} props.onChange.metropolitanArea - Metropolitan area name
 *   @param {string} props.onChange.city - City name
 *   @param {string} props.onChange.zipCode - Postal code
 * @param {string} props.label - Label text for the input field
 * @param {Function} [props.onClick] - Optional click handler
 * @returns {React.ReactElement} A dropdown component with location autocomplete functionality
 */
function GeoLocationWithAutoComplete(props) {
  const { val, onChange, label, ...rest } = props;

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);

  // Initialize Google Places services
  useEffect(() => {
    if (window.google) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
    }
  }, []);

  const getDropdownData = async type => {
    const url = `${IGN_API.picklists}/${type}`;
    //   if (!openDrawer) return [];
    const res = await getMethodWithCancelTokenApi(
      url,
      {
        limit: 10000
      },
      {}
    );
    return res?.data;
  };
  const getStateData = async (countryName, stateFromGeoLocation, countryId) => {
    if (!countryName) return;
    const res = await getDropdownData(`/countries/states?countryName=${countryName}&name=${stateFromGeoLocation}`);
    if (res?.length > 0) {
      const stateExist = res?.find(item => item?.name === stateFromGeoLocation);
      if (stateExist) {
        return stateExist;
      }
    } else {
      const getNewState = await createState(stateFromGeoLocation, countryId);
      if (getNewState) {
        return getNewState;
      }
    }
    return null;
  };
  const getCountryData = async countryName => {
    if (!countryName) return;
    const res = await getDropdownData(`/countries?countryName=${countryName}`);
    if (res?.data?.length > 0) {
      const countryExist = res?.data?.[0] || null;
      if (countryExist) {
        return countryExist;
      } else {
        await createCountry(countryName);
        const res = await getDropdownData(`/countries?countryName=${countryName}`);
        if (res?.data?.length > 0) {
          const countryExist = res?.data?.[0] || null;
          return countryExist;
        }
      }
    }
    return null;
  };
  const getStreetAddress = addressComponents => {
    if (!addressComponents?.length) return '';

    const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';

    const route = addressComponents.find(component => component.types.includes('route'))?.short_name || '';

    return [streetNumber, route].filter(Boolean).join(' ');
  };
  const autoFillBasedOnGeoLocation = async value => {
    try {
      let countryResponse = null;
      let stateResponse = null;
      let cityResponse = '';
      let zipCodeResponse = '';
      let metropolitanAreaResponse = '';
      let addressLinesResponse = val;
      const geoLocationData = await getPlaceDetails(value?.place_id);
      if (geoLocationData?.formatted_address) {
        const addressLines = getStreetAddress(geoLocationData?.address_components) || '';
        const country = geoLocationData?.address_components?.find(item => item?.types?.includes('country'))?.long_name || '';
        const state = geoLocationData?.address_components?.find(item => item?.types?.includes('administrative_area_level_1'))?.long_name || '';
        const city = geoLocationData?.address_components?.find(item => item?.types?.includes('locality'))?.long_name || '';
        const zipCode = geoLocationData?.address_components?.find(item => item?.types?.includes('postal_code'))?.long_name || '';
        const metropolitanArea =
          geoLocationData?.address_components
            ?.filter(
              item =>
                item?.types?.includes('political') &&
                !item?.types?.includes('country') &&
                !item?.types?.includes('administrative_area_level_1') &&
                !item?.types?.includes('locality') &&
                !item?.types?.includes('postal_code')
            )
            ?.map(item => item?.long_name)
            .join(',') || '';

        if (country) {
          const countryData = await getCountryData(country);
          if (countryData) {
            countryResponse = countryData;
          } else {
            countryResponse = null;
          }
        } else {
          countryResponse = null;
        }

        if (state) {
          const stateData = await getStateData(country, state, countryResponse?.id);
          if (stateData) {
            stateResponse = stateData;
          } else {
            stateResponse = null;
          }
        } else {
          stateResponse = null;
        }

        if (city) {
          cityResponse = city;
        } else {
          cityResponse = '';
        }

        if (zipCode) {
          zipCodeResponse = zipCode;
        } else {
          zipCodeResponse = '';
        }

        if (metropolitanArea) {
          metropolitanAreaResponse = metropolitanArea;
        } else {
          metropolitanAreaResponse = '';
        }
        if (addressLines) {
          addressLinesResponse = addressLines;
        } else {
          addressLinesResponse = '';
        }
      }

      return {
        country: countryResponse,
        state: stateResponse,
        city: cityResponse,
        zipCode: zipCodeResponse,
        metropolitanArea: metropolitanAreaResponse,
        addressLines: addressLinesResponse || val || ''
      };
    } catch (error) {
      return {
        addressLines: val || '',
        country: null,
        state: null,
        city: null,
        zipCode: null,
        metropolitanArea: null
      };
    } finally {
      setLoading(false);
    }
  };
  const fetchData = async searchText => {
    try {
      setLoading(true);
      if (!autocompleteService || !searchText) {
        setOptions([]);
        return;
      }

      autocompleteService.getPlacePredictions({ input: searchText }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setOptions(predictions);
        } else {
          setOptions([]);
        }
        setLoading(false);
      });
    } catch (err) {
      console.error('Error fetching places:', err);
      setLoading(false);
    }
  };

  const getPlaceDetails = placeId => {
    return new Promise((resolve, reject) => {
      if (!placesService) {
        reject(new Error('Places service not initialized'));
        return;
      }

      placesService.getDetails({ placeId: placeId, fields: ['address_components', 'formatted_address'] }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          reject(new Error('Failed to fetch place details'));
        }
      });
    });
  };

  const handleChange = async (e, v) => {
    setLoading(true);
    if (v) {
      try {
        const placeDetails = await getPlaceDetails(v.place_id);
        const data = await autoFillBasedOnGeoLocation({ ...v, result: placeDetails });
        onChange(true, data?.addressLines || val, data?.country, data?.state, data?.metropolitanArea || '', data?.city || '', data?.zipCode || '');
      } catch (error) {
        console.error('Error handling selection:', error);
      }
    }
    setLoading(false);
  };

  const debounceFetchData = useCallback(debounce(fetchData, 500), [autocompleteService]);

  return (
    <CustomMuiDropdown
      options={options}
      autoCompleteProps={{
        freeSolo: true
      }}
      label={label}
      getOptionLabel={option => option?.description || ''}
      isLoading={loading}
      inputValue={val}
      onChange={handleChange}
      size={'small'}
      textFieldProps={{
        value: val,
        onChange: e => {
          onChange(false, e.target.value);
          debounceFetchData(e.target.value);
        }
      }}
      {...rest}
    />
  );
}

export default GeoLocationWithAutoComplete;

GeoLocationWithAutoComplete.propTypes = {
  val: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  ...CustomMuiDropdown.propTypes
};
