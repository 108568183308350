import { capitalize, isEmpty } from 'lodash';
import { tooltipValueGrid } from '../../../../../utils/common';
import { formatDate } from '../../../../../utils/date';

export const defaultColumnOptions = {
  filter: 'agMultiColumnFilter',
  menuTabs: ['filterMenuTab'],
  headerComponentParams: {
    menuIcon: 'fa-bars'
  },
  flex: 1,
  minWidth: 200,
  resizable: true,
  sortable: true,
  enableRowGroup: true,
  enableValue: true
};

export const generateWorkbenchProjectColumn = t => {
  return [
    {
      ...defaultColumnOptions,
      field: 'color_code.color_name',
      colId: 'color',
      headerName: 'Color',
      suppressSizeToFit: true,
      cellRenderer: 'colorRenderer',
      valueGetter: ({ data }) => {
        if (!data) {
          return null;
        }
        try {
          if (data.color_code) {
            if (!isEmpty(data.color_code.workbench_labels)) {
              const selected = data.color_code.workbench_labels.find(color => color.workbench_id === data.workbench_id);
              return selected.label;
            }
            return data.color_code.color_name ? capitalize(data.color_code.color_name) : data.color_code.color_code;
          }
          return null;
        } catch {
          return null;
        }
      },
      minWidth: 100,
      maxWidth: 150,
      visible: true,
      filter: false
    },
    {
      ...defaultColumnOptions,
      field: 'project.job_number',
      headerName: t('grid.projectId'),
      minWidth: 150,
      cellRenderer: 'projectNumberRenderer'
    },
    {
      ...defaultColumnOptions,
      field: 'job_title',
      headerName: 'Title',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'stage',
      headerName: 'Status',
      flex: 2,
      cellRendererFramework: ({ value }) => capitalize(value || '--')
    },
    {
      ...defaultColumnOptions,
      field: 'created_by',
      headerName: 'Owner',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'days_open',
      headerName: 'Days Open',
      flex: 2,
      valueGetter: ({ data }) => Number(data?.days_open)
    },
    {
      ...defaultColumnOptions,
      field: 'project.ign_companies.name',
      headerName: 'Company',
      cellRenderer: 'companyRenderer'
    },
    {
      ...defaultColumnOptions,
      field: 'industries',
      headerName: 'Industries',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'job_function',
      headerName: 'Job Function',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'location',
      headerName: 'Location',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'consultants',
      headerName: 'Consultants',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'capital_structure',
      headerName: 'Capital Structure',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'vertical_practice',
      headerName: 'Vertical Practice',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'horizontal_practice',
      headerName: 'Horizontal Practice',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'service_offering',
      headerName: 'Service Offering',
      flex: 2
    },
    {
      ...defaultColumnOptions,
      field: 'projected_start_date',
      headerName: 'Projected Start Date',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'job_number',
      headerName: 'Job Number',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'company_name',
      headerName: 'Company Name',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'location_description',
      headerName: 'Company Location',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'fee_percentage',
      headerName: 'Fee Percentage',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'billing_region',
      headerName: 'Billing Region',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'estimated_fee_amount',
      headerName: 'Estimated Fee Amount',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'actual_revenue',
      headerName: 'Revenue',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'billing_status',
      headerName: 'Billing Status',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'approval_requested_date',
      headerName: 'Approval Date',
      cellRendererFramework: ({ value }) => value
    },
    {
      ...defaultColumnOptions,
      field: 'billing_notes',
      headerName: 'Billing Notes',
      filter: 'agTextColumnFilter',
      valueGetter: ({ data }) => data?.billing_notes || '--'
    },
    {
      ...defaultColumnOptions,
      field: 'job_type',
      headerName: 'Job Type',
      filter: 'agTextColumnFilter',
      valueGetter: ({ data }) => data?.job_type || '--'
    },
    {
      ...defaultColumnOptions,
      field: 'location_city',
      headerName: 'Location City',
      filter: 'agTextColumnFilter',
      valueGetter: ({ data }) => data?.location_city || '--'
    },
    {
      ...defaultColumnOptions,
      field: 'created_at',
      colId: 'created_at',
      headerName: 'Date Added',
      editable: false,
      valueGetter: ({ data }) => formatDate(data?.created_at)
    },
    {
      ...defaultColumnOptions,
      field: 'updated_at',
      headerName: 'Last Activity Date',
      filter: 'agTextColumnFilter',
      valueGetter: ({ data }) => formatDate(data?.updated_at)
    },
    {
      headerName: 'Actions',
      width: 120,
      pinned: 'right',
      field: 'actions',
      cellRenderer: 'actionRenderer',
      suppressFilter: true,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      filter: false,
      sortable: false
    }
  ];
};

export const workbenchContactColumn = [
  {
    ...defaultColumnOptions,
    field: 'color_code.color_name',
    colId: 'color',
    flex: 1,
    suppressSizeToFit: true,
    headerName: 'Color',
    cellRenderer: 'colorRenderer',
    minWidth: 100,
    maxWidth: 150,
    visible: true,
    filter: false,
    sortable: true,
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }
      try {
        if (data.color_code) {
          if (!isEmpty(data.color_code.workbench_labels)) {
            const selected = data.color_code.workbench_labels.find(color => color.workbench_id === data.workbench_id);
            return selected.label;
          }
          return data.color_code.color_name ? capitalize(data.color_code.color_name) : data.color_code.color_code;
        }
        return null;
      } catch {
        return null;
      }
    }
  },
  {
    ...defaultColumnOptions,
    field: 'contact.name',
    headerName: 'Name',
    cellRenderer: 'nameRenderer'
  },
  {
    ...defaultColumnOptions,
    field: 'company_name',
    headerName: 'Company',
    cellRenderer: 'companyRenderer',
    valueGetter: params => params?.data?.company_name || null
  },
  {
    ...defaultColumnOptions,
    field: 'job_title',
    headerName: 'Job Title',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'job_function',
    headerName: 'Job Function',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'location',
    headerName: 'Location',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'contact_country_p1',
    headerName: 'Country',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'owner',
    headerName: 'Owner',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'phone_number',
    headerName: 'Phone Number',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'email',
    headerName: 'Email',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'source',
    headerName: 'Source',
    flex: 2
  },
  {
    ...defaultColumnOptions,
    field: 'gender',
    headerName: 'Gender',
    cellRendererFramework: ({ value }) => capitalize(value || '--'),
    flex: 2
  },
  {
    ...defaultColumnOptions,
    headerName: 'About',
    field: 'about',
    colId: 'current_job_title',
    maxWidth: 250,
    cellRenderer: 'aboutRenderer',
    filter: 'agTextColumnFilter',
    visible: true,
    minWidth: 150,
    sortable: true,
    filterParams: {
      maxNumConditions: 1
    },
    valueGetter: params => params?.data?.current_job_title || '-'
  },
  {
    ...defaultColumnOptions,
    field: 'status',
    headerName: 'Status',
    flex: 2,
    cellRendererFramework: ({ value }) => capitalize(value || '--')
  },
  {
    ...defaultColumnOptions,
    headerName: 'Linkedin',
    field: 'linkedin_url',
    colId: 'linkedin_url',
    sortable: true,
    tooltipValueGetter: params => tooltipValueGrid(params),
    cellRenderer: 'LinkedInRenderer',
    maxWidth: 100,
    minWidth: 50
  },
  {
    ...defaultColumnOptions,
    headerName: 'Actions',
    width: 120,
    pinned: 'right',
    field: 'actions',
    cellRenderer: 'actionRenderer',
    suppressFilter: true,
    filter: false,
    sortable: false,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true
  }
];
