import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress, Grid, MenuItem, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AssignTag from '../../Containers/Contacts/AssignTag';
import { getMethodWithCancelTokenApi, ignContactDataApi } from '../../services/ApiService';
import { GET, IGN_API, PATCH } from '../../services/constantService';
import { checkDuplicateOnBlur } from '../../utils/common';
import CustomPhoneInput from '../common/CustomPhoneInput';
import Loader from '../common/Loader';
function EditContactPopup(props) {
  const { getValues, setValue, watch, register, unregister } = useForm();
  const { contactId, contactData, updateHeader, open, setOpen } = props;

  const [data, setData] = React.useState(contactData || {});
  const [loading, setLoading] = React.useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = React.useState(false);
  const [_, setIsEdited] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState({
    first_name: false,
    last_name: false,
    contact_emails: false,
    contact_phones: false,
    contact_address: false,
    contact_company: false,
    contact_tags: false,
    linkedin_url: false,
    created_by: false,
    current_job_title: false
  });
  const [duplicate, setDuplicate] = React.useState({
    contact_phones: false,
    contact_emails: false,
    linkedin_url: false
  });
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getContact = async id => {
    setLoading(true);
    const { data } = await ignContactDataApi(GET, id);
    setLoading(false);
    setApiValues(data);
  };
  const getStateData = async countryName => {
    if (!countryName) return;

    const res = await getDropdownData(`/countries/states?countryName=${countryName}`);

    return res;
  };
  const getDropdownData = async type => {
    const url = `${IGN_API.picklists}/${type}`;
    const res = await getMethodWithCancelTokenApi(url, {}, {}).then(response => {
      const { data } = response;

      return data;
    });
    return res;
  };
  const handleClose = () => setOpen(false);
  const setApiValues = data => {
    setData(data);
    setValue('first_name', data?.first_name);
    setValue('last_name', data?.last_name);
    setValue('contact_emails', data?.contact_emails?.filter(item => item.is_primary === true)[0]);
    setValue('contact_phones', data?.contact_phones?.filter(item => item.is_primary === true)[0]);
    setValue('contact_address', getLocation(data?.contact_address) || {});
    setValue('contact_company', data?.contact_company);
    setValue('contact_tags', data?.contact_tags);
    setValue('linkedin_url', data?.linkedin_url);
    setValue('created_by', data?.created_by);
    setValue('current_job_title', data?.current_job_title);
  };

  React.useEffect(() => {
    register('first_name');
    register('last_name');
    register('contact_emails'); //
    register('contact_phones'); //
    register('contact_address');
    register('contact_company');
    register('contact_tags');
    register('linkedin_url');
    register('created_by');
    register('current_job_title');

    return () => {
      unregister('first_name');
      unregister('last_name');
      unregister('contact_emails');
      unregister('contact_phones');
      unregister('contact_address');
      unregister('contact_company');
      unregister('contact_tags');
      unregister('linkedin_url');
      unregister('created_by');
      unregister('current_job_title');
    };
  }, [register]);

  React.useEffect(() => {
    setApiValues(contactData);
  }, [contactData]);

  const onBlurPhoneNumber = async value => {
    const phoneNumberData = { contact_phones: [{ phone_number: value }] };
    const { isDuplicate } = await checkDuplicateOnBlur(phoneNumberData, setLoading);
    setDuplicate({ ...duplicate, contact_phones: isDuplicate });
    return isDuplicate;
  };

  const onBlurLinkedInUrl = async value => {
    const linkedInUrlData = { linkedin_url: value };
    const { isDuplicate } = await checkDuplicateOnBlur(linkedInUrlData, setLoading);
    setDuplicate({ ...duplicate, linkedin_url: isDuplicate });
    return isDuplicate;
  };

  const onBlurEmail = async value => {
    const emailData = { contact_emails: [{ email: value || '' }] };
    const { isDuplicate } = await checkDuplicateOnBlur(emailData, setLoading);
    setDuplicate({ ...duplicate, contact_emails: isDuplicate });
    return isDuplicate;
  };
  const validate = async () => {
    try {
      isTouched.contact_phones && (await onBlurPhoneNumber(getValues().contact_phones));
      isTouched.contact_emails && (await onBlurEmail(getValues().contact_emails));
      isTouched.linkedin_url && (await onBlurLinkedInUrl(getValues().linkedin_url));
      if (duplicate.contact_phones || duplicate.contact_emails || duplicate.linkedin_url) {
        throw new Error('Duplicate record found');
      }
      return duplicate.contact_phones || duplicate.contact_emails || duplicate.linkedin_url;
    } catch (e) {
      enqueueSnackbar('Duplicate record found');
    }
  };
  const transformAddressForApi = async value => {
    if (!value?.address_lines) return data?.contact_address || [];
    const { countryValue, stateValue, cityValue } = await autoFillBasedOnGeoLocation(value?.address_lines || '');
    let newAddress = [];
    if (data?.contact_address?.length > 0) {
      const existingAddress = data?.contact_address?.filter(item => item?.is_primary !== true);
      newAddress = [
        ...existingAddress,
        {
          id: value?.id,
          contact_id: contactId,
          address_lines: value?.address_lines,
          is_primary: true,
          metropolitan_area: value?.address_lines,
          country_id: countryValue?.id || null,
          state_id: stateValue?.id || null,
          city: cityValue || '',
          address_type: 'work',
          zip_code: ''
        }
      ];
    } else {
      newAddress.push({ ...value, country_id: countryValue?.id || null, state_id: stateValue?.id || null, city: cityValue || '', is_primary: true });
    }
    return newAddress;
  };
  const handleSave = async () => {
    try {
      const values = getValues();
      setLoading(true);
      const body = {};
      isTouched.first_name && (body.first_name = values.first_name);
      isTouched.last_name && (body.last_name = values.last_name);
      isTouched.contact_emails && (body.contact_emails = [...(data?.contact_emails?.filter(item => item.is_primary !== true) || []), values.contact_emails]);
      isTouched.contact_phones && (body.contact_phones = [...(data?.contact_phones?.filter(item => item.is_primary !== true) || []), values.contact_phones]);
      isTouched.contact_address && (body.contact_address = await transformAddressForApi(values.contact_address, data?.contact_address));
      isTouched.contact_company && (body.contact_company = values.contact_company);
      isTouched.contact_tags && (body.contact_tags = values.contact_tags);
      isTouched.linkedin_url && (body.linkedin_url = values.linkedin_url);
      isTouched.current_job_title && (body.current_job_title = values.current_job_title);
      const duplicate = await validate();
      if (duplicate) {
        return;
      }
      const res = await ignContactDataApi(PATCH, contactId, body, '', '');
      enqueueSnackbar(res?.data?.message);
      await updateHeader();
      setLoading(false);
      setOpen(false);
    } catch {
      enqueueSnackbar('Unable to update contact.');
      await updateHeader();
      setLoading(false);
      setOpen(false);
    }
  };
  const handleAssignTag = () => {
    setIsTouched({ ...isTouched, contact_tags: true });
    setIsTagPopupOpen(true);
  };
  const autoFillBasedOnGeoLocation = async value => {
    try {
      let countryValue = '';
      let stateValue = '';
      let cityValue = '';
      const arr = value?.split(',') || [];
      let country = arr[arr?.length - 1] || '';

      if (country) {
        country = country?.trim() || '';
        const countryList = (await getDropdownData('countries')) || [];

        const countryExist = countryList?.data?.find(item => item?.name === country);
        if (countryExist) {
          countryValue = countryExist;
        }
      }
      let state = arr[arr?.length - 2] || '';
      if (state) {
        state = state?.trim() || '';
        const stateList = await getStateData(country);

        const stateExist = stateList?.find(item => item?.name === state);
        if (stateExist) {
          stateValue = stateExist;
        }
      }
      let city = arr[arr?.length - 3] || '';
      if (city) {
        city = city?.trim() || '';
        cityValue = city;
      }

      return { countryValue, stateValue, cityValue };
    } catch (error) {
      console.log(error);
    }
  };
  const getLocation = address => {
    if (address?.find(item => item?.is_primary === true)) {
      return address
        ?.map(item => {
          return {
            ...item,
            exists: true
          };
        })
        ?.find(item => item?.is_primary === true);
    }
    if (address?.length === 0) {
      return {
        metropolitan_area: '',
        is_primary: true,
        country: '',
        city: '',
        state: '',
        postal_code: '',
        address_type: 'work',
        address_line1: '',
        exists: false
      };
    } else {
      return address?.[0];
    }
  };
  const firstSpace = [
    {
      id: 'first_name',
      label: 'First Name',
      type: 'text',
      required: true,
      disabled: false,
      value: watch('first_name'),
      onChange: e => {
        setIsTouched({ ...isTouched, first_name: true });
        setValue('first_name', e.target.value);
      }
    },
    {
      id: 'contact_emails',
      label: 'Email',
      type: 'email',
      required: true,
      disabled: false,
      value: watch('contact_emails')?.email,
      onChange: e => {
        setIsTouched({ ...isTouched, contact_emails: true });
        setValue('contact_emails', { ...watch('contact_emails'), email: e.target.value, is_primary: true });
      }
    },
    // {
    //   id: 'current_job_title',
    //   label: 'Job Title',
    //   type: 'text',
    //   required: false,
    //   disabled: false,
    //   value: watch('current_job_title'),
    //   onChange: e => {
    //     setIsTouched({ ...isTouched, current_job_title: true });
    //     setValue('current_job_title', e.target.value);
    //   }
    // },
    // {
    //   id: 'contact_address',
    //   label: 'Location',
    //   component: (
    //     <Box height={'54px'} margin={'0.75rem 0'} border={'1px solid #E0E0E0'} borderRadius={'5px'}>
    //       <GeoLocationInput
    //         sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '-webkit-appearance': 'menulist-button', height: '50px' }}
    //         className='w-100'
    //         border={'none'}
    //         fullWidth
    //         label={'Location'}
    //         val={watch('contact_address')?.address_lines}
    //         onChange={(event, location) => {
    //           setIsTouched({ ...isTouched, contact_address: true });
    //           setValue('contact_address', {
    //             ...watch('contact_address'),
    //             address_lines: location?.description,
    //             metropolitan_area: location?.description,
    //             is_primary: true
    //           });
    //         }}
    //       />
    //     </Box>
    //   )
    // },
    {
      id: 'linkedin_url',
      label: 'LinkedIn URL',
      type: 'text',
      required: false,
      disabled: false,
      value: watch('linkedin_url'),
      onChange: e => {
        setIsTouched({ ...isTouched, linkedin_url: true });
        setValue('linkedin_url', e.target.value);
      }
    }
  ];
  const secondSpace = [
    {
      id: 'last_name',
      label: 'Last Name',
      type: 'text',
      required: true,
      disabled: false,
      value: watch('last_name'),
      onChange: e => {
        setIsTouched({ ...isTouched, last_name: true });
        setValue('last_name', e.target.value);
      }
    },
    {
      id: 'contact_phones',
      label: 'Phone',
      component: (
        <CustomPhoneInput
          margin={'normal'}
          value={watch('contact_phones')?.phone_number}
          onChange={value => {
            setIsTouched({ ...isTouched, contact_phones: true });
            setValue('contact_phones', {
              ...watch('contact_phones'),
              phone_number: value,
              is_primary: true
            });
          }}
        />
      ),
      type: 'text',
      required: true,
      disabled: false,
      value: watch('contact_phones'),
      onChange: e => {
        setIsTouched({ ...isTouched, contact_phones: true });
        setValue('contact_phones', { ...watch('contact_phones'), phone_number: e.target.value, is_primary: true });
      }
    },
    // {
    //   id: 'contact_company',
    //   label: 'Company',
    //   component: (
    //     <Box height={'54px'} margin={'0.75rem 0'} border={'1px solid #E0E0E0'} borderRadius={'5px'}>
    //       <CompanySelection
    //         variant={'outlined'}
    //         fullWidth
    //         sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '-webkit-appearance': 'menulist-button', height: '50px', width: '100%!important' }}
    //         label={'Company Name'}
    //         defaultValue={watch('contact_company')}
    //         onChange={(e, data) => {
    //           setIsTouched({ ...isTouched, contact_company: true });
    //           setValue('contact_company', data);
    //         }}
    //       />
    //     </Box>
    //   )
    // },
    {
      id: 'contact_tags',
      label: 'Tags',
      type: 'text',
      component: (
        <>
          <TextField
            fullWidth
            margin={'normal'}
            id='contact_tags'
            label='Tags'
            value={watch('contact_tags')
              ?.map(tag => tag?.ign_tags?.name)
              ?.join(', ')}
            onClick={handleAssignTag}
          />
          <AssignTag
            tagPopUp={data?.contact_tags}
            tagId={data?.id}
            setIsPopupOpen={setIsTagPopupOpen}
            isPopupOpen={isTagPopupOpen}
            label={t('utils.selectTag')}
            getHeaderDetails={() => getContact(contactId)}
            setIsEdited={setIsEdited}
          />
        </>
      )
    }
  ];
  return (
    <React.Fragment>
      {/* <MenuItem onClick={handleClickOpen} className='pannel-menu-items d-flex align-items-center'>
        <Box className='pannel-menu-icon mr-1 mb-1'>
          <EditIcon />
        </Box>
        <Typography variant='body2' className='p-2 fs-14'>
          {t('actions.edit')}
        </Typography>
      </MenuItem> */}
      {/* <Button
        variant='text'
        onClick={handleClickOpen}
        sx={{
          padding: '0px',
          margin: '0px',
          minWidth: '0px',
          zIndex: 999000
        }}
      >
        <EditIcon
          color='#000'
          fontSize='inherit'
          sx={{
            padding: '0px',
            margin: '0px'
          }}
        />
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='lg'
        sx={{
          overflow: 'hidden'
        }}
      >
        <DialogTitle id='alert-dialog-title'>Update Contact</DialogTitle>
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            color: 'red',
            fontSize: '12px'
          }}
        >
          {duplicate.contact_phones || duplicate.contact_emails || (duplicate.linkedin_url && 'Duplicate record found')}
        </DialogTitle>
        <Loader show={loading} className={'h-100 w-100'} />
        <DialogContent sx={{ overflow: 'hidden' }}>
          <DialogContentText id='alert-dialog-description'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {firstSpace.map((item, index) => (
                  <div key={index}>
                    {item.component ? (
                      item.component
                    ) : (
                      <TextField
                        fullWidth
                        margin={'normal'}
                        key={index}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        required={item.required}
                        disabled={item.disable}
                        value={item.value}
                        onChange={item.onChange}
                        error={duplicate[item.id]}
                      />
                    )}
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} sm={6}>
                {secondSpace.map((item, index) => (
                  <div key={index}>
                    {item.component ? (
                      item.component
                    ) : (
                      <TextField
                        fullWidth
                        margin={'normal'}
                        key={index}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        required={item.required}
                        disabled={item.disable}
                        value={item.value}
                        onChange={item.onChange}
                        error={duplicate[item.id]}
                      />
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSave} autoFocus variant='contained' disabled={loading}>
            Update {loading && <CircularProgress color={'primary'} className='loader-class' size={'small'} />}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

EditContactPopup.propTypes = {
  contactId: PropTypes.string.isRequired,
  contactData: PropTypes.object.isRequired,
  updateHeader: PropTypes.func.isRequired,
  updateData: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default EditContactPopup;
