import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updatePicklistData(state, action) {
      state[action.payload.key] = action.payload.data;
    },
    updateUserData(state, action) {
      state.userData = action.payload;
    },
    userSecurityData(state, action) {
      state.userSecurityData = action.payload.appSecurity;
      state.isSecurityEnabled = action.payload.isSecurityEnabled;
    },
    setDocumentTypesList(state, action) {
      state.documentTypesList = action.payload;
    },
    setDisallowedFileTypes(state, action) {
      state.disallowedTypes = action.payload;
    },
    updateProjectData(state, action) {
      state.projectData = { ...state.projectData, ...action.payload.data };
    }
  }
});

export const commonActions = commonSlice.actions;

export default commonSlice.reducer;
