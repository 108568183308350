import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomMuiDropdown from '../../common/CustomMuiDropdown';
import CustomPhoneInput from '../../common/CustomPhoneInput';
import RichText from '../../common/RichText';
import GeoLocationWithAutoComplete from '../../GeoLocationWithAutoComplete/GeoLocationWithAutoComplete';
function FormGridComponent(props) {
  const { type, ...customProps } = props;

  switch (type) {
    case 'text':
      return (
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          InputLabelProps={{
            shrink: customProps?.value || customProps?.value === 0,
            ...customProps?.InputLabelProps
          }}
          {...customProps}
        />
      );
    case 'dropdown':
      return <CustomMuiDropdown fullWidth size='small' {...customProps} />;
    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
          <DatePicker slotProps={{ textField: { size: 'small', fullWidth: true } }} {...customProps} />
        </LocalizationProvider>
      );
    case 'checkbox':
      return (
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={customProps?.value} onChange={customProps?.onChange} />} label={customProps.label} {...customProps} />
        </FormGroup>
      );
    case 'richText':
      return (
        <div className='create-contact-hilighlight'>
          <Typography fontSize={'12px'} fontWeight={'600'} pb={1}>
            {customProps?.label || ''}
          </Typography>
          <RichText size='small' fullWidth {...customProps} />
        </div>
      );
    case 'phone':
      return <CustomPhoneInput {...customProps} />;
    case 'empty':
      return <Box sx={{ height: '100%', width: '100%' }} />;
    case 'geoLocation':
      return <GeoLocationWithAutoComplete {...customProps} />;
    case 'custom':
      return customProps?.customComponent(props);
    default:
      return null;
  }
}
function FormGrid(props) {
  const { columns = [], xs = 12, globalLoading = false, ...customProps } = props;
  const { t } = useTranslation();
  return (
    <Grid container xs={xs} position={'relative'} {...customProps}>
      {columns.map(column => (
        <Grid item xs={column.xs} key={column.id}>
          {column?.loading || globalLoading ? (
            <Skeleton variant='rounded' width={'100%'} height={'40px'} {...column} />
          ) : (
            <Tooltip title={t(column?.tooltipTitle || '')} placement='top'>
              <div style={{ width: '100%', height: '100%', ...column?.style }}>
                <FormGridComponent type={column.type} fullWidth {...column} label={t(column?.label || '')} placeholder={t(column?.placeholder || '')} />
              </div>
            </Tooltip>
          )}
        </Grid>
      ))}
    </Grid>
  );
}

FormGrid.propTypes = {
  columns: PropTypes.array.isRequired,

  ...FormGridComponent.propTypes,
  ...Grid.propTypes
};

FormGridComponent.propTypes = {
  type: PropTypes.string.isRequired,
  xs: PropTypes.number,
  globalLoading: PropTypes.bool,
  ...TextField.propTypes,
  ...CustomMuiDropdown.propTypes,
  ...DatePicker.propTypes
};

export { FormGrid, FormGridComponent };
