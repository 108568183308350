import { isEmpty, round } from 'lodash';

const decimalValueFormatter = ({ value }) => {
  if (!isEmpty(value) && value.value) {
    return round(value.value, 1);
  }
  return value;
};
export const interviewGridColumn = [
  {
    field: 'project.job_number',
    headerName: 'Project ID'
  },
  {
    field: 'project.job_title',
    headerName: 'Job Title',
    flex: 2
  },
  {
    field: 'company',
    headerName: 'Company',

    flex: 1
  },
  {
    field: 'Candidate_name',
    headerName: 'Candidate Name',

    flex: 1
  },
  {
    field: 'Partners',
    headerName: 'Partners Name',

    flex: 1
  },
  {
    field: 'Time_scheduled',
    headerName: 'Time Scheduled',

    flex: 1
  },
  {
    field: 'Date_scheduled',
    headerName: 'Date Scheduled',

    flex: 2
  }
];

const homePageDefaultColDef = {
  maxWidth: 450,
  resizable: true,
  enableValue: true,
  enableRowGroup: true,
  sortable: true,
  visible: true,
  filter: 'agMultiColumnFilter',
  menuTabs: ['filterMenuTab'],
  headerComponentParams: {
    menuIcon: 'fa-bars'
  }
};
export const generateProjectGridColumns = t => {
  return [
    {
      ...homePageDefaultColDef,
      field: 'job_number',
      headerName: t('grid.projectId'),
      minWidth: 100,
      flex: 1,

      cellRenderer: params => {
        return params.value;
      }
    },
    {
      ...homePageDefaultColDef,
      field: 'job_title',
      headerName: 'Job Title',
      minWidth: 200,
      flex: 2
    },
    {
      ...homePageDefaultColDef,
      field: 'ign_companies.name',
      headerName: 'Company',
      minWidth: 150,
      flex: 1
    },
    {
      ...homePageDefaultColDef,
      field: 'stage',
      headerName: 'Stage',
      minWidth: 90,
      flex: 1
    },
    {
      ...homePageDefaultColDef,
      field: 'location',
      headerName: 'Location',
      minWidth: 150,
      flex: 2
    },
    {
      ...homePageDefaultColDef,
      field: 'creator.name',
      headerName: 'Added By',
      minWidth: 100,
      flex: 1
    },
    {
      ...homePageDefaultColDef,
      field: 'candidate_count',
      headerName: 'Candidate Count',
      minWidth: 150,
      hide: false,
      flex: 2,

      valueGetter: params => Number(params.data?.candidate_count) || '',
      valueFormatter: decimalValueFormatter
    },
    {
      ...homePageDefaultColDef,
      field: 'created_at',
      headerName: 'Days Open',
      minWidth: 90,
      flex: 1,

      aggFunc: 'avg',
      cellRenderer: params => {
        return Number(params?.value);
      },
      valueGetter: ({ data }) => {
        return Math.floor((new Date() - new Date(data?.created_at)) / (1000 * 60 * 60 * 24)) || '';
      },
      valueFormatter: decimalValueFormatter
    },
    {
      ...homePageDefaultColDef,
      field: 'industries',
      headerName: 'Industry',
      minWidth: 150,
      hide: false,
      flex: 2,

      valueGetter: ({ data }) => {
        const industryString = data?.industries ? data.industries.join(', ') : '';
        return industryString;
      }
    },
    {
      ...homePageDefaultColDef,
      field: 'service_offering',
      headerName: 'Service Offering',
      minWidth: 150,
      hide: false,
      flex: 2,

      valueGetter: ({ data }) => {
        return data?.service_offering_value?.short_desc;
      }
    },

    {
      ...homePageDefaultColDef,
      field: 'probability',
      headerName: 'Probability',
      minWidth: 150,
      hide: false,
      flex: 2
    },
    {
      ...homePageDefaultColDef,
      field: 'min_experience',
      headerName: 'Min Experience',
      minWidth: 200,
      hide: false,
      flex: 2,

      valueFormatter: decimalValueFormatter
    },
    {
      ...homePageDefaultColDef,
      field: 'max_experience',
      headerName: 'Max Experience',
      minWidth: 200,
      hide: false,
      flex: 2,

      valueFormatter: decimalValueFormatter
    },
    {
      ...homePageDefaultColDef,
      field: 'min_compensation',
      headerName: 'Min Compensation',
      minWidth: 200,
      hide: false,
      flex: 2,

      valueGetter: ({ data }) => Number(data?.min_compensation) || '',
      valueFormatter: decimalValueFormatter
    },
    {
      ...homePageDefaultColDef,
      field: 'max_compensation',
      headerName: 'Max Compensation',
      minWidth: 200,
      hide: false,
      flex: 2,

      valueGetter: ({ data }) => Number(data?.max_compensation) || '',
      valueFormatter: decimalValueFormatter
    }
  ];
};

export const getAllProjectApiQuery = {
  // also using in engagement tab, company module.
  query: {
    limit: 100,
    order: [['job_number', 'DESC']]
  },
  countForStages: [],
  type: 'my-projects'
};
