import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentTypeListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const DocumentTypeSelection = props => {
  const { placeholder, isForCompany = false, onChange = () => {}, label = '', value = '', required = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const documentTypesList = useSelector(state => state.commonReducer.documentTypesList);
  const dispatch = useDispatch();
  //key will replaced by document type key
  useEffect(() => {
    if (!documentTypesList) {
      dispatch(documentTypeListData());
    } else {
      if (isForCompany) {
        const updatedOptions = documentTypesList.map(option => ({
          ...option,
          disabled: option.doctype_desc === value
        }));
        setOptions(updatedOptions?.filter(item => item?.used_in?.includes('Contacts')));
      } else {
        setOptions(documentTypesList?.filter(item => item?.used_in?.includes('Contacts')));
      }
    }
  }, [documentTypesList, dispatch, value]);
  return (
    <>
      <CustomDropdown {...rest} required={required} options={options} placeholder={placeholder} onChange={onChange} label={label} defaultValue={value} disabled={isForCompany} />
    </>
  );
};
DocumentTypeSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  isForCompany: PropTypes.bool
};
export default DocumentTypeSelection;
