import { Box, List, ListItem } from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addFromLinkedinApi, genericQuickSearchApi } from '../../services/ApiService';
import ResumeParser from '../common/ResumeParser';
import AlertDialog from './AlertDialog';
import AddContactDrawerAccordion from './component/AddContactDrawerAccordion';
import AddContactDrawerHeader from './component/AddContactDrawerHeader';
import AddContactDrawerSectionEight from './component/AddContactDrawerJobFunctionSectionEight';
import AddContactDrawerSectionEleven from './component/AddContactDrawerHighlightsSectionEleven';
import AddContactDrawerSectionFive from './component/AddContactDrawerCertificationSectionFive';
import AddContactDrawerSectionFour from './component/AddContactDrawerEducationSectionFour';
import AddContactDrawerSectionNine from './component/AddContactDrawerIndustrySectionNine';
import AddContactDrawerSectionOne from './component/AddContactDrawerPersonalInfoSectionOne';
import AddContactDrawerSectionSeven from './component/AddContactDrawerLanguageSectionSeven';
import AddContactDrawerSectionSix from './component/AddContactDrawerCommunicationSectionSix';
import AddContactDrawerSectionTen from './component/AddContactDrawerRelationShipSectionTen';
import AddContactDrawerSectionThree from './component/AddContactDrawerProfessionalExperienceSectionThree';
import AddContactDrawerSectionTwelve from './component/AddContactDrawerDemographicsSectionTwelve';
import AddContactDrawerSectionTwo from './component/AddContactDrawerAssignmentSectionTwo';
import AddContactDrawerSubHeader from './component/AddContactDrawerSubHeader';
import EducationForEdit from './component/EducationForEdit';
import ProfessionalExperienceForEdit from './component/ProfesstionalExperienceForEdit';

const ContactDrawerUI = forwardRef(
  (
    {
      contactData,
      recordTypeDropdownOptions,
      projectList,
      projectStageList,
      countryList,
      stateList,
      companyNameList,
      levelOfEducationList,
      certificationTypeList,
      phoneTypeList,
      emailTypeList,
      addressTypeList,
      languageList,
      levelOfProficiencyList,
      jobFunctionList,
      industryList,
      nationalityList,
      getDropdownData,
      RelationTypeList,
      loadingDropdownData,
      saveContact,
      toggleDrawer,
      CustomHeader,
      includeHeader,
      includeSubHeader,
      customHeaderHeight = 0,
      sectionVisibility = {
        sectionOne: true,
        sectionTwo: true,
        sectionThree: true,
        sectionFour: true,
        sectionFive: true,
        sectionSix: true,
        sectionSeven: true,
        sectionEight: true,
        sectionNine: true,
        sectionTen: true,
        sectionEleven: true,
        sectionTwelve: true
      },
      duplicateContact,
      setDuplicateContact,
      type = 'create',
      defaultValues = {},
      globalLoading = false,
      callback = () => {},
      updateContactBySection,
      deleteSectionApi,
      isTabChange = false
    },
    ref
  ) => {
    const [expand, setExpand] = useState({
      sectionOne: true,
      sectionTwo: true,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true,
      sectionNine: true,
      sectionTen: true,
      sectionEleven: true,
      sectionTwelve: true
    });
    const [showUnSavedChangesWarning, setShowUnSavedChangesWarning] = useState(false);
    const [newProfessionalExperienceOpen, setNewProfessionalExperienceOpen] = useState(!type === 'edit');
    const [newEducationOpen, setNewEducationOpen] = useState(!type === 'edit');
    const [newCertificateAndLicensesOpen, setNewCertificateAndLicensesOpen] = useState(!type === 'edit');
    const [newRelationshipOpen, setNewRelationshipOpen] = useState(!type === 'edit');
    const [newLanguageOpen, setNewLanguageOpen] = useState(!type === 'edit');
    const [openCreateContactFromResume, setOpenCreateContactFromResume] = useState(false);
    const [linkedinURL, setLinkedinURL] = useState('');
    // const [showUnSavedChangesWarning, setShowUnSavedChangesWarning] = useState(false);
    // const [showLinkedInMismatchAlert, setShowLinkedInMismatchAlert] = useState(false);

    const { t } = useTranslation();
    const {
      register,
      unregister,
      handleSubmit,
      setValue,
      getValues,
      watch,

      formState: { errors }
    } = useForm();

    const defaultTouchedValues = {
      linkedin_url: false,
      record_type: false,
      contact_job_functions: false,
      contact_industries: false,
      highlights: false,
      birth_date: false,
      gender: false,
      nationality: false,
      additional_last_name: false,
      pronouns: false,
      external_id: false
    };
    useEffect(() => {
      register('first_name', { required: true });
      register('last_name', { required: true });
      register('avatar', { required: false });
      register('email', { required: true });
      register('phone', { required: true });
      register('linkedin_url', { required: false });
      register('linkedin_username', { required: false });
      register('record_type', { required: false });
      register('record_source', { required: false });
      register('project_name', { required: false });
      register('project_stage', { required: false });
      register('contact_work_experience', { required: false });
      register('education', { required: false });
      register('certifications_and_licenses', { required: false }); // need to separate before saving - contact_certificate
      register('contact_languages', { required: false });
      register('contact_phones', { required: false });
      register('contact_emails', { required: false });
      register('contact_address', { required: false }); // state_id country_id
      register('contact_job_functions', { required: false });
      register('contact_industries', { required: false });
      register('contact_relationship_type', { required: false });
      register('highlights', { required: false });
      register('birth_date', { required: false });
      register('gender', { required: false });
      register('nationality', { required: false });
      register('additional_last_name', { required: false });
      register('pronouns', { required: false });
      register('external_id', { required: false });
      register('is_confidential', { required: false });
      register('linkedin_data', { required: false });
      register('linkedin_avatar', { required: false });
      register('is_touched', {
        required: false,
        value: defaultTouchedValues
      });
    }, []);
    const getStateData = async countryName => {
      if (!countryName) return;
      const res = await getDropdownData(`/countries/states?countryName=${countryName}`);
      return res || [];
    };
    const firstLetterUpperCase = str => {
      if (!str) return '';
      const a = str?.charAt(0)?.toUpperCase() + str?.slice(1);
      if (!a) return '';
      return a;
    };

    const createBlobFromUrl = async url => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      } catch (error) {
        console.error('Error fetching image:', error);
        return null;
      }
    };
    const addFromLinkedin = async e => {
      try {
        let data = {
          linkedin_url: e
        };
        let response = await addFromLinkedinApi(data);

        if (response?.status === 400) {
          setDuplicateContact({
            isDuplicate: true,
            string: response?.message,
            data: response?.data
          });
          return;
        }
        if (response?.message && response?.status !== 200) {
          // enqueueSnackbar(`Error getting profile data: ${response?.message || ''}`, { variant: 'error' });
          enqueueSnackbar(t('contacts.addContact.wrongLinkedInUrlMessage'), { variant: 'info' });
          return;
        }
        const res = response?.data;
        setValue('linkedin_data', res?.source_data || '');
        setValue('linkedin_url', res?.linkedIn_url || '');
        setValue('record_source', res?.source || '');
        // setValue('linkedin_username', res?.['linkedIn_url']?.split('/')[2] || '');
        const name = res?.data?.['Candidate Name']?.split(' ') || '';
        setValue('first_name', name?.slice(0, -1)?.join(' ') || '');
        setValue('last_name', name?.[name?.length - 1] || '');
        setValue('highlights', `${res?.data?.['Title'] || res?.data?.['Summary']}`);
        if (res?.data?.['ImageUrl']) {
          setValue('linkedin_avatar', await createBlobFromUrl(res?.data?.['ImageUrl'] || ''));
          setValue('avatar', '');
        }
        const findEducation = res?.data?.['Education']?.map((education, index) => ({
          id: index + 1,
          degree_name: firstLetterUpperCase(education?.major),
          school_name: firstLetterUpperCase(education?.institute),
          country: '',
          start_date: education?.start_year ? dayjs.utc(education?.start_year) : null,
          end_date: education?.end_year ? dayjs.utc(education?.end_year) : null,
          // start_date: dayjs(education?.start_year).isValid() ? reverseDayjsTimeZoneChange(education?.start_year) : null,
          // end_date: dayjs(education?.end_year).isValid() ? reverseDayjsTimeZoneChange(education?.end_year) : null,
          is_present: dayjs(education?.end_year).isAfter(dayjs()) ? true : false,
          education_level: education
        }));
        setValue('education', findEducation);

        const findCertification = res?.source_data?.['certifications']?.map((certification, index) => ({
          id: index + 1,
          type: certificationTypeList?.find(i => 'certificate' === i?.id?.toLowerCase()) ||
            certificationTypeList?.find(i => 'Certificate' === i?.name?.toLowerCase()) || {
              id: 'Certificate',
              name: 'Certificate',
              new: true
            },
          title: certification?.title,
          expires: dayjs(certification?.date_to).isValid() ? dayjs.utc(certification?.date_to) : null
        }));
        setValue('certifications_and_licenses', findCertification);
        setNewCertificateAndLicensesOpen(false);
        const findCompany = async companyName => {
          const company = await genericQuickSearchApi({
            modelName: 'ign_company',
            size: 1,
            from: 0,
            and: [
              {
                'name.keyword': companyName
              }
            ],
            or: [],
            fields: ['id', 'name']
          });

          if (company?.data?.length > 0) {
            return {
              id: company?.data[0]?.id || '',
              name: company?.data[0]?.name || '',
              exists: true
            };
          }
          return {
            id: null,
            name: companyName || '',
            exists: false
          };
        };
        const findExperience = await Promise.all(
          res?.data?.['Experience']?.map(async (experience, index) => ({
            description: experience?.summary,
            id: index + 1,
            title: firstLetterUpperCase(experience?.title),
            company: await findCompany(experience?.company),
            country: countryList.find(country => country?.name === experience?.GeoLocation?.country) || {
              id: null,
              name: experience?.GeoLocation?.country || '',
              new: true
            },
            state: getStateData(experience?.GeoLocation?.state)?.[0] || {
              id: null,
              name: experience?.GeoLocation?.state || '',
              new: true
            },
            city: experience?.GeoLocation?.city || '',
            start_date: dayjs(experience?.date_from).isValid() ? dayjs.utc(experience?.date_from) : null,
            end_date: dayjs(experience?.date_to).isValid() ? dayjs.utc(experience?.date_to) : null,
            is_present: dayjs(experience?.date_to).isAfter(dayjs()) ? true : false,
            board_start_date: null,
            board_end_date: null,
            is_board_member: false,
            board_is_present: false,
            board_committee: null
          }))
        );

        setValue('contact_work_experience', findExperience);
        setNewProfessionalExperienceOpen(false);
        const languageProficiency = {
          'Full professional proficiency': 'Fluent',
          'Native or bilingual proficiency': 'Native',
          other: 'Intermediate',
          Elementary: 'Basic'
        };
        const findLanguage = res?.source_data?.['languages']?.map((contact_languages, index) => ({
          id: index + 1,
          contact_languages: languageList.find(item => item.name === contact_languages?.language) || {
            id: null,
            name: contact_languages?.language || '',
            new: true
          },
          level_of_expertise: {
            name: languageProficiency[contact_languages?.proficiency] || 'Intermediate',
            id: languageProficiency[contact_languages?.proficiency]?.toLowerCase() || 'intermediate'
          }
        }));
        setValue('contact_languages', findLanguage);
        const contactAddress = [
          {
            id: 1,
            address_type: addressTypeList?.[0] || {
              id: 'home',
              name: 'Home',
              new: true
            },
            address_line: '',
            city: res?.data?.['GeoLocation']?.city || '',
            country: countryList.find(country => country?.name === res?.data?.['GeoLocation']?.country) || {
              id: null,
              name: res?.data?.['GeoLocation']?.country || '',
              new: true
            },
            state: getStateData(res?.data?.['GeoLocation']?.state)?.[0] || {
              id: null,
              name: res?.data?.['GeoLocation']?.state || '',
              new: true
            },
            zip_code: res?.data?.['GeoLocation']?.zip_code || '',

            is_primary: true
          }
        ];

        setValue('contact_address', contactAddress);
        console.error('education', findEducation, res?.source_data, res?.data);
        // ---------------------------------------------------------------------

        // ---------------------------------------------------------------------
        // const createBlobFromUrl = async url => {
        //   const response = await fetch(url, {});
        //   const blob = await response.blob();
        //   return new File([blob], 'avatar.jpg', { type: 'image/jpeg' });
        // };
        // setValue('avatar', (await createBlobFromUrl(res?.data?.data?.ImageUrl || res?.source_data?.['picture_url'])) || '');
      } catch (error) {
        enqueueSnackbar(`Error getting profile data: ${error?.message || ''}`, { variant: 'error' });
      }
    };
    const expandAll = () => {
      setExpand({
        ...expand,
        sectionOne: true,
        sectionTwo: true,
        sectionThree: true,
        sectionFour: true,
        sectionFive: true,
        sectionSix: true,
        sectionSeven: true,
        sectionEight: true,
        sectionNine: true,
        sectionTen: true,
        sectionEleven: true,
        sectionTwelve: true
      });
    };
    const collapseAll = () => {
      setExpand({
        ...expand,
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
        sectionFour: false,
        sectionFive: false,
        sectionSix: false,
        sectionSeven: false,
        sectionEight: false,
        sectionNine: false,
        sectionTen: false,
        sectionEleven: false,
        sectionTwelve: false
      });
    };
    const validateCheck = () => {
      if (type === 'create') return true;
      const values = getValues();
      // const validLinkedInUrl = checkLinkedInUrlMatch();
      // const validRecordType = checkRecordTypeMatch();
      // const validJobFunction = contactJobFunction();
      // const validContactIndustry = checkContactIndustry();
      // const validcheckBirthDate = checkBirthDate();
      // const validcheckGender = checkGender();
      // const validcheckNationality = checkNationality();
      // const validcheckAdditionalLastName = checkAdditionalLastName();
      // const validcheckPronouns = checkPronouns();
      // const validcheckExternalId = checkExternalId();
      // const validcheckHighlight = checkHighlight();
      const isTouched = Object.values(values?.is_touched).some(item => item === true);

      const checkWorkExperience = values?.contact_work_experience?.some(item => item?.edit);
      const checkEducation = values?.education?.some(item => item?.edit);
      const checkCertificate = values?.certifications_and_licenses?.some(item => item?.edit);
      const checkLanguage = values?.contact_languages?.some(item => item?.edit);
      const checkPhone = values?.contact_phones?.some(item => item?.edit);
      const checkEmail = values?.contact_emails?.some(item => item?.edit);
      const checkAddress = values?.contact_address?.some(item => item?.edit);
      const checkRelationship = values?.contact_relationship_type?.some(item => item?.edit);
      let isValid = !(
        (checkWorkExperience || checkEducation || checkCertificate || checkLanguage || checkPhone || checkEmail || checkAddress || checkRelationship || isTouched)
        // validLinkedInUrl ||
        // validRecordType ||
        // validJobFunction ||
        // validContactIndustry ||
        // validcheckBirthDate ||
        // validcheckGender ||
        // validcheckNationality ||
        // validcheckAdditionalLastName ||
        // validcheckPronouns ||
        // validcheckExternalId ||
        // validcheckHighlight
      );
      if (!isValid) {
        setShowUnSavedChangesWarning(true);
      } else {
        setShowUnSavedChangesWarning(false);
      }
      return isValid;
    };
    useImperativeHandle(ref, () => ({
      validateCheck,
      onSave
    }));
    const saveAPI = async () => {
      try {
        console.error('getValues()', getValues());
        const values = getValues();
        await saveContact(values).then(response => {
          console.error('response', response);
          const val = getValues();
          console.error('val', val);
          if (response) {
            setLinkedinURL('');

            resetForm();
          }
          throw new Error('Error saving contact');
        });
      } catch (error) {
        console.error('error', error);
      }
    };
    const onSave = async () => {
      try {
        await saveAPI();
        setValue('is_touched', defaultTouchedValues);
        toggleDrawer(false);
        callback?.();
      } catch (error) {
        console.error('error', error);
      }
    };
    const resetForm = () => {
      setValue('first_name', '');
      setValue('last_name', '');
      setValue('avatar', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('linkedin_url', '');
      setValue('linkedin_username', '');
      setValue('record_type', []);
      setValue('record_source', '');
      setValue('project_name', null);
      setValue('project_stage', null);
      setValue('contact_work_experience', []);
      setValue('education', []);
      setValue('certifications_and_licenses', []); // need to separate before saving - contact_certificate
      setValue('contact_languages', []);
      setValue('contact_phones', []);
      setValue('contact_emails', []);
      setValue('contact_industries', []); // state_id country_id
      setValue('contact_job_functions', []);
      setValue('contact_industries', []);
      setValue('contact_relationship_type', []);
      setValue('highlights', '');
      setValue('birth_date', null);
      setValue('gender', '');
      setValue('nationality', '');
      setValue('additional_last_name', '');
      setValue('pronouns', '');
      setValue('external_id', '');
      setValue('is_touched', defaultTouchedValues);
    };
    const onSaveAndAddAnother = async () => {
      try {
        await saveAPI();
        setValue('is_touched', defaultTouchedValues);
        toggleDrawer(true);
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleResumeParseClose = () => {
      setOpenCreateContactFromResume(false);
    };
    const setEditToFalse = section => {
      const list = watch(section) || [];
      const newList = list.map(item => ({ ...item, edit: false }));
      setValue(section, newList);
      return newList;
    };
    const handleFirstSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('personal_information', data);
        if (res?.status === 200) {
          setEditToFalse('personal_information');
          setValue('is_touched', {
            ...watch('is_touched'),
            linkedin_url: false,
            record_type: false
          });
          enqueueSnackbar(t('addContactDrawer.personalInformationSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorPersonalInformation'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    // const handleThirdSectionSave = async () => {
    //   try {
    //     const data = getValues();
    //     await updateContactBySection?.('contact_work_experience_all', data);
    //     enqueueSnackbar('Contact education details saved', { variant: 'success' });
    //   } catch (error) {
    //     console.error('error', error);
    //   }
    // };
    // const handleForthSectionSave = async () => {
    //   try {
    //     const data = getValues();
    //     await updateContactBySection?.('contact_education_details_all', data);
    //     enqueueSnackbar('Contact education details saved', { variant: 'success' });
    //   } catch (error) {
    //     console.error('error', error);
    //   }
    // };

    const handleFifthSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('certifications_and_licenses', data);
        if (res?.status === 200) {
          setEditToFalse('certifications_and_licenses');
          enqueueSnackbar(t('addContactDrawer.certificationsAndLicensesSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorCertificationsAndLicenses'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handlePhoneSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('contact_phones', data);
        if (res?.status === 200) {
          setEditToFalse('contact_phones');
          enqueueSnackbar(t('addContactDrawer.contactPhoneSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorContactPhone'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleEmailSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('contact_emails', data);
        if (res?.status === 200) {
          setEditToFalse('contact_emails');
          enqueueSnackbar(t('addContactDrawer.contactEmailSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorContactEmail'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleAddressSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('contact_address', data);
        if (res?.status === 200) {
          setEditToFalse('contact_address');
          enqueueSnackbar(t('addContactDrawer.contactAddressSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorContactAddress'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleSeventhSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('contact_languages', data);
        if (res?.status === 200) {
          setEditToFalse('contact_languages');
          enqueueSnackbar(t('addContactDrawer.contactLanguageSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorContactLanguage'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleEighthSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('job_function', data);
        if (res?.status === 200) {
          setEditToFalse('job_function');
          setValue('is_touched', {
            ...watch('is_touched'),
            contact_job_functions: false
          });
          enqueueSnackbar(t('addContactDrawer.jobFunctionSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorJobFunction'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleNinthSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('industry', data);
        if (res?.status === 200) {
          setEditToFalse('industry');
          setValue('is_touched', {
            ...watch('is_touched'),
            contact_industries: false
          });
          enqueueSnackbar(t('addContactDrawer.industrySaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorIndustry'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleTenthSectionSave = async () => {
      try {
        if (!type === 'edit') return;
        const data = getValues();
        const res = await updateContactBySection?.('contact_relationship', data);
        if (res?.status === 200) {
          setEditToFalse('contact_relationship');
          enqueueSnackbar(t('addContactDrawer.relationshipSaved'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('addContactDrawer.errorContactRelationship'), { variant: 'error' });
        }
        return res;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleEleventhSectionSave = async () => {
      try {
        if (type === 'edit') {
          const data = getValues();
          const res = await updateContactBySection?.('highlights', data);
          if (res?.status === 200) {
            setEditToFalse('highlights');
            setValue('is_touched', {
              ...watch('is_touched'),
              highlights: false
            });
            enqueueSnackbar(t('addContactDrawer.highlightsSaved'), { variant: 'success' });
          } else {
            enqueueSnackbar(t('addContactDrawer.errorHighlights'), { variant: 'error' });
          }
          return res;
        }
        return;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleTwelfthSectionSave = async () => {
      try {
        if (type === 'edit') {
          const data = getValues();
          const res = await updateContactBySection?.('other_demographics', data);
          if (res?.status === 200) {
            setEditToFalse('other_demographics');
            setValue('is_touched', {
              ...watch('is_touched'),
              birth_date: false,
              gender: false,
              nationality: false,
              additional_last_name: false,
              pronouns: false,
              external_id: false
            });
            enqueueSnackbar(t('addContactDrawer.otherDemographicsSaved'), { variant: 'success' });
          } else {
            enqueueSnackbar(t('addContactDrawer.errorOtherDemographics'), { variant: 'error' });
          }
          return res;
        }
        return;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleUpdateExperience = async data => {
      try {
        if (type === 'edit') {
          const res = await updateContactBySection?.('contact_work_experience', data);
          enqueueSnackbar(t('addContactDrawer.experienceSaved'), { variant: 'success' });
          return res;
        }
        return data;
      } catch (error) {
        console.error('error', error);
      }
    };
    const deleteExperience = async id => {
      try {
        if (type === 'edit') {
          const res = await deleteSectionApi?.('contact_work_experience', id);
          enqueueSnackbar(t('addContactDrawer.experienceDeleted'), { variant: 'success' });
          return res;
        }
        return id;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleUpdateEducation = async data => {
      try {
        if (type === 'edit') {
          const res = await updateContactBySection?.('contact_education_details', data);
          enqueueSnackbar(t('addContactDrawer.educationSaved'), { variant: 'success' });
          return res;
        }
        return data;
      } catch (error) {
        console.error('error', error);
      }
    };
    const handleDeleteEducation = async id => {
      try {
        if (type === 'edit') {
          const res = await deleteSectionApi?.('contact_education_details', id);
          enqueueSnackbar(t('addContactDrawer.educationDeleted'), { variant: 'success' });
          return res;
        }
        return id;
      } catch (error) {
        console.error('error', error);
      }
    };
    const addNewColumnBySection = section => {
      switch (section) {
        case 'contact_work_experience':
          {
            const list = watch('contact_work_experience') || [];
            const newList = [
              ...list,
              {
                id: list?.length + 1 || 0,
                title: '',
                company: '',
                city: '',
                state: null,
                country: null,
                end_date: null,
                start_date: null,
                is_present: false,
                is_board_member: false,
                board_end_date: null,
                board_start_date: null,
                edit: true,
                board_committee: null,
                description: '',
                new: true
              }
            ];
            setValue('contact_work_experience', newList);
          }
          break;
        case 'education':
          {
            const list = watch('education') || [];
            const newList = [
              ...list,
              {
                id: list?.length + 1 || 100,
                degree_name: '',
                school_name: '',
                country: null,
                state: null,
                end_date: null,
                start_date: null,
                is_present: false,
                new: true,
                edit: true
              }
            ];
            setValue('education', newList);
          }
          break;
        case 'certifications_and_licenses':
          {
            const list = watch('certifications_and_licenses') || [];
            setValue('certifications_and_licenses', [
              ...list,
              {
                id: list?.length + 1 || 0,
                type: null,
                title: null,
                expires: dayjs(),
                edit: true,
                new: true
              }
            ]);
          }
          break;
        case 'contact_address':
          {
            const list = watch('contact_address') || [];
            setValue('contact_address', [
              ...list,
              {
                id: list?.length + 1 || 0,
                address_type: null,
                address_lines: null,

                is_primary: false,
                edit: true
              }
            ]);
          }
          break;
        case 'contact_emails':
          {
            const list = watch('contact_emails') || [];
            setValue('contact_emails', [
              ...list,
              {
                id: list?.length + 1 || 0,
                email_type: null,
                email: null,
                is_primary: false,
                edit: true
              }
            ]);
          }
          break;
        case 'contact_phones':
          {
            const list = watch('contact_phones') || [];
            setValue('contact_phones', [
              ...list,
              {
                id: list?.length + 1 || 0,
                phone_type: null,
                phone_number: null,
                is_primary: false,
                edit: true
              }
            ]);
          }
          break;
        case 'contact_languages':
          {
            const list = watch('contact_languages') || [];
            setValue('contact_languages', [
              ...list,
              {
                id: list?.length + 1 || 0,
                contact_languages: null,
                level_of_expertise: null,
                edit: true,
                new: true
              }
            ]);
          }
          break;
        case 'contact_relationship_type':
          {
            const list = watch('contact_relationship_type') || [];
            setValue('contact_relationship_type', [
              ...list,
              {
                id: list?.length + 1 || 0,
                type: null,
                title: null,
                expires: dayjs(),
                edit: true,
                new: true
              }
            ]);
          }
          break;
        default:
          break;
      }
    };
    const sections = [
      {
        title: t('addContactDrawer.sectionOne'),
        id: 'sectionOne',
        expand: expand.sectionOne,
        visible: sectionVisibility?.sectionOne,
        setExpand: () => setExpand({ ...expand, sectionOne: !expand.sectionOne }),
        children: props => <AddContactDrawerSectionOne {...props} />,
        onSave: type === 'edit' ? handleFirstSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionTwo'),
        id: 'sectionTwo',
        expand: expand.sectionTwo,
        visible: sectionVisibility?.sectionTwo,
        setExpand: () => setExpand({ ...expand, sectionTwo: !expand.sectionTwo }),
        children: props => <AddContactDrawerSectionTwo {...props} />
      },
      {
        title: t('addContactDrawer.sectionThree'),
        id: 'sectionThree',
        addTitle: expand.sectionThree ? t('addContactDrawer.add') : null,
        onClickAdd: () => {
          addNewColumnBySection('contact_work_experience');
          setExpand({ ...expand, sectionThree: true });
        },
        expand: expand.sectionThree,
        visible: sectionVisibility?.sectionThree,

        setExpand: () => setExpand({ ...expand, sectionThree: !expand.sectionThree }),
        children: props => (type === 'edit' ? <ProfessionalExperienceForEdit {...props} /> : <AddContactDrawerSectionThree {...props} />),

        onSaveTitle: t('addContactDrawer.saveAll')
      },
      {
        title: t('addContactDrawer.sectionFour'),
        id: 'sectionFour',
        expand: expand.sectionFour,
        addTitle: expand.sectionFour ? t('addContactDrawer.add') : null,
        onClickAdd: () => {
          addNewColumnBySection('education');
          setExpand({ ...expand, sectionFour: true });
        },
        visible: sectionVisibility?.sectionFour,
        setExpand: () => setExpand({ ...expand, sectionFour: !expand.sectionFour }),
        children: props => (type === 'edit' ? <EducationForEdit {...props} /> : <AddContactDrawerSectionFour {...props} />),

        onSaveTitle: t('addContactDrawer.saveAll')
      },
      {
        title: t('addContactDrawer.sectionFive'),
        id: 'sectionFive',
        expand: expand.sectionFive,
        addTitle: expand.sectionFive ? t('addContactDrawer.add') : null,
        onClickAdd: () => {
          addNewColumnBySection('certifications_and_licenses');
          setExpand({ ...expand, sectionFive: true });
        },
        setExpand: () => setExpand({ ...expand, sectionFive: !expand.sectionFive }),
        visible: sectionVisibility?.sectionFive,
        children: props => <AddContactDrawerSectionFive {...props} />,
        onSave: type === 'edit' ? handleFifthSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionSix'),
        id: 'sectionSix',
        expand: expand.sectionSix,
        setExpand: () => setExpand({ ...expand, sectionSix: !expand.sectionSix }),
        visible: sectionVisibility?.sectionSix,
        children: props => <AddContactDrawerSectionSix {...props} />
      },
      {
        title: t('addContactDrawer.sectionSeven'),
        id: 'sectionSeven',
        expand: expand.sectionSeven,
        addTitle: !newLanguageOpen && expand.sectionSeven ? t('addContactDrawer.add') : null,
        onClickAdd: () => {
          addNewColumnBySection('contact_languages');
          setExpand({ ...expand, sectionSeven: true });
        },
        setExpand: () => setExpand({ ...expand, sectionSeven: !expand.sectionSeven }),
        children: props => <AddContactDrawerSectionSeven {...props} />,
        visible: sectionVisibility?.sectionSeven,
        onSave: type === 'edit' ? handleSeventhSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionEight'),
        id: 'sectionEight',
        expand: expand.sectionEight,
        setExpand: () => setExpand({ ...expand, sectionEight: !expand.sectionEight }),
        children: props => <AddContactDrawerSectionEight {...props} />,
        visible: sectionVisibility?.sectionEight,
        onSave: type === 'edit' ? handleEighthSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionNine'),
        id: 'sectionNine',
        expand: expand.sectionNine,
        setExpand: () => setExpand({ ...expand, sectionNine: !expand.sectionNine }),
        children: props => <AddContactDrawerSectionNine {...props} />,
        visible: sectionVisibility?.sectionNine,
        onSave: type === 'edit' ? handleNinthSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionTen'),
        id: 'sectionTen',
        expand: expand.sectionTen,
        addTitle: !newRelationshipOpen && expand.sectionTen ? t('addContactDrawer.add') : null,
        onClickAdd: () => {
          addNewColumnBySection('contact_relationship_type');
          setExpand({ ...expand, sectionTen: true });
        },
        setExpand: () => setExpand({ ...expand, sectionTen: !expand.sectionTen }),
        children: props => <AddContactDrawerSectionTen {...props} />,
        visible: sectionVisibility?.sectionTen,
        onSave: type === 'edit' ? handleTenthSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionEleven'),
        id: 'sectionEleven',
        expand: expand.sectionEleven,

        setExpand: () => setExpand({ ...expand, sectionEleven: !expand.sectionEleven }),
        children: props => <AddContactDrawerSectionEleven {...props} />,
        visible: sectionVisibility?.sectionEleven,
        onSave: type === 'edit' ? handleEleventhSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      },
      {
        title: t('addContactDrawer.sectionTwelve'),
        id: 'sectionTwelve',
        expand: expand.sectionTwelve,
        setExpand: () => setExpand({ ...expand, sectionTwelve: !expand.sectionTwelve }),
        children: props => <AddContactDrawerSectionTwelve {...props} />,
        visible: sectionVisibility?.sectionTwelve,
        onSave: type === 'edit' ? handleTwelfthSectionSave : null,
        onSaveTitle: t('addContactDrawer.save')
      }
    ];
    const props = {
      register,
      unregister,
      handleSubmit,
      setValue,
      getValues,
      watch,
      errors,
      uploadTranslateTitle: t('addContactDrawer.upload'),

      setNewProfessionalExperienceOpen,
      newProfessionalExperienceOpen,
      setNewEducationOpen,
      newEducationOpen,
      newCertificateAndLicensesOpen,
      setNewCertificateAndLicensesOpen,
      newRelationshipOpen,
      setNewRelationshipOpen,
      newLanguageOpen,
      setNewLanguageOpen,
      recordTypeDropdownOptions,
      projectList,
      projectStageList,
      countryList,
      stateList,
      companyNameList,
      levelOfEducationList,
      certificationTypeList,
      phoneTypeList,
      emailTypeList,
      addressTypeList,
      languageList,
      levelOfProficiencyList,
      jobFunctionList,
      industryList,
      nationalityList,
      getDropdownData,
      RelationTypeList,
      loadingDropdownData,
      type,
      globalLoading,
      handlePhoneSectionSave,
      handleEmailSectionSave,
      handleAddressSectionSave,
      handleUpdateExperience,
      handleUpdateEducation,
      deleteExperience,
      handleDeleteEducation,
      addNewColumnBySection
    };
    useEffect(() => {
      if (type === 'edit') {
        for (const key in contactData) {
          if (contactData[key]) {
            setValue(key, contactData[key]);
          }
        }
      }
      if (defaultValues) {
        for (const key in defaultValues) {
          if (defaultValues[key]) {
            setValue(key, defaultValues[key]);
          }
        }
      }
    }, [contactData]);

    // setValue('birth_date', null);
    // setValue('gender', '');
    // setValue('nationality', '');
    // setValue('additional_last_name', '');
    // setValue('pronouns', '');
    // setValue('external_id', '');

    return (
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          height: '100%',
          overflowY: 'scroll',
          bgcolor: 'background.main',
          borderRadius: '0 0 5px 5px',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          // smooth scroll
          scrollBehavior: 'smooth'
        }}
        id='scrollable-drawer'
        role='presentation'
      >
        <List disablePadding sx={{ bgcolor: '#e9e9ed', width: '100%', minHeight: '100%' }}>
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              height: customHeaderHeight || (duplicateContact?.isDuplicate ? '172px' : '134px'),
              position: 'sticky',
              top: 0,
              zIndex: 1000,
              marginBottom: '14px'
            }}
            disablePadding
          >
            {includeHeader && (
              <AddContactDrawerHeader
                title={t('addContactDrawer.title')}
                subtitle={t('addContactDrawer.createContactSubtitle')}
                onClose={() => toggleDrawer(false)}
                onSave={onSave}
                onSaveAndAddAnother={onSaveAndAddAnother}
              />
            )}
            {includeSubHeader && (
              <AddContactDrawerSubHeader
                uploadResumeTitle={t('addContactDrawer.uploadResumeToCreateContact')}
                linkedinURLTitle={t('addContactDrawer.LinkedinURL')}
                getProfileData={t('addContactDrawer.getProfileData')}
                expandAll={t('addContactDrawer.expandAll')}
                collapseAll={t('addContactDrawer.collapseAll')}
                onExpandAll={expandAll}
                onCollapseAll={collapseAll}
                onGetProfileData={addFromLinkedin}
                allExpanded={Object.values(expand).every(value => value === true)}
                allCollapsed={Object.values(expand).every(value => value === false)}
                onOpenCreateContactFromResume={() => setOpenCreateContactFromResume(true)}
                onAddFromLinkedin={() => {}}
                duplicateContact={duplicateContact}
                setDuplicateContact={() =>
                  setDuplicateContact({
                    isDuplicate: false,
                    string: null,
                    data: []
                  })
                }
                linkedinURL={linkedinURL}
                setLinkedinURL={setLinkedinURL}
              />
            )}
            {CustomHeader && (
              <CustomHeader
                uploadResumeTitle={t('addContactDrawer.uploadResumeToCreateContact')}
                linkedinURLTitle={t('addContactDrawer.LinkedinURL')}
                getProfileData={t('addContactDrawer.getProfileData')}
                expandAll={t('addContactDrawer.expandAll')}
                collapseAll={t('addContactDrawer.collapseAll')}
                onExpandAll={expandAll}
                onCollapseAll={collapseAll}
                onGetProfileData={addFromLinkedin}
                allExpanded={Object.values(expand).every(value => value === true)}
                allCollapsed={Object.values(expand).every(value => value === false)}
                onOpenCreateContactFromResume={() => setOpenCreateContactFromResume(true)}
                onAddFromLinkedin={() => {}}
                onSave={onSave}
                showUnSavedChangesWarning={false}
                globalLoading={globalLoading}
              />
            )}
          </ListItem>
          <ListItem
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '16px'
            }}
          >
            {sections.map((section, index) => (
              <AddContactDrawerAccordion
                key={index}
                title={section.title}
                expand={section.expand}
                setExpand={section.setExpand}
                addTitle={section.addTitle}
                onClickAdd={section.onClickAdd}
                // loading={loadingDropdownData}
                visible={section.visible}
                globalLoading={globalLoading}
                onSave={section.onSave}
                onSaveTitle={section.onSaveTitle}
              >
                {section.children(props)}
              </AddContactDrawerAccordion>
            ))}
          </ListItem>
        </List>
        {showUnSavedChangesWarning && (
          <AlertDialog
            open={showUnSavedChangesWarning}
            handleClose={() => setShowUnSavedChangesWarning(false)}
            handleSubmit={async () => {
              await onSave();
              setShowUnSavedChangesWarning(false);
            }}
            title={t('addContactDrawer.unsavedChanges')}
            description={t('addContactDrawer.unsavedChangesDescription')}
            cancelText={t('addContactDrawer.goBack')}
            submitText={t('addContactDrawer.saveAndContinue')}
          />
        )}
        {openCreateContactFromResume && <ResumeParser isPopupOpen={openCreateContactFromResume} handleClose={handleResumeParseClose} />}
      </Box>
    );
  }
);

export default ContactDrawerUI;
ContactDrawerUI.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  recordTypeDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  projectList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  projectStageList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  countryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  stateList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  companyNameList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  levelOfEducationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  certificationTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  phoneTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emailTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addressTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  languageList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelOfProficiencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  jobFunctionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  industryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nationalityList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  RelationTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  saveContact: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  includeHeader: PropTypes.bool.isRequired,
  includeSubHeader: PropTypes.bool.isRequired,
  CustomHeader: PropTypes.func.isRequired,
  customHeaderHeight: PropTypes.string.isRequired,
  sectionVisibility: PropTypes.object,
  customSectionPrefix: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string, // create or edit
  contactData: PropTypes.object,
  duplicateContact: PropTypes.object,
  setDuplicateContact: PropTypes.func,
  defaultValues: PropTypes.object,
  globalLoading: PropTypes.bool,
  callback: PropTypes.func,
  updateContactBySection: PropTypes.func,
  deleteSectionApi: PropTypes.func,
  isTabChange: PropTypes.bool
};
