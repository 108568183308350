//-----------------------------------------------------------// In-built Imports // ------------------------------

import { capitalize } from 'lodash';
import React from 'react';
//-----------------------------------------------------------// External Imports // ------------------------------

import { Button, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import ColorSelection from '../../components/ColorSelection';
import CompanySelection from '../../components/CompanySelection';
import CountrySelection from '../../components/CountrySelection';
import DiversityCandidateSelection from '../../components/DiversityCandidateSelection';
import ExcludeFromClientSuite from '../../components/ExcludeFromClientSuite';
import GdprReleaseSelection from '../../components/GdprReleaseSelection';
import IndustrySelection from '../../components/IndustrySelection';
import JobFunctionSelection from '../../components/jobFunctionSelection';
import StateSelection from '../../components/StateSelection';
import UserSelection from '../../components/UserSelection';
import { acquireLockApi, contactDataApi, releaseLockApi } from '../../services/ApiService';
import * as AttachmentsService from '../../services/AttachmentsService';
import { EMAIL_REGEX, ERROR, GET, LINKEDIN_SCRAPER, PUT, SUCCESS, WARNING } from '../../services/constantService';
import { notFoundMessage, requireMessage, successMessage, unableMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { tooltipValueGrid } from '../../utils/common';
import { formatDate } from '../../utils/date';
import { DateRenderer, getDateFilterOptions, getFilterQuery, getList, getMultiValueParam, updateContact, updateWorkbench } from '../Commons/Utils';
import ActionsPopover from './ActionsPopover';
import CustomHeader from './components/CustomHeader';
import NamePopover from './NamePopover';
import PhonePopover from './PhonePopover';
// import CandidatePrioritySelection from "../../components/CandidatePrioritySelection";
import GenderSelection from '../../components/GenderSelection';
import './index.scss';

export const PAGE_LIMIT = 50;
export const SUCCESS_STATUS_CODE = 200;

const getLastActivityType = params => (params.data?.contact_activities ? params.data?.contact_activities[0]?.type : null);
const getLastActivityDate = params => (params.data?.contact_activities ? params.data?.contact_activities[0]?.updated_at : null);
const getLastActivityUser = params => (params.data?.contact_activities ? params.data?.contact_activities[0]?.created_user.name : null);
const getName = params => (params.value ? params.value.name : '');
const getNotes = params => params.data && params.data.contact_note && params.data.contact_note[0].contact_note;
const getUsers = params => {
  const name = [];
  if (params && params.data) {
    if (params && params.data && params.data.kg && Array.isArray(params.data.kg)) {
      params.data.kg.forEach(item => {
        if (item.name) {
          name.push(item.name);
        } else {
          let string = `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`;
          name.push(string);
        }
      });
    }
  }
  return name.join(', ');
};
const getCreatedUser = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.value ? params.value.first_name + ' ' + params.value.last_name : '';
};
const getIndustries = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params.data && params.data.industries && Array.isArray(params.data.industries)) {
    return params.data.industries.join(', ');
  }
  return '';
};
const stateSelection = params => {
  const country = params.data.country ? params.data.country : null;
  return StateSelection({ ...params, country: country });
};
const getEditable = params => {
  return params?.data?.country ? true : false;
};
// const getInternationaleExperience = (params) => {
//   const status = checkContactFetchingStatus(params)
//   if (status) return status;
//   if (
//     params.data &&
//     params.data.international_experience &&
//     Array.isArray(params.data.international_experience)
//   ) {
//     return params.data.international_experience.join(", ");
//   }
//   return "";
// };
const getJobFunctions = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params.value) {
    return params.value.join(', ');
  }
  return '';
};
const getColumnValue = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params.value) {
    return params.data.country + '/' + params.data.city + '/' + params.data.state;
  }
};
const getEmailValue = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params.value) {
    return params.data.work_email + '/' + params.data.private_email;
  }
};

const getStatus = params => {
  if (params.data) {
    if (params.data && params.data.pdlStatus?.length === 0) {
      return ' ';
    } else {
      if (params.data && params.data.pdlStatus[0].merged === true) {
        return `Enriched on ${formatDate(params.data.pdlStatus[0].updated_at)} By ${params.data.pdlStatus[0].fetched_user.first_name} ${params.data.pdlStatus[0].fetched_user.last_name}`;
      } else {
        return `Processing: Enrichment started on ${formatDate(params.data.pdlStatus[0].created_at)} By ${params.data.pdlStatus[0].fetched_user.first_name} ${
          params.data.pdlStatus[0].fetched_user.last_name
        }`;
      }
    }
  }
};

const getCellStyle = params => {
  if (params.data) {
    if (params.data && params.data.pdlStatus?.length === 0) {
      return;
    } else {
      if (params.data && params.data.pdlStatus[0].merged === true) {
        return { color: 'green' };
      } else {
        return { color: 'red' };
      }
    }
  } else {
    return;
  }
};

export const cleanupQuery = queryData => {
  if (queryData.currentCompany) {
    queryData.currentCompany = queryData.currentCompany.map(item => item.id);
  }
  if (queryData.title) {
    queryData.title = queryData.title.map(item => item.id);
  }
  if (queryData.previousCompany) {
    queryData.previousCompany = queryData.previousCompany.map(item => item.id);
  }
  if (queryData.country) {
    queryData.country = queryData.country.map(item => item.name);
  }
  if (queryData.industry) {
    queryData.industry = queryData.industry.map(item => item);
  }
  if (queryData.bdTarget) {
    queryData.bdTarget = queryData.bdTarget.map(item => item.id);
  }
  if (queryData.createdBy) {
    queryData.createdBy = queryData.createdBy.map(item => item.id);
  }
  if (queryData.mailing_list) {
    queryData.mailing_list = queryData.mailing_list.map(item => item.id);
  }
  if (queryData.jobFunction) {
    queryData.jobFunction = queryData.jobFunction.map(jobFunction => {
      return jobFunction === 'R&D' ? 'R%26D' : jobFunction;
    });
  }
  if (queryData.internationalExperience) {
    queryData.internationalExperience = queryData.internationalExperience.map(item => item.name);
  }
  if (queryData.kg) {
    queryData.kg = queryData.kg.map(item => item.id);
  }
  return queryData;
};

export const checkContactFetchingStatus = params => {
  // return params.data?.contact ? (params?.data?.contact?.data_fetching_status === 'PROCESSING' ? 'PROCESSING...' : false)  : (params?.data?.data_fetching_status === 'PROCESSING' ? 'PROCESSING...' : false )
  const contact = params.data?.contact || params?.data;
  if (contact?.data_fetching_status === LINKEDIN_SCRAPER.PROCESSING) {
    return LINKEDIN_SCRAPER.PROCESSING_LABEL;
  } else if (contact?.data_fetching_status === LINKEDIN_SCRAPER.FAILED) {
    return LINKEDIN_SCRAPER.FAILED_LABEL;
  }
  return false;
};

export const columnDefs = t =>
  // showActivityHistory,
  // enqueueSnackbar,
  // closeSnackbar,
  // SetLoader,
  // option,
  // showFilter = true,
  // setJobHistoryPopupStates,
  {
    let columns = [
      // {
      //   headerName: '',
      //   field: 'check_box',
      //   colId: 'check_box',
      //   sortable: false,
      //   filter: false,
      //   maxWidth: 60,
      //   minWidth: 60,
      //   headerComponent: 'HeaderCheckbox',
      //   cellRenderer: 'HeaderCheckbox',
      //   suppressColumnsToolPanel: true,
      //   headerCheckboxSelectionFilteredOnly: false,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      //   pinned: 'left',
      //   lockPinned: true,
      //   lockPosition: true,
      //   visible: true
      // },
      {
        headerName: t('utils.name'),
        cellRenderer: 'nameColumn',

        field: 'name',
        colId: 'first_name',
        maxWidth: 250,
        minWidth: 150,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: true,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.aboutSection'),
        field: 'about',
        colId: 'current_job_title',
        maxWidth: 250,
        minWidth: 150,
        sortable: true,
        cellRenderer: 'aboutRenderer',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: true,
        filterParams: {
          maxNumConditions: 1
        },
        valueGetter: params => params?.data?.current_job_title || ''
      },
      {
        headerName: t('contacts.addContact.contactDetails'),
        field: 'contact_Details',
        colId: 'contact_Details',
        maxWidth: 400,
        minWidth: 380,
        width: 380,
        sortable: true,
        cellRenderer: 'contactDetailsRenderer',

        headerComponentFramework: CustomHeader,
        filter: false,
        visible: true,
        valueGetter: params => {
          const parts = [];

          // Add address if available
          if (params?.data?.contact_address?.[0]) {
            const address = params.data.contact_address[0];
            const addressParts = [];

            if (address.city) {
              addressParts.push(address.city);
            }
            if (address.contact_state?.name) {
              addressParts.push(address.contact_state.name);
            }
            if (address.contact_country?.name) {
              addressParts.push(address.contact_country.name);
            }

            if (addressParts.length > 0) {
              parts.push(`Address: ${addressParts.join(', ')}`);
            }
          }

          // Add phone numbers if available
          if (params?.data?.contact_phones) {
            const primaryPhone = Array.isArray(params?.data?.contact_phones) ? params?.data?.contact_phones.find(phone => phone.is_primary) : null;

            if (primaryPhone) {
              const phoneType = capitalize(primaryPhone?.phone_type);
              parts.push(`${phoneType} Phone Number: ${primaryPhone?.phone_number}`);
            }
          }

          // Add emails if available
          if (params?.data?.contact_emails) {
            const primaryEmail = Array.isArray(params?.data?.contact_emails) ? params?.data?.contact_emails.find(email => email.is_primary) : null;

            if (primaryEmail) {
              const emailType = capitalize(primaryEmail?.email_type);
              parts.push(`${emailType} Email: ${primaryEmail?.email}`);
            }
          }

          // Add LinkedIn URL if available
          if (params?.data?.linkedin_url) {
            parts.push(`LinkedIn: ${params?.data?.linkedin_url}`);
          }

          return parts.join(' | ');
        }
      },
      {
        headerName: t('contacts.recentActivity'),
        field: 'recent_activity',
        colId: 'recent_activity',
        maxWidth: 250,
        minWidth: 329,
        sortable: true,
        cellRenderer: 'getLastActivity',

        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: true,
        filterParams: {
          maxNumConditions: 1
        },
        valueGetter: params => {
          if (!params.data?.contact_notes_all?.[0]) {
            return '';
          }

          const latestNote = params?.data?.contact_notes_all?.[0];
          const noteText = latestNote?.notes?.replace(/<\/?p>/g, '').trim() || '';

          return noteText;
        }
      },
      {
        headerName: t('contacts.addContact.contactStatus'),
        field: 'status',
        colId: 'contact_status',
        maxWidth: 400,
        minWidth: 380,
        width: 380,
        sortable: true,
        cellRenderer: 'contactStatusColumn',

        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: true,
        filterParams: {
          maxNumConditions: 1
        },
        valueGetter: params => {
          return params?.data?.contact_status || '';
        }
      },
      {
        headerName: t('common.tags'),
        field: 'contact_tags',
        colId: 'contact_tags',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: false,
        visible: true,
        valueGetter: params => {
          if (!params?.data?.contact_tags?.length) {
            return '';
          }
          return params?.data?.contact_tags
            .map(tag => tag.ign_tags?.name)
            .filter(Boolean)
            .join(', ');
        }
      },
      {
        headerName: t('utils.linkedInUrl'),
        field: 'linkedin_url',
        colId: 'linkedin_url',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: 'LinkedInRenderer',

        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.linkedinUsername'),
        field: 'linkedin_username',
        colId: 'linkedin_username',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.currentJobStartYear'),
        field: 'current_job_start_year',
        colId: 'current_job_start_year',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.currentJobEndYear'),
        field: 'current_job_end_year',
        colId: 'current_job_end_year',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.birthDate'),
        field: 'birth_date',
        colId: 'birth_date',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: 'dateRenderer',

        headerComponentFramework: CustomHeader,
        filter: false,
        visible: false,
        valueGetter: params => params?.data?.birth_date || ''
      },
      {
        headerName: t('utils.age'),
        field: 'age',
        colId: 'age',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        },
        valueGetter: params => {
          if (!params?.data?.birth_date) return '';

          const birthDate = new Date(params?.data?.birth_date);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }

          return age;
        }
        //valueGetter: params => params.data?.age || ''
      },
      {
        headerName: t('contacts.addContact.gender'),
        field: 'gender',
        colId: 'gender',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.confidential'),
        field: 'is_confidential',
        colId: 'is_confidential',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.boardCandidate'),
        field: 'is_board_candidate',
        colId: 'is_board_candidate',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.assistant'),
        field: 'is_assistant',
        colId: 'is_assistant',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        // cellRenderer: 'getTags',
        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('contacts.addContact.companyName'),
        field: 'contact_company.name',
        colId: 'contact_company',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        headerComponentFramework: CustomHeader,
        filter: false,
        visible: false
      },
      {
        headerName: t('utils.createdAt'),
        field: 'created_at',
        colId: 'created_at',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: 'dateRenderer',

        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.updatedAt'),
        field: 'updated_at',
        colId: 'updated_at',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: 'dateRenderer',

        headerComponentFramework: CustomHeader,
        filter: 'agTextColumnFilter',
        visible: false,
        filterParams: {
          maxNumConditions: 1
        }
      },
      {
        headerName: t('utils.createdBy'),
        field: 'created_by',
        colId: 'created_by',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: params => params?.data?.created_user?.name,
        headerComponentFramework: CustomHeader,
        filter: false,
        visible: false,
        filterParams: {
          maxNumConditions: 1
        },
        valueGetter: params => {
          if (!params?.data?.created_user?.name) return '';
          const created_by = params?.data?.created_user?.name;
          return created_by;
        }
      },
      {
        headerName: t('utils.updatedBy'),
        field: 'updated_by',
        colId: 'updated_by',
        maxWidth: 250,
        sortable: true,
        minWidth: 200,
        cellRenderer: params => params?.data?.updated_user?.name,
        headerComponentFramework: CustomHeader,
        filter: false,
        visible: false,
        filterParams: {
          maxNumConditions: 1
        },
        valueGetter: params => {
          if (!params?.data?.updated_user?.name) return '';
          const updated_by = params?.data?.updated_user?.name;
          return updated_by;
        }
      }
    ];

    columns.push({
      headerName: '',
      sortable: false,
      filter: false,
      field: 'action',
      colId: 'action',
      maxWidth: 80,
      minWidth: 80,
      pinned: 'right',
      // display: 'flex',
      cellRenderer: 'GetActionRenderer',
      visible: true,
      floatingFilter: false,
      suppressColumnsToolPanel: true, //for not displaying column in column selector
      suppressHeaderMenuButton: true //for disabling there menu dots on column header
    });

    // columns.forEach(ele => {
    //   if (ele.field !== 'first_name' && ele.field !== 'linkedin_url' && ele.field !== 'freeze_dropdown' && ele.field !== 'check_box' && ele.field !== 'international_experience' && ele.field !== 'source' && ele.field !== 'state') {
    //     ele.edit = ele.editable
    //     ele.editable = (params) => {
    //       return checkContactFetchingStatus(params) ? false : ele.edit
    //     }
    //     if (!ele.cellRenderer) {
    //       ele.cellRenderer = (params) => {
    //         const status = checkContactFetchingStatus(params)
    //         return status ? status : params?.value
    //       }
    //     }
    //   }
    // })

    // }
    return columns;
  };

export const columnMergeContactDefs = (enqueueSnackbar, closeSnackbar, SetLoader, setJobHistoryPopupStates) => {
  let columns = [
    {
      headerName: '',
      field: 'check_box',
      colId: 'check_box',
      maxWidth: 250,
      minWidth: 150,
      headerComponent: 'HeaderCheckbox',
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      lockPosition: true
    },
    {
      headerName: 'Name',
      valueGetter: params => params?.data && params?.data?.first_name + ' ' + params?.data?.last_name,
      field: 'first_name',
      colId: 'first_name',
      sortable: false,
      maxWidth: 200,
      minWidth: 150,
      cellRenderer: 'NameRenderer',
      lockPinned: true
    },
    {
      field: 'status',
      colId: 'status',
      headerName: 'Status',
      tooltipField: 'status',
      sortable: false,
      tooltipValueGetter: getStatus,
      cellRenderer: getStatus,
      cellStyle: getCellStyle,
      maxWidth: 300,
      minWidth: 150
    },
    {
      field: 'company',
      colId: 'company',
      maxWidth: 200,
      minWidth: 150,
      headerName: 'Current Company',
      cellRenderer: 'CompanyNameRenderer',
      sortable: false
    },
    {
      field: 'current_job_title',
      colId: 'current_job_title',
      tooltipField: 'current_job_title',
      headerName: 'Current Job Title',
      maxWidth: 200,
      sortable: false,
      minWidth: 150,
      /* if enable to editable it must show an employment history confirmation popup */
      valueSetter: async params => {
        if (!params.newValue) {
          const message = requireMessage('Current Job Title', 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
        const newValue = { ...params };
        setJobHistoryPopupStates({ newContactData: newValue });
        SetLoader(true);
        let returnResult = true;
        const { status, data } = await contactDataApi(GET, params.data.id);
        if (status === 200) {
          setJobHistoryPopupStates({
            currentContact: data,
            showJobTitleChangedPopup: true
          });
        } else {
          const message = notFoundMessage('Contact');
          enqueueSnackbar(message, { variant: ERROR });
          returnResult = false;
        }
        SetLoader(false);
        return returnResult;
      }
    },
    {
      field: 'job_functions',
      colId: 'job_functions',
      maxWidth: 200,
      minWidth: 150,
      headerName: 'Functions',
      sortable: false,
      tooltipValueGetter: getJobFunctions,
      cellRenderer: getJobFunctions
    },
    {
      field: 'industries',
      colId: 'industries',
      headerName: 'Industries',
      sortable: false,
      tooltipValueGetter: getIndustries,
      cellRenderer: getIndustries,
      maxWidth: 200,
      minWidth: 50
    },
    {
      field: 'city',
      colId: 'city',
      maxWidth: 200,
      minWidth: 150,
      sortable: false,
      tooltipValueGetter: getColumnValue,
      cellRenderer: getColumnValue,
      headerName: 'Location/City/State'
    },
    {
      field: 'linkedin_url',
      colId: 'linkedin_url',
      headerName: 'LinkedIn',
      // tooltipField: "linkedin_url",
      tooltipValueGetter: params => {
        return tooltipValueGrid(params);
      },
      hide: true,
      editable: true,
      maxWidth: 100,
      minWidth: 150,
      cellRenderer: 'LinkedInRenderer',
      sortable: false,
      valueSetter: params => {
        const lable = 'linkedin_url';
        acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
      }
    },
    {
      field: 'work_email',
      colId: 'work_email',
      headerName: 'Work/Personal Email',
      tooltipField: 'work_email',
      sortable: false,
      tooltipValueGetter: getEmailValue,
      cellRenderer: getEmailValue,
      maxWidth: 300,
      minWidth: 50
    }
  ];

  columns.forEach(ele => {
    if (ele.field !== 'first_name' && ele.field !== 'linkedin_url' && ele.field !== 'freeze_dropdown' && ele.field !== 'check_box' && ele.field !== 'state') {
      ele.edit = ele.editable;
      ele.editable = params => {
        return checkContactFetchingStatus(params) ? false : ele.edit;
      };
      if (!ele.cellRenderer) {
        ele.cellRenderer = params => {
          const status = checkContactFetchingStatus(params);
          return status ? status : params?.value;
        };
      }
    }
  });
  return columns;
};

export const columnWorkbenchDefs = (showActivityHistory, enqueueSnackbar, closeSnackbar, showFilter = true, workbenchId, SetLoader, option, setJobHistoryPopupStates) => [
  {
    headerName: '',
    field: 'check_box',
    colId: 'check_box',
    maxWidth: 50,
    minWidth: 50,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPosition: true
  },
  // set this field only for workbench contacts
  {
    field: 'contact_color',
    colId: 'contact_color',
    headerName: 'Color',
    cellRenderer: 'ColorRenderer',
    maxWidth: 100,
    minWidth: 100,
    pinned: 'left',
    lockPinned: true,
    filter: 'CustomFilter',
    filterParams: {
      Component: ColorSelection,
      field: 'value'
    }
  },
  {
    headerName: 'Name',
    valueGetter: params => params?.data && params?.data?.first_name + ' ' + params?.data?.last_name,
    field: 'first_name',
    colId: 'first_name',
    sortable: true,
    maxWidth: 200,
    minWidth: 150,
    tooltipField: 'first_name',
    cellRenderer: 'NameRenderer',
    pinned: 'left',
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'company',
    colId: 'company',
    maxWidth: 200,
    minWidth: 150,
    headerName: 'Current Company',
    tooltipValueGetter: getName,
    cellRenderer: 'CompanyNameRenderer',
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: CompanySelection,
      multiple: true
    }
  },
  {
    field: 'gender',
    colId: 'gender',
    headerName: 'Gender',
    tooltipField: 'gender',
    minWidth: 150,
    maxWidth: 150,
    sortable: true,
    editable: false,
    cellEditor: 'GenericCellEditor',
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: GenderSelection,
      multiple: false
    }
  },
  {
    field: 'current_job_title',
    colId: 'current_job_title',
    tooltipField: 'current_job_title',
    maxWidth: 200,
    minWidth: 150,
    headerName: 'Current Job Title',
    editable: true,
    valueSetter: async params => {
      if (!params.newValue) {
        const message = requireMessage('Current Job Title', 'is');
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
      const newValue = { ...params };
      setJobHistoryPopupStates({ newContactData: newValue });
      SetLoader(true);
      let returnResult = true;
      const { status, data } = await contactDataApi(GET, params.data.id);
      if (status === 200) {
        setJobHistoryPopupStates({
          currentContact: data,
          showJobTitleChangedPopup: true
        });
      } else {
        const message = notFoundMessage('Contact');
        enqueueSnackbar(message, { variant: ERROR });
        returnResult = false;
      }
      SetLoader(false);
      return returnResult;
    },
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'job_functions',
    colId: 'job_functions',
    headerName: 'Functions',
    maxWidth: 200,
    minWidth: 50,
    tooltipValueGetter: getJobFunctions,
    cellRenderer: getJobFunctions,
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: JobFunctionSelection,
      multiple: true
    }
  },
  {
    field: 'country',
    colId: 'country',
    tooltipField: 'country',
    headerName: 'Location',
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: CountrySelection,
      label: '',
      placeholder: ''
    },
    valueSetter: params => {
      params.newValue = params.newValue.name;
      const lable = 'country';
      return acquire(params, lable, enqueueSnackbar, closeSnackbar, true, SetLoader);
    },
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: CountrySelection,
      multiple: true
    }
  },
  {
    field: 'city',
    colId: 'city',
    maxWidth: 200,
    minWidth: 150,
    tooltipField: 'city',
    headerName: 'City',
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'state',
    colId: 'state',
    tooltipField: 'state',
    headerName: 'State',
    maxWidth: 200,
    minWidth: 50,
    editable: getEditable,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: stateSelection,
      label: '',
      placeholder: ''
    },
    valueSetter: params => {
      params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
      if (params.oldValue === params.newValue || params.newValue === undefined) {
        return false;
      }
      const lable = 'state';
      return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
    },
    filter: false, //showFilter && "CustomFilter",
    filterParams: {
      Component: StateSelection,
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'linkedin_url',
    colId: 'linkedin_url',
    headerName: 'LinkedIn',
    maxWidth: 200,
    minWidth: 50,
    // tooltipField: "linkedin_url",
    tooltipValueGetter: params => {
      return tooltipValueGrid(params);
    },
    cellRenderer: 'LinkedInRenderer',
    editable: true,
    sortable: false,
    valueSetter: params => {
      const lable = 'linkedin_url';
      acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
    }
  },
  {
    field: 'contact_resumes',
    colId: 'contact_resumes',
    headerName: 'Resume',
    maxWidth: 120,
    minWidth: 50,
    cellRenderer: 'ResumeRenderer',
    cellRendererParams: { enqueueSnackbar },
    hide: true
  },
  {
    field: 'created_user',
    colId: 'created_user',
    headerName: 'Added by',
    tooltipValueGetter: getCreatedUser,
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    cellRenderer: getCreatedUser,
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: UserSelection,
      multiple: true,
      option: option ? option : []
    }
  },
  {
    field: 'bd_target',
    colId: 'bd_target',
    headerName: 'BD Target',
    tooltipValueGetter: getCreatedUser,
    cellRenderer: getCreatedUser,
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: UserSelection,
      field: 'id',
      option: option ? option : []
    }
  },
  {
    field: 'kg',
    colId: 'kg_25',
    headerName: 'KG 25',
    tooltipField: 'kg_25',
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    sortable: true,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: UserSelection,
      label: 'KG 25',
      placeholder: '',
      multiple: true,
      option: option ? option : []
    },
    valueSetter: params => {
      const old = [];
      const newValues = [];
      const lable = 'kg';
      params.data.kg.map(item => old.push(item.name));
      params.newValue.map(item => newValues.push(item));
      params.oldValue = old;
      params.newValue = newValues;
      return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
    },
    cellRenderer: getUsers,
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: UserSelection,
      multiple: true,
      option: option ? option : []
    }
  },
  {
    field: 'notes',
    colId: 'contact_note',
    tooltipField: 'Notes',
    headerName: 'Notes',
    editable: true,
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    valueGetter: getNotes,
    valueSetter: params => {
      const lable = 'notes';
      const notes = 'notes';
      return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader, notes, workbenchId);
    },
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    },
    cellRenderer: getNotes
  },
  {
    field: 'last_activity_type',
    colId: 'last_activity_type',
    tooltipValueGetter: getLastActivityType,
    cellRenderer: getLastActivityType,
    headerName: 'Last Activity Type',
    maxWidth: 200,
    minWidth: 50,
    tooltipField: 'last_activity_type',
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    },
    sortable: true,
    hide: true
  },
  {
    field: 'last_activity_date',
    colId: 'last_activity_date',
    tooltipValueGetter: getLastActivityDate,
    valueGetter: getLastActivityDate,
    cellRenderer: 'DateTimeRenderer',
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Last Activity Date',
    tooltipField: 'last_activity_date',
    hide: true,
    sortable: true,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'last_activity_user',
    colId: 'last_activity_user',
    tooltipValueGetter: getLastActivityUser,
    cellRenderer: getLastActivityUser,
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Last Activity User',
    tooltipField: 'last_activity_user',
    filter: showFilter && 'agTextColumnFilter' && 'CustomFilter',
    filterParams: {
      Component: UserSelection,
      field: 'id',
      option: option ? option : []
    },
    sortable: true,
    hide: true
  },
  {
    field: 'updated_at',
    colId: 'updated_at',
    headerName: 'Updated On',
    tooltipValueGetter: DateRenderer,
    cellRenderer: DateRenderer,
    maxWidth: 200,
    minWidth: 150,
    hide: true,
    filter: showFilter && 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'mobile_phone',
    colId: 'mobile_phone',
    headerName: 'Phone Number',
    cellRenderer: 'PhoneRenderer',
    maxWidth: 300,
    minWidth: 200,
    hide: true,
    sortable: false,
    editable: true,
    cellEditor: 'PhoneEditor',
    valueSetter: params => {
      const lable = 'mobile_phone';
      return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, SetLoader);
    },
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'work_email',
    colId: 'work_email',
    headerName: 'Work Email',
    tooltipField: 'work_email',
    cellRenderer: 'EmailRenderer',
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    valueSetter: params => {
      if (params.oldValue === params.newValue || params.newValue === undefined) {
        return false;
      }
      const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
      if (params.newValue && !email) {
        const message = validateMessage('valid work email', 'enter');
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        const label = 'work_email';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, SetLoader);
      }
    },
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'private_email',
    colId: 'private_email',
    headerName: 'Personal Email',
    tooltipField: 'private_email',
    cellRenderer: 'EmailRenderer',
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    valueSetter: params => {
      if (params.oldValue === params.newValue || params.newValue === undefined) {
        return false;
      }
      const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
      if (params.newValue && !email) {
        const message = validateMessage('valid personal email', 'enter');
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        const label = 'private_email';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, SetLoader);
      }
    },
    filter: showFilter && 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: '',
    colId: 'actions',
    headerName: 'Actions',
    maxWidth: 130,
    minWidth: 130,
    tooltipField: '',
    cellRenderer: 'ActionsRenderer',
    cellRendererParams: {
      showActivityHistory
    },
    sortable: false
  },
  {
    field: '',
    colId: 'add_contact_to',
    headerName: 'Add Contact to',
    maxWidth: 135,
    minWidth: 135,
    tooltipField: '',
    cellRenderer: 'AddContactToRenderer',
    sortable: false
  },
  {
    field: '',
    colId: 'athena',
    headerName: 'Athena',
    tooltipField: '',
    sortable: false,
    cellRenderer: 'AthenaRenderer',
    maxWidth: 200,
    minWidth: 50
  },
  {
    field: 'base_salary',
    colId: 'base',
    headerName: 'Base',
    cellRenderer: 'CompensationRenderer',
    maxWidth: 200,
    minWidth: 150,
    sortable: false
  },
  {
    field: 'actual_bonus_value',
    colId: 'actual_bonus_value',
    headerName: 'Bonus',
    sortable: false,
    cellRenderer: 'CompensationRenderer',
    hide: true,
    maxWidth: 200,
    minWidth: 50
    // filter: showFilter && "agNumberColumnFilter",
    // filterParams: {
    //   filterOptions: getMatchFilterOptions(),
    //   buttons: ["reset", "apply"],
    //   suppressAndOrCondition: true,
    //   closeOnApply: true,
    // },
  },
  {
    field: 'equity',
    colId: 'equity',
    headerName: 'Equity',
    tooltipField: 'equity',
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    sortable: false
    // filter: showFilter && 'agTextColumnFilter',
    // filterParams: {
    //   filterOptions: ['contains'],
    //   buttons: ['reset', 'apply'],
    //   suppressAndOrCondition: true,
    //   closeOnApply: true
    // }
  },
  {
    field: 'industries',
    colId: 'industries',
    headerName: 'Industries',
    tooltipValueGetter: getIndustries,
    cellRenderer: getIndustries,
    maxWidth: 200,
    minWidth: 50,
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: IndustrySelection,
      multiple: true,
      isSearchIndustry: true
    }
  },
  {
    field: 'gdpr_release',
    colId: 'gdpr_release',
    headerName: 'GDPR Release',
    maxWidth: 100,
    minWidth: 50,
    cellRenderer: params => (params.value ? 'Yes' : 'No'),
    filter: 'CustomFilter',
    filterParams: {
      Component: GdprReleaseSelection,
      field: 'id'
    }
  },
  {
    field: 'diversity_candidate',
    colId: 'diversity_candidate',
    headerName: 'Diversity Candidate',
    maxWidth: 100,
    minWidth: 50,
    cellRenderer: params => (params.value ? 'Yes' : 'No'),
    filter: showFilter && 'CustomFilter',
    filterParams: {
      Component: DiversityCandidateSelection,
      field: 'id'
    }
  },
  {
    field: 'international_experience',
    colId: 'international_experience',
    headerName: 'International Experience',
    sortable: true,
    maxWidth: 200,
    minWidth: 50,
    filter: 'CustomFilter',
    filterParams: {
      Component: CountrySelection,
      multiple: true
    }
  },
  {
    field: 'hide_in_client_suite',
    colId: 'hide_in_client_suite',
    headerName: 'Exclude from ClientSuite',
    maxWidth: 200,
    minWidth: 50,
    cellRenderer: params => (params.value ? 'Yes' : 'No'),
    filter: 'CustomFilter',
    filterParams: {
      Component: ExcludeFromClientSuite,
      field: 'id'
    }
  },
  {
    field: 'source',
    tooltipField: 'source',
    colId: 'source',
    headerName: 'Source',
    minWidth: 50,
    maxWidth: 200,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'freeze_dropdown',
    colId: 'freeze_dropdown',
    headerName: '',
    sortable: false,
    filter: false,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    pinned: 'right',
    lockPinned: true
  }
];

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress className='loader-class' />;
};

export const acquire = async (params, lable, enqueueSnackbar, closeSnackbar, required = false, SetLoader, notes, workbenchId, changeJobHistory = false) => {
  const newValue = { ...params };
  SetLoader(true);
  let { status, data } = await acquireLockApi(params.data.id);
  if (status && status === 200 && data.message === undefined) {
    if (notes === 'notes') {
      return updateWorkbench(newValue, lable, enqueueSnackbar, required, workbenchId, SetLoader);
    } else {
      return updateContact(newValue, lable, enqueueSnackbar, required, SetLoader, undefined, changeJobHistory);
    }
  } else {
    SetLoader(false);
    //if (data.isAdmin) {
    enqueueSnackbar(data.message, {
      variant: WARNING,
      // eslint-disable-next-line react/display-name
      action: key => (
        <>
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={() => {
              closeSnackbar(key);
              releaseLock(params.data.id, newValue, lable, enqueueSnackbar, closeSnackbar, required, SetLoader, notes, workbenchId, changeJobHistory);
            }}
          >
            Force Unlock
          </Button>
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={async () => {
              closeSnackbar(key);
            }}
          >
            CLOSE
          </Button>
        </>
      )
    });
    return false;
    /* }
    else {
      enqueueSnackbar(data.message, { variant: WARNING });
      return false;
    } */
  }
};
const releaseLock = async (id, params, lable, enqueueSnackbar, closeSnackbar, required, SetLoader, notes, workbenchId, changeJobHistory) => {
  await releaseLockApi(id);
  acquire(params, lable, enqueueSnackbar, closeSnackbar, required, SetLoader, notes, workbenchId, changeJobHistory);
};

const downloadAttachment = async (attachmentId, enqueueSnackbar) => {
  try {
    const url = await AttachmentsService.download(attachmentId, 'contact', 'resumes');
    if (url) {
      window.open(url);
    } else {
      const message = unableMessage('Resume', 'download');
      enqueueSnackbar(message, { variant: ERROR });
    }
  } catch (e) {
    console.log('Error found in downloadAttachment::', e);
  }
};

export const ResumeRenderer = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.value && params.value?.length ? (
    <Typography className='text-link' color={'primary'} onClick={() => downloadAttachment(params.value[0].id, params.colDef?.cellRendererParams.enqueueSnackbar)}>
      Yes
    </Typography>
  ) : (
    'No'
  );
};

export const EmailRenderer = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params.value) {
    return (
      <a href={`mailto:${params.value}`} target={'noopener'} className='action-label' style={{ color: params.data?.offlimit?.length ? 'red' : 'default' }}>
        {params.value}
      </a>
    );
  }
  return null;
};

export const ActionsRenderer = params => {
  const list = [
    {
      label: 'Log An Activity',
      link: `/contacts/${params?.data?.id}/log-an-activity/general-info`
    },
    {
      label: 'Show History',
      onClick: params.colDef ? () => params.colDef.cellRendererParams.showActivityHistory(params.data) : null
    }
  ];
  return <ActionsPopover list={list} />;
};

export const PhoneRenderer = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  const phones = [];
  if (params.value) {
    phones.push({ label: 'Work', value: params.value });
  }

  if (params.data && params.data.home_phone) {
    phones.push({ label: 'Personal', value: params.data.home_phone });
  }

  if (phones?.length > 0) {
    return (
      <PhonePopover phones={phones}>
        <a href={`tel:${phones[0].value}`} target={'noopener'} className='action-label'>
          {phones[0].value}
        </a>
      </PhonePopover>
    );
  }
  return <div />;
};

export const ContactNameRenderer = props => {
  const { params, handleAddToSearch } = props;
  const getName = params => {
    if (params.data) {
      if (params.data.contact) {
        const firstName = params.data.contact.first_name || '';
        const lastName = params.data.contact.last_name || '';
        return `${firstName} ${lastName}`;
      }
      const firstName = params.data.first_name || '';
      const lastName = params.data.last_name || '';
      return `${firstName} ${lastName}`;
    }
    return '';
  };
  if (params.data) {
    return (
      <>
        {params.data.isFromGalaxy === false || checkContactFetchingStatus(params) ? (
          <>{getName(params)}</>
        ) : (
          <NamePopover contact={params.data.contact ? params.data.contact : params.data} handleAddToSearch={handleAddToSearch}>
            {/* <Link
                to={`/contacts/${
                params.data.contact ? params.data.contact.id : params.data.id
              }/details`}
                target="_blank"
                rel="noopener noreferrer"
              > */}
            <Typography className='text-link' color={params.data?.isFromGalaxy ? 'primary' : params.data?.offlimit === true || params.data?.contact?.offlimit === true ? 'error' : 'primary'}>
              {getName(params)}
            </Typography>
            {/* </Link> */}
          </NamePopover>
        )}
      </>
    );
  }
  return null;
};

ContactNameRenderer.propTypes = {
  params: PropTypes.object,
  handleAddToSearch: PropTypes.func
};
// export const EnrichRenderer = props => {
//   const { data } = props;

//   const getEnrichDate = data => {
//     if (data && data.pdlStatus && data.pdlStatus.updated_at) {
//       return data.pdlStatus.updated_at;
//     }
//     return null;
//   };

//   const enrichDate = getEnrichDate(data);

//   if (enrichDate) {
//     return <Typography>{formatDate(enrichDate)}</Typography>;
//   }
//   return <Typography>-</Typography>;
// };

// EnrichRenderer.propTypes = {
//   data: PropTypes.object
// };

export const getQueryParamsString = data => {
  const name = getSingleValueParam(data, 'name');
  const currentJobTitle = getSingleValueParam(data, 'current_job_title');
  const city = getSingleValueParam(data, 'city');
  const state = getSingleValueParam(data, 'state');
  const fromCreate = data.fromCreate ? `&created_at_from=${data.fromCreate}` : '';
  const toCreate = data.toCreate ? `&created_at_to=${data.toCreate}` : '';
  const schoolName = getSingleValueParam(data, 'schoolName');
  const previousJobTitle = getSingleValueParam(data, 'previousJobTitle');
  const diversityCandidate = getSingleValueParam(data, 'diversity_candidate');
  const currentCompany = getMultiValueParam(data.currentCompany, 'company');
  const job_number = getMultiValueParam(data.title, 'job_number');
  const kg = getMultiValueParam(data.kg, 'kg');
  const jobFunction = getMultiValueParam(getList(data['jobFunction'], 'name'), 'job_functions');
  const industry = getMultiValueParam(getList(data['industry'], 'name'), 'industry');
  const country = getMultiValueParam(data.country, 'country');
  const previousCompany = getMultiValueParam(data.previousCompany, 'previousCompany');
  const bdTarget = getMultiValueParam(data.bdTarget, 'bdTarget');
  const createdBy = getMultiValueParam(data.createdBy, 'created_by');
  const internationalExperience = getMultiValueParam(data.internationalExperience, 'international_experience');
  const mailingList = getMultiValueParam(data.mailing_list, 'mailing_list');
  const gender = getSingleValueParam(data, 'gender');
  return (
    name +
    currentJobTitle +
    diversityCandidate +
    city +
    state +
    fromCreate +
    toCreate +
    currentCompany +
    jobFunction +
    industry +
    country +
    previousCompany +
    schoolName +
    previousJobTitle +
    bdTarget +
    createdBy +
    internationalExperience +
    mailingList +
    kg +
    job_number +
    gender
  );
};

const getSingleValueParam = (data, param) => {
  return data[param] ? `&${param}=${data[param]}` : '';
};

export const addAsMyBDTarget = async (contact, user, gridApi, enqueueSnackbar) => {
  if (contact.bd_target) {
    const message = `${VALIDATION_MESSAGE.bd_target} ${contact.bd_target.name}. Please contact ${contact.bd_target.name} for more info`;
    enqueueSnackbar(message, { variant: WARNING });
    return;
  }

  gridApi.showLoadingOverlay();
  const payload = { bd_target_id: user?.id };
  const { status, data: res } = await contactDataApi(PUT, contact.id, payload);
  if (status === 200) {
    gridApi.hideOverlay();
    const message = successMessage('BD Target', 'added as your');
    enqueueSnackbar(message, { variant: SUCCESS });
  } else {
    const message = unableMessage('contact details', 'update');
    enqueueSnackbar(res.message || message, { variant: ERROR });
  }
  gridApi.hideOverlay();
};

const queryAliases = {
  first_name: 'first_name',
  industries: 'industry',
  created_user: 'created_by'
  /* title: "job_number" */
};
const queryValueTypes = {
  job_functions: { field: 'name' },
  location: { field: 'name' },
  country: { field: 'name' },
  industry: { field: 'name' },
  international_experience: { field: 'name' }
};

export const getFilterParamString = (data, prefix, AdvancedQuery) => {
  return getFilterQuery(data, queryAliases, queryValueTypes, prefix, AdvancedQuery);
};

export const defaultColumns = ['check_box', 'action'];

export const getSelectedContactIds = gridApi => {
  const contactIds = [];
  gridApi.forEachNode(node => {
    if (node.selected) {
      contactIds.push(node.data.id); // Only for contact grids
    }
  });
  return contactIds;
};

const getFilterParam = (key, filter, queryAliases) => {
  if (filter.filterType === 'date' || filter.filterType === 'number') {
    return `${key}_${filter.type === 'lessThan' ? 'to' : 'from'}`;
  }
  return queryAliases[key] || key;
};

export const getFilterQueryData = (data, queryAliases, queryValueTypes, prefix) => {
  let queryData = {};
  Object.keys(data).forEach(key => {
    const filterParam = getFilterParam(key, data[key], queryAliases);
    const filterKey = (prefix ? 'sub_' : '') + filterParam;
    let queryKeyValue = data[key]?.filter;
    if (Array.isArray(queryKeyValue) && queryKeyValue?.length) {
      if (!queryValueTypes[filterParam]?.isValueType) {
        queryKeyValue = getList(queryKeyValue, queryValueTypes[filterParam]?.field || 'id');
      }
    } else {
      if (data[key].filterType === 'date') {
        const { dateFrom, dateTo } = data[key];
        queryKeyValue = dateFrom ? dateFrom : dateTo;
      }
    }
    if (queryKeyValue) {
      queryData[filterKey] = queryKeyValue;
    }
  });
  return queryData;
};

const getFormattedQueryData = queryData => {
  const data = {};
  const aliases = {
    fromCreate: 'created_at_from',
    toCreate: 'created_at_to',
    currentCompany: 'company',
    jobFunction: 'job_functions',
    createdBy: 'created_by',
    internationalExperience: 'international_experience'
  };

  Object.keys(queryData).forEach(key => {
    data[aliases[key] || key] = queryData[key];
  });

  return data;
};

export const getQueryPayload = (queryData, filterModel) => {
  const filterData = getFilterQueryData(filterModel, queryAliases, queryValueTypes, true);
  const parsedQueryData = getFormattedQueryData(queryData);
  return { ...parsedQueryData, ...filterData };
};
