import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SegmentIcon from '@mui/icons-material/Segment';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPickLists } from '../../../actions';
import AddToWorkbench from '../../../components/common/AddToWorkbench';
import { ButtonDropDown } from '../../../components/common/ButtonDropdown';
import CustomOptions from '../../../components/common/CustomCheckbox';
import CustomModal from '../../../components/common/CustomModal';
import CloneProjectModal from '../../../components/common/CustomModal/ProjectCloneModal';
import { BillingInfoApi, CandidateStageSetupAPi, ProjectDataApi } from '../../../services/ApiService';
import { GET, POST, ROUTES } from '../../../services/constantService';
import { projectActions } from '../../../store/projectSlice';
import { getDateFormatFromLocale } from '../../../utils/date';
import CompanyImageAvatar from '../CompanyImageAvatar';
import AssignTag from './AssignTag';
import ViewReportDrawer from './CandidatePipeline/ViewReportDrawer/index';
import { ProjectDataContext } from './Context';
import './index.scss';
import LinkExistingProject from './LinkExistingProject';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));
function HeaderOpened(props) {
  const dispatch = useDispatch();
  const bdStatusList = useSelector(state => state.commonReducer.bdStatus);
  const [bdStatusOptions, setBdStatusOptions] = useState([]);
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const { dateFormat } = useSelector(state => state.profileFormatSlice);
  const { i18n } = useTranslation();
  useEffect(() => {
    if (!bdStatusList) {
      dispatch(fetchPickLists('BD_STATUS', 'bdStatus'));
    } else {
      setBdStatusOptions(bdStatusList);
    }
  }, [bdStatusList, dispatch]);

  const tr = useTranslation();
  const { t } = useTranslation();

  const {
    fetchProjectData,
    handleExpand,
    handleAddCandidate,
    isPublished,
    handlePublish,
    stages,
    handleStageChange = () => {},
    handleBdStatusChange = () => {},
    stage,
    bdStatus,
    handleContactClick = () => {}
  } = props;
  const { projectData, bgData, isBD } = useContext(ProjectDataContext);
  const [viewReport, setViewReport] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isWorkbenchModalOpen, setIsWorkbenchModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]); // will be a state for selected options for cloning project
  const [showExistingProject, setShowExistingProject] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState(null);
  const [candidateStages, setCandidateStages] = useState([null]);

  const handleProjectButtonOnClick = () => {
    dispatch(projectActions.toggleButton(true)); // Dispatch the action to toggle the button state
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    if (!bdStatusList) {
      dispatch(fetchPickLists('BD_STATUS', 'bdStatus'));
    } else {
      setBdStatusOptions(bdStatusList);
    }
  }, [bdStatusList, dispatch]);

  const handleAddProject = key => {
    if (key === tr.t('project.linkExistingProject')) {
      handleLinkExistingProject();
    } else if (key === tr.t('project.createNewProject')) {
      setIsModalOpen(true);
    }
  };

  const handleLinkExistingProject = () => {
    setShowExistingProject(true);
  };
  const currencyCode = bgData?.bd_currency_values?.code || '';
  const estimatedAmt = parseInt(bgData?.estimated_revenue) || '';

  const handleCreateNewProject = async bdStatus => {
    try {
      /**
       * for creation => required fields are:
       *  - company name
       *  - job title
       *  - industry name
       *  - location
       */
      /** API TO CREATE PROJECT ===>>> STARTS */
      const createProjectPayload = {
        location_place_id: projectData?.location_place_id,
        location: projectData?.location,
        company_id: projectData?.company_id,
        industry_id: projectData?.industry_id,
        job_title: projectData?.job_title,
        bd_project_id: projectData.id,
        bd_currency: projectData?.currency,
        job_type: projectData?.job_type || '',
        max_compensation: projectData?.projectData || 0,
        max_experience: projectData?.max_experience || 0,
        min_compensation: projectData?.min_compensation || 0,
        min_experience: projectData?.min_experience || 0,
        pay_frequency: projectData?.pay_frequency || '',
        stage: 'Draft'
      };
      setIsLoading(true);
      const { data: response } = await ProjectDataApi(POST, '', createProjectPayload, '', '');
      /** API TO CREATE PROJECT ===>>> ENDS */

      /**
       * POST CREATION ADD API TO CLONE PREFERENCES
       */
      const createdProjectId = response.id;

      /** API TO CLONE OVERVIEW ===>>> STARTS */
      if (selectedOptions.includes('overview')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-overview-from-bd'
        );
      }
      /** API TO CLONE OVERVIEW ===>>> ENDS */

      /** API TO CLONE BILLING INFO ===>>> STARTS */
      if (selectedOptions.includes('billing')) {
        await BillingInfoApi(
          POST,
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'replicate_revenues'
        );
      }
      /** API TO CLONE BILLING INFO ===>>> ENDS */

      /** API TO CLONE ROADMAP ===>>> STARTS */
      if (selectedOptions.includes('roadmap')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-roadmap-from-bd'
        );
      }
      /** API TO CLONE ROADMAP ===>>> ENDS */

      /** API TO CLONE CANDIDATE PIPELINE ===>>> STARTS */
      if (selectedOptions.includes('candidatePipeline')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-candidates-from-project'
        );
      }
      /** API TO CLONE CANDIDATE PIPELINE ===>>> ENDS */

      /** UPDATE BD STATUS */
      if (bdStatus) {
        await handleBdStatusChange(bdStatus);
      }

      // Update created project Id
      setCreatedProjectId(createdProjectId);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  // navigate to newly created project
  const onProjectCloneSuccess = () => {
    // Open the newly created project in a new tab
    const path = ROUTES.vProject.replace(':id', createdProjectId);
    window.open(path, '_blank');
  };

  const leadConsultant = bgData?.partners?.find(partner => partner?.is_lead === true);
  const leadConsultantContactId = leadConsultant?.user?.user_contacts?.[0]?.id;

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };
  const maxVisibleTags = 1;

  const handleMoreActions = (val, e) => {
    if (val === 'Published' || val === 'Unpublished') {
      handlePublish(e);
    } else if (val === tr.t('project.cloneProject')) {
      setIsProjectModalOpen(true);
    } else if (val === tr.t('utils.addToWorkbench')) {
      setIsWorkbenchModalOpen(true);
    } else if (val === tr.t('project.viewClientPortal')) {
      window.open(`/client-portal?show=all&project=${projectData?.id}`, '_blank');
    }
  };
  const getCandidateStages = async () => {
    const { data: stagesRes } = await CandidateStageSetupAPi(GET, '/all');
    const stages = stagesRes.map(stage => stage.stage_name);
    setCandidateStages(stages);
  };
  useEffect(() => {
    getCandidateStages();
  }, [projectData]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const formatCurrency = (currencyCode, value) => {
    if (!currencyCode) return 'N/A';
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: currencyCode
    }).format(value);
  };

  const primaryColor = themeColor ? themeColor.primary_color : '#009d81';
  const textColor = themeColor ? themeColor.text_color : '#333';

  return (
    <Grid
      container
      className='p-2 pl-4'
      sx={{
        backgroundColor: 'titleBarBackground.main',
        width: '100%',
        borderRadius: '12px',
        position: 'relative',
        boxShadow: '0px 3px 5px 0px rgba(224, 224, 224, 1)'
      }}
    >
      <Box className='d-flex flex-row w-100 mt-1'>
        <Box sx={{ flexGrow: 1 }}>
          <Stack alignItems={'center'} direction={'row'} sx={{ height: '36px', width: '100%' }}>
            <Typography variant='h2' className='fs-18 heading-test-ellipsis' sx={{ fontWeight: 600, lineHeight: '25px', marginTop: '-5px' }}>
              {projectData?.job_title}
            </Typography>
            {!!projectData?.job_number && (
              <Typography variant='caption' className='ml-3 mr-3'>
                {projectData?.record_type} : {projectData?.job_number}
              </Typography>
            )}
            {!isBD ? (
              <Select
                components={'div'}
                className='stage-drop-down'
                size='small'
                sx={{ maxWidth: '150px', backgroundColor: primaryColor }}
                value={stage ?? ''}
                onChange={e => handleStageChange(e.target.value)}
              >
                {stages
                  .filter(stage => stage.name.toLowerCase() !== 'bd draft')
                  .map(stage => (
                    <MenuItem key={stage.id} value={stage.name}>
                      {stage.name}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <Select
                components={'div'}
                className='stage-drop-down'
                size='small'
                sx={{ maxWidth: '150px', backgroundColor: primaryColor }}
                value={bdStatus ?? ''}
                onChange={e => handleBdStatusChange(e.target.value)}
              >
                {bdStatusOptions.map(bdStatusRecord => (
                  <MenuItem key={bdStatusRecord.short_desc} value={bdStatusRecord?.field_value}>
                    {bdStatusRecord.short_desc}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Stack>
        </Box>
        <Box className='d-flex flex-row'>
          <Button
            className='banner-primary-color'
            show={!isBD}
            onClick={() => {
              setViewReport(true);
            }}
            sx={{ color: primaryColor, border: '1px solid ' + primaryColor, '&:hover': { background: primaryColor, color: '#fff' } }}
          >
            <LibraryBooksIcon />
            {tr.t('common.viewReports')}
          </Button>
          {/* <CustomButton
            className='banner-primary-color'
            show={!isBD}
            variant='contained'
            buttonText={tr.t('common.viewReports')}
            type={'primary'}
            onClick={() => {
              setViewReport(true);
            }}
          /> */}
          <IconButton onClick={handleClick} size='small' sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {isBD ? (
              // <ButtonDropDown
              //   iconRight={<ArrowDropDownIcon />}
              //   variant='contained'
              //   options={[tr.t('project.createNewProject'), tr.t('project.linkExistingProject')]}
              //   buttonText={`${tr.t('actions.add')} ${tr.t('module.project')}`}
              //   onClick={handleAddProject}
              // />
              <>
                {[
                  {
                    icon: <ControlPointIcon />,
                    label: t('project.createNewProject')
                  },
                  {
                    icon: <AddLinkIcon />,
                    label: t('project.linkExistingProject')
                  }
                ].map((option, index) => (
                  <MenuItem onClick={e => handleAddProject(option?.label, e)} key={index} className='pannel-menu-items d-flex align-items-center'>
                    <Box className='pannel-menu-icon mr-1'>{option.icon}</Box>
                    <Typography variant='body2' className='p-2 fs-14'>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
              </>
            ) : (
              <>
                <Typography variant='body2' className='d-flex p-1 pl-3 pb-2 fs-12 off-color'>
                  {tr.t('module.project')}
                </Typography>
                {[
                  {
                    icon: <ContentCopyIcon />,
                    label: t('project.cloneProject')
                  },
                  {
                    icon: <ImportantDevicesIcon />,
                    label: t('project.viewClientPortal')
                  },
                  {
                    icon: <SegmentIcon />,
                    label: t('utils.addToWorkbench')
                  },
                  isPublished
                    ? {
                        icon: <CheckCircleIcon />,
                        label: t('project.published')
                      }
                    : {
                        icon: <UnpublishedIcon />,
                        label: t('project.projectUnPublished')
                      }
                ].map((option, index) => (
                  <MenuItem onClick={e => handleMoreActions(option?.label, e)} key={index} className='pannel-menu-items d-flex align-items-center'>
                    <Box className='pannel-menu-icon mr-1'>{option.icon}</Box>
                    <Typography variant='body2' className='p-2 fs-14'>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
                ,
                <Typography variant='body2' className='d-flex p-1 pl-3 pb-2 fs-12 off-color'>
                  {tr.t('utils.candidate')}
                </Typography>
                {[
                  {
                    icon: <ControlPointIcon />,
                    label: t('project.quickAdd')
                  },
                  {
                    icon: <PostAddIcon />,
                    label: t('project.addFromResume')
                  },
                  {
                    icon: <HowToRegIcon />,
                    label: t('project.selectExistingContact')
                  }
                ].map((option, index) => (
                  <MenuItem onClick={e => handleAddCandidate(option?.label, e)} key={index} className='pannel-menu-items d-flex align-items-center'>
                    <Box className='pannel-menu-icon mr-1'>{option.icon}</Box>
                    <Typography variant='body2' className='p-2 fs-14'>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
              </>
            )}
          </Menu>
        </Box>
      </Box>

      <Box className='d-flex flex-row w-100 mt-2 mb-2'>
        <Box className='d-flex mr-2 project-Avatar'>
          <CompanyImageAvatar
            id={projectData?.ign_companies?.id}
            className='project-Avatar'
            sx={{
              margin: 'auto',
              borderRadius: '50%'
            }}
          />
        </Box>
        <Box className='d-flex w-100'>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 4 }} direction={'row'} sx={{ width: '100%' }} className='ml-1 mr-2'>
            <Grid item xs={4} className='small-screen-padding-left'>
              <Stack direction={'column'} spacing={0.5}>
                <Box className='d-flex flex-row align-items-center'>
                  <WorkIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  <Typography variant='caption' className='pe-1 test-ellipsis-3'>
                    <Link
                      className='panel-text-link'
                      style={{
                        color: textColor
                      }}
                      target='_blank'
                      to={`${ROUTES.allCompanies}/${projectData?.ign_companies?.id}`}
                    >
                      {projectData?.ign_companies?.name}{' '}
                    </Link>
                  </Typography>
                </Box>
                <Box className='d-flex flex-row align-items-center'>
                  <LocationOnIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  {projectData?.location?.length > 20 ? (
                    <Tooltip placement='top' title={projectData?.location}>
                      <Typography variant='caption' className='test-ellipsis-3'>
                        {projectData?.location}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant='caption' className='test-ellipsis-3'>
                      {projectData?.location}
                    </Typography>
                  )}
                </Box>
                <Box className='d-flex flex-row align-items-center'>
                  <TimerOutlinedIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  <Typography variant='caption' className='test-ellipsis-3'>
                    {t('project.started')} {t('utils.on')}{' '}
                    <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                      {projectData?.projected_start_date ? getDateFormatFromLocale({ dateFormat: dateFormat?.value, date: projectData?.projected_start_date }) : '--'}
                    </Typography>{' '}
                    {t('project.by')}{' '}
                    <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                      {' ' + projectData?.creator?.name}
                    </Typography>
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4} className='small-screen-padding-left'>
              <Stack direction={'column'} spacing={0.5}>
                {isBD && leadConsultant && (
                  <Box className='d-flex flex-row align-items-center'>
                    <Tooltip placement='top' title={t('common.consultant')}>
                      <SupervisedUserCircleIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                    </Tooltip>
                    <Typography component={'span'} variant='caption' className='test-ellipsis-3'>
                      <Link
                        style={{
                          color: 'black',
                          textDecoration: leadConsultantContactId ? 'underline' : 'none',
                          fontSize: '0.8rem',
                          cursor: leadConsultantContactId ? 'pointer' : 'default'
                        }}
                        target={leadConsultantContactId ? '_blank' : '_self'}
                        to={leadConsultantContactId ? `${ROUTES.allContactGrid}/${leadConsultantContactId}` : '#'}
                      >
                        {leadConsultant?.user?.name}
                      </Link>
                    </Typography>
                  </Box>
                )}
                {!isBD && (
                  <Box className='d-flex flex-row align-items-center'>
                    <Tooltip placement='top' title={t('project.hiringManager')}>
                      <AccountBoxIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                    </Tooltip>
                    {!isBD && (
                      <Typography component={'span'} variant='caption' className='test-ellipsis-3'>
                        <Link
                          style={{
                            color: 'black',
                            textDecoration: 'underline',
                            display: 'inline'
                          }}
                          target='_blank'
                          to={`${ROUTES.allContactGrid}/${projectData?.hiring_manager?.[0]?.contact?.id}`}
                        >
                          {projectData?.hiring_manager?.[0]?.contact?.name}
                        </Link>
                        {!isBD && projectData?.hiring_manager?.slice(1)?.length !== 0 && (
                          <CustomTooltip
                            title={
                              <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                                {projectData?.hiring_manager?.slice(1)?.map(item => (
                                  <Link
                                    style={{
                                      color: 'black'
                                    }}
                                    key={item?.contact?.id}
                                    target='_blank'
                                    to={`${ROUTES.allContactGrid}/${item?.contact?.id}`}
                                  >
                                    <p style={{ fontSize: 12, fontWeight: 600 }}>{item?.contact?.name}</p>
                                  </Link>
                                ))}
                              </Box>
                            }
                            placement='top'
                            arrow
                          >
                            <Typography component={'span'} className='ml-1 cursor-pointer' style={{ fontSize: 12, fontWeight: 600, color: 'black', display: 'inline' }}>
                              {projectData?.hiring_manager?.slice(1)?.length ? `+${projectData.hiring_manager?.length - 1}` : ''}
                            </Typography>
                          </CustomTooltip>
                        )}
                      </Typography>
                    )}
                  </Box>
                )}
                {!isBD && (
                  <Box className='d-flex flex-row align-items-center'>
                    <Tooltip placement='top' title={t('contacts.addContact.industry')}>
                      <ApartmentIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                    </Tooltip>
                    <Typography component={'span'} variant='caption' className='test-ellipsis-3'>
                      {projectData?.ign_industries?.name}
                    </Typography>
                  </Box>
                )}
                <Box className='d-flex flex-row align-items-center'>
                  <Tooltip placement='top' title={t('contacts.filterContacts.tags')}>
                    <BookmarkIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  </Tooltip>
                  {projectData?.project_tags?.length !== 0 ? (
                    projectData?.project_tags?.slice(0, maxVisibleTags).map((tag, index) => (
                      <Box key={index} className={`tag-border tag${tag?.tag?.name}`}>
                        <p className='fs-12 text-black mr-1'>{tag?.tag?.name}</p>
                      </Box>
                    ))
                  ) : (
                    <p className='fs-12 font-normal mr-1'>N/A</p>
                  )}

                  <CustomTooltip
                    title={
                      projectData?.project_tags?.slice(1)?.length !== 0 ? (
                        <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                          {projectData?.project_tags?.slice(1)?.map((tag, index) => (
                            <Box key={index} className={`tag-border mr-2 tag${tag?.tag?.name}`}>
                              <p style={{ fontSize: 12, fontWeight: 600 }}>{tag?.tag?.name}</p>
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        ''
                      )
                    }
                    placement='top'
                  >
                    <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600 }}>
                      {projectData?.project_tags?.slice(1)?.length ? `+${projectData.project_tags?.length - 1}` : ''}
                    </p>
                  </CustomTooltip>

                  <Link component='button' onClick={handleAssignTag}>
                    <p style={{ fontSize: 12, fontWeight: 600, color: primaryColor }}>+Tag</p>
                  </Link>
                </Box>
                <Box className='mb-2'>
                  <AssignTag
                    tagPopUp={projectData?.project_tags}
                    projectId={projectData?.id}
                    setIsPopupOpen={setIsTagPopupOpen}
                    isPopupOpen={isTagPopupOpen}
                    label={t('utils.selectTag')}
                    getHeaderDetails={() => {}}
                    setIsEdited={() => {}}
                    fetchProjectData={fetchProjectData}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4} className='small-screen-padding-left'>
              <Stack direction={'column'} spacing={0.5}>
                {!isBD && (
                  <Box className='d-flex flex-row align-items-center'>
                    <Tooltip placement='top' title={t('utils.candidatesInPipeline')}>
                      <LinearScaleIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                    </Tooltip>
                    <Typography component={'span'} variant='caption' className='test-ellipsis-3 cursor-pointer' onClick={handleProjectButtonOnClick}>
                      <Typography component={'span'} variant='caption' sx={{ color: textColor, fontWeight: 'bold' }}>
                        {projectData?.candidate_count ?? 0}
                      </Typography>
                      <Typography component={'span'} variant='caption'>
                        {' '}
                        Candidates in Pipeline
                      </Typography>
                    </Typography>
                  </Box>
                )}
                {
                  <Box className='d-flex flex-row align-items-center'>
                    <Tooltip placement='top' title={t('project.projectHeader.billingStatus')}>
                      <ReceiptIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                    </Tooltip>
                    <Typography component={'span'} variant='caption' className='test-ellipsis-3'>
                      {`${bgData?.billing_status_desc?.short_desc ?? 'Billing status may not be updated yet'}`}
                    </Typography>
                  </Box>
                }
                {isBD && (
                  <Box className='d-flex flex-row align-items-center'>
                    <Tooltip placement='top' title={t('utils.probability')}>
                      <EmojiEventsIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                    </Tooltip>
                    <Typography component={'span'} variant='caption' className='test-ellipsis-3'>
                      {' '}
                      {projectData?.probability || '0%'}
                    </Typography>
                  </Box>
                )}
                <Box className='d-flex flex-row align-items-center'>
                  <Tooltip placement='top' title={t('utils.estimatedRevenue')}>
                    <TrendingUpIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  </Tooltip>
                  <Typography component={'span'} variant='caption' className='test-ellipsis-3'>
                    <Typography component={'span'} variant='caption' sx={{ fontWeight: 'bold' }}>
                      {currencyCode ?? ''} {formatCurrency(currencyCode, estimatedAmt)}
                    </Typography>
                    <Typography component={'span'} variant='caption' sx={{ color: '#777' }}>
                      {' '}
                      (Estimated Revenue)
                    </Typography>
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div style={{ marginTop: '50px', display: 'none' }}>
        <Grid container Item xs={1} direction='column' justifyContent='space-between' alignItems='center' sx={{ maxWidth: '90%' }}></Grid>
        <Grid item xs={9} className='p-2'>
          <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
              {/* <EditIcon className="p-1" />
            <DeleteIcon className="p-1" /> */}
              {/* <Typography variant="caption">
              <CheckCircleOutlineIcon className="p-1" />
              Added to client Suite
            </Typography> */}
            </Stack>
          </Stack>
          <Grid container paddingTop={1}>
            <Grid container Item xs={4} direction='column' justifyContent='flex-start' alignItems='flex-start'></Grid>
            <Grid container Item xs={4} direction={'column'} justifyContent={'flex-start'}>
              <Stack direction={'row'} alignItems={'center'}></Stack>
            </Grid>
            <Grid container Item xs={4} direction='column' justifyContent='flex-start' alignItems='flex-start' gap={1}></Grid>
          </Grid>
        </Grid>
        <Grid container item xs={2} direction={'column'} justifyContent={'center'} alignItems={'flex-end'}>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-end'}>
            <ButtonDropDown
              show={!isBD}
              iconRight={<ArrowDropDownIcon />}
              options={[
                tr.t('project.cloneProject'),
                {
                  component: (
                    <Box key={'publish_unpublish'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                      {isPublished ? tr.t('project.published') : tr.t('project.projectUnPublished')} <ArrowDropDownIcon />
                    </Box>
                  ),
                  close: false
                },
                tr.t('project.viewClientPortal')
              ]}
              buttonText={tr.t('actions.moreActions')}
              className={'my-2'}
              onClick={handleMoreActions}
              MenuItemStyle={{
                borderBottom: '1px solid black',
                fontSize: '14px',
                fontWeight: 600
              }}
            />

            <ButtonDropDown
              show={!isBD}
              iconRight={<ArrowDropDownIcon />}
              variant='contained'
              options={['Quick add', 'Add from resume', 'Select existing contact']}
              buttonText={t('project.projectHeader.addCandidate')}
              onClick={handleAddCandidate}
              MenuItemStyle={{
                borderBottom: '1px solid black',
                fontSize: '14px',
                fontWeight: 600
              }}
            />

            <ButtonDropDown
              show={isBD}
              iconRight={<ArrowDropDownIcon />}
              variant='contained'
              options={[tr.t('project.createNewProject'), tr.t('project.linkExistingProject')]}
              buttonText={`${tr.t('actions.add')} ${tr.t('module.project')}`}
              onClick={handleAddProject}
            />
          </Box>
        </Grid>
      </div>

      {isModalOpen && isBD && (
        <CustomModal
          title='Create Project'
          isModalOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setIsSuccess(false);
            setSelectedOptions([]);
          }}
          onSubmit={handleCreateNewProject}
          isSuccess={isSuccess}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          isLoading={isLoading}
          onSuccess={onProjectCloneSuccess}
          bdStatusOptions={bdStatusOptions}
        >
          <CustomOptions value='overview' label='Overview' />
          <CustomOptions value='billing' label='Billing' />
          <CustomOptions value='roadmap' label='Roadmap' />
          <CustomOptions value='candidatePipeline' label='Candidate Pipeline' />
        </CustomModal>
      )}
      {isProjectModalOpen && !isBD && (
        <CloneProjectModal
          title='Clone Project'
          open={isProjectModalOpen}
          onClose={() => setIsProjectModalOpen(false)}
          projectData={projectData}
          setCreatedProjectId={setCreatedProjectId}
          onSuccess={onProjectCloneSuccess}
          candidateStages={candidateStages}
        />
      )}
      {isWorkbenchModalOpen && !isBD && (
        <AddToWorkbench isPopupOpen={isWorkbenchModalOpen} isProject={true} moduleIds={[projectData.id]} handleClose={() => setIsWorkbenchModalOpen(false)}></AddToWorkbench>
      )}
      <LinkExistingProject companyId={projectData?.company_id} bgId={bgData?.id} open={showExistingProject} onClose={() => setShowExistingProject(false)} />
      <KeyboardArrowUpIcon
        sx={{
          position: 'absolute',
          bottom: '-10px',
          left: '48%',
          backgroundColor: 'primary.main',
          color: 'titleBarBackground.main',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
        onClick={handleExpand}
      />
      {viewReport && <ViewReportDrawer fromHeaderBar projectName={projectData?.job_title} isDrawerOpen={viewReport} setIsDrawerOpen={setViewReport} projectId={projectData?.id} />}
    </Grid>
  );
}

HeaderOpened.propTypes = {
  handleExpand: PropTypes.func,
  isPublished: PropTypes.bool,
  handlePublish: PropTypes.func,
  stages: PropTypes.array,
  handleStageChange: PropTypes.func,
  handleBdStatusChange: PropTypes.func,
  stage: PropTypes.string,
  bdStatus: PropTypes.string,
  handleAddCandidate: PropTypes.func,
  fetchProjectData: PropTypes.func,
  handleContactClick: PropTypes.func
};

export default React.memo(HeaderOpened);
