//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

export default function ConfirmPopup(props) {
  const {
    header = 'Confirm',
    buttonSize = 'medium',
    headerStyle = {},
    message = '',
    extraClassName = '',
    onCancel,
    onClose,
    onConfirm,
    cancelText = 'No',
    confirmText = 'Yes',
    disabledCancel = false,
    show = false
  } = props;
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      setOpen(false);
      onCancel && onCancel();
    } else {
      handleClose();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm && onConfirm();
  };

  return (
    <Popup overlayStyle={{ zIndex: '1401 !important' }} open={open} modal closeOnDocumentClick={false} closeOnEscape={false} className='confirmation-popup'>
      <>
        <div style={{ minWidth: 400, ...headerStyle }} className='confirmation-content d-flex'>
          <div className='flex-grow-1 d-flex flex-column pr-5'>
            <div className='confirmation-header' dangerouslySetInnerHTML={{ __html: header }}></div>
            {message && <div className={`confirmation-message ${extraClassName}`} dangerouslySetInnerHTML={{ __html: message }}></div>}
          </div>
          <span className='close-icon d-flex align-items-center' onClick={handleClose}>
            <CloseIcon fontSize='inherit' />
          </span>
        </div>
        <div className='action-buttons'>
          {!disabledCancel && (
            <Button size={buttonSize} style={{ marginRight: 10 }} variant='text' color='primary' className='button' onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          <Button size={buttonSize} variant='contained' className='button' color='primary' onClick={handleConfirm}>
            {confirmText}
          </Button>
        </div>
      </>
    </Popup>
  );
}

ConfirmPopup.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  extraClassName: PropTypes.string,
  disabledCancel: PropTypes.bool,
  headerStyle: PropTypes.object,
  buttonSize: PropTypes.string,
  show: PropTypes.bool
};
