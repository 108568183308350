import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SourceIcon from '@mui/icons-material/Source';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { get } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../../Containers/Commons/OffLimitsWarningPopup/utils';
import AssignTag from '../../../Containers/Contacts/AssignTag';
import { AddToPipelineBtn } from '../../../Containers/Searches/ViewProject/Comman';
import contact_image from '../../../assets/images/default_contact.png';
import AddContactToProject from '../../../components/common/AddContactToProject';
import { AllDocumentsApi, CandidateStageSetupAPi, contactDocumentApi, getPdlAndContactDataApi, ignContactDataApi } from '../../../services/ApiService';
import { CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS, CONTACT_STATUS, DEFAULT_CONTACT_STATUS, ERROR, GET, IGN_API, PATCH, POST, SUCCESS } from '../../../services/constantService';
import { contactActions } from '../../../store/contactSlice';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { useOfflimit } from '../../../utils/Hooks/useOfflimit';
import { convertArrayBufferToBlobUrl } from '../../../utils/common';
import { addHttps } from '../../../utils/string';
import { default as variables } from '../../../variables.scss';
import CustomConfirmationPopup from '../../common/CustomConfirmationPopup';
import CustomPopover from '../../common/CustomPopover';
import MarkOffLimits from '../../common/MarkOffLimits';
import OffLimitWarning from '../../common/OffLimitWarning';
import ShowOffLimits from '../../common/ShowOffLimits';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import UpdateContactInfo from '../../common/UpdateContactInfo';
import EnrichContact from '../EnrichContact/EnrichContact';
import CandidateActions from './CandidateActions';
import './index.scss';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));

const AvatarStyle = withStyles({
  root: {
    height: '150px',
    width: '140px'
  }
})(Avatar);

// Define a function to convert base64 data to Blob objects and generate URIs
const convertBase64ToBlob = file => {
  if (file && file.data) {
    try {
      // Convert base64 to binary
      const binaryData = atob(file.data);
      const uint8Array = new Uint8Array([...binaryData].map(char => char.charCodeAt(0)));

      // Create Blob from Uint8Array
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      const uri = window.URL.createObjectURL(blob);
      return {
        uri: uri,
        fileName: file.fileName
      };
    } catch (error) {
      console.error('Error creating blob:', error);
      return null;
    }
  } else {
    console.error('File or file data is null or undefined:', file);
    return null;
  }
};

const ViewContactHeader = (props, ref) => {
  const {
    data,
    avatarSizeXs,
    avatarSizeMd,
    avatarSizeSm,
    mainContentWidthXs,
    mainContentWidthMd,
    mainContentWidthSm,
    buttonSizeXs,
    buttonSizeMd,
    buttonSizeSm,
    updateField,
    register,
    unregister,
    setValue = () => {},
    editingField,
    setEditingField = () => {},
    headerDrawer = () => {},
    getHeaderDetails = () => {},
    setIsEdited = () => {},
    setIsIconOpen = () => {},
    setIsHeaderNav = () => {},
    isHeaderNav,
    isIconOpen,
    setIsHeaderOpen = () => {},
    isHeaderOpen,
    offLimitItems,
    setLoading = false,
    allContact,
    projectAndActivitiesData,
    // handleProjectClick,
    isCandidateProductOne = false,
    project_id,
    selectedCandidate,
    changeStage,
    handleCandidateColorChange,
    fromCandidatePipeline,
    refetchProductOneCandidates
  } = props;
  const { t } = useTranslation();
  /* const [editing, setEditing] = React.useState({}); */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewReasonAnchorEl, setViewReasonAnchorEl] = React.useState(null);
  const [anchorElType, setAnchorElType] = React.useState(null);
  const [selectedItem, setSelectedItem] = useState(data?.contact_status_tv?.short_desc || DEFAULT_CONTACT_STATUS);
  const [dialogopen, setDialogopen] = React.useState(false);
  const [stages, setStages] = React.useState([]);
  // const [anchorElHoverPopup, setAnchorElHoverPopup] = React.useState(null);
  const [anchorElAddByHoverPopup, setAnchorElAddByHoverPopup] = useState(null);
  const [isAddToProjectOpen, setIsAddToProjectOpen] = useState(false);
  const { contactOffLimit, createDirectOfflimit, getOffLimitFromSummary, updateDirectOfflimit, deleteDirectOfflimit } = useOfflimit();
  const [isDirectOffLimitPopupOpen, setIsDirectOffLimitPopupOpen] = useState(false);
  const [showContactOffLimit, setShowContactOfflimit] = useState(false);
  const isOffLimitApplied = !!contactOffLimit.length > 0;

  const offLimitClass = useMemo(() => {
    const isHardLimitPresent = contactOffLimit?.find(offlimit => offlimit?.severity === 'hard');
    return isHardLimitPresent ? 'hard' : 'soft';
  }, [contactOffLimit]);

  const isDirectLimitApplied = contactOffLimit.find(entry => entry.type === 'Direct');
  const [profileUrl, setProfileUrl] = useState('');
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  // const [isShowProjects, setIsShowProjects] = useState(true);
  const [isShowAddedBy, setIsShowAddedBy] = useState(true);
  const edit = {
    first_name: false
  };
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [docs, setDocs] = useState([]);
  const { translateMessage } = useCustomMessageHook();

  const [editing, setEditing] = useState(edit);
  const maxVisibleTags = 1;
  // const commonLimitForOpenHeader = 30;
  // const commonLimitForcloseHeader = 30;
  const [offLimitWarningPopup, setOffLimitWarningPopup] = useState(false);
  const [isEnrichDataDialogOpen, setIsEnrichDataDialogOpen] = useState(false);
  const [comparisonData, setComparisonData] = useState(null);
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const openOfflimitWarningPopup = () => setOffLimitWarningPopup(true);
  const handleOfflimitWarningPopupClose = () => setOffLimitWarningPopup(false);

  const dispatch = useDispatch();

  const handleProjectButtonOnClick = () => {
    dispatch(contactActions.toggleButton(true)); // Dispatch the action to toggle the button state
  };

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    register('profile_img');
    register('additionalDetails');
    register('first_name');
    register('last_name');
    register('contact_name');
    return () => {
      unregister('profile_img');
      unregister('additionalDetails');
      unregister('first_name');
      unregister('last_name');
      unregister('contact_name');
    };
  }, [register, unregister]);

  // const getLimitedString = (string, limit) => {
  //   if (string?.length <= limit) {
  //     return string;
  //   } else {
  //     const limitString = string?.substring(0, limit) + '...';
  //     return <Tooltip title={string}>{limitString}</Tooltip>;
  //   }
  // };

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };

  const fetchImage = useCallback(async id => {
    const response = await contactDocumentApi(GET, id, null, null, null, 'image');
    if (response.status === 200) {
      const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
      setProfileUrl(url);
    } else {
      setProfileUrl(contact_image);
    }
  }, []);

  const fetchDocument = useCallback(async id => {
    const response = await contactDocumentApi(GET, id, null, null, null, 'document');
    setDocs(response?.data);
  }, []);

  useEffect(() => {
    if (data?.id) {
      fetchImage(data.id);
      fetchDocument(data.id);
      getOffLimitFromSummary(data.id);
    }
  }, [data?.id]);

  useEffect(() => {
    setValue('contact_name', {
      first_name: data.first_name,
      last_name: data.last_name
    });
  }, []);

  const handleEnrichDataClick = async () => {
    setIsEnrichDataDialogOpen(true);
    let response = null;
    if (data.id) {
      let pdlRefreshPayload = [];
      pdlRefreshPayload.push({
        id: data.id,
        linkedin_url: data.linkedin_url
      });
      response = await getPdlAndContactDataApi(data.id);
      setComparisonData(response.data); // Set the response in the state comparisonData
    }
  };

  const handleEnrichDataDialogClose = () => {
    setIsEnrichDataDialogOpen(false);
  };

  const open = Boolean(anchorEl);
  const openType = Boolean(anchorElType);

  const saveResume = data => {
    setSelectedFile(data);
    if (data) {
      setOpenConfirmationPopup(true);
    }
  };
  const sub_route1 = `${IGN_API.add_document}/${data?.id}/attachments`;

  const onConfirm = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append('file_name', selectedFile.name);
    bodyFormData.append('file_type', 'resume');
    bodyFormData.append('file', selectedFile);
    setLoading(true);
    const { status, data } = await AllDocumentsApi(POST, sub_route1, bodyFormData);
    processUpload(status, data, selectedFile.name);
  };

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      setLoading(false);
      // getContactDetails(data?.id)
      getHeaderDetails();
      const message = translateMessage('Successfully', false, `${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
      setOpenConfirmationPopup(false);
    } else {
      const message = translateMessage('UnableMessage', false, `${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const handleClickOpen = () => {
    setDialogopen(true);
  };
  const handleClose1 = () => {
    setDialogopen(false);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleOffLimitClick = event => {
    setAnchorElType(event.currentTarget);
  };
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOffLimitClose = () => {
    setAnchorElType(null);
  };

  // TODO may require in future
  // const maxVisibleProjects = 1;

  const maxVisibleAddedBy = 1;

  const toggleHeader = () => {
    if (isHeaderOpen) {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-open');
      setIsHeaderNav('closed');
      headerDrawer('closed');
    } else {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-closed');
      setIsHeaderNav('open');
      headerDrawer('open');
    }
  };
  const handleEditClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: true }));
  };
  const handleCloseClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: false }));
  };
  useImperativeHandle(ref, () => ({
    handleCloseClick: type => {
      return handleCloseClick(type);
    }
  }));
  // TODO may require is future
  // const openProjectPopup = (event) => {
  //   if (isShowProjects) {
  //     setIsShowProjects(!isShowProjects);
  //     setAnchorElHoverPopup(event.currentTarget);
  //   } else {
  //     setIsShowProjects(!isShowProjects);
  //     setAnchorElHoverPopup(null);
  //   }
  // };

  const openAddedByPopup = event => {
    setAnchorElAddByHoverPopup(event.currentTarget);
    if (isShowAddedBy) {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(event.currentTarget);
    } else {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(null);
    }
  };

  const handleAddToProjectClose = () => {
    setIsAddToProjectOpen(false);
  };
  const handleCloseDirectOffLimitPopup = () => {
    setIsDirectOffLimitPopupOpen(false);
  };

  const handleOffLimitInfo = () => {
    setShowContactOfflimit(false);
  };

  // TODO may require is future
  // const closePopup = () => {
  //   setAnchorElHoverPopup(null);
  //   setIsShowProjects(true);
  // };

  const closeAddedByPopup = () => {
    setAnchorElAddByHoverPopup(null);
  };

  const onComfirmOfflimitWarning = () => {
    setIsAddToProjectOpen(true);
    handleOfflimitWarningPopupClose();
    handleClose();
  };

  const handleMenuItemClick = async item => {
    const fieldValue = get(item, 'field_value');

    if (item === 'Project') {
      if (isOffLimitApplied) {
        openOfflimitWarningPopup();
      } else {
        setIsAddToProjectOpen(true);
        handleClose();
      }
    }
    if (fieldValue === 'off_limits') {
      if (isOffLimitApplied) {
        setShowContactOfflimit(true);
      } else {
        setIsDirectOffLimitPopupOpen(true);
      }
      handleOffLimitClose();
    } else if (!(item === 'Project') && !(item === 'List')) {
      setSelectedItem(item?.short_desc);
      handleOffLimitClose();
      setLoading(true);
      await ignContactDataApi(PATCH, data?.id, { contact_status: item?.field_value });
      setLoading(false);
    }
  };

  const handleProfileImage = e => {
    const file = e.target.files[0];

    if (file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
        enqueueSnackbar('Please upload a valid image file (jpeg, png, jpg)', { variant: 'error' });
        return;
      }
      setValue('profile_img', e.target.files);
      const fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      };
      fr.readAsDataURL(file);
    }
  };
  const handleCloseClickProfileImg = () => {
    setEditingField('');
  };

  const handleMouseEnter = event => {
    setViewReasonAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setViewReasonAnchorEl(null);
  };

  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };

  const showOffLimitPopup = () => {
    const offLimitPopOverOpen = Boolean(viewReasonAnchorEl);
    const id = offLimitPopOverOpen ? 'simple-popover' : undefined;
    const open = Boolean(viewReasonAnchorEl);
    const contactName = (data?.offlimit && data.offlimit[0]?.name) || '';
    const reasons = (data?.offlimit && data.offlimit[0]?.reasons) || [];
    if (reasons?.length > 0) {
      return (
        <React.Fragment>
          <Link className='view-reason-txt fs-10' onMouseEnter={handleMouseEnter}>
            {t('utils.viewReasons')}{' '}
          </Link>
          <Popover
            id={id}
            open={open}
            onClose={handleMouseLeave}
            anchorEl={viewReasonAnchorEl}
            className='reason-container'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label='spanning table'>
                <TableHead>
                  <TableRow className='theme-bg-color'>
                    <TableCell className='theme-text-color' align='center' colSpan={2}>
                      {t('utils.offLimitsReasons')}
                    </TableCell>
                  </TableRow>
                  <TableRow className='theme-bg-cool-grey'>
                    <TableCell align='center'>{t('utils.offLimitsCause')}</TableCell>
                    <TableCell align='center'>{t('utils.details')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reasons.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='theme-bg-error-color'>
                          {item.cause}
                        </TableCell>
                        <TableCell align='center'>{getMessage(item, contactName)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  // const openPopupViewMore = Boolean(anchorElHoverPopup);
  const openAddedbyViewMore = Boolean(anchorElAddByHoverPopup);

  const [additionalContent, setAdditionalContent] = useState(null);
  const onAdditionalContentClick = key => {
    if (key === CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO) {
      setAdditionalContent({ key, data: { id: data?.id, current_job_title: data?.current_job_title, contact_company: data?.contact_company } });
    }
  };
  const handleAdditionalContentClose = () => {
    setAdditionalContent(null);
  };

  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };

  const onSubmitOffLimit = async (offLimitData, setIsLoading) => {
    const obj = { ...offLimitData, contactID: data?.id };
    const isEditMode = showContactOffLimit && !!isDirectLimitApplied;
    const contactOffLimitAction = isEditMode ? updateDirectOfflimit : createDirectOfflimit;
    await contactOffLimitAction(obj);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
  };

  const onDeleteContactOffLimit = async setIsLoading => {
    await deleteDirectOfflimit(data?.id);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
  };

  const handleMoreActions = action => {
    if (action === data?.addButtonLabel) {
      handleMenuItemClick('Project');
    }
    if (action === data?.viewResumeButton) {
      handleClickOpen();
    }
  };

  const fetchCandidateStages = () => {
    CandidateStageSetupAPi(GET, `/all?projectId=${project_id}&showLogicalStages=false`).then(res => {
      setStages(res.data);
    });
  };

  const stagesWithoutLogical = useMemo(() => stages?.filter(stage => !stage?.isLogicalStage)?.map(stage => stage?.stage_name), [stages]);

  useEffect(() => {
    if (project_id) {
      fetchCandidateStages();
    }
  }, [project_id]);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const primaryColor = themeColor ? themeColor.primary_color : '#009d81';
  const textColor = themeColor ? themeColor.text_color : '#333';

  return (
    <>
      {isAddToProjectOpen && <AddContactToProject isPopupOpen={isAddToProjectOpen} handleClose={handleAddToProjectClose} data={allContact} />}
      <Dialog onClose={handleClose1} open={dialogopen} id='resume-dialog'>
        {docs && <DocViewer documents={docs?.map(file => convertBase64ToBlob(file))} pluginRenderers={DocViewerRenderers} />}
      </Dialog>

      <Box id='view-contact-header-container' pb={2}>
        <Paper elevation={3} className={`activity-bars-head ${data.disableEditing === false ? `header${isHeaderNav}` : 'logan-activity-head'}`}>
          {isHeaderNav === 'closed' ? (
            <Stack direction='row' spacing={0} className='main-sub-content-closed d-flex align-items-center px-2'>
              <Box className='main-container d-flex align-items-center' gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Box className={`avatar-preview ${!isOffLimitApplied && selectedItem === DEFAULT_CONTACT_STATUS ? 'border-green' : 'border-red'}`}>
                    <Avatar sx={{ width: '34px', height: '34px' }} src={profileUrl} />
                  </Box>
                  <Typography fontWeight={600} className='heading-test-ellipsis fs-16'>
                    {`${data?.first_name} ${data?.last_name}`}
                  </Typography>
                  {data?.linkedin_url ? (
                    <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                      <Tooltip
                        title={t('utils.viewLinkedIn')}
                        placement='top'
                        TransitionComponent={Zoom}
                        arrow
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: { offset: [0, -5] }
                              }
                            ]
                          }
                        }}
                      >
                        <LinkedInIcon className='linkedin-icon-header fs-18 ml-2 mr-2' />
                      </Tooltip>
                    </a>
                  ) : (
                    <LinkedInIcon className='disabled-link fs-18 ml-2 mr-2' />
                  )}

                  <Box display='flex' justifyContent='center'>
                    {offLimitItems && offLimitItems.length > 0 && (
                      <>
                        <Button
                          className={`off-limit-text-container ${isOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === DEFAULT_CONTACT_STATUS ? 'button-green' : 'button-red'}`}
                          size='small'
                          aria-controls='offlimit-menu'
                          aria-haspopup='true'
                          onClick={handleOffLimitClick}
                          endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                        >
                          <Box component='span' className='off-limit-text fs-13'>
                            {capitalize(selectedItem || DEFAULT_CONTACT_STATUS)}
                          </Box>
                        </Button>
                        <Button className='p-0 ml-4' sx={{ minWidth: 'auto' }}>
                          {isOffLimitApplied && (
                            <Box onClick={() => setShowContactOfflimit(true)} className='off-limit-indicator'>
                              <Tooltip placement='bottom' title={t('project.viewOffLimit')}>
                                <WarningIcon
                                  className={`offlimit-caution cursor-pointer ${isOffLimitApplied ? offLimitClass : ''}`}
                                  sx={{
                                    fontSize: 20
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          )}
                        </Button>
                      </>
                    )}

                    {offLimitItems && offLimitItems.length > 1 && (
                      <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                        {offLimitItems.map((item, index) => (
                          <MenuItem key={index} onClick={() => handleMenuItemClick(item)} className='fs-12'>
                            {item?.short_desc === 'Off-Limits' && isOffLimitApplied ? `View ${capitalize(item?.short_desc)}` : capitalize(item?.short_desc)}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1, marginLeft: '100px', marginRight: '50px' }}>
                  <Box sx={{ flexGrow: 1 }} justifyContent='space-evenly' className='mt-1 d-flex flex-row align-items-center hide-1200'>
                    {data?.additionalContent &&
                      Object.keys(data?.additionalContent)
                        .slice(0, 2)
                        .map(contentKey => (
                          <Box className='d-flex flex-row align-items-center w-100 mb-1' key={contentKey} alignItems={'center'}>
                            <Typography variant='span' className='icon-font-size-candidate' sx={{ fill: textColor }}>
                              {data?.additionalContent[contentKey]?.icon || null}
                            </Typography>
                            <Typography variant='caption' className='test-ellipsis-small-2 mt-1'>
                              {data?.additionalContent[contentKey]?.text || null}
                            </Typography>
                          </Box>
                        ))}
                    <Box className='d-flex flex-row align-items-center hide-1450 w-100'>
                      <Tooltip placement='top' title={t('project.heading')}>
                        <BusinessCenterIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                      </Tooltip>
                      <Typography component={'span'} variant='caption' className='test-ellipsis-small-2 fs-13'>
                        {projectAndActivitiesData?.length}
                      </Typography>
                    </Box>
                    <Box className='d-flex flex-row align-items-center hide-1550 w-100'>
                      <Tooltip placement='top' title={t('contacts.source')}>
                        <SourceIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                      </Tooltip>
                      <Typography component={'span'} variant='caption' className='test-ellipsis-small-2  fs-13'>
                        {data.source || '-'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* ************** BUTTONS ***********start ***/}
                <Box className='d-flex flex-row'>
                  <div>
                    {data?.screened ? (
                      <Button
                        className='banner-primary-color-new'
                        // className={data?.screen?.className}
                        startIcon={data?.screen?.icon}
                        //endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        endIcon={<ExpandMoreIcon className={`expand-icon ${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                        onClick={handleClick}
                        sx={{ color: primaryColor, border: '1px solid ' + primaryColor, '&:hover': { background: primaryColor, color: '#fff' } }}
                      >
                        {data?.screen?.text}
                      </Button>
                    ) : (
                      <Box display={'flex'} alignItems={'end'} flexDirection={'row'}>
                        {isCandidateProductOne && <AddToPipelineBtn id={selectedCandidate?.id} stage={selectedCandidate?.stage} refetch={refetchProductOneCandidates} />}
                        {/* {!isCandidateProductOne && <MoreActionsBtn />} */}

                        {fromCandidatePipeline && (
                          <CandidateActions
                            selectedCandidate={selectedCandidate}
                            handleCandidateColorChange={handleCandidateColorChange}
                            changeStage={changeStage}
                            stagesWithoutLogical={stagesWithoutLogical}
                            isHeaderClose={isHeaderNav === 'closed'}
                          />
                        )}
                        <Button
                          variant='outlined'
                          className='banner-primary-color-new'
                          size='medium'
                          onClick={handleEnrichDataClick}
                          startIcon={<AcUnitIcon className='fs-13' sx={{ color: data.pdlStatus && data.pdlStatus ? 'green' : 'red' }} />}
                          sx={{
                            marginLeft: '8px',
                            color: primaryColor,
                            border: '1px solid ' + primaryColor,
                            '&:hover': { background: primaryColor, color: '#fff' }
                          }}
                        >
                          Enrich Data
                        </Button>
                        <EnrichContact open={isEnrichDataDialogOpen} onClose={handleEnrichDataDialogClose} data={data} />
                      </Box>
                    )}
                    {additionalContent?.key === CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO && (
                      <UpdateContactInfo
                        isPopupOpen={false} // feature removed
                        handleClose={handleAdditionalContentClose}
                        data={additionalContent?.data || ''}
                        getHeaderDetails={getHeaderDetails}
                      />
                    )}
                  </div>
                  <IconButton
                    onClick={handleClick2}
                    size='small'
                    sx={{ ml: 2, mr: 2 }}
                    aria-controls={open2 ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open2 ? 'true' : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl2}
                    id='account-menu'
                    open={open2}
                    onClose={handleClose2}
                    onClick={handleClose2}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                          },
                          '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                          }
                        }
                      }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {data?.editIcon || null}
                    {data.deleteIcon || null}
                    <Typography variant='body2' className='d-flex p-1 pl-3 pb-2 fs-12 off-color'>
                      {t('utils.candidate')}
                    </Typography>
                    {!isCandidateProductOne && (
                      <>
                        <MenuItem onClick={e => handleMoreActions(data?.addButtonLabel, e)} className='pannel-menu-items d-flex align-items-center'>
                          <Box className='pannel-menu-icon mr-1'>
                            <ControlPointIcon />
                          </Box>
                          <Typography variant='body2' className='p-2 fs-14'>
                            {data?.addButtonLabel}
                          </Typography>
                        </MenuItem>

                        {data?.resume && docs?.length !== 0 ? (
                          <MenuItem onClick={e => handleMoreActions(data?.viewResumeButton, e)} className='pannel-menu-items d-flex align-items-center'>
                            <Box className='pannel-menu-icon mr-1'>
                              <PostAddIcon />
                            </Box>
                            <Typography variant='body2' className='p-2 fs-14'>
                              {data?.viewResumeButton}
                            </Typography>
                          </MenuItem>
                        ) : (
                          <MenuItem className='pannel-menu-items d-flex align-items-center'>
                            <Box className='pannel-menu-icon mr-1'>
                              <CloudUploadIcon />
                            </Box>
                            <Typography variant='body2' className='p-2 fs-14 d-flex align-items-center file-upload-buttons'>
                              <FileUploader handleChange={saveResume} className='mb-0 '>
                                {t('actions.addResume')}
                              </FileUploader>
                            </Typography>
                          </MenuItem>
                        )}
                      </>
                    )}
                  </Menu>
                </Box>
                {/* ************** BUTTONS ***********start ***/}
              </Box>
            </Stack>
          ) : (
            <>
              <Grid container className={isOffLimitApplied ? 'p-2 pl-4 padding-left-30 relative' : 'p-2 pl-4 relative'}>
                {isOffLimitApplied && (
                  <span className={`isOffLimitApplied ${isOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === DEFAULT_CONTACT_STATUS ? 'button-green' : 'button-red'}`}>
                    Off Limit Applied
                  </span>
                )}
                <Box className='d-flex flex-row w-100 mt-1'>
                  <Box sx={{ flexGrow: 1 }}>
                    <Stack alignItems={'center'} direction={'row'} sx={{ height: '36px', width: '100%' }}>
                      <div>
                        <Box className='d-flex align-items-center'>
                          {editing?.first_name && data?.disableEditing === false ? (
                            <Box className='d-flex align-items-center content-space'>
                              <Box className='w-50 mr-2' pb={1}>
                                <CustomInputField
                                  defaultValue={data?.first_name || ''}
                                  onChange={e => {
                                    setValue('contact_name.first_name', e.target.value);
                                  }}
                                  label={t('contacts.addContact.contactFirstName')}
                                />
                              </Box>
                              <Box className='w-50' pb={1}>
                                <CustomInputField
                                  defaultValue={data?.last_name || ''}
                                  onChange={e => {
                                    setValue('contact_name.last_name', e.target.value);
                                  }}
                                  label={t('contacts.addContact.contactLastName')}
                                />
                              </Box>
                              <Box className='d-flex' pr={1} pb={1}>
                                {renderActions('contact_name', false, () => handleCloseClick('first_name'))}
                              </Box>
                            </Box>
                          ) : (
                            <Box component='span' className='header-font header-text fs-18 mr-2' onDoubleClick={() => handleEditClick('first_name')}>
                              {`${data?.first_name} ${data?.last_name}`}
                            </Box>
                          )}
                          {data?.linkedin_url ? (
                            <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                              <Tooltip
                                title={t('utils.viewLinkedIn')}
                                placement='top'
                                TransitionComponent={Zoom}
                                arrow
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: 'offset',
                                        options: { offset: [0, -5] }
                                      }
                                    ]
                                  }
                                }}
                              >
                                <LinkedInIcon className='linkedin-icon-header fs-16' />
                              </Tooltip>
                            </a>
                          ) : (
                            <Tooltip
                              title={t('utils.viewLinkedIn')}
                              placement='top'
                              TransitionComponent={Zoom}
                              arrow
                              slotProps={{
                                popper: {
                                  modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                                }
                              }}
                            >
                              <LinkedInIcon className='disabled-link fs-14' />
                            </Tooltip>
                          )}
                        </Box>
                      </div>
                      <div>
                        <Box display='flex' justifyContent='center'>
                          {offLimitItems && offLimitItems.length > 0 && (
                            <Button
                              className={`off-limit-text-container-collapse position-relative ${isOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === DEFAULT_CONTACT_STATUS ? 'button-green' : 'button-red'}`}
                              size='small'
                              aria-controls='offlimit-menu'
                              aria-haspopup='true'
                              onClick={handleOffLimitClick}
                              endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                            >
                              <Box component='span' className='off-limit-text fs-13'>
                                {capitalize(selectedItem || DEFAULT_CONTACT_STATUS)}
                              </Box>
                            </Button>
                          )}
                          <Button className='p-0 ml-4' sx={{ minWidth: 'auto' }}>
                            {isOffLimitApplied && (
                              <Box onClick={() => setShowContactOfflimit(true)} className='off-limit-indicator'>
                                <Tooltip placement='bottom' title={t('project.viewOffLimit')}>
                                  <WarningIcon
                                    className={`offlimit-caution cursor-pointer ${isOffLimitApplied ? offLimitClass : ''}`}
                                    sx={{
                                      fontSize: 20
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            )}
                          </Button>
                        </Box>
                        {selectedItem === CONTACT_STATUS.OFF_LIMITS && <React.Fragment>{showOffLimitPopup()}</React.Fragment>}
                        {offLimitItems && offLimitItems.length > 1 && (
                          <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                            {offLimitItems.map((item, index) => (
                              <MenuItem key={index} onClick={() => handleMenuItemClick(item)} className='fs-12'>
                                {item?.short_desc === 'Off-Limits' && isOffLimitApplied ? `View ${capitalize(item?.short_desc)}` : capitalize(item?.short_desc)}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      </div>
                    </Stack>
                  </Box>
                  {/* ************** BUTTONS ***********start ***/}
                  <Box className='d-flex flex-row'>
                    <div>
                      {data?.screened ? (
                        <Button
                          className='banner-primary-color-new'
                          // className={data?.screen?.className}
                          startIcon={data?.screen?.icon}
                          //endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          endIcon={<ExpandMoreIcon className={`expand-icon ${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                          onClick={handleClick}
                          sx={{ color: primaryColor, border: '1px solid ' + primaryColor, '&:hover': { background: primaryColor, color: '#fff' } }}
                        >
                          {data?.screen?.text}
                        </Button>
                      ) : (
                        <Box display={'flex'} alignItems={'end'} flexDirection={'row'}>
                          {isCandidateProductOne && <AddToPipelineBtn id={selectedCandidate?.id} stage={selectedCandidate?.stage} refetch={refetchProductOneCandidates} />}
                          {/* {!isCandidateProductOne && <MoreActionsBtn />} */}

                          {fromCandidatePipeline && (
                            <CandidateActions
                              selectedCandidate={selectedCandidate}
                              handleCandidateColorChange={handleCandidateColorChange}
                              changeStage={changeStage}
                              stagesWithoutLogical={stagesWithoutLogical}
                              isHeaderClose={isHeaderNav === 'closed'}
                            />
                          )}
                          <Button
                            variant='outlined'
                            className='banner-primary-color-new'
                            size='medium'
                            onClick={handleEnrichDataClick}
                            startIcon={<AcUnitIcon className='fs-13' sx={{ color: data.pdlStatus && data.pdlStatus ? 'green' : 'red' }} />}
                            sx={{
                              marginLeft: '8px',
                              color: primaryColor,
                              border: '1px solid ' + primaryColor,
                              '&:hover': { background: primaryColor, color: '#fff' }
                            }}
                          >
                            Enrich Data
                          </Button>
                          <EnrichContact open={isEnrichDataDialogOpen} onClose={handleEnrichDataDialogClose} data={data} />
                        </Box>
                      )}
                      {additionalContent?.key === CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO && (
                        <UpdateContactInfo
                          isPopupOpen={false} // feature removed
                          handleClose={handleAdditionalContentClose}
                          data={additionalContent?.data || ''}
                          getHeaderDetails={getHeaderDetails}
                        />
                      )}
                    </div>

                    <IconButton onClick={handleClick2} size='small' sx={{ ml: 2 }} aria-controls={open2 ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open2 ? 'true' : undefined}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl2}
                      id='account-menu'
                      open={open2}
                      onClose={handleClose2}
                      onClick={handleClose2}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1
                            },
                            '&::before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0
                            }
                          }
                        }
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      {data?.editIcon || null}
                      {data.deleteIcon || null}
                      <Typography variant='body2' className='d-flex p-1 pl-3 pb-2 fs-12 off-color'>
                        {t('utils.candidate')}
                      </Typography>
                      {!isCandidateProductOne && (
                        <>
                          <MenuItem onClick={e => handleMoreActions(data?.addButtonLabel, e)} className='pannel-menu-items d-flex align-items-center'>
                            <Box className='pannel-menu-icon mr-1'>
                              <ControlPointIcon />
                            </Box>
                            <Typography variant='body2' className='p-2 fs-14'>
                              {data?.addButtonLabel}
                            </Typography>
                          </MenuItem>

                          {data?.resume && docs?.length !== 0 ? (
                            <MenuItem onClick={e => handleMoreActions(data?.viewResumeButton, e)} className='pannel-menu-items d-flex align-items-center'>
                              <Box className='pannel-menu-icon mr-1'>
                                <PostAddIcon />
                              </Box>
                              <Typography variant='body2' className='p-2 fs-14'>
                                {data?.viewResumeButton}
                              </Typography>
                            </MenuItem>
                          ) : (
                            <MenuItem className='pannel-menu-items d-flex align-items-center'>
                              <Box className='pannel-menu-icon mr-1'>
                                <CloudUploadIcon />
                              </Box>
                              <Typography variant='body2' className='p-2 fs-14 d-flex align-items-center file-upload-buttons'>
                                <FileUploader handleChange={saveResume} className='mb-0 '>
                                  {t('actions.addResume')}
                                </FileUploader>
                              </Typography>
                            </MenuItem>
                          )}
                        </>
                      )}
                    </Menu>
                  </Box>
                  {/* ************** BUTTONS ***********start ***/}
                </Box>
                <Box className='d-flex flex-row w-100 mt-2 mb-2'>
                  <Box className='d-flex mr-2 project-Avatar-contact'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '80px'
                      }}
                    >
                      {data?.avatar && data?.disableEditing ? (
                        <Box className={`avatar-preview ${selectedItem === DEFAULT_CONTACT_STATUS ? 'border-green' : 'border-red'} mr-1`}>
                          <Box id='imagePreview'>
                            <AvatarStyle className='log-an-activity-profile-image' src={profileUrl} />
                          </Box>
                        </Box>
                      ) : (
                        <Box className='avatar-upload'>
                          <Box className='avatar-edit'>
                            <input
                              type='file'
                              id='imageUpload'
                              accept='.png, .jpg, .jpeg'
                              onClick={() => {
                                setEditingField('profile_img');
                              }}
                              onChange={e => handleProfileImage(e)}
                              className='d-none'
                            />
                            <InputLabel htmlFor='imageUpload'>
                              <Box>
                                <EditIcon className='editIcon fs-40' />
                              </Box>
                            </InputLabel>
                          </Box>
                          <Box position={'relative'}>
                            <Box className={`avatar-preview ${!isOffLimitApplied && selectedItem === DEFAULT_CONTACT_STATUS ? 'border-green' : 'border-red'} mr-1`}>
                              <Box id='imagePreview'>
                                <AvatarStyle className='image-size' src={profileUrl} />
                              </Box>
                            </Box>
                            {editingField === 'profile_img' && (
                              <Box position={'absolute'} top={0} right={-12}>
                                {renderActions('profile_img', true, handleCloseClickProfileImg)}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </div>
                  </Box>
                  <Box className='d-flex w-100 ml-4'>
                    {data?.mainContent ? null : (
                      <>
                        <Grid item xs={6} md={3.5} sm={6} className={`${data?.additionalContentClass?.className} col_2`}>
                          <Stack direction={'column'} spacing={0.5}>
                            {data?.additionalContent &&
                              Object.keys(data?.additionalContent).map(contentKey => (
                                <Box onDoubleClick={() => onAdditionalContentClick(contentKey)} key={contentKey}>
                                  <Box className='d-flex flex-row align-items-center' style={{ height: '27px' }}>
                                    <Typography variant='span' className='icon-font-size-candidate' sx={{ fill: textColor }}>
                                      {data?.additionalContent[contentKey]?.icon || null}
                                    </Typography>
                                    <Typography
                                      variant='body'
                                      className='test-ellipsis fs-13'
                                      onClick={data?.additionalContent[contentKey]?.text ? data?.additionalContent[contentKey]?.onClick : () => {}}
                                      sx={{
                                        display: 'inline'
                                      }}
                                    >
                                      <Typography variant='body' sx={{ cursor: `${data?.additionalContent[contentKey]?.onClick ? 'pointer' : 'default'}` }}>
                                        {data?.additionalContent[contentKey]?.text}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                </Box>
                              ))}
                          </Stack>
                        </Grid>
                        {/* ***************SECOND COLUMN DATA (recent- engagement -tag)**********start: expand ****/}
                        <Grid item xs={6} md={3.5} sm={6} className={'col_2'}>
                          <Stack direction={'column'} spacing={0.5}>
                            <Box className='d-flex flex-row align-items-center'>
                              <Tooltip placement='top' title={t('contacts.addContact.phone')}>
                                <PhoneAndroidIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                              </Tooltip>
                              <Typography component={'span'} variant='caption' className='test-ellipsis-2'>
                                {data.lastActivityTime.text || '-'}
                              </Typography>
                            </Box>

                            <Box className='d-flex flex-row align-items-center'>
                              <Tooltip placement='top' title={t('contacts.addContact.email')}>
                                <MarkunreadIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                              </Tooltip>
                              <Typography component={'span'} variant='caption' className='test-ellipsis-2'>
                                {data?.recentEngagement?.text || ''}
                              </Typography>
                            </Box>
                            <Box className='d-flex flex-row align-items-center'>
                              <Tooltip placement='top' title={t('common.tags')}>
                                <BookmarkIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                              </Tooltip>

                              {data?.contact_tags.length !== 0 ? (
                                data?.contact_tags.slice(0, maxVisibleTags).map((tag, index) => (
                                  <Box key={index} className={`tag-border font-normal fs-13 mr-2 tag${tag?.ign_tags?.name}`}>
                                    {tag?.ign_tags?.name}
                                  </Box>
                                ))
                              ) : (
                                <p className='fs-12 font-normal mr-1'>N/A</p>
                              )}

                              <CustomTooltip
                                title={
                                  data?.contact_tags?.slice(1)?.length !== 0 ? (
                                    <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                                      {data?.contact_tags?.slice(1)?.map((tag, index) => (
                                        <Box key={index} className={`tag-border mr-2 tag${tag?.ign_tags?.name}`}>
                                          <p style={{ fontSize: 12, fontWeight: 600 }}>{tag?.ign_tags?.name}</p>
                                        </Box>
                                      ))}
                                    </Box>
                                  ) : (
                                    ''
                                  )
                                }
                                placement='top'
                              >
                                <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600 }}>
                                  {data?.contact_tags?.slice(1)?.length ? `+${data?.contact_tags?.length - 1}` : ''}
                                </p>
                              </CustomTooltip>

                              <Link
                                component='button'
                                onClick={() => {
                                  handleAssignTag();
                                }}
                              >
                                <p style={{ fontSize: 12, fontWeight: 600, color: primaryColor }}>+Tag</p>
                              </Link>
                            </Box>
                            <Box className='mb-2'>
                              <AssignTag
                                tagPopUp={data?.contact_tags}
                                tagId={data?.id}
                                setIsPopupOpen={setIsTagPopupOpen}
                                isPopupOpen={isTagPopupOpen}
                                label={t('utils.selectTag')}
                                getHeaderDetails={getHeaderDetails}
                                setIsEdited={setIsEdited}
                              />
                            </Box>
                          </Stack>
                        </Grid>
                        {/* ***************SECOND COLUMN DATA (recent- engagement -tag)**********end: expand ****/}
                        {/* ***************THIRD COLUMN DATA (added_by and project)**********start: expand ****/}
                        <Grid item xs={12} md={5} sm={12} className='col_8'>
                          <Stack direction={'column'} spacing={0.5}>
                            {data?.addedByTitle && (
                              <Box className='d-flex flex-row align-items-center'>
                                <Tooltip placement='top' title={t('project.projectHeader.contactAddedBy')}>
                                  <PersonAddAlt1Icon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                                </Tooltip>
                                {data?.contact_added_by?.length !== 0 ? (
                                  <Box className='d-flex'>
                                    {data.contact_added_by.length > maxVisibleAddedBy && (
                                      <Box className='fs-13 d-flex align-items-center'>
                                        <Box
                                          aria-owns={openAddedbyViewMore ? 'mouse-over-popover-added-by' : undefined}
                                          aria-haspopup='true'
                                          onClick={openAddedByPopup}
                                          sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                          }}
                                        >
                                          {`+${data?.contact_added_by.length - maxVisibleAddedBy}`}
                                          <CustomPopover
                                            id='mouse-over-popover-added-by'
                                            open={openAddedbyViewMore}
                                            anchorEl={anchorElAddByHoverPopup}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left'
                                            }}
                                            onClose={closeAddedByPopup}
                                          >
                                            <Box onClick={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
                                              {data?.contact_added_by && (
                                                <Box
                                                  className='d-flex flex-column align-items-center overflow-auto'
                                                  sx={{
                                                    maxHeight: '75px',
                                                    height: 'fit-content'
                                                  }}
                                                >
                                                  {data?.contact_added_by.slice(maxVisibleAddedBy).map((addedBy, index) => (
                                                    <Box
                                                      key={index}
                                                      component='span'
                                                      // Removed classes: rectangle-blue p-1
                                                      className='fs-13 m-1'
                                                      sx={{
                                                        borderRadius: '4px',
                                                        backgroundColor: variables.heading,
                                                        boxSizing: 'border-box',
                                                        color: variables.titleBarBackground
                                                      }}
                                                    >
                                                      {/* <Box
                                                        component="span"
                                                        pr={1}
                                                      >
                                                        {data?.addedBy?.icon
                                                          ? data?.addedBy?.icon
                                                          : ""}
                                                      </Box> */}
                                                      {addedBy?.added_user?.name || ''}
                                                    </Box>
                                                  ))}
                                                </Box>
                                              )}
                                            </Box>
                                          </CustomPopover>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  '-'
                                )}
                              </Box>
                            )}
                            <Box className='d-flex flex-row align-items-center'>
                              <Tooltip placement='top' title={t('project.heading')}>
                                <BusinessCenterIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                              </Tooltip>
                              <Typography component={'span'} className='fs-13 cursor-pointer display-inline' onClick={handleProjectButtonOnClick}>
                                {projectAndActivitiesData?.length}
                              </Typography>
                            </Box>
                            <Box className='d-flex flex-row align-items-center'>
                              <Tooltip placement='top' title={t('contacts.source')}>
                                <SourceIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                              </Tooltip>
                              <Typography component={'span'} variant='caption' className='test-ellipsis fs-13'>
                                {data.source || '-'}
                              </Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Paper>
      </Box>

      {isDirectOffLimitPopupOpen && (
        <MarkOffLimits
          data={[isDirectLimitApplied]}
          editMode={showContactOffLimit && !!isDirectLimitApplied}
          isPopupOpen={isDirectOffLimitPopupOpen}
          handleClose={handleCloseDirectOffLimitPopup}
          onSubmit={onSubmitOffLimit}
          onDelete={onDeleteContactOffLimit}
        />
      )}

      {showContactOffLimit && (
        <ShowOffLimits
          showInherited={true}
          showDirect={true}
          isPopupOpen={true}
          handleClose={handleOffLimitInfo}
          onEdit={() => {
            setIsDirectOffLimitPopupOpen(true);
          }}
          data={contactOffLimit}
        />
      )}

      <OffLimitWarning open={offLimitWarningPopup} onClose={handleOfflimitWarningPopupClose} onConfirm={onComfirmOfflimitWarning} offlimits={contactOffLimit} />

      <CustomConfirmationPopup
        open={openConfirmationPopup}
        onClose={closeConfirmationPopup}
        type='Upload Document'
        deletingItem='contact'
        customMessage='Are you sure you want Upload Resume?'
        // deletingItemName={contact?.data?.name}
        cancelText='No'
        confirmText='Yes'
        onConfirm={onConfirm}
        setOpen={setOpenConfirmationPopup}
      />

      {data.disableEditing === false && (
        <Box id='view-header-icon' className='toggle-header' onClick={toggleHeader}>
          <ExpandMoreRoundedIcon cursor='pointer' className={`header-arrow-icon fs-20 ${isIconOpen}`} />
        </Box>
      )}
    </>
  );
};

ViewContactHeader.propTypes = {
  data: PropTypes.object,
  avatarSizeXs: PropTypes.number,
  avatarSizeMd: PropTypes.number,
  avatarSizeSm: PropTypes.number,
  mainContentWidthXs: PropTypes.number,
  mainContentWidthMd: PropTypes.number,
  mainContentWidthSm: PropTypes.number,
  buttonSizeXs: PropTypes.number,
  buttonSizeMd: PropTypes.number,
  buttonSizeSm: PropTypes.number,
  updateField: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  headerDrawer: PropTypes.func,
  watch: PropTypes.func,
  editingField: PropTypes.string,
  setEditingField: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  setIsEdited: PropTypes.func,
  setIsHeaderBar: PropTypes.func,
  isHeaderBar: PropTypes.string,
  setIsIconOpen: PropTypes.func,
  isIconOpen: PropTypes.string,
  setIsHeaderOpen: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
  setIsHeaderNav: PropTypes.func,
  isHeaderNav: PropTypes.string,
  offLimitItems: PropTypes.array,
  setLoading: PropTypes.bool,
  allContact: PropTypes.object,
  projectAndActivitiesData: PropTypes.string,
  handleProjectClick: PropTypes.func,
  isCandidateProductOne: PropTypes.bool,
  project_id: PropTypes.string,
  selectedCandidate: PropTypes.object,
  changeStage: PropTypes.func,
  handleCandidateColorChange: PropTypes.func,
  fromCandidatePipeline: PropTypes.bool,
  refetchProductOneCandidates: PropTypes.func
};

export default forwardRef(ViewContactHeader);
