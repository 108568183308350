import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SegmentIcon from '@mui/icons-material/Segment';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPickLists } from '../../../actions';
import AddToWorkbench from '../../../components/common/AddToWorkbench';
import CustomOptions from '../../../components/common/CustomCheckbox';
import CustomModal from '../../../components/common/CustomModal';
import CloneProjectModal from '../../../components/common/CustomModal/ProjectCloneModal';
import { BillingInfoApi, ProjectDataApi } from '../../../services/ApiService';
import { POST, ROUTES } from '../../../services/constantService';
import CompanyImageAvatar from '../CompanyImageAvatar';
import ViewReportDrawer from './CandidatePipeline/ViewReportDrawer';
import { ProjectDataContext } from './Context';
import LinkExistingProject from './LinkExistingProject';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));
function HeaderClosed(props) {
  const { handleAddCandidate, handleExpand, isPublished, handlePublish, stages, handleStageChange = () => {}, handleBdStatusChange = () => {}, stage, bdStatus, handleContactClick = () => {} } = props;
  const { projectData, bgData, isBD } = useContext(ProjectDataContext);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const bdStatusList = useSelector(state => state.commonReducer.bdStatus);
  const [bdStatusOptions, setBdStatusOptions] = useState([]);
  const [themeColor, setThemeColor] = useState({});
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  const [viewReport, setViewReport] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isWorkbenchModalOpen, setIsWorkbenchModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]); // will be a state for selected options for cloning project
  const [showExistingProject, setShowExistingProject] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState(null);
  const [candidateStages, setCandidateStages] = useState([null]);

  const tr = useTranslation();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (brandConfigs) {
      setThemeColor(brandConfigs);
    }
  }, [brandConfigs]);

  useEffect(() => {
    if (!bdStatusList) {
      dispatch(fetchPickLists('BD_STATUS', 'bdStatus'));
    } else {
      setBdStatusOptions(bdStatusList);
    }
  }, [bdStatusList, dispatch]);

  const handleExpandFunction = () => {
    handleExpand(false);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMoreActions = (val, e) => {
    if (val === 'Published' || val === 'Unpublished') {
      handlePublish(e);
    } else if (val === tr.t('project.cloneProject')) {
      setIsProjectModalOpen(true);
    } else if (val === tr.t('utils.addToWorkbench')) {
      setIsWorkbenchModalOpen(true);
    } else if (val === tr.t('project.viewClientPortal')) {
      window.open(`/client-portal?show=all&project=${projectData?.id}`, '_blank');
    }
  };
  const handleLinkExistingProject = () => {
    setShowExistingProject(true);
  };
  const handleAddProject = key => {
    if (key === tr.t('project.linkExistingProject')) {
      handleLinkExistingProject();
    } else if (key === tr.t('project.createNewProject')) {
      setIsModalOpen(true);
    }
  };
  const leadConsultant = bgData?.partners?.find(partner => partner?.is_lead === true);
  const leadConsultantContactId = leadConsultant?.user?.user_contacts?.[0]?.id;

  const handleCreateNewProject = async bdStatus => {
    try {
      /**
       * for creation => required fields are:
       *  - company name
       *  - job title
       *  - industry name
       *  - location
       */
      /** API TO CREATE PROJECT ===>>> STARTS */
      const createProjectPayload = {
        location_place_id: projectData?.location_place_id,
        location: projectData?.location,
        company_id: projectData?.company_id,
        industry_id: projectData?.industry_id,
        job_title: projectData?.job_title,
        bd_project_id: projectData.id,
        bd_currency: projectData?.currency,
        job_type: projectData?.job_type || '',
        max_compensation: projectData?.projectData || 0,
        max_experience: projectData?.max_experience || 0,
        min_compensation: projectData?.min_compensation || 0,
        min_experience: projectData?.min_experience || 0,
        pay_frequency: projectData?.pay_frequency || '',
        stage: 'Draft'
      };
      setIsLoading(true);
      const { data: response } = await ProjectDataApi(POST, '', createProjectPayload, '', '');
      /** API TO CREATE PROJECT ===>>> ENDS */

      /**
       * POST CREATION ADD API TO CLONE PREFERENCES
       */
      const createdProjectId = response.id;

      /** API TO CLONE OVERVIEW ===>>> STARTS */
      if (selectedOptions.includes('overview')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-overview-from-bd'
        );
      }
      /** API TO CLONE OVERVIEW ===>>> ENDS */

      /** API TO CLONE BILLING INFO ===>>> STARTS */
      if (selectedOptions.includes('billing')) {
        await BillingInfoApi(
          POST,
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'replicate_revenues'
        );
      }
      /** API TO CLONE BILLING INFO ===>>> ENDS */

      /** API TO CLONE ROADMAP ===>>> STARTS */
      if (selectedOptions.includes('roadmap')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-roadmap-from-bd'
        );
      }
      /** API TO CLONE ROADMAP ===>>> ENDS */

      /** API TO CLONE CANDIDATE PIPELINE ===>>> STARTS */
      if (selectedOptions.includes('candidatePipeline')) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'copy-candidates-from-project'
        );
      }
      /** API TO CLONE CANDIDATE PIPELINE ===>>> ENDS */

      /** UPDATE BD STATUS */
      if (bdStatus) {
        await handleBdStatusChange(bdStatus);
      }

      // Update created project Id
      setCreatedProjectId(createdProjectId);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  // navigate to newly created project
  const onProjectCloneSuccess = () => {
    // Open the newly created project in a new tab
    const path = ROUTES.vProject.replace(':id', createdProjectId);
    window.open(path, '_blank');
  };

  const primaryColor = themeColor ? themeColor.primary_color : '#009d81';
  const textColor = themeColor ? themeColor.text_color : '#333';
  return (
    <>
      <Grid
        container
        className='p-2 pl-4'
        sx={{
          backgroundColor: 'titleBarBackground.main',
          width: '100%',
          borderRadius: '12px',
          position: 'relative',
          boxShadow: '0px 3px 5px 0px rgba(224, 224, 224, 1)'
        }}
      >
        <Box className='d-flex flex-row w-100 mt-1'>
          <Box className='d-flex mr-2 project-Avatar-small'>
            <CompanyImageAvatar
              id={projectData?.ign_companies?.id}
              className='project-Avatar-small'
              sx={{
                margin: 'auto',
                borderRadius: '50%'
              }}
            />
          </Box>
          <Box>
            <Stack alignItems={'center'} direction={'row'} sx={{ height: '36px', width: '100%' }}>
              <Typography variant='h2' className='fs-18 heading-test-ellipsis' sx={{ fontWeight: 600, marginTop: '-5px', lineHeight: 2 }}>
                {projectData?.job_title}
              </Typography>
              {!!projectData?.job_number && (
                <Typography variant='caption' className='ml-3 mr-3' sx={{ whiteSpace: 'nowrap' }}>
                  {projectData?.record_type} : {projectData?.job_number}
                </Typography>
              )}
              {!isBD ? (
                <Select
                  components={'div'}
                  className='stage-drop-down'
                  size='small'
                  sx={{ maxWidth: '150px', backgroundColor: primaryColor }}
                  value={stage ?? ''}
                  onChange={e => handleStageChange(e.target.value)}
                >
                  {stages
                    .filter(stage => stage.name.toLowerCase() !== 'bd draft')
                    .map(stage => (
                      <MenuItem key={stage.id} value={stage.name}>
                        {stage.name}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Select
                  components={'div'}
                  className='stage-drop-down'
                  size='small'
                  sx={{ maxWidth: '150px', backgroundColor: primaryColor }}
                  value={bdStatus ?? ''}
                  onChange={e => handleBdStatusChange(e.target.value)}
                >
                  {bdStatusOptions.map(bdStatusRecord => (
                    <MenuItem key={bdStatusRecord.short_desc} value={bdStatusRecord?.field_value}>
                      {bdStatusRecord.short_desc}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Stack>
          </Box>
          <Box sx={{ flexGrow: 1, marginLeft: '100px', marginRight: '50px' }}>
            <Box sx={{ flexGrow: 1 }} justifyContent='space-evenly' className='mt-1 d-flex flex-row align-items-center '>
              <Box className='d-flex flex-row align-items-center hide-992 w-100'>
                <WorkIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                <Typography variant='caption' className='pe-1 test-ellipsis'>
                  <Link
                    className='panel-text-link'
                    style={{
                      color: textColor
                    }}
                    target='_blank'
                    to={`${ROUTES.allCompanies}/${projectData?.ign_companies?.id}`}
                  >
                    {projectData?.ign_companies?.name}{' '}
                  </Link>
                </Typography>
              </Box>
              <Box className='d-flex flex-row align-items-center hide-992 w-100'>
                <LocationOnIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                {projectData?.location?.length > 20 ? (
                  <Tooltip placement='bottom' title={projectData?.location}>
                    <Typography variant='caption' className='test-ellipsis-small'>
                      {projectData?.location}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant='caption' className='test-ellipsis-small'>
                    {projectData?.location}
                  </Typography>
                )}
              </Box>
              {isBD && leadConsultant && (
                <Box className='d-flex flex-row align-items-center hide-1200 w-100'>
                  <SupervisedUserCircleIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  <Typography component={'span'} variant='caption' className='test-ellipsis-small'>
                    <Link
                      style={{
                        color: 'black',
                        textDecoration: leadConsultantContactId ? 'underline' : 'none',
                        fontSize: '0.8rem',
                        cursor: leadConsultantContactId ? 'pointer' : 'default'
                      }}
                      target={leadConsultantContactId ? '_blank' : '_self'}
                      to={leadConsultantContactId ? `${ROUTES.allContactGrid}/${leadConsultantContactId}` : '#'}
                    >
                      {leadConsultant?.user?.name}
                    </Link>
                  </Typography>
                </Box>
              )}

              {!isBD && (
                <Box className='d-flex flex-row align-items-center hide-1200  w-100'>
                  <AccountBoxIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  {!isBD && (
                    <Typography component={'span'} variant='caption' className='test-ellipsis'>
                      <Link
                        style={{
                          color: 'black',
                          textDecoration: 'underline',
                          display: 'inline'
                        }}
                        target='_blank'
                        to={`${ROUTES.allContactGrid}/${projectData?.hiring_manager?.[0]?.contact?.id}`}
                      >
                        {projectData?.hiring_manager?.[0]?.contact?.name}
                      </Link>
                      {!isBD && projectData?.hiring_manager?.slice(1)?.length !== 0 && (
                        <CustomTooltip
                          title={
                            <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                              {projectData?.hiring_manager?.slice(1)?.map(item => (
                                <Link
                                  style={{
                                    color: 'black'
                                  }}
                                  key={item?.contact?.id}
                                  target='_blank'
                                  to={`${ROUTES.allContactGrid}/${item?.contact?.id}`}
                                >
                                  <p style={{ fontSize: 12, fontWeight: 600 }}>{item?.contact?.name}</p>
                                </Link>
                              ))}
                            </Box>
                          }
                          placement='top'
                          arrow
                        >
                          <Typography component={'span'} className='ml-1 cursor-pointer' style={{ fontSize: 12, fontWeight: 600, color: 'black', display: 'inline' }}>
                            {projectData?.hiring_manager?.slice(1)?.length ? `+${projectData.hiring_manager?.length - 1}` : ''}
                          </Typography>
                        </CustomTooltip>
                      )}
                    </Typography>
                  )}
                </Box>
              )}
              {!isBD && (
                <Box className='d-flex flex-row align-items-center hide-1450  w-100'>
                  <ApartmentIcon className='p-1 icon-font-size' sx={{ fill: textColor }} />
                  <Typography component={'span'} variant='caption' className='test-ellipsis-small'>
                    {projectData?.ign_industries?.name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box className='d-flex flex-row'>
            <Button
              className='banner-primary-color'
              show={!isBD}
              onClick={() => {
                setViewReport(true);
              }}
              sx={{ color: primaryColor, border: '1px solid ' + primaryColor, '&:hover': { background: primaryColor, color: '#fff' } }}
            >
              <LibraryBooksIcon />
              {tr.t('common.viewReports')}
            </Button>
            <IconButton onClick={handleClick} size='small' sx={{ ml: 2 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {isBD ? (
                <>
                  {[
                    {
                      icon: <ControlPointIcon />,
                      label: t('project.createNewProject')
                    },
                    {
                      icon: <AddLinkIcon />,
                      label: t('project.linkExistingProject')
                    }
                  ].map((option, index) => (
                    <MenuItem onClick={e => handleAddProject(option?.label, e)} key={index} className='pannel-menu-items d-flex align-items-center'>
                      <Box className='pannel-menu-icon mr-1'>{option.icon}</Box>
                      <Typography variant='body2' className='p-2 fs-14'>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </>
              ) : (
                <>
                  <Typography variant='body2' className='d-flex p-1 pl-3 pb-2 fs-12 off-color'>
                    {tr.t('module.project')}
                  </Typography>
                  {[
                    {
                      icon: <ContentCopyIcon />,
                      label: t('project.cloneProject')
                    },
                    {
                      icon: <ImportantDevicesIcon />,
                      label: t('project.viewClientPortal')
                    },
                    {
                      icon: <SegmentIcon />,
                      label: t('utils.addToWorkbench')
                    },
                    isPublished
                      ? {
                          icon: <CheckCircleIcon />,
                          label: t('project.published')
                        }
                      : {
                          icon: <UnpublishedIcon />,
                          label: t('project.projectUnPublished')
                        }
                  ].map((option, index) => (
                    <MenuItem onClick={e => handleMoreActions(option?.label, e)} key={index} className='pannel-menu-items d-flex align-items-center'>
                      <Box className='pannel-menu-icon mr-1'>{option.icon}</Box>
                      <Typography variant='body2' className='p-2 fs-14'>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                  <Typography variant='body2' className='d-flex p-1 pl-3 pb-2 fs-12 off-color'>
                    {tr.t('utils.candidate')}
                  </Typography>
                  {[
                    {
                      icon: <ControlPointIcon />,
                      label: t('project.quickAdd')
                    },
                    {
                      icon: <PostAddIcon />,
                      label: t('project.addFromResume')
                    },
                    {
                      icon: <HowToRegIcon />,
                      label: t('project.selectExistingContact')
                    }
                  ].map((option, index) => (
                    <MenuItem onClick={e => handleAddCandidate(option?.label, e)} key={index} className='pannel-menu-items d-flex align-items-center'>
                      <Box className='pannel-menu-icon mr-1'>{option.icon}</Box>
                      <Typography variant='body2' className='p-2 fs-14'>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </>
              )}
            </Menu>
          </Box>
        </Box>
        <ExpandMoreIcon
          sx={{
            position: 'absolute',
            bottom: '-10px',
            left: '48%',
            backgroundColor: 'primary.main',
            color: 'titleBarBackground.main',
            borderRadius: '50%',
            cursor: 'pointer'
          }}
          onClick={() => handleExpandFunction()}
        />
      </Grid>

      {isModalOpen && isBD && (
        <CustomModal
          title='Create Project'
          isModalOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setIsSuccess(false);
            setSelectedOptions([]);
          }}
          onSubmit={handleCreateNewProject}
          isSuccess={isSuccess}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          isLoading={isLoading}
          onSuccess={onProjectCloneSuccess}
          bdStatusOptions={bdStatusOptions}
        >
          <CustomOptions value='overview' label='Overview' />
          <CustomOptions value='billing' label='Billing' />
          <CustomOptions value='roadmap' label='Roadmap' />
          <CustomOptions value='candidatePipeline' label='Candidate Pipeline' />
        </CustomModal>
      )}
      {isProjectModalOpen && !isBD && (
        <CloneProjectModal
          title='Clone Project'
          open={isProjectModalOpen}
          onClose={() => setIsProjectModalOpen(false)}
          projectData={projectData}
          setCreatedProjectId={setCreatedProjectId}
          onSuccess={onProjectCloneSuccess}
          candidateStages={candidateStages}
        />
      )}
      {isWorkbenchModalOpen && !isBD && (
        <AddToWorkbench isPopupOpen={isWorkbenchModalOpen} isProject={true} moduleIds={[projectData.id]} handleClose={() => setIsWorkbenchModalOpen(false)}></AddToWorkbench>
      )}
      <LinkExistingProject companyId={projectData?.company_id} bgId={bgData?.id} open={showExistingProject} onClose={() => setShowExistingProject(false)} />

      {viewReport && <ViewReportDrawer fromHeaderBar projectName={projectData?.job_title} isDrawerOpen={viewReport} setIsDrawerOpen={setViewReport} projectId={projectData?.id} />}
    </>
  );
}

HeaderClosed.propTypes = {
  handleExpand: PropTypes.func,
  isPublished: PropTypes.bool,
  handlePublish: PropTypes.func,
  stages: PropTypes.array,
  handleStageChange: PropTypes.func,
  handleBdStatusChange: PropTypes.func,
  stage: PropTypes.string,
  bdStatus: PropTypes.string,
  handleContactClick: PropTypes.func,
  handleAddCandidate: PropTypes.func
};

export default HeaderClosed;
