import { Box, Snackbar, TextField } from '@mui/material';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import Loader from '../../../components/common/Loader';
import QuickAddContact from '../../../components/common/QuickAddContact/v1/QuickAddContact';
import { QUICK_ADD_CONTACT_COMMON_FIELD } from '../../../components/common/QuickAddContact/v1/utils/Constants';
import ResumeParser from '../../../components/common/ResumeParser';
import ConfirmPopup from '../../../components/ConfirmPopup/index';
import ViewContactDrawer from '../../../components/ViewContactDrawer';
import { projectSelector } from '../../../selectors';
import {
  BDDataApi,
  CandidateColorsDataApi,
  CandidateStageSetupAPi,
  IgnContactFromResumeApi,
  PositionProfileDataApi,
  ProjectCandidateApi,
  ProjectDataApi,
  ProjectStagesDataApi,
  picklistDropDownApis,
  picklistsDataApis
} from '../../../services/ApiService';
import { ERROR, FEE_TYPES, GET, IGN_API, POST, PUT, ROUTES, SUCCESS, WARNING } from '../../../services/constantService';
import { commonActions } from '../../../store/commonSlice';
import { updateViewProjectData } from '../../../store/viewProjectSlice';
import useApi from '../../../utils/Hooks/useApiHook';
import withRouter from '../../../utils/withRouter';
import ChooseFromContact from './Comman/ChooseFromContact';
import { ProjectDataContext } from './Context';
import HeaderClosed from './HeaderClosed';
import HeaderOpened from './HeaderOpened';
import ProjectTabLayout from './ProjectTabLayout';

import { debounce } from 'lodash';

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const NewFields = ({ activeStage, stages, setValue, setActiveStage }) => {
  const changeOptionOnLoad = useRef(null);

  useEffect(() => {
    if (changeOptionOnLoad.current !== null) {
      setValue('company', changeOptionOnLoad.current);
      changeOptionOnLoad.current = null;
    }
  }, []);

  return (
    <CustomMuiDropdown
      options={
        stages?.map(stage => {
          if (stage.stage_name !== 'All') return { label: stage.stage_name };
        }) || []
      }
      label='Stage'
      value={activeStage?.stage_name !== 'All' ? { label: activeStage?.stage_name } : null}
      renderInput={params => <TextField {...params} variant='outlined' />}
      onChange={(event, newVal) => {
        setActiveStage({ stage_name: newVal?.label });
        // { label: 'KG Interview' }
      }}
      getOptionLabel={option => {
        return option?.label || '';
      }}
    />
  );
};

NewFields.propTypes = {
  setValue: PropTypes.func,
  setAddCompanyPopup: PropTypes.func,
  control: PropTypes.func,
  addCompanyPopup: PropTypes.bool,
  projectData: PropTypes.object,
  activeStage: PropTypes.string,
  stages: PropTypes.object,
  setActiveStage: PropTypes.func
};

function ViewProject(props) {
  const [universalState, setUniversalState] = useState({});
  const [headerExpandedView, setExpandedView] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [projectContactData, setProjectContactData] = useState({});
  const [isPublished, setPublished] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [loader, setLoader] = useState(false);
  const [stage, setStage] = useState(projectData?.stage ?? '');
  const [bdStatus, setBdSatus] = useState(projectData?.bd_status ?? '');
  const [tabIndexSet, setTabIndexSet] = useState([]);
  const location = useLocation();

  const navigate = useNavigate();
  const [isComingFromViewProject, setIsComingFromViewProject] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [showDeletedRows, setShowDeletedRows] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isContactDrawerOpen, setIsContactDrawerOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState('');
  const [stages, setStages] = useState([]);
  const [activeStage, setActiveStage] = useState(null);
  const [dummyText, setDummyText] = useState({
    experience: '',
    leadership: ''
  });
  const [openPipeline, setOpenPipeline] = useState(false);

  const handleContactClick = id => {
    setSelectedContactId(id);
    setIsContactDrawerOpen(true);
  };

  let queryParams = useQuery();
  const projectId = props.params.id;

  const { data: DBData } = useApi({
    queryFn: () => {
      return BDDataApi(GET, projectId, null, null, null);
    }
  });
  const { data: PositionProfileData } = useApi({
    queryFn: () => {
      return PositionProfileDataApi(GET, projectId, null, '', null);
    }
  });

  const fetchTranslate = async () => {
    const res = await picklistsDataApis(GET, 'ROADMAP_HEADER');
    const experience = res?.data?.filter(text => text?.field_value === 'EXPRIENCE');
    const leadership = res?.data?.filter(text => text?.field_value === 'COMPETENCY');

    const dummyText = {
      experience: experience?.[0]?.short_desc ?? '',
      leadership: leadership?.[0]?.short_desc ?? ''
    };

    setDummyText({ ...dummyText });
  };
  useEffect(() => {
    fetchTranslate();
  }, []);

  useEffect(() => {
    const bdbyId = async () => {
      let feeObj = {};
      if (isEmpty(DBData)) {
        return;
      }
      if (!DBData?.data?.fee_type_desc || DBData?.data?.fee_type_desc === FEE_TYPES.ONE_THIRD) {
        feeObj = {
          fee_type_desc: DBData?.data?.fee_type ? DBData?.data?.fee_type : FEE_TYPES.ONE_THIRD,
          fee_percentage: DBData?.data?.fee_percentage ? DBData?.data?.fee_percentage : 33.33
        };
      }
      setCurrentData({
        ...DBData?.data,
        intervals_desc: DBData?.data?.intervals_desc ? DBData?.data?.intervals_desc : DBData?.data?.intervals,
        project_indirect_fees: DBData?.data?.project_indirect_fees?.length ? DBData?.data?.project_indirect_fees : [{}],
        estimated_percentage_bases: DBData?.data?.estimated_percentage_bases?.length ? DBData?.data?.estimated_percentage_bases : [{}],
        recruiters: DBData?.data?.recruiters?.length ? DBData?.data?.recruiters : [{}],
        researchers: DBData?.data?.researchers?.length ? DBData?.data?.researchers : [{}],
        basic_partners: DBData?.data?.partners ? DBData?.data?.partners.map(partner => partner.user)?.filter(user => user) : [],
        client_team: DBData?.data?.client_team ? DBData?.data?.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
        eas: DBData?.data?.eas?.length ? DBData?.data?.eas : [{}],
        partners: DBData?.data?.partners ? DBData?.data?.partners : [],
        billing_desc: DBData?.data?.billing_desc ? DBData?.data?.billing_desc : '',
        bd_status: DBData?.data?.bd_status ? DBData?.data?.bd_status : 'target_identified',
        actual_percentage_bases: DBData?.data?.actual_percentage_bases?.length ? DBData?.data?.actual_percentage_bases : [{}],
        ...feeObj
      });
    };
    bdbyId();
  }, [DBData]);

  useEffect(() => {
    const getCompanyList = async () => {
      const sub_route = 'companies-picklist';
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if (status === 200) {
        setCompanyList(data);
      }
    };
    getCompanyList();
  }, [projectId]);

  const getBD = async id => {
    try {
      if (id) {
        const { status, data } = await BDDataApi(GET, id);
        if (status === 200) {
          let feeObj = {};
          if (!data.fee_type_desc || data.fee_type_desc === FEE_TYPES.ONE_THIRD) {
            feeObj = {
              fee_type_desc: FEE_TYPES.ONE_THIRD,
              fee_percentage: 33.33
            };
          }
          setCurrentData({
            ...data,
            intervals_desc: data.intervals_desc ? data.intervals_desc : data.intervals,
            project_indirect_fees: data.project_indirect_fees?.length ? data.project_indirect_fees : [{}],
            estimated_percentage_bases: data.estimated_percentage_bases?.length ? data.estimated_percentage_bases : [{}],
            recruiters: data.recruiters?.length ? data.recruiters : [{}],
            researchers: data.researchers?.length ? data.researchers : [{}],
            basic_partners: data.partners ? data.partners.map(partner => partner.user)?.filter(user => user) : [],
            client_team: data.client_team ? data.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
            eas: data.eas?.length ? data.eas : [{}],
            partners: data.partners ? data.partners : [],
            billing_desc: data.billing_desc ? data.billing_desc : '',
            bd_status: data.bd_status ? data.bd_status : 'target_identified',
            ...feeObj
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(error, 'error');
    }
  };

  const fetchProjectData = async () => {
    try {
      setLoader(true);
      const res = await ProjectDataApi(GET, projectId, null, null, null);
      if (res?.data) {
        const data = res?.data?.clientContacts || [];
        const hiringManager = data.filter(data => data?.client_contact_types?.findIndex(type => type?.rel_type === 'Hiring Manager') !== -1);
        const clientManager = data.filter(data => data?.client_contact_types?.findIndex(type => type?.rel_type === 'Hiring Manager') === -1);

        setProjectContactData({
          hiring_manager: hiringManager,
          client_contact: clientManager
        });
        setProjectData(res?.data);
      } else {
        throw new Error('Not Found');
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
      setRefresh(true);
    }
  };
  const projectCompany = { id: projectData?.ign_companies?.id, name: projectData?.ign_companies?.name };

  const dispatch = useDispatch();

  const fetchColorLabels = async () => {
    const candidateColorsData = await CandidateColorsDataApi(GET, queryParams.get('id'));
    dispatch(updateViewProjectData({ key: 'selectedColor', data: null }));
    dispatch(updateViewProjectData({ key: 'colors', data: candidateColorsData?.data || [] }));
  };

  useEffect(() => {
    fetchColorLabels();
  }, [projectId]);

  useEffect(() => {
    fetchProjectData();
  }, [tabIndexSet]);

  const handlePublishButton = e => {
    e.preventDefault();
    setPublished(!isPublished);
  };

  const handleUniversalState = event => {
    const key = event.target.name;
    const value = event.target.value;
    universalState[key] = value;
    setUniversalState(Object.assign({}, universalState));
  };

  const getValueFromUniversalState = key => {
    return universalState[key] ?? '';
  };

  const getUniversalState = () => {
    return universalState;
  };

  const handleTabChanges = (tabIndex, childTabIndex) => {
    setTabIndexSet([tabIndex, childTabIndex]);
  };

  const { data: stageResponse } = useApi({
    queryFn: () => {
      return ProjectStagesDataApi(GET);
    }
  });

  const fetchCandidateStages = () => {
    CandidateStageSetupAPi(GET, `/all?projectId=${projectData?.id ? projectData?.id : queryParams.get('id')}&showLogicalStages=true`).then(res => {
      setStages(res.data);
      if (activeStage) {
        const stage = res.data.find(stage => stage.stage_name === activeStage.stage_name);
        setActiveStage(stage);
      } else {
        setActiveStage(res.data?.[0]);
      }
    });
  };

  useEffect(() => {
    fetchCandidateStages();
  }, []);

  useEffect(() => {
    setStage(projectData?.stage ?? DBData?.data?.stage ?? '');
    setBdSatus(projectData?.bd_status ?? DBData?.data?.bd_status ?? '');
  }, [projectData, DBData]);

  const handleStageChange = async value => {
    try {
      setLoader(true);
      const payload = {
        id: projectData?.id,
        stage: value
      };
      await ProjectDataApi(PUT, '', payload, '', '');
      setStage(value);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleBdStatusChange = async value => {
    try {
      setLoader(true);
      const payload = {
        id: projectData?.id,
        bd_status: value
      };
      await ProjectDataApi(PUT, '', payload, '', '');
      setBdSatus(value);
      setCurrentData(prev => ({ ...prev, bd_status: value }));
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (location?.state?.draft || isComingFromViewProject) {
      setIsComingFromViewProject(true);
      if (PositionProfileData && PositionProfileData?.data?.description && PositionProfileData?.data?.description.length > 0) {
        navigate(`${ROUTES.projectDetails}/${projectId}/Overview/Details`);
      }
    }
  }, [PositionProfileData]);

  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const [openCreateFromResume, setOpenCreateFromResume] = useState(false);
  const [openChooseFromContact, setOpenChooseFromContact] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const { register, handleSubmit, setValue, control, getValues, watch } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState({});
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);

  useEffect(() => {
    debounceCheckDuplicate();
  }, [
    watch(QUICK_ADD_CONTACT_COMMON_FIELD.firstName),
    watch(QUICK_ADD_CONTACT_COMMON_FIELD.first_name),
    watch(QUICK_ADD_CONTACT_COMMON_FIELD.lastName),
    watch(QUICK_ADD_CONTACT_COMMON_FIELD.last_name),
    watch(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin),
    watch(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url)
  ]);

  const processMatching = duplicateRecord => {
    return duplicateRecord?.data?.exactMatch?.length > 0
      ? { match: duplicateRecord?.data?.exactMatch?.[0], matchType: 'exact' }
      : duplicateRecord?.data?.potentialMatch?.length > 0
        ? { match: duplicateRecord?.data?.potentialMatch?.[0], matchType: 'potential' }
        : { match: null, matchType: null };
  };

  // Memoize the debounced API call
  const debounceCheckDuplicate = useCallback(
    debounce(async () => {
      try {
        const duplicateRecord = await IgnContactFromResumeApi(POST, `${IGN_API.check_contact_duplication}`, getValues());
        const { match, matchType } = processMatching(duplicateRecord);
        if (match && matchType) {
          const errorMessage = match?.id ? (
            <Box>
              We found {matchType == 'exact' ? 'an' : 'a'} {matchType} match for this contact in the system: Please review :{' '}
              <a style={{ color: matchType == 'exact' ? 'yellow' : 'black' }} target='_blank' href={`/contacts/list/all-contacts/${match?.id}`} rel='noreferrer'>
                {match?.name}
              </a>{' '}
            </Box>
          ) : (
            'Duplicate Contact'
          );
          enqueueSnackbar(errorMessage, { variant: matchType == 'exact' ? ERROR : WARNING });
          setDisabled(matchType == 'exact');
        }
      } catch (error) {
        console.error('Unable to check for duplicate', error);
      }
    }, 1200),
    [getValues]
  );

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().nullable().optional(),
    linkedin: yup.string().url().nullable().optional(),
    phonenumber: yup
      .string()
      .transform((value, originalValue) => {
        // Coerce empty string or null to null
        if (originalValue === '' || originalValue === null) return null;
        return value;
      })
      .matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .nullable(),
    jobtitle: yup.string().nullable().optional(),
    company: yup.object().nullable().optional().shape({
      id: yup.string().required(),
      name: yup.string().required()
    })
  });

  const clearInputs = () => {
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.first_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.last_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.lastName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.email, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_emails, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.jobtitle, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title, null);
  };

  const onAddSubmit = (data, selectedStage) => {
    setDisabled(true);
    schema
      .validate(data)
      .then(() => {
        if (!data?.email && !data?.linkedin && !data?.phonenumber) {
          enqueueSnackbar('Email/LinkedIn/Phone number is required.', { variant: ERROR });
          return;
        } else if (!data?.current_job_title && data?.company) {
          enqueueSnackbar('Job-title is required with Company.', { variant: ERROR });
          return;
        } else if (data?.current_job_title && !data?.company) {
          enqueueSnackbar('Company is required with Job-title .', { variant: ERROR });
          return;
        } else {
          setLoader(true);
          ProjectCandidateApi(POST, '/create-from-contact', {
            contact: { ...data, force: true },
            projectId: projectData?.id,
            stage: activeStage.stage_name || selectedStage?.label,
            current_company_id: data.company.id,
            defaultWorkExDates: true
          })
            .then(res => {
              if (res.status === 500) {
                enqueueSnackbar(res.data.message, { variant: ERROR });
              } else {
                enqueueSnackbar('Candidate added successfully', { variant: SUCCESS });
                setOpenQuickAddContact(false);
                clearInputs();
                // Refreshing candidates list.
                refreshDataCbRef.current = false;
                setTimeout(() => {
                  refreshDataCbRef.current = true;
                }, 300);
              }
            })
            .catch(err => {
              setAlert({ open: true, message: err });
            })
            .finally(() => {
              setDisabled(false);
              setLoader(false);
            });
        }
      })
      .catch(err => {
        setAlert({ open: true, message: err.errors[0] });
        setDisabled(false);
      });
    setDisabled(false);
  };

  const handleAddCandidate = option => {
    if (option === 'Quick add') {
      setOpenQuickAddContact(true);
    } else if (option === 'Add from resume') {
      setOpenCreateFromResume(true);
    } else if (option === 'Select existing contact') {
      setOpenChooseFromContact(true);
    }
  };

  const openCandidateDrawer = id => {
    setIsDrawerOpen(true);
    setSelectedCandidate(id);
  };

  const refreshDataCbRef = useRef(null);

  const projectState = useSelector(projectSelector);

  const handleCancel = () => {
    dispatch(
      commonActions.updateProjectData({
        data: {
          onSave: null,
          onContinue: null,
          showUnsavedModal: false
        }
      })
    );
  };

  const handleConfirm = async () => {
    try {
      const res = await projectState?.onSave?.();
      if (res) {
        projectState?.onContinue?.();
        dispatch(
          commonActions.updateProjectData({
            data: {
              onSave: null,
              onContinue: null,
              showUnsavedModal: false
            }
          })
        );
      } else {
        dispatch(
          commonActions.updateProjectData({
            data: {
              onSave: null,
              onContinue: null,
              showUnsavedModal: false
            }
          })
        );
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: ERROR });
    }
  };

  return (
    <ProjectDataContext.Provider
      value={{
        projectData: {
          ...projectData,
          ...projectContactData
        },
        isBD: projectData?.record_type === 'Business Development',
        bgData: currentData,
        saveInitiate: false,
        universalState: universalState,
        tabIndexSet,
        handleUniversalState,
        getUniversalState,
        getValueFromUniversalState,
        setUniversalState,
        handleTabChanges,
        setProjectData,
        fetchProjectData,
        companyList,
        dummyText,
        showDeletedRows,
        setShowDeletedRows,
        refresh,
        setRefresh
      }}
    >
      <ConfirmPopup
        show={projectState?.showUnsavedModal || false}
        headerStyle={{ background: '#f5f5f6' }}
        message={t('project.unsaved_changes')}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        onClose={handleCancel}
        cancelText={t('actions.goBack')}
        confirmText={t('actions.saveAndContinue')}
        buttonSize='small'
      />
      <Box className='set-background'>
        <Loader show={loader}></Loader>

        <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={selectedCandidate?.contact_id} />

        <QuickAddContact
          open={openQuickAddContact}
          setOpen={setOpenQuickAddContact}
          label={activeStage?.stage_name}
          projectId={projectData?.id}
          useFormController={{ register, handleSubmit, setValue, control, getValues }}
          newFields={() => (
            <NewFields
              setActiveStage={setActiveStage}
              activeStage={activeStage}
              stages={stages}
              setStages={setStages}
              projectData={projectData}
              setAddCompanyPopup={setAddCompanyPopup}
              setValue={setValue}
              control={control}
              addCompanyPopup={addCompanyPopup}
            />
          )}
          isAddDisabled={disabled}
          onAddSubmit={onAddSubmit}
          defaultCompany={projectCompany}
        />
        <ResumeParser
          isPopupOpen={openCreateFromResume}
          callback={() => {
            refreshDataCbRef.current = true;
          }}
          handleClose={() => setOpenCreateFromResume(false)}
          createProjectCandidate={true}
          stages={stages}
          stage={activeStage?.stage_name}
          projectId={projectData?.id}
        />
        <ChooseFromContact
          callback={() => {
            refreshDataCbRef.current = true;
            setTimeout(() => {
              refreshDataCbRef.current = false;
            }, 300);
          }}
          stages={stages}
          open={openChooseFromContact}
          setOpen={setOpenChooseFromContact}
          openCandidateDrawer={openCandidateDrawer}
          initStage={activeStage?.stage_name}
          projectId={projectData?.id}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert?.open === true}
          onClose={() => setAlert(alertOld => ({ ...alertOld, open: false }))}
          message={alert?.message}
          key={0}
        />
        {headerExpandedView && (
          <Box className='pt-2' sx={{ marginRight: '2rem' }}>
            <HeaderOpened
              handleContactClick={handleContactClick}
              handleExpand={() => setExpandedView(false)}
              isPublished={isPublished}
              stages={stageResponse?.data ?? []}
              handleStageChange={handleStageChange}
              handleBdStatusChange={handleBdStatusChange}
              handlePublish={handlePublishButton}
              stage={stage}
              bdStatus={bdStatus}
              handleAddCandidate={handleAddCandidate}
              fetchProjectData={fetchProjectData}
            />
          </Box>
        )}
        {!headerExpandedView && (
          <Box className='pt-2' sx={{ marginRight: '1.5rem' }}>
            <HeaderClosed
              handleContactClick={handleContactClick}
              handleExpand={() => setExpandedView(true)}
              isPublished={isPublished}
              stages={stageResponse?.data ?? []}
              handlePublish={handlePublishButton}
              handleStageChange={handleStageChange}
              handleBdStatusChange={handleBdStatusChange}
              handleAddCandidate={handleAddCandidate}
              stage={stage}
              bdStatus={bdStatus}
            />
          </Box>
        )}
        <>
          <ProjectTabLayout
            data={{ ...projectData, ...projectContactData }}
            getValueFromUniversalState={getValueFromUniversalState}
            handleUniversalState={handleUniversalState}
            DBData={currentData}
            getBD={getBD}
            setCurrentData={setCurrentData}
            PositionProfileData={PositionProfileData}
            refreshDataCbRef={refreshDataCbRef}
            paramsProjectId={projectId}
            openPipelineTab={openPipeline}
          />
          {selectedContactId && isContactDrawerOpen && (
            <ViewContactDrawer
              navigateToAllContacts={false}
              isDrawerOpen={isContactDrawerOpen}
              setIsDrawerOpen={() => {
                setIsContactDrawerOpen(false);
              }}
              id={selectedContactId}
            />
          )}
        </>
      </Box>
    </ProjectDataContext.Provider>
  );
}

ViewProject.propTypes = {
  params: PropTypes.object
};

export default withRouter(ViewProject);
