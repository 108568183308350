import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isButtonClicked: false
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    toggleButton(state, action) {
      state.isButtonClicked = action.payload;
    },
    resetProjectState: () => initialState
  }
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
