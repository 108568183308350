import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import askIgnyteIcon from '../../assets/images/askIgnyte.svg';
import askIgnyteFullLogo from '../../assets/images/askIgnyteHeaderLogo.png';
import ValidatePermit from '../../security/ValidatePermit';
import { getUserSelector, projectSelector } from '../../selectors';
import { commonActions } from '../../store/commonSlice';
import { useNavItems } from '../../utils/Hooks/useNavItems';
import withRouter from '../../utils/withRouter';
import ChatPopup from '../AskIgynte';
import './index.scss';

function SideNav(props) {
  // const location = useLocation()
  const navItems = useNavItems();
  const { t } = useTranslation();
  const { location, showChatBot /* clientSuite */ } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isIconOpen, setIsIconOpen] = useState('rotate-icon-closed');
  const [isSideNav, setIsSideNav] = useState('closed');
  /* const [isAdmin, setIsAdmin] = useState(false); */
  const [selected, setSelected] = useState(null);
  // const isShow = true;

  const userData = useSelector(getUserSelector);

  const setSelectedTab = tab => {
    setSelected(tab);
  };

  const findSelectedTab = useCallback(
    path => {
      const tab = navItems.find(item => {
        return item.route === '/' + path;
      });
      return tab ? tab.key : null;
    },
    [navItems]
  );
  const handleChatClick = () => {
    setIsChatOpen(!isChatOpen);
    setSelected('chat');
  };

  useEffect(() => {
    /* if (userData && userData.isAdmin) {
      setIsAdmin(true);
    } */
    const path = location?.pathname.split('/')[1];
    setSelected(findSelectedTab(path));
  }, [userData, location, findSelectedTab]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectState = useSelector(projectSelector);
  const loc = useLocation();

  const checkForUnsavedProjectData = async item => {
    const result = await projectState?.onTabChange(null, projectState?.activeTab, projectState?.activeSubTab, true, projectState?.overview);

    if (result?.modalType === 'show_unsaved_data_popup') {
      const updatedState = {
        showUnsavedModal: true,
        onSave: result?.onSave,
        onContinue: () => {
          handleNavigation(item, true);
        }
      };
      dispatch(
        commonActions.updateProjectData({
          data: updatedState
        })
      );
      return false;
    }

    return true;
  };

  const handleNavigation = async (item, skipCheck = false) => {
    let shouldNavigate = true;
    const isProjectPage = loc?.pathname?.startsWith('/projects/view-project') && /\/(Overview|Roadmap)/.test(loc?.pathname);

    if (!skipCheck && isProjectPage) {
      shouldNavigate = await checkForUnsavedProjectData(item);
    }

    if (shouldNavigate) {
      setSelectedTab(item.key);
      if (item?.target === '_blank') {
        window.open(item.route, '_blank');
      } else {
        navigate(item.route);
      }
    }
  };

  const getNavItems = () => {
    /* After integrate security module and uncomment and change the code accordingly 
    if (isAdmin === false) {
      const deleteditem = { label: "Setup", route: "/productSetUp", key: "productSetUp", logo: SettingsOutlinedIcon };
      navItems.splice(navItems.findIndex((a) => a.key === deleteditem.key), 1);
    } */
    return navItems.map(item => {
      if (isSideNav === 'open') {
        return (
          <ValidatePermit root={true} key={item.key} parent={item.permissionKey} type='hide'>
            <div className={`nav-item m-1 d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={() => handleNavigation(item)}>
              <Typography>
                <item.logo />
              </Typography>
              <div className='label-space nav-content p-1' style={{ whiteSpace: 'break-spaces' }}>
                {item?.label?.toUpperCase()}
              </div>
            </div>
          </ValidatePermit>
        );
      } else {
        return (
          <ValidatePermit root={true} key={item.key} parent={item.permissionKey} type='hide'>
            <Tooltip title={item.label}>
              <div className={`nav-item m-1 d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={() => handleNavigation(item)}>
                <Typography>
                  <item.logo />
                </Typography>
              </div>
            </Tooltip>
          </ValidatePermit>
        );
      }
    });
  };

  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(!isMenuOpen);
      setIsIconOpen('rotate-icon-closed');
      setIsSideNav('closed');
    } else {
      setIsMenuOpen(!isMenuOpen);
      setIsIconOpen('rotate-icon-open');
      setIsSideNav('open');
    }
  };
  return (
    <>
      <div className={`side-nav-container ${isSideNav}`}>
        <div
          className='side-nav-items m-auto pt-2 flex-column flex-grow-1'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            paddingBottom: '16px'
          }}
        >
          <Stack className='nav-stack'>{getNavItems(navItems)}</Stack>

          {showChatBot && (
            <Stack className='nav-stack'>
              <Tooltip title={t('navMenu.askIgnyte')}>
                <IconButton
                  onClick={handleChatClick}
                  className='chat-toggle-btn'
                  sx={{
                    backgroundColor: 'var(--palette-title-bar-main)',
                    borderRadius: '30px',
                    width: '100%'
                  }}
                >
                  <img
                    src={isSideNav === 'open' ? askIgnyteFullLogo : askIgnyteIcon}
                    alt='Chat'
                    className={selected === 'chat' ? 'active' : ''}
                    style={{
                      width: isSideNav === 'open' ? '100px' : '24px',
                      height: isSideNav === 'open' ? 'auto' : '24px'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </div>
      </div>
      <div className='hamburger-span' onClick={toggleMenu}>
        <ExpandMoreRoundedIcon cursor='pointer' className={`material-icons-round arrow-icon fs-20 ${isIconOpen}`} />
      </div>
      <ChatPopup open={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
}

SideNav.propTypes = {
  location: PropTypes.object,
  clientSuite: PropTypes.bool,
  showChatBot: PropTypes.bool
};

export default withRouter(SideNav);
