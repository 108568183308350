import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGrid } from './FormGrid';
function AddContactDrawerSectionEleven(props) {
  const { setValue, watch } = props;

  const columns = [
    {
      id: 'highlights',
      // label: 'Highlights',
      defaultValue: watch('highlights'),
      onChange: value => {
        setValue('highlights', value);
        setValue('is_touched', {
          ...watch('is_touched'),
          highlights: true
        });
      },
      type: 'richText',
      size: 'small',
      required: false,
      error: false,
      xs: 12
    }
  ];
  return (
    <Stack>
      <FormGrid columns={columns} gap={1} rowGap={3} />
    </Stack>
  );
}

export default AddContactDrawerSectionEleven;

AddContactDrawerSectionEleven.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired
};
