import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Box, TableCell, TableRow } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import Search from '../../Containers/Contacts/Search';
import { picklistsDataApis } from '../../services/ApiService';
import { ERROR, GET, PICKLISTS } from '../../services/constantService';
import useApi from '../../utils/Hooks/useApiHook';
import CustomDropdown from '../common/CustomDropdown';
import CustomPhoneInput from '../common/CustomPhoneInput';
import CustomTable from '../common/CustomTable';
import CustomInputField from '../common/StyledComponents/CustomInputField';
import ViewContactDrawer from '../ViewContactDrawer';
import './index.scss';

const ClientContacts = props => {
  const { setValue, projectData, updateField } = props;
  const [clientContacts, setClientContacts] = useState([{}]);
  const [isEditingContact, setIsEditingContact] = useState(true);
  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const contacts = clientContacts?.filter(i => Object.keys(i).length > 0);
    setValue('project_client_team', contacts);
  }, [clientContacts, setValue]);

  useEffect(() => {
    if (projectData?.project_client_team && projectData?.project_client_team[0]?.contact) {
      setClientContacts(projectData?.project_client_team || []);
    }
  }, [projectData?.project_client_team]);

  const {
    data: clientContactTypeResponse
    // success: isJobTypeResponse
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.CLIENT_RELATIONSHIP);
    }
  });

  const handleEditContactChange = value => {
    setIsEditingContact(value);
  };

  const handleRemoveClientContact = index => {
    let existingClientContacts = [...clientContacts];
    existingClientContacts.splice(index, 1);
    setClientContacts(existingClientContacts);
  };
  const handleAddClientContact = () => {
    let newClientContacts = [...clientContacts, {}];
    setClientContacts(newClientContacts);
  };

  const handleSearchChange = (value, index) => {
    handleChangeContactOpenSearch(value, index);
  };

  const handleChangeContactOpenSearch = async (clientData, index) => {
    try {
      if (clientData) {
        const matchingContact = clientContacts.filter(item => item.contact_id == clientData.id);
        if (matchingContact && matchingContact?.length > 0) {
          enqueueSnackbar('Client contact already exists.', { variant: ERROR });
          return;
        }
        // const primaryEmail = clientContactData.contact_emails.find(emailObj => emailObj.is_primary === true);
        // const primaryPhone = clientContactData.contact_phones.find(emailObj => emailObj.is_primary === true);
        const primaryEmail = clientData.contact_emails;
        const primaryPhone = clientData.contact_phones;
        let updatedContact = {
          // id: uniqueId(),
          name: `${clientData?.first_name} ${clientData?.last_name}`,
          contact_emails: primaryEmail || [],
          contact_phones: primaryPhone || [],
          current_job_title: clientData?.current_job_title,
          project_id: projectData.id,
          contact_id: clientData.id,
          hiring_mgr: false,
          contact: {
            id: clientData?.id,
            name: `${clientData?.first_name} ${clientData?.last_name}`,
            contact_emails: primaryEmail || [],
            contact_phones: primaryPhone || [],
            current_job_title: clientData?.current_job_title,
            project_id: projectData.id,
            contact_id: clientData.id
          }
        };
        const updatedClientContact = [...clientContacts];

        updatedClientContact.splice(index, 1, updatedContact);
        setClientContacts(updatedClientContact);
        // updatedClientContact.pop();
      }
    } catch (err) {
      console.log('error in getCompanyDetails::', err);
    }
  };

  const handleClientContactTypeChange = async (index, types) => {
    let tempClientContacts = clientContacts;
    tempClientContacts[index].client_contact_types = types.map(type => {
      return { client_team_id: tempClientContacts[index]?.id, rel_type: type.short_desc, created_at: new Date(), contact_id: tempClientContacts[index]?.contact_id };
    });
    setClientContacts([...tempClientContacts]);
  };

  return (
    <div id='view-company' className={'custom-scrollbar'}>
      <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'>
          <Box className='section-details'>
            <Box className='communication-section'>
              <Box className='mt-4'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input'>
                    <CustomTable
                      headerData={['Name', 'Email', 'Phone Number', 'Job Title', 'Type']}
                      title={'Client Contact'}
                      handleAnotherRow={handleAddClientContact}
                      rows={clientContacts}
                      isEditing={isEditingContact}
                      showAddAnotherButton={false}
                      onEditChange={handleEditContactChange}
                      customTableId={'view-contact-content-full'}
                      headerSize={'fs-12'}
                      titleSize={'fs-16 p-2'}
                      isDrawer={true}
                      handleAddContact={() => {
                        setOpenQuickAddContact(true);
                      }}
                      headerClassName={'client-contact-header-text fs-14 fs-bold'}
                      updateField={updateField}
                      //dataList={phoneList}
                      //handleCloseClick={handleCloseClick}
                      saveDataKey={'client_team'}
                      className={'w-100 my-2'}
                    >
                      {isEditingContact ? (
                        clientContacts?.length === 0 ? (
                          <TableRow>
                            <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          clientContacts?.map((contact, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                                  <Box
                                    className='fs-11'
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setSelectedCandidate(contact);
                                      setIsDrawerOpen(true);
                                    }}
                                  >
                                    {contact?.contact?.name || '-'}
                                  </Box>
                                </TableCell>
                                <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                                  <Box className='fs-11'>{contact?.contact?.contact_emails.filter(item => item.is_primary)[0]?.email || '-'}</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                                  <Box className='fs-11'>
                                    {contact?.contact?.contact_phones ? (
                                      <div className=''>
                                        <CustomPhoneInput
                                          className='phone-input-read-only d-inline-block'
                                          id='outlined-basic'
                                          variant='outlined'
                                          size='small'
                                          value={contact?.contact?.contact_phones.filter(item => item.is_primary)[0]?.phone_number}
                                          onChange={() => {}}
                                          disabled={true}
                                        />
                                        <span className='text-truncate'>{formatPhoneNumber(contact?.contact?.contact_phones.filter(item => item.is_primary)[0]?.phone_number)}</span>
                                      </div>
                                    ) : (
                                      '-'
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                                  <Box className='fs-11'>{contact?.contact?.current_job_title || '-'}</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                                  <Box className='fs-11'>
                                    {' '}
                                    {contact?.client_contact_types?.length > 0
                                      ? contact?.client_contact_types.map((item, index) => {
                                          return item.rel_type + (index != contact?.client_contact_types.length - 1 ? ', ' : '');
                                        })
                                      : '-'}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )
                      ) : (
                        clientContacts?.map((phoneNumber, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className='viewcontact-icon-style p-2' align='center' style={{ minWidth: 250 }}>
                                <Box className=''>
                                  <SearchHandling placeholder={t('common.search')} handleChange={handleSearchChange} index={index} name={phoneNumber?.contact?.name} phoneNumber={phoneNumber} />
                                </Box>
                              </TableCell>
                              <TableCell align='center' className='p-2' style={{ minWidth: 250 }}>
                                <CustomInputField
                                  // type="number"
                                  disabled={true}
                                  value={phoneNumber?.contact?.contact_emails.filter(item => item.is_primary)[0]?.email || ''}
                                  // onChange={(e) =>
                                  //   handleChangePhoneNumber(index, "phone_number", e.target.value)
                                  // }
                                  label={'Email'}
                                  className={'w-100'}
                                ></CustomInputField>
                              </TableCell>
                              <TableCell align='center' className='primary-checkbox p-2' style={{ minWidth: 250 }}>
                                <CustomPhoneInput
                                  id='outlined-basic'
                                  variant='outlined'
                                  size='small'
                                  label={'Phone number'}
                                  disabled={true}
                                  value={phoneNumber?.contact?.contact_phones.filter(item => item.is_primary)[0]?.phone_number || ''}
                                />
                              </TableCell>
                              <TableCell align='center' className='primary-checkbox p-2' style={{ minWidth: 250 }}>
                                <CustomInputField
                                  disabled={true}
                                  //type="number"
                                  value={phoneNumber?.contact?.current_job_title || ''}
                                  // onChange={(e) =>
                                  //   handleChangePhoneNumber(index, "phone_number", e.target.value)
                                  // }
                                  label={'Job Title'}
                                ></CustomInputField>
                              </TableCell>
                              <TableCell className='viewcontact-icon-style p-2' align='center' style={{ minWidth: 250 }}>
                                <Box className='contact-selection'>
                                  <CustomDropdown
                                    options={clientContactTypeResponse?.data ?? []}
                                    value={
                                      phoneNumber?.client_contact_types?.map(item => {
                                        return { short_desc: item.rel_type };
                                      }) ?? []
                                    }
                                    size='small'
                                    multiple={true}
                                    onChange={async (event, data) => {
                                      await handleClientContactTypeChange(index, data);
                                    }}
                                    className={'w-100'}
                                    selectedValue={phoneNumber?.client_contact_type?.map(item => item.rel_type) ?? []}
                                  />
                                </Box>
                              </TableCell>
                              {clientContacts?.length > 1 && (
                                <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                  <CloseIcon onClick={() => handleRemoveClientContact(index)} className='table-close-icon' />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      )}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box id='view-contact-drawer' zIndex={100000000}>
        <>
          {isDrawerOpen && (
            <ViewContactDrawer id={selectedCandidate.contact_id} project_id={projectData.id} navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
          )}
        </>
      </Box>
    </div>
  );
};

ClientContacts.propTypes = {
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  getTotalPercentageOfBase: PropTypes.func,
  watch: PropTypes.func,
  type: PropTypes.string,
  projectData: PropTypes.object,
  updateField: PropTypes.func
};

const SearchHandling = props => {
  const { name, index, handleChange, placeholder = 'Search' } = props;
  const [open, setOpen] = useState(false);
  return (
    <div style={{ width: '300px' }} className='search-bar'>
      {open ? (
        <Search
          value={name}
          onChange={(e, val) => {
            handleChange(val, index);
            if (val) {
              setOpen(false);
            }
          }}
          type={'contacts'}
          placeholder={placeholder}
          showSearchIcon={false}
        />
      ) : (
        <Box
          size='small'
          style={{ color: '#aaa' }}
          className={'bg-light rounded text-start py-2 px-4'}
          fullWidth
          onClick={() => {
            setOpen(true);
          }}
        >
          {name || 'Search'}
        </Box>
      )}
    </div>
  );
};

SearchHandling.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default ClientContacts;
