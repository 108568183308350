import PropTypes from 'prop-types';

import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { CircularProgress, ClickAwayListener, IconButton, MenuItem, Paper, Popper, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { picklistDropDownApis } from '../../../../services/ApiService';

const ProjectSelection = props => {
  const { label, className, onChange = () => {}, defaultValue, size = 'small', onInputChange = () => {}, required } = props;
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setSearch(defaultValue);
    }
  }, [defaultValue]);

  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const params = { projectsType: 'all-projects', ...(search && { name: encodeURIComponent(search) }) };
      const { data } = await picklistDropDownApis('get', 'quicksearches', {}, params);

      setOptions(
        (data?.data || []).map(project => ({
          ...project,
          name: `${project?.job_number} - ${project?.job_title || '()'}`
        }))
      );
    } catch (err) {
      console.error('Error fetching projects:', err);
    } finally {
      setLoading(false);
    }
  }, [search]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProjects();
    }, 500);

    return () => clearTimeout(timer);
  }, [fetchProjects]);

  // useEffect(() => {
  //   if (props?.project_id && options?.length) {
  //     const selectedOpt = options.find(option => {
  //       return option?.id === props?.project_id;
  //     });
  //     if (selectedOpt) {
  //       onChange && onChange(null, selectedOpt);
  //     }
  //   }
  // }, [options, props]);

  const handleSelect = (e, option) => {
    setSelectedOption(option);
    setOpen(false);
    onChange && onChange(e, option);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ position: 'relative', width: '100%' }}>
        <TextField
          size={size}
          fullWidth
          variant='outlined'
          value={selectedOption ? selectedOption.name : search}
          className={`AutoCompleteStyle animate-icon ${className}`}
          required={required}
          onChange={e => {
            setSearch(e.target.value);
            setSelectedOption(null);
            onInputChange && onInputChange(e);
          }}
          label={label}
          onFocus={e => {
            setAnchorEl(e.currentTarget);
            setOpen(true);
          }}
          InputProps={{
            endAdornment: (
              <IconButton size='small' className='mr-1'>
                {(selectedOption || search) && (
                  <Close
                    onClick={() => {
                      setSelectedOption(null);
                      setSearch('');
                    }}
                    fontSize='small'
                  />
                )}
                {open ? <ExpandLess onClick={() => setOpen(!open)} fontSize='medium' /> : <ExpandMore onClick={() => setOpen(!open)} fontSize='medium' />}
              </IconButton>
            )
          }}
        />
        <Popper open={open} anchorEl={anchorEl} placement='bottom-start' sx={{ width: anchorEl?.parentElement?.offsetWidth || 'auto', zIndex: 1300, position: 'absolute' }}>
          <Paper elevation={3} style={{ maxHeight: 300, overflowY: 'auto', width: '100%' }}>
            {loading ? (
              <MenuItem disabled style={{ fontSize: '12px', padding: '6px 12px' }}>
                <CircularProgress size={20} />
              </MenuItem>
            ) : options.length > 0 ? (
              options.map((option, index) => (
                <MenuItem key={index} onClick={e => handleSelect(e, option)} style={{ fontSize: '12px', padding: '6px 12px' }}>
                  <Typography variant='caption' ml={2} style={{ fontSize: '17px' }}>
                    {option?.name}
                  </Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled style={{ fontSize: '12px', padding: '6px 12px' }}>
                &nbsp;
              </MenuItem>
            )}
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

ProjectSelection.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  project_id: PropTypes.string,
  editActivity: PropTypes.any,
  size: PropTypes.string,
  onInputChange: PropTypes.func,
  required: PropTypes.bool
};

export default ProjectSelection;
