import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';
import * as momentTimeZone from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomMuiDropdown from '../../components/common/CustomMuiDropdown';
import { getUserSelector } from '../../selectors';
import { userConfigs } from '../../services/ApiService';
import { PATCH } from '../../services/constantService';
import { updateProfileFormat } from '../../store/profileFormatSlice';
function ProfilePage() {
  const profileFormat = useSelector(state => state.profileFormatSlice);
  const userData = useSelector(getUserSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [localProfileFormat, setLocalProfileFormat] = useState({
    dateFormat: profileFormat.dateFormat,
    timeFormats: profileFormat.timeFormats,
    timeZoneFormat: profileFormat.timeZoneFormat,
    language: profileFormat.language
  });

  const timezoneOption = momentTimeZone.tz.names();
  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' },
    { label: 'Portuguese', value: 'esp' }
  ];
  const handleUpdateProfile = async () => {
    try {
      await userConfigs(PATCH, {
        userId: userData.id || '',
        time_zone: localProfileFormat.timeZoneFormat?.value,
        date_format: localProfileFormat.dateFormat?.value,
        time_format: localProfileFormat.timeFormats?.value,
        language: localProfileFormat.language?.value
      });
      dispatch(updateProfileFormat(localProfileFormat));
      enqueueSnackbar('Profile Updated Successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating profile', { variant: 'error' });
    }
  };
  return (
    <Stack
      spacing={4}
      sx={{
        width: '100%',
        height: '100%',
        padding: 2
      }}
      bgcolor={'Background'}
    >
      <Stack direction='row' gap={2} alignItems='center'>
        <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }}>
          <KeyboardBackspaceOutlinedIcon />
        </Box>
        <Typography variant='h6' fontWeight='bold'>
          {t('utils.profile')}
        </Typography>
      </Stack>
      <Stack direction='row' gap={4} alignItems='center'>
        <Avatar sx={{ width: 100, height: 100 }} />
        <Stack gap={0.5}>
          <Typography variant='h6' fontWeight={'bold'}>
            {userData?.name || ''}
          </Typography>
          <Typography variant='p'>{userData?.email || ''}</Typography>
          <Typography variant='p'>{userData?.type || ''}</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Typography
        variant='h6'
        sx={{
          margin: 2,
          fontWeight: 'bold'
        }}
      >
        {t('profile.preferences')}
      </Typography>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          {t('profile.dateFormat')}
        </Typography>
        <CustomMuiDropdown
          options={[
            { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
            { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' }
          ]}
          label=''
          value={localProfileFormat?.dateFormat}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setLocalProfileFormat(prev => ({ ...prev, dateFormat: value }));
          }}
        />
      </Stack>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          {t('profile.timeFormat')}
        </Typography>
        <CustomMuiDropdown
          options={[
            { label: '12-hours', value: '12-hours' },
            { label: '24-hours', value: '24-hours' }
          ]}
          label=''
          value={localProfileFormat?.timeFormats}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setLocalProfileFormat(prev => ({ ...prev, timeFormats: value }));
          }}
        />
      </Stack>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          {t('profile.timeZone')}
        </Typography>
        <CustomMuiDropdown
          options={timezoneOption.map(timezone => ({
            label: timezone,
            value: timezone
          }))}
          label=''
          value={localProfileFormat?.timeZoneFormat}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setLocalProfileFormat(prev => ({ ...prev, timeZoneFormat: value }));
          }}
        />
      </Stack>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          {t('reports.language')}
        </Typography>
        <CustomMuiDropdown
          options={languageOptions}
          label=''
          value={localProfileFormat?.language}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setLocalProfileFormat(prev => ({ ...prev, language: value }));
          }}
        />
      </Stack>
      <Stack
        sx={{
          justifyContent: 'right',
          flexDirection: 'row'
        }}
      >
        <Button
          variant='outlined'
          color='primary'
          onClick={handleUpdateProfile}
          sx={{
            width: '300px',
            height: '40px'
          }}
        >
          Save and Refresh
        </Button>
      </Stack>
    </Stack>
  );
}

export default ProfilePage;
