import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import '../../../Containers/Commons/DatePickerStyle/index.scss';
const DatePickerCommon = props => {
  const {
    value,
    onChange,
    label = '',
    color = 'heading',
    className,
    maxDate,
    minDate,
    disabled,
    disableFuture,
    onError,
    slotProps,
    required = false,
    isDefaultDatePickerStyle = true,
    hideLabel = false,
    slots = {},
    ...res
  } = props;
  const { dateFormat } = useSelector(state => state.profileFormatSlice);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} {...res}>
      <DatePicker
        {...res}
        defaultValue={value ? dayjs(value, dateFormat?.value) : null}
        value={value ? dayjs(value) : null}
        color={color}
        keyboardIcon={<CalendarMonthIcon></CalendarMonthIcon>}
        onChange={onChange}
        InputProps={{
          disableUnderline: true
        }}
        slots={slots}
        format={dateFormat?.value}
        className={`${isDefaultDatePickerStyle ? 'DatePickerStyle' : ''} ${className}`}
        label={
          hideLabel ? (
            ''
          ) : required ? (
            <Box component='span' className='required'>
              {label}
            </Box>
          ) : (
            label
          )
        }
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        disableFuture={disableFuture}
        onError={onError}
        slotProps={slotProps}
      />
    </LocalizationProvider>
  );
};

DatePickerCommon.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  onError: PropTypes.func,
  slotProps: PropTypes.object,
  required: PropTypes.bool,
  isDefaultDatePickerStyle: PropTypes.bool,
  hideLabel: PropTypes.bool,
  slots: PropTypes.object
};

export default DatePickerCommon;
