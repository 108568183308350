import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGrid } from './FormGrid';

function AddContactDrawerSectionEight(props) {
  const { setValue, watch, jobFunctionList, loadingDropdownData } = props;

  const columns = [
    {
      id: 'contact_job_functions',
      label: 'addContactDrawer.jobFunction',
      type: 'dropdown',
      size: 'small',
      required: false,
      error: false,
      xs: 12,
      multiple: true,
      value: watch('contact_job_functions') || [],
      options: jobFunctionList,
      loading: loadingDropdownData,
      getOptionLabel: option => option.name,
      onChange: (e, value) => {
        setValue('contact_job_functions', value);
        setValue('is_touched', {
          ...watch('is_touched'),
          contact_job_functions: true
        });
      },
      autoCompleteProps: {
        disableCloseOnSelect: true
      },
      renderTags: (value, getTagProps) =>
        value?.map((option, index) => ({
          key: option.id,
          id: option.id,
          label: option.name,
          ...getTagProps({ index })
        }))
    }
  ];

  return (
    <Grid container px={1} pb={0}>
      <Grid item xs={12}>
        <FormGrid columns={columns} gap={1} rowGap={3} />
      </Grid>
    </Grid>
  );
}

AddContactDrawerSectionEight.propTypes = {
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  jobFunctionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired
};

export default AddContactDrawerSectionEight;
