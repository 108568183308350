import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import ColorDropdowns from '../../../../../components/ColorLegends/Workbenches/ColorDropdownSelection';
import RouteOpener from '../../../../../components/common/RouteToNewPage';
import ViewContactDrawer from '../../../../../components/ViewContactDrawer';
import { ignWorkbenchDataApi } from '../../../../../services/ApiService';
import { PUT, ROUTES } from '../../../../../services/constantService';
import { CustomActionRenderer } from '../../../../../utils/ActionRenderer';
import AgGridWrapper from '../../../../../utils/AgGridWrapper';
import { AthenaEmailInviteModal } from '../../../../../utils/AthenaInvite';
import { LinkedInRenderer } from '../../../../Commons/Utils';
import { workbenchContactColumn } from './GridData';
let linkedInPopupHandler;

function ContactGrid({ colors, data, onSelect, handleDeleteContact, gridState, gridType, gridApiRef, savedSortModel, savedFilterModel }) {
  const defaultColumnDefs = useMemo(() => workbenchContactColumn, []);
  const handleProjectColorChange = id => async colorId => {
    const payload = {
      id,
      color_id: colorId
    };

    await ignWorkbenchDataApi(PUT, '', payload, 'update-color-label');
  };

  const tableData = useCallback(() => {
    if (Array.isArray(gridState) && gridState.length) {
      const savedConfigMap = gridState.reduce((acc, column) => {
        acc[column.field] = column;
        return acc;
      }, {});

      const updatedColDefs = defaultColumnDefs.map(col => {
        const savedConfig = savedConfigMap[col.field];
        if (savedConfig) {
          return {
            ...col,
            ...savedConfig
          };
        }
        return col;
      });

      updatedColDefs.sort((a, b) => {
        const indexA = gridState.findIndex(column => column.field === a.field);
        const indexB = gridState.findIndex(column => column.field === b.field);
        return indexA - indexB;
      });

      return updatedColDefs;
    } else {
      return defaultColumnDefs;
    }
  }, [gridState]);

  const sx = {};
  const handleAthenaInviteOnClick = useCallback(data => {
    const contact = {
      ...data.contact
    };

    setAthenaInviteData(contact);
    setAthenaInviteModalOpen(true);
  }, []);
  const actionOptions = [
    {
      name: 'Invite To Athena',
      onClick: elem => handleAthenaInviteOnClick(elem)
    }
  ];

  const handleDelete = useCallback(elem => {
    handleDeleteContact([elem]);
  }, []);
  const otherActionOption = [
    {
      component: Delete,
      onClick: handleDelete
    }
  ];

  const columns = useMemo(() => tableData(), [gridState]);

  const onGridReady = useCallback(params => {
    gridApiRef(params);
  }, []);

  //Athena Invite
  const [athenaInviteModalOpen, setAthenaInviteModalOpen] = useState(false);
  const [athenaInviteData, setAthenaInviteData] = useState();

  //Contact Drawer
  const [selectedContactId, setSelectedContactId] = useState('');

  const routeCompany = id => {
    const path = ROUTES.viewCompanyWithoutCurrentTab.replace(':id', id);
    window.open(path, '_blank');
  };

  const routeContact = id => {
    const path = ROUTES.allContactGridWithDrawerOpen.replace(':id', id);
    window.open(path, '_blank');
  };

  const CompanyRenderer = useCallback(params => {
    return params.value ? <RouteOpener type='company' id={params.data?.contact?.contact_company?.id} label={params.value} capitalize={true} /> : null;
  }, []);

  const ColorRenderer = useCallback(
    params => {
      return <ColorDropdowns colors={colors} onChange={colorId => handleProjectColorChange(params.data?.primaryId)(colorId)} selectedColor={params.data.color_id} />;
    },
    [colors]
  );

  const NameRenderer = useCallback(params => {
    return params.value ? <RouteOpener type='contact' id={params.data?.contact_id} label={params.value} capitalize={true} /> : null;
  }, []);

  const ActionRenderer = useCallback(
    params => {
      return <CustomActionRenderer item={params.data} options={actionOptions} otherButtons={otherActionOption} />;
    },
    [handleAthenaInviteOnClick, handleDelete]
  );
  const LinkedInRenderers = useCallback(params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  }, []);

  const rowData = useMemo(() => {
    return data.map(elem => {
      return {
        ...elem,
        ...elem.contact,
        primaryId: elem?.id || '-',
        email: elem.contact?.contact_emails[0]?.email || '-',
        phone_number: elem.contact?.contact_phones[0]?.phone_number || '-',
        name: elem?.contact?.name || '-',
        company_name: elem.contact?.contact_company?.name,
        country: elem.contact?.contact_country_p1 || '-',
        status: elem?.contact?.contact_status || '-',
        job_title: elem?.contact?.current_job_title || '-',
        owner: elem?.contact?.created_user?.name || '-',
        location: elem?.contact?.contact_address.length > 0 ? elem?.contact?.contact_address.length[0]?.city : '-',
        linkedin_url: elem?.contact?.linkedin_url || '-',
        contact_country_p1: elem?.contact?.contact_country_p1 || '-',
        source: elem?.contact?.source || '-'
      };
    });
  }, [data]);
  return (
    <Stack id='myGrid' className='ag-theme-alpine workbench-table'>
      <AgGridWrapper
        defaultColumnDefs={defaultColumnDefs}
        gridType={gridType}
        columnDefs={columns}
        rowData={rowData}
        sortModel={savedSortModel}
        filterModel={savedFilterModel}
        suppressRowClickSelection={true}
        paginationPageSize={50}
        isRowSelectable={useCallback(() => {
          return true;
        }, [])}
        rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
        enableRangeSelection={true}
        showHeaderMenu={true}
        // headerComponentFramework={CustomHeader}
        {...sx}
        onSelectionChanged={useCallback(event => onSelect(event), [onSelect])}
        columnMenu={true}
        onGridReady={onGridReady}
        enableAdvancedFilter={true}
        render
        rowGroupPanelShow='always'
        saveGridSetting={true}
        components={{
          companyRenderer: CompanyRenderer,
          actionRenderer: ActionRenderer,
          colorRenderer: ColorRenderer,
          nameRenderer: NameRenderer,
          linkedInRenderer: LinkedInRenderers
        }}
      />
      <AthenaEmailInviteModal isOpen={athenaInviteModalOpen} onClose={() => setAthenaInviteModalOpen(false)} contact={athenaInviteData} />
      <ViewContactDrawer isDrawerOpen={selectedContactId.length} setIsDrawerOpen={() => setSelectedContactId('')} navigateToAllContacts={false} id={selectedContactId} />
    </Stack>
  );
}

ContactGrid.propTypes = {
  colors: PropTypes.array,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  gridType: PropTypes.string,
  gridState: PropTypes.array,
  gridApiRef: PropTypes.func,
  savedFilterModel: PropTypes.object,
  savedSortModel: PropTypes.array
};

export default ContactGrid;
