import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dateFormat: {
    label: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY'
  },
  timeFormats: {
    label: '12-hours',
    value: '12-hours'
  },
  timeZoneFormat: {
    label: 'UTC',
    value: 'UTC'
  },
  language: {
    label: 'en',
    value: 'en'
  }
};

const profileFormatSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateDateFormats(state, action) {
      state.dateFormat = action.payload;
    },
    updateTimeFormats(state, action) {
      state.timeFormats = action.payload;
    },
    updateTimeZoneFormat(state, action) {
      state.timeZoneFormat = action.payload;
    },
    updateLanguage(state, action) {
      state.language = action.payload;
    },
    updateProfileFormat(state, action) {
      state.dateFormat = action.payload.dateFormat;
      state.timeFormats = action.payload.timeFormats;
      state.timeZoneFormat = action.payload.timeZoneFormat;
      state.language = action.payload.language;
    }
  }
});

export const { updateDateFormats, updateTimeFormats, updateTimeZoneFormat, updateLanguage, updateProfileFormat } = profileFormatSlice.actions;
// export { profileFormatSlice };

export default profileFormatSlice.reducer;
