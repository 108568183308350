//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const ProjectTypeSelection = props => {
  const { label, className, onChange = () => {}, defaultValue, project_id, ...rest } = props;
  const [options, setOptions] = useState([]);
  const ProjectSelection = useSelector(state => state.commonReducer.project);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ProjectSelection) {
      dispatch(fetchPickListData('project?includeBd=true', 'project'));
    } else {
      setOptions(ProjectSelection.data);
    }
  }, [ProjectSelection, dispatch]);

  useEffect(() => {
    if (options.length && project_id) {
      const selectedOption = options.find(option => {
        return option.id === project_id;
      });
      if (selectedOption) {
        props.onChange(null, selectedOption);
      }
    }
  }, [options, project_id]);

  return <CustomDropdown {...rest} options={options} label={label} className={className} onChange={onChange} value={defaultValue} />;
};

ProjectTypeSelection.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  project_id: PropTypes.string,
  editActivity: PropTypes.any
};

export default ProjectTypeSelection;
